<template>
    <LoaderTemplate :haveData="haveData">
        <template v-slot:content>
            <GhTableCommon
                :extratable="{
                    id: 'assignments'
                }"
                :header="header"
                :data="getterList.support !== undefined && getterList.support['assignments'] !== undefined ? getterList.support['assignments'].data : []"
            >
                <template v-slot:actions="{itemProp}">
                    <template v-if="getterList.support['assignments'].actions.view">
                        <router-link :to="{name: 'config_support_assignments_view', params: {id: itemProp.id, view: true}}">
                            <GhAction
                                :dataction="{
                                    id: 'action_view_assignments_' + itemProp.id,
                                    text: $t('view'),
                                    icon: require('@/assets/gh_new_dessign/view.svg')
                                }"
                            />
                        </router-link>
                    </template>
                    <template v-if="getterList.support['assignments'].actions.modify">
                        <router-link :to="{name: 'config_support_assignments_modify', params: {id: itemProp.id}}">
                            <GhAction
                                :dataction="{
                                    id: 'action_edit_assignments_' + itemProp.id,
                                    text: $t('modify'),
                                    icon: require('@/assets/gh_new_dessign/edit.svg')
                                }"
                            />
                        </router-link>
                    </template>
                    <template v-if="getterList.support['assignments'].actions.delete && itemProp.delete">
                        <GhAction
                            :dataction="{
                                id: 'action_delete_assignments_' + itemProp.id,
                                text: $t('delete'),
                                icon: require('@/assets/gh_new_dessign/trash.svg')
                            }"
                            @click="Global.deleteConfirm('delete_confirm','removeByType', {
                                from: 'support',
                                type: 'assignments',
                                id: itemProp.id
                            },{
                                'group_assignment': itemProp.name,
                                'description': itemProp.description,
                                'users': itemProp.users
                            }, $t('delete_assignment'), $t('preparing_delete_assignment'), {
                                'getListByType': [
                                    {
                                        from: 'support',
                                        type: 'assignments'
                                    },
                                    {
                                        from: 'support',
                                        type: 'priorities'
                                    },
                                    {
                                        from: 'support',
                                        type: 'schedule'
                                    },
                                    {
                                        from: 'support',
                                        type: 'schedule-per-group'
                                    }
                                ],
                                'getCountersByType': {
                                    type: 'support'
                                }
                            })"
                        />
                    </template>
                </template>
                <template v-slot:footer>
                    <template v-if="getterList.support['assignments'].actions.add">
                        <div class="centered">
                            <router-link :to="{name: 'config_support_assignments'}">
                                <GhButton
                                    :datainput="{
                                        id: 'btn_new_assignments',
                                        text: $t('add')
                                    }"
                                />
                            </router-link>
                        </div>
                    </template>
                </template>
            </GhTableCommon>
        </template>
    </LoaderTemplate>
</template>

<script>
    import LoaderTemplate from '@/view/Shared/Loader/loader.vue';
    import GhTableCommon from 'fe-gh-table-common-lib';
    import GhAction from "fe-gh-action-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapGetters} from "vuex";

    export default {
        name: 'list_assignments',
        components: {
            LoaderTemplate,
            GhTableCommon,
            GhAction,
            GhButton
        },
        computed: {
            ...mapGetters(['getterList'])
        },
        data(){
            return {
                header: [
                    {text: 'group_assignment', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                    {text: 'description', field: 'description', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 40%;'},
                    {text: 'users', field: 'users', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 40%;'},
                    {text: '', field: 'actions', sorting: false}
                ],
                haveData: false
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getListByType', {
                from: 'support',
                type: 'assignments'
            }, {root: true});

            this.$nextTick(() => {
                this.haveData = true;
            });
        }
    }
</script>