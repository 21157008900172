<template>
    <LoaderTemplate :haveData="haveData">
        <template v-slot:content>
            <form id="form_data">
                <div class="gh-content-global">
                    <CodeNameDescription
                        :code="{
                            show: true,
                            required: true,
                            read_only: $root._route.params.id !== undefined,
                            value: getterGet.procedure !== undefined && getterGet.procedure['procedure-category'] !== undefined ? getterGet.procedure['procedure-category'].code : ''
                        }"
                        :name="{
                            show: true,
                            required: true,
                            read_only: $root._route.params.id !== undefined,
                            value: getterGet.procedure !== undefined && getterGet.procedure['procedure-category'] !== undefined ? getterGet.procedure['procedure-category'].name : ''
                        }"
                        :description="{
                            show: true,
                            required: false,
                            value: getterGet.procedure !== undefined && getterGet.procedure['procedure-category'] !== undefined ? getterGet.procedure['procedure-category'].description : ''
                        }"
                    />
                    <template v-if="$root._route.params.id !== undefined">
                        <div class="gh-row-content">
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('procedure_subcategory') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{getterGet.procedure !== undefined && getterGet.procedure['procedure-category'] !== undefined ? getterGet.procedure['procedure-category'].procedure_subcategory : ''}}</label>
                        </div>
                    </template>
                </div>
            </form>
        </template>
    </LoaderTemplate>
</template>

<script>
    import LoaderTemplate from '@/view/Shared/Loader/loader.vue';
    import CodeNameDescription from '@/view/Shared/Form/code_name_description.vue';

    import {mapGetters} from "vuex";

    export default {
        name: 'form_procedure_category',
        components: {
            LoaderTemplate,
            CodeNameDescription
        },
        computed: {
            ...mapGetters(['getterGet', 'getterFeed'])
        },
        data(){
            return {
                haveData: false
            }
        },
        async beforeDestroy(){
            await this.$store.dispatch('destroyGetByType', {
                from: 'procedure'
            }, {root: true});
        },
        async beforeMount(){
            if(this.$root._route.params.id !== undefined) {
                await this.$store.dispatch('getByType', {
                    from: 'procedure',
                    type: 'procedure-category',
                    id: this.$root._route.params.id
                }, {root: true});
            }

            this.$nextTick(() => {
                this.haveData = true;
                this.$parent.$parent.$parent.haveData = this.haveData;
            });
        }
    }
</script>

<style>
    #data_select_procedure_category_selector_label, #data_select_department_selector_label{
        width: 150px;
        text-align: right;
    }
</style>