var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LoaderTemplate',{attrs:{"haveData":_vm.haveData},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('form',{attrs:{"id":"form_data"}},[_c('div',{staticClass:"gh-content-global"},[_c('CodeNameDescription',{attrs:{"code":{
                        show: true,
                        required: true,
                        value: _vm.getterGet.general !== undefined && _vm.getterGet.general['constraint-type'] !== undefined ? _vm.getterGet.general['constraint-type'].code : ''
                    },"name":{
                        show: true,
                        required: true,
                        value: _vm.getterGet.general !== undefined && _vm.getterGet.general['constraint-type'] !== undefined ? _vm.getterGet.general['constraint-type'].name : ''
                    },"description":{
                        show: true,
                        required: false,
                        value: _vm.getterGet.general !== undefined && _vm.getterGet.general['constraint-type'] !== undefined ? _vm.getterGet.general['constraint-type'].description : ''
                    }}}),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                                id: 'data_select_constraint_category',
                                class: 'T15_b',
                                subtitle: _vm.$t('select_constraint_category') + ':',
                                text: _vm.$t('constraint_category') + '*',
                                label: _vm.$t('constraint_category'),
                                type: 'radio',
                                columns: 3,
                                style: '',
                                required: true
                            },"datainput":{
                                id: 'value_data_select_constraint_category',
                                name: 'data[constraint_category]'
                            },"selected_input":_vm.getterGet.general !== undefined && _vm.getterGet.general['constraint-type'] !== undefined ? _vm.getterGet.general['constraint-type'].constraint_category : [],"feed":_vm.getterFeed['constraint-category']}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('constraint_category') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(_vm.getterGet.general !== undefined && _vm.getterGet.general['constraint-type'] !== undefined ? Object.values(_vm.getterGet.general['constraint-type'].constraint_category).join(', ') : ''))])]],2)],1)])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }