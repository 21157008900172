import Support from '@/view/Configuration/Support/main.vue';

import Categories from './Categories/router';
import Priorities from './Priorities/router';
import Assignments from './Assignments/router';
import Schedule from './Schedule/router';
import SchedulePerGroup from './SchedulePerGroup/router';
import GeneralSchedule from './GeneralSchedule/router';

const route = [
    {
        path: '/configuration/support',
        name: 'config_support',
        props: {showMenu: true, showUser: true},
        component: Support
    }
];

const routes = [
    ...route,
    ...Categories,
    ...Priorities,
    ...Assignments,
    ...Schedule,
    ...SchedulePerGroup,
    ...GeneralSchedule
];

export default routes;