<template>
    <div>
        <GhMenu ref="refMenu">
            <template v-slot:cabezera>
                <div class="logo_cabecera_square" style="margin-left: 8px;" >
                    <img alt="" :src="require('@/assets/menu/sickkids-logo.png')" style="max-width: 95px !important; margin-top: 40px;"/>
                </div>
            </template>
            <template v-slot:header-platform_info>
                <template v-if="$route.matched[0] !== undefined && $route.matched[0].props.default.showUser">
                    <GhUserIcon
                        :generalConfiguration="{
                            class: 'flex-row-textt',
                            icon: require('@/assets/gh_new_dessign/user.svg'),
                            title: '',
                            style: 'top: 53px; right: 20px; position:fixed;',
                            id: 'icon_user'
                        }"
                        :bodyConfiguration="{
                            class: 'flex-row-text',
                            title: '',
                            style: '',
                            id: 'icon_user_body'
                        }"
                    />
                </template>
                <GhHelpIcon
                    :dataBlock="{
                        icon: require('@/assets/gh_new_dessign/help.svg')
                    }"
                />
                <GhLanguage
                    :dataBlock="{
                        title: $t('select_language'),
                        id:'select_lang',
                        icon: require('@/assets/gh_new_dessign/arrowdown.svg')
                    }"
                    :language="languages"
                    :selected="selectedLanguage"
                />
                <div class="header-phone T4">
                    {{ '935 950 764' }}
                </div>
                <div class="header-email T4">
                    {{ 'info@globalholdings2000.es' }}
                </div>
            </template>
        </GhMenu>
        <div style="margin-top: 93px;"></div>
        <GhLocation
            :key="refreshLocation"
            :dataLocation="{
                showButton: false,
                secondWindow: false,
                showLoader: 'OK',
                text: $route.name === 'register' || $route.name === 'register_type' ? $t('registration_application') : ($route.matched[0].props.default.otherTitle !== undefined ? $t($route.matched[0].props.default.otherTitle) : $t(Tabs.TabActive))
            }"
            :dataButton="{id: '', text: 'Boton', style: ''}"
        />
    </div>
</template>

<script>
    import GhMenu from 'fe-gh-menu-lib';
    import GhUserIcon from 'fe-gh-user-icon-lib';
    import GhHelpIcon from 'fe-gh-help-lib';
    import GhLanguage from 'fe-gh-language-lib';
    import GhLocation from 'fe-gh-location-lib';

    import {mapGetters, mapState} from "vuex";

    export default {
        name: 'menu_main',
        components: {
            GhMenu,
            GhUserIcon,
            GhHelpIcon,
            GhLanguage,
            GhLocation
        },
        computed: {
            ...mapGetters(['getterTabsByPerm', 'getterConfigurationTabsByPerm', 'getterPlanTabsByPerm', 'getterAdvertisementsTabsByPerm', 'getterPermissionsTabsByPerm']),
            ...mapState(['Tabs'])
        },
        data(){
            return {
                refreshLocation: 0,
                languages: [
                    {id: 'fr', extra_value_lang: this.$t('french')},
                    {id: 'en', extra_value_lang: this.$t('english')},
                    {id: 'es', extra_value_lang: this.$t('spanish')}
                ],
                selectedLanguage: localStorage.getItem('Language-selected-select_lang') ? JSON.parse(localStorage.getItem('Language-selected-select_lang')) : {id: 'en', extra_value_lang: this.$t('english')},
            }
        },
        watch: {
            async '$i18n.locale'(){
                this.Global.changeLanguage();
                this.languages = [
                    {id: 'fr', extra_value_lang: this.$t('french')},
                    {id: 'en', extra_value_lang: this.$t('english')},
                    {id: 'es', extra_value_lang: this.$t('spanish')}
                ];
            },
            'Tabs.TabActive'(){
                this.refreshLocation++
            }
        },
        mounted() {
            this.$i18n.locale = this.selectedLanguage.id;
            this.checkPermissions();
        },
        beforeMount() {
            this.checkPermissions();
        },
        methods: {
            checkPermissions(){
                if(this.$route.matched !== undefined && this.$route.matched[0] !== undefined && this.$route.matched[0].props.default.showMenu) {
                    this.$router.options.GhTabs = this.$route.name === 'register' || this.$route.name === 'register_type' ? [] : this.getterTabsByPerm;
                    this.$router.options.GhSubTabsConfiguration = this.getterConfigurationTabsByPerm;
                    this.$router.options.GhSubTabsPlan = this.getterPlanTabsByPerm;
                    this.$router.options.GhSubTabsAdvertisements = this.getterAdvertisementsTabsByPerm;
                    this.$router.options.GhSubTabsPermissions = this.getterPermissionsTabsByPerm;
                }
            }
        }
    }
</script>

<style>
    @import '~fe-gh-menu-lib/dist/fe-gh-menu-lib.css';
    @import '~fe-gh-user-icon-lib/dist/fe-gh-user-icon-lib.css';
    @import "~fe-gh-help-lib/dist/fe-gh-help-lib.css";
    @import "~fe-gh-language-lib/dist/fe-gh-language-lib.css";
</style>
