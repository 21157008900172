var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LoaderTemplate',{attrs:{"haveData":_vm.haveData},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('form',{attrs:{"id":"form_data"}},[_c('div',{staticClass:"gh-content-global"},[_c('CodeNameDescription',{attrs:{"code":{
                        show: true,
                        required: true,
                        value: _vm.getterGet.department !== undefined && _vm.getterGet.department['group-department'] !== undefined ? _vm.getterGet.department['group-department'].code : ''
                    },"name":{
                        show: true,
                        required: true,
                        value: _vm.getterGet.department !== undefined && _vm.getterGet.department['group-department'] !== undefined ? _vm.getterGet.department['group-department'].name : ''
                    },"description":{
                        show: false,
                        required: false,
                        value: ''
                    }}}),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhSearchWindow',{attrs:{"dataBlock":{
                                label: {
                                    style: 'margin-right: 10px; width: 150px; min-width: 150px; text-align: right;'
                                },
                                ResultSearch: 'search',
                                nameStore: 'Feed',
                                imagelocation: require('@/assets/gh_new_dessign/floating.svg'),
                                id: 'staff',
                                text: _vm.$t('staff'),
                                textlocation: _vm.$t('add') + ' ' + _vm.$t('staff'),
                                textuserselected: _vm.$t('selected_staff'),
                                actionresult: 'loadSearch',
                                nameResult: 'data[staff]',
                                supportPermision: false,
                                loader: require('@/assets/gh_new_dessign/ajax-loader_blue.gif')
                            },"mainSelector":{
                                name: 'staff_search',
                                formId: 'data',
                                actionsearch: 'setSearch'
                            },"tableResolt":{
                                header: _vm.header,
                                data: []
                            },"tableSelected":{
                                headersummary: _vm.summary,
                                selected: _vm.getterGet.department !== undefined && _vm.getterGet.department['group-department'] !== undefined ? _vm.getterGet.department['group-department'].staff : []
                            }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('staff') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.staff))])]],2)],1)])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }