<template>
    <LoaderTemplate :haveData="haveData">
        <template v-slot:content>
            <form id="form_data">
                <div class="gh-content-global">
                    <CodeNameDescription
                        :code="{
                            show: true,
                            required: true,
                            value: code,
                            read_only: $root._route.params.id !== undefined
                        }"
                        :name="{
                            show: true,
                            required: true,
                            other_name: 'location',
                            value: location,
                            read_only: $root._route.params.id !== undefined
                        }"
                        :description="{
                            show: false,
                            required: false,
                            value: ''
                        }"
                    />
                    <div class="gh-row-content">
                        <template v-if="!$root._route.params.view">
                            <GhDataSelector
                                :datablock="{
                                    id: 'data_select_location_type',
                                    class: 'T15_b',
                                    subtitle: $t('select_location_type') + ':',
                                    text: $t('location_type') + '*',
                                    label: $t('location_type'),
                                    type: 'checkbox',
                                    columns: 3,
                                    style: '',
                                    required: true
                                }"
                                :datainput="{
                                    id: 'value_data_select_type',
                                    name: 'data[location_type][]'
                                }"
                                :selected_input="location_type"
                                :feed="getterFeed['location-type']"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('location_type') + ':'}}</label>
                            <label class="gh_text_field-label T15_b">{{Object.values(location_type).join(', ')}}</label>
                        </template>
                    </div>
                    <div class="gh-row-content">
                        <label class="gh_text_field-label" :class="$root._route.params.view ? 'T15' : 'T15_b'" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('schedule') + ($root._route.params.view ? ':' : '*')}}</label>
                        <template v-if="!$root._route.params.view">
                            <label @click="openNewSchedule" class="gh_data_selector-more T19" style="margin-right: 10px; width: fit-content; text-align: right;cursor: pointer;">{{'[ + ]'}}</label>
                        </template>
                        <input id="has_shedule" :label_required="$t('schedule') + '*'" type="hidden" class="required" :value="getterCasuistry['schedule'] !== undefined && getterCasuistry['schedule'].length !== 0 ? true : ''">
                    </div>
                    <template v-if="getterCasuistry['schedule'] !== undefined && getterCasuistry['schedule'].length !== 0">
                        <div class="gh-row-content" :key="'schedule_' + refreshSchedule">
                            <Schedule :scheduleData="getterCasuistry['schedule'] !== undefined ? getterCasuistry['schedule'] : []" :view="true"/>
                        </div>
                    </template>
                    <div class="gh-row-content" :style="getterCasuistry['schedule'].length !== 0 ? 'margin-top: -12px;' : ''">
                        <template v-if="!$root._route.params.view">
                            <GhSearchWindow
                                :dataBlock="{
                                    label: {
                                        style: 'margin-right: 10px; width: 150px; min-width: 150px; text-align: right;'
                                    },
                                    ResultSearch: 'search',
                                    nameStore: 'Feed',
                                    imagelocation: require('@/assets/gh_new_dessign/floating.svg'),
                                    id: 'equipment',
                                    text: $t('equipment'),
                                    textlocation: $t('add') + ' ' + $t('equipment'),
                                    textuserselected: $t('selected_equipment'),
                                    actionresult: 'loadSearch',
                                    nameResult: 'data[equipment]',
                                    supportPermision: false,
                                    required: false,
                                    loader: require('@/assets/gh_new_dessign/ajax-loader_blue.gif')
                                }"
                                :mainSelector="{
                                    name: 'equipment_search',
                                    formId: 'data',
                                    actionsearch: 'setSearch'
                                }"
                                :tableResolt="{
                                    header: header,
                                    data: []
                                }"
                                :tableSelected="{
                                    headersummary: summary,
                                    selected: equipment
                                }"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('equipment') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{equipment}}</label>
                        </template>
                    </div>
                </div>
            </form>
        </template>
    </LoaderTemplate>
</template>

<script>
    import LoaderTemplate from "@/view/Shared/Loader/loader.vue";
    import CodeNameDescription from "@/view/Shared/Form/code_name_description.vue";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import {mapGetters} from "vuex";
    import GhSearchWindow from "fe-gh-float-window-lib";
    import Schedule from "@/view/Shared/Form/schedule.vue";

    export default {
        name: 'form_location',
        components: {
            Schedule,
            GhSearchWindow,
            GhDataSelector,
            CodeNameDescription,
            LoaderTemplate
        },
        computed: {
            ...mapGetters(['getterGet', 'getterFeed', 'getterCasuistry'])
        },
        data(){
            return{
                haveData: false,
                tempId: null,
                code: '',
                location: '',
                location_type: [],
                equipment: [],
                header: [
                    {text: '', field: 'check', checkall: true, typeCheck: 'checkbox', sorting: false, style: 'min-width: 16px; width: 16px;'},
                    {text: 'code', field: 'code', sorting: true, typeSort: 'code', style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 10%;'},
                    {text: 'description', field: 'description', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 30%;'},
                    {text: 'equipment_type', field: 'equipment_type', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                    {text: 'location', field: 'location', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                    {text: 'availability', field: 'availability', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                    {text: 'training', field: 'training', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                ],
                summary: {
                    0: 'code',
                    1: 'name'
                },
                refreshSchedule: 0
            }
        },
        async beforeDestroy(){
            if(!this.$root._route.params.view){
                await this.$store.dispatch('destroyFeed', {
                    from: 'location-type'
                }, {root: true});

                await this.$store.dispatch('removeByType', {
                    from: 'location',
                    type: 'location',
                    id: this.$root._route.params.id !== undefined ? this.$root._route.params.id : this.tempId
                }, {root: true});
            }

            await this.$store.dispatch('destroyGetByType', {
                from: 'location'
            }, {root: true});

            await this.$store.dispatch('destroyCasuistry', {
                from: 'schedule'
            }, {root: true});
        },
        async beforeMount(){
            if(!this.$root._route.params.view){
                await this.$store.dispatch('getFeed', {
                    from: 'location-type'
                }, {root: true});
            }

            if(this.$root._route.params.id !== undefined){
                await this.$store.dispatch('getByType', {
                    from: 'location',
                    type: 'location',
                    id: this.$root._route.params.id
                }, {root: true});

                this.code = this.getterGet.location['location'].code;
                this.location = this.getterGet.location['location'].location;
                this.location_type = this.getterGet.location['location'].location_type;
                this.equipment = this.getterGet.location['location'].equipment;

                await this.$store.dispatch('getCasuistry', {
                    from: 'schedule',
                    url: 'location/location/' + (this.$root._route.params.id === undefined ? this.tempId : this.$root._route.params.id) + '/schedule'
                }, {root: true});
            }

            if(this.$root._route.params.id === undefined){
                await this.$store.dispatch('setByType', {
                    from: 'location',
                    type: 'location',
                    extra_url: 'temp-id',
                    formData: []
                }, {root: true}).then((response) => {
                    if(response.status === 200) {
                        this.tempId = response.data.data;
                    }
                });
            }

            if(this.$root._route.params.view){
                this.equipment = '';
                this.getterGet.location['location'].equipment.forEach((i) => {
                    this.equipment += i.code + ' ' + i.name + ', ';
                });

                this.equipment = this.equipment.substring(0, this.equipment.length - 2);
            }


            this.$nextTick(() => {
                this.haveData = true;
                this.$parent.$parent.$parent.haveData = this.haveData;
            });
        },
        methods: {
            openNewSchedule(){
                this.persistance();

                this.Global.windowOpen(this.Global.openSecondWindow('schedule_by_from_type', {
                    id: this.$root._route.params.id !== undefined ? this.$root._route.params.id : this.tempId,
                    from: 'location',
                    type: 'location'
                }),'window_new_schedule');

                var that = this;
                window.UpdatedSelect = async function () {
                    await that.$store.dispatch('getCasuistry', {
                        from: 'schedule',
                        url: 'location/location/' + (that.$root._route.params.id === undefined ? that.tempId : that.$root._route.params.id) + '/schedule'
                    }, {root: true});

                    that.refreshSchedule++;
                }
            },
            persistance(){
                if(this.$root._route.params.id === undefined) {
                    this.code = this.$children[0].$children[0].$refs['RefCode'].datainput.value;
                    this.location = this.$children[0].$children[0].$refs['RefName'].datainput.value;
                }
            }
        }
    }
</script>

<style>
    #data_select_location_type_selector_label,#data_select_fixed_location_selector_label{
        text-align: right;
        width: 150px;
    }
</style>