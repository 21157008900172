var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LoaderTemplate',{attrs:{"haveData":_vm.haveData},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('GhTableCommon',{staticStyle:{"margin-top":"10px"},attrs:{"extratable":{
                id: 'patients'
            },"header":_vm.header,"data":_vm.getterList.patients !== undefined && _vm.getterList.patients['patients'] !== undefined ? _vm.getterList.patients['patients'].data : []},scopedSlots:_vm._u([{key:"actions",fn:function({itemProp}){return [(_vm.getterList.patients['patients'].actions.view)?[_c('GhAction',{attrs:{"dataction":{
                            id: 'action_view_patients_' + itemProp.id,
                            text: _vm.$t('view'),
                            icon: require('@/assets/gh_new_dessign/view.svg')
                        }},on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('patients_patients_view', {
                            id: itemProp.id,
                            view: true,
                        }),'window_patient')}}})]:_vm._e(),(_vm.getterList.patients['patients'].actions.modify && itemProp.modify)?[_c('router-link',{attrs:{"to":{name: 'patients_patients_modify', params: {id: itemProp.id}}}},[_c('GhAction',{attrs:{"dataction":{
                                id: 'action_edit_patients_' + itemProp.id,
                                text: _vm.$t('modify'),
                                icon: require('@/assets/gh_new_dessign/edit.svg')
                            }}})],1)]:_vm._e()]}},{key:"footer",fn:function(){return [(_vm.getterList.patients['patients'].actions.add)?[_c('div',{staticClass:"centered"},[_c('router-link',{attrs:{"to":{name: 'patients_patients'}}},[_c('GhButton',{attrs:{"datainput":{
                                    id: 'btn_new_patients',
                                    text: _vm.$t('add')
                                }}})],1)],1)]:_vm._e()]},proxy:true}])})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }