import Users from '@/view/Permissions/Users/main.vue';

import ViewUsers from './Users/router';

const route = [
    {
        path: '/permissions/users',
        name: 'permissions_users',
        props: {showMenu: true, showUser: true},
        component: Users
    }
];

const routes = [
    ...route,
    ...ViewUsers
];

export default routes;