var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
        id: 'popup_general',
        title: _vm.title !== undefined ? _vm.$t(_vm.title) : _vm.$t('warning'),
        type: _vm.type,
        style: _vm.stylePopup !== '' && _vm.stylePopup !== undefined ? _vm.stylePopup : 'width: 650px; min-width: 650px; max-width: 650px;'
    },"bodyConfiguration":{
        id: '',
        class: '',
        style: 'padding:2px;',
        text: _vm.subTitle !== undefined ? _vm.$t(_vm.subTitle) : ''
    },"footerConfiguration":{
        id: '',
        class: '',
        style: '',
        content: ''
    }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"gh-content-global",staticStyle:{"padding":"unset"}},[_c('div',{staticClass:"gh_delete_confirm-content",staticStyle:{"overflow":"auto","max-height":"800px","width":"100%","padding-bottom":"15px"}},[_c('div',{staticClass:"T15_b",staticStyle:{"margin-left":"20px"}},[_vm._v(" "+_vm._s(_vm.$t(_vm.bodyText))+" ")])])]),_vm._t("content")]},proxy:true},{key:"footer",fn:function(){return [(_vm.showButton)?[_c('GhButton',{attrs:{"datainput":{
                    id: 'button_accept_popup_warning_content_text',
                    text: _vm.$t('accept'),
                    class: 'T19 container-md_button_content',
                    style: ' display: flex;'
                }},on:{"click":function($event){return _vm.$emit('accept');}}})]:_vm._e(),_c('GhButton',{attrs:{"datainput":{
                id: 'button_close_popup_warning_content_text',
                text: _vm.$t('close'),
                class: 'T19 container-md_button_content',
                style: ' display: flex;'
            }},on:{"click":function($event){return _vm.Global.closePopUp('popup_general');}}})]},proxy:true}],null,true)})
}
var staticRenderFns = []

export { render, staticRenderFns }