<template>
    <LoaderTemplate :haveData="haveData">
        <template v-slot:content>
            <form id="form_data">
                <div class="gh-content-global">
                    <CodeNameDescription
                        :code="{
                            show: true,
                            required: true,
                            read_only: $root._route.params.id !== undefined,
                            value: code
                        }"
                        :name="{
                            show: true,
                            required: true,
                            read_only: $root._route.params.id !== undefined,
                            other_name: 'diagnosis',
                            value: diagnosis
                        }"
                        :description="{
                            show: false,
                            required: false,
                            value: ''
                        }"
                    />
                    <div class="gh-row-content">
                        <template v-if="!$root._route.params.view && $root._route.params.id === undefined">
                            <GhDataSelector
                                :datablock="{
                                    id: 'data_select_procedure_service',
                                    class: 'T15_b',
                                    subtitle: $t('select_procedure_service') + ':',
                                    text: $t('procedure_service') + '*',
                                    label: $t('procedure_service'),
                                    type: 'radio',
                                    columns: 3,
                                    style: '',
                                    required: true
                                }"
                                :datainput="{
                                    id: 'value_data_select_procedure_service',
                                    name: 'data[procedure_service]'
                                }"
                                :selected_input="procedure_service"
                                :feed="getterFeed['procedure-service']"
                                ref="RefProcedureService"
                                @accept="checkSelector('RefProcedureService')"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('procedure_service') + ':'}}</label>
                            <label class="gh_text_field-label T15_b">{{Object.values(procedure_service).join(', ')}}</label>
                        </template>
                    </div>
                    <div class="gh-row-content" :key="'REFRESH_PROCEDURE_CATEGORY_' + refresh">
                        <template v-if="procedure_service.length === 0 || getterCasuistry['procedure-category'] === undefined || getterCasuistry['procedure-category'].length === 0">
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('procedure_category')}}</label>
                            <label class="gh_text_field-label T15_b">{{procedure_service.length === 0 ? $t('first_select_procedure_service') : $t('procedure_service_no_contains_procedure_category')}}</label>
                        </template>
                        <template v-else-if="$root._route.params.view">
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('procedure_category') + ':'}}</label>
                            <label class="gh_text_field-label T15_b">{{Object.values(procedure_category).join(', ')}}</label>
                        </template>
                        <template v-else>
                            <GhDataSelector
                                :datablock="{
                                    id: 'data_select_procedure_category',
                                    class: 'T16_b',
                                    subtitle: $t('select_procedure_category') + ':',
                                    text: $t('procedure_category'),
                                    label: $t('procedure_category'),
                                    type: 'radio',
                                    columns: 3,
                                    style: '',
                                    required: false
                                }"
                                :datainput="{
                                    id: 'value_data_select_procedure_category',
                                    name: 'data[procedure_category]'
                                }"
                                :selected_input="procedure_category"
                                :feed="getterCasuistry['procedure-category']"
                                ref="RefProcedureCategory"
                                @accept="checkSelector('RefProcedureCategory')"
                            />
                        </template>
                    </div>
                    <div class="gh-row-content" :key="'REFRESH_PROCEDURE_SUBCATEGORY_' + refresh">
                        <template v-if="procedure_category.length === 0 || getterCasuistry['procedure-subcategory'] === undefined || getterCasuistry['procedure-subcategory'].length === 0">
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('subcategory')}}</label>
                            <label class="gh_text_field-label T15_b">{{procedure_category.length === 0 ? $t('first_select_procedure_category') : $t('procedure_category_no_contains_procedure_subcategory')}}</label>
                        </template>
                        <template v-else-if="$root._route.params.view">
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('subcategory') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" :style="na ? '' : 'min-width: 500px;'">{{Object.values(procedure_subcategory).join(', ')}}</label>
                        </template>
                        <template v-else>
                            <template v-if="!na">
                                <GhDataSelector
                                    :datablock="{
                                        id: 'data_select_procedure_subcategory',
                                        class: 'T16_b',
                                        subtitle: $t('select_procedure_subcategory') + ':',
                                        text: $t('subcategory'),
                                        label: $t('procedure_subcategory'),
                                        type: 'checkbox',
                                        columns: 3,
                                        style: '',
                                        required: false
                                    }"
                                    :datainput="{
                                        id: 'value_data_select_procedure_subcategory',
                                        name: 'data[procedure_subcategory][]'
                                    }"
                                    :selected_input="procedure_subcategory"
                                    :feed="getterCasuistry['procedure-subcategory']"
                                    ref="RefProcedureSubcategory"
                                    @accept="checkSelector('RefProcedureSubcategory')"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('subcategory')}}</label>
                            </template>
                        </template>
                        <template v-if="procedure_category.length !== 0 && getterCasuistry['procedure-subcategory'] !== undefined && getterCasuistry['procedure-subcategory'].length !== 0">
                            <GhChecks
                                :datalabel="{
                                    text: $t('na')
                                }"
                                :datainput="{
                                    required: false,
                                    type: 'checkbox',
                                    id: 'data_na_select_procedure_subcategory',
                                    value: 1,
                                    name: 'data[na]',
                                    selected: na,
                                    disabled: $root._route.params.view
                                }"
                                ref="RefProcedureSubcategoryNa"
                                @click="checkSelector('RefProcedureSubcategoryNa')"
                            />
                        </template>
                    </div>
                    <div class="gh-row-content">
                        <template v-if="$root._route.params.view">
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('w2_priority') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" >{{Object.values(w2_priority).join(', ')}}</label>
                        </template>
                        <template v-else>
                            <GhDataSelector
                                :datablock="{
                                    id: 'data_select_w2_priority',
                                    class: 'T16_b',
                                    subtitle: $t('select_w2_priority') + ':',
                                    text: $t('w2_priority'),
                                    label: $t('w2_priority'),
                                    type: 'radio',
                                    columns: 3,
                                    style: '',
                                    required: false
                                }"
                                :datainput="{
                                    id: 'value_data_select_w2_priority',
                                    name: 'data[w2_priority]'
                                }"
                                :selected_input="w2_priority"
                                :feed="getterFeed['w2-priority']"
                                ref="RefW2Prioirty"
                                @accept="checkSelector('RefW2Prioirty')"
                            />
                        </template>
                    </div>
                </div>
            </form>
        </template>
    </LoaderTemplate>
</template>

<script>
    import LoaderTemplate from '@/view/Shared/Loader/loader.vue';
    import CodeNameDescription from '@/view/Shared/Form/code_name_description.vue';

    import {mapGetters} from "vuex";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhChecks from "fe-gh-checks-lib";

    export default {
        name: 'form_pcats_code',
        components: {
            GhChecks,
            GhDataSelector,
            LoaderTemplate,
            CodeNameDescription
        },
        computed: {
            ...mapGetters(['getterGet', 'getterFeed', 'getterCasuistry'])
        },
        data(){
            return {
                code: '',
                diagnosis: '',
                procedure_service: [],
                procedure_category: [],
                procedure_subcategory: [],
                na: false,
                w2_priority: [],
                refresh: 0,
                haveData: false
            }
        },
        async beforeDestroy(){
            if(!this.$root._route.params.view) {
                await this.$store.dispatch('destroyFeed', {
                    from: 'procedure-service'
                }, {root: true});

                await this.$store.dispatch('destroyFeed', {
                    from: 'w2-priority'
                }, {root: true});

                if(this.getterCasuistry['procedure-category'] !== undefined) {
                    await this.$store.dispatch('destroyCasuistry', {
                        from: 'procedure-category'
                    }, {root: true});
                }

                if(this.getterCasuistry['procedure-subcategory'] !== undefined) {
                    await this.$store.dispatch('destroyCasuistry', {
                        from: 'procedure-subcategory'
                    }, {root: true});
                }
            }

            await this.$store.dispatch('destroyGetByType', {
                from: 'procedure'
            }, {root: true});
        },
        async beforeMount(){
            if(!this.$root._route.params.view){
                await this.$store.dispatch('getFeed', {
                    from: 'procedure-service'
                }, {root: true});

                await this.$store.dispatch('getFeed', {
                    from: 'w2-priority'
                }, {root: true});
            }

            if(this.$root._route.params.id !== undefined) {
                await this.$store.dispatch('getByType', {
                    from: 'procedure',
                    type: 'pcats-code',
                    id: this.$root._route.params.id
                }, {root: true});


                this.code = this.getterGet.procedure['pcats-code'].code;
                this.diagnosis = this.getterGet.procedure['pcats-code'].diagnosis;
                this.procedure_service = this.getterGet.procedure['pcats-code'].procedure_service;

                if(Object.keys(this.procedure_service).length !== 0) {
                    await this.$store.dispatch('getCasuistry', {
                        from: 'procedure-category',
                        url: 'procedure-category/feed/by-procedure-service/' + Object.keys(this.procedure_service)[0],
                    }, {root: true});
                }

                this.procedure_category = this.getterGet.procedure['pcats-code'].procedure_category;

                if(Object.keys(this.procedure_category).length !== 0) {
                    await this.$store.dispatch('getCasuistry', {
                        from: 'procedure-subcategory',
                        url: 'procedure-subcategory/feed/by-procedure-category/' + Object.keys(this.procedure_category)[0],
                    }, {root: true});
                }

                this.procedure_subcategory = this.getterGet.procedure['pcats-code'].procedure_subcategory;
                this.na = this.getterGet.procedure['pcats-code'].na;
                this.w2_priority = this.getterGet.procedure['pcats-code'].w2_priority;
            }

            this.$nextTick(() => {
                this.haveData = true;
                this.$parent.$parent.$parent.haveData = this.haveData;
            });
        },
        methods: {
            async checkSelector(ref){
                if(this.$root._route.params.id === undefined) {
                    this.code = this.$children[0].$children[0].$refs['RefCode'].datainput.value;
                    this.diagnosis = this.$children[0].$children[0].$refs['RefName'].datainput.value;
                }

                let selected = '';
                var from = '';
                var url = '';
                var dispatch = false;

                if(this.$refs[ref].checkdata !== undefined){
                    selected = Object.entries(this.$refs[ref].checkdata);
                }

                switch (ref){
                    case 'RefProcedureService':
                        this.procedure_service = this.$refs[ref].checkdata;
                        this.procedure_category = [];
                        this.procedure_subcategory = [];
                        from = 'procedure-category';
                        url = from + '/feed/by-procedure-service/' + selected[0][0];
                        dispatch = true;
                        break;
                    case 'RefProcedureCategory':
                        this.procedure_category = this.$refs[ref].checkdata;
                        this.procedure_subcategory = [];
                        from = 'procedure-subcategory';
                        url = from + '/feed/by-procedure-category/' + selected[0][0];
                        dispatch = true;
                        break;
                    case 'RefProcedureSubcategory':
                        this.procedure_subcategory = this.$refs[ref].checkdata;
                        break;
                    case 'RefProcedureSubcategoryNa':
                        this.na = this.$refs[ref].selected;
                        this.procedure_subcategory = [];
                        break;
                    case 'RefW2Priority':
                        this.w2_priority = this.$refs[ref].checkdata;
                        break;
                }

                if(selected.length !== 0 && dispatch){
                    await this.$store.dispatch('getCasuistry', {
                        from: from,
                        url: url,
                    }, {root: true});
                }

                this.$nextTick(() => {
                    this.refresh++;
                });
            }
        }
    }
</script>

<style>
    #data_select_procedure_service_selector_label, #data_select_procedure_category_selector_label, #data_select_procedure_subcategory_selector_label, #data_select_w2_priority_selector_label{
        width: 150px;
        text-align: right;
    }

    #data_select_procedure_subcategory_data_select_SUMMARY {
        min-width: 500px;
    }
</style>