<template>
    <div class="float_window">
        <NoDropSections :sections="sections"/>
        <div class="centered_footer">
            <GhButton v-if="!$root._route.params.view && haveData"
                :datainput="{
                    id: 'btn_save',
                    text: $t('save')
                }"
                @click="doSet"
            />
            <GhButton
                :datainput="{
                    id: 'btn_return',
                    text: $t('back')
                }"
                @click="router.go(-1)"
            />
        </div>
    </div>
</template>

<script>
    import NoDropSections from "@/view/Shared/Sections/NoDropSections.vue";
    import GhButton from "fe-gh-button-lib";

    import form from './form.vue';

    export default {
        name: 'section_gender',
        components: {
            NoDropSections,
            GhButton
        },
        data(){
            return {
                sections: [{id: 'gender', name: 'gender', component: form}],
                haveData: false
            }
        },
        methods: {
            doSet(){
                if(this.Global.checkRequired('form_data') === 'OK') {
                    const formData = new FormData(document.getElementById('form_data'));
                    this.$store.dispatch('setByType', {
                        formData: [...formData],
                        id: this.$root._route.params.id,
                        from: 'general',
                        type: 'gender',
                        redirect: 'configuration'
                    }, {root: true});
                }
            }
        }
    }
</script>