var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LoaderTemplate',{attrs:{"haveData":_vm.haveData},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('form',{attrs:{"id":"form_data"}},[_c('div',{staticClass:"gh-content-global"},[_c('CodeNameDescription',{attrs:{"code":{
                        show: true,
                        required: true,
                        read_only: _vm.$root._route.params.id !== undefined,
                        value: _vm.getterGet.procedure !== undefined && _vm.getterGet.procedure['procedure-category'] !== undefined ? _vm.getterGet.procedure['procedure-category'].code : ''
                    },"name":{
                        show: true,
                        required: true,
                        read_only: _vm.$root._route.params.id !== undefined,
                        value: _vm.getterGet.procedure !== undefined && _vm.getterGet.procedure['procedure-category'] !== undefined ? _vm.getterGet.procedure['procedure-category'].name : ''
                    },"description":{
                        show: true,
                        required: false,
                        value: _vm.getterGet.procedure !== undefined && _vm.getterGet.procedure['procedure-category'] !== undefined ? _vm.getterGet.procedure['procedure-category'].description : ''
                    }}}),(_vm.$root._route.params.id !== undefined)?[_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('procedure_subcategory') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.getterGet.procedure !== undefined && _vm.getterGet.procedure['procedure-category'] !== undefined ? _vm.getterGet.procedure['procedure-category'].procedure_subcategory : ''))])])]:_vm._e()],2)])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }