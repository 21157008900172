var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LoaderTemplate',{attrs:{"haveData":_vm.haveData},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('form',{attrs:{"id":"form_data"}},[_c('div',{staticClass:"gh-content-global"},[_c('CodeNameDescription',{attrs:{"code":{
                        show: false,
                        required: false,
                        value: ''
                    },"name":{
                        show: false,
                        required: false,
                        value: ''
                    },"description":{
                        show: true,
                        required: true,
                        value: _vm.getterGet.support !== undefined && _vm.getterGet.support['general-schedule'] !== undefined ? _vm.getterGet.support['general-schedule'].description : ''
                    }}}),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhCalendar',{attrs:{"datalabel":{
                                id: 'label_date_schedule',
                                text: _vm.$t('date'),
                                style: 'width: 150px; min-width: 150px; text-align: right; margin-right: 12px;'
                            },"datainput":{
                                id:'data_date',
                                name:'data[date]',
                                value: _vm.getterGet.support !== undefined && _vm.getterGet.support['general-schedule'] !== undefined ? _vm.getterGet.support['general-schedule'].date : '',
                                required: true,
                                format: 'DD/MM',
                                placeholder: 'DD/MM'
                            }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('date') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"width":"155px","margin-right":"10px"}},[_vm._v(_vm._s(_vm.getterGet.support !== undefined && _vm.getterGet.support['general-schedule'] !== undefined && _vm.getterGet.support['general-schedule'].date !== '' ? _vm.getterGet.support['general-schedule'].date.split('/')[0] + '/' + _vm.getterGet.support['general-schedule'].date.split('/')[1]: ''))])]],2)],1)])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }