<template>
    <div>
        <LoaderTemplate :haveData="haveData">
            <template v-slot:content>
                <DropSections :sections="sections" :result="getterCounters" :perm="getterPermission"/>
            </template>
        </LoaderTemplate>
    </div>
</template>

<script>
    import LoaderTemplate from "@/view/Shared/Loader/loader.vue";
    import DropSections from '@/view/Shared/Sections/DropSections.vue';

    import {mapGetters} from "vuex";

    import actions from './Actions/main.vue';
    import procedures from './Procedures/main.vue';

    export default {
        name: 'configuration_actions',
        components: {
            LoaderTemplate,
            DropSections
        },
        computed: {
            ...mapGetters(['getterCounters', 'getterPermission'])
        },
        data(){
            return {
                sections: [
                    {id: 1, name: 'actions', component: actions, result: 'actions', second_text: 'results'},
                    {id: 2, name: 'procedures', component: procedures, result: 'procedures', second_text: 'results'},
                ],
                haveData: false
            }
        },
        beforeDestroy(){
            this.$store.dispatch('destroyListByType', {
                from: 'actions'
            }, {root: true});
        },
        async beforeMount(){
            await this.$store.dispatch('getPermissionByType', {type: 'actions'}, {root: true});
            await this.$store.dispatch('getCountersByType', {type: 'actions'}, {root: true});

            this.$nextTick(() => {
                this.haveData = true;
            });
        }
    }
</script>