var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LoaderTemplate',{attrs:{"haveData":_vm.haveData},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('form',{attrs:{"id":"form_data"}},[_c('div',{staticClass:"gh-content-global"},[_c('CodeNameDescription',{attrs:{"code":{
                        show: true,
                        required: true,
                        value: _vm.code,
                        read_only: _vm.$root._route.params.id !== undefined
                    },"name":{
                        show: true,
                        required: true,
                        other_name: 'location',
                        value: _vm.location,
                        read_only: _vm.$root._route.params.id !== undefined
                    },"description":{
                        show: false,
                        required: false,
                        value: ''
                    }}}),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                                id: 'data_select_location_type',
                                class: 'T15_b',
                                subtitle: _vm.$t('select_location_type') + ':',
                                text: _vm.$t('location_type') + '*',
                                label: _vm.$t('location_type'),
                                type: 'checkbox',
                                columns: 3,
                                style: '',
                                required: true
                            },"datainput":{
                                id: 'value_data_select_type',
                                name: 'data[location_type][]'
                            },"selected_input":_vm.location_type,"feed":_vm.getterFeed['location-type']}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('location_type') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(Object.values(_vm.location_type).join(', ')))])]],2),_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label",class:_vm.$root._route.params.view ? 'T15' : 'T15_b',staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('schedule') + (_vm.$root._route.params.view ? ':' : '*')))]),(!_vm.$root._route.params.view)?[_c('label',{staticClass:"gh_data_selector-more T19",staticStyle:{"margin-right":"10px","width":"fit-content","text-align":"right","cursor":"pointer"},on:{"click":_vm.openNewSchedule}},[_vm._v(_vm._s('[ + ]'))])]:_vm._e(),_c('input',{staticClass:"required",attrs:{"id":"has_shedule","label_required":_vm.$t('schedule') + '*',"type":"hidden"},domProps:{"value":_vm.getterCasuistry['schedule'] !== undefined && _vm.getterCasuistry['schedule'].length !== 0 ? true : ''}})],2),(_vm.getterCasuistry['schedule'] !== undefined && _vm.getterCasuistry['schedule'].length !== 0)?[_c('div',{key:'schedule_' + _vm.refreshSchedule,staticClass:"gh-row-content"},[_c('Schedule',{attrs:{"scheduleData":_vm.getterCasuistry['schedule'] !== undefined ? _vm.getterCasuistry['schedule'] : [],"view":true}})],1)]:_vm._e(),_c('div',{staticClass:"gh-row-content",style:(_vm.getterCasuistry['schedule'].length !== 0 ? 'margin-top: -12px;' : '')},[(!_vm.$root._route.params.view)?[_c('GhSearchWindow',{attrs:{"dataBlock":{
                                label: {
                                    style: 'margin-right: 10px; width: 150px; min-width: 150px; text-align: right;'
                                },
                                ResultSearch: 'search',
                                nameStore: 'Feed',
                                imagelocation: require('@/assets/gh_new_dessign/floating.svg'),
                                id: 'equipment',
                                text: _vm.$t('equipment'),
                                textlocation: _vm.$t('add') + ' ' + _vm.$t('equipment'),
                                textuserselected: _vm.$t('selected_equipment'),
                                actionresult: 'loadSearch',
                                nameResult: 'data[equipment]',
                                supportPermision: false,
                                required: false,
                                loader: require('@/assets/gh_new_dessign/ajax-loader_blue.gif')
                            },"mainSelector":{
                                name: 'equipment_search',
                                formId: 'data',
                                actionsearch: 'setSearch'
                            },"tableResolt":{
                                header: _vm.header,
                                data: []
                            },"tableSelected":{
                                headersummary: _vm.summary,
                                selected: _vm.equipment
                            }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('equipment') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.equipment))])]],2)],2)])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }