<template>
    <div class="login">
        <GhLogin>
            <template v-slot:header>
                <div style="display: flex; padding-bottom: -50px;">
                    <div>
                        <img alt="" :src="require('../../assets/login/sickkids-logo-header.png')" width="312"/>
                    </div>
                </div>
            </template>
            <template v-slot:content>
                <div class="error-login" :style="getterErrorAuthentication.flag ? 'background-color: #ff04042b;' : ''">
                    <template v-if="getterErrorAuthentication.flag">
                        <label class="gh_text_field-label T15_b" style="margin-right: 10px; color: red; padding-top: 15px; margin-left: 5px;">{{getterErrorAuthentication.error}}</label>
                    </template>
                </div>
                <div class="container-user">
                    <GhInputLogin
                        :datalabel="{
                            id: 'user_label',
                            text: $t('user')
                        }"
                        :datainput="{
                            id: 'user_labelf',
                            type: 'text',
                            name: 'username',
                            class: 'username',
                            error: '',
                            show_message: false
                        }"
                        @keyup="enterSubmit"
                        ref="GhInputUser"
                    />
                </div>
                <div class="container-password" style="padding-top: 46px">
                    <GhInputLogin
                        :datalabel="{
                            id: 'pass_label',
                            text: $t('password')
                        }"
                        :datainput="{
                            id: 'pass_labelf',
                            type: 'password',
                            name: 'password',
                            class: 'password',
                            error: '',
                            show_message: true
                        }"
                        @keyup="enterSubmit"
                        ref="GhInputPassword"
                    />
                </div>
                <div class="recordar_t5_2_ t5 text-inline alink" style="margin-top:47px; color: #1080B5;" @click="Global.openPopUp('remember_password')">
                    <p>{{$t('remember_password')}}</p>
                </div>
                <div class="botonera" style="margin-top: 66px;">
                    <GhButton
                        :datainput="{
                            id: 'enter_button',
                            text: $t('enter'),
                            class: 'container-md_button text-inline t13',
                            style: 'padding-top: 7px; padding-left: 15px; padding-right: 15px;'
                        }"
                        @click="submit()"
                    />
                </div>
            </template>
            <template v-slot:popups>
                <component :is="component.popup_remember_password"/>
                <component :is="component.popup_remember_password_success"/>
                <component :is="component.popup_remember_password_error"/>
            </template>
        </GhLogin>
    </div>
</template>

<script>
    import GhLogin from 'fe-gh-login-lib';
    import GhInputLogin from 'fe-gh-input-login-lib';
    import GhButton from 'fe-gh-button-lib';

    import {mapGetters} from "vuex";

    import popup_remember_password from "@/view/Login/Popups/popup_remember_password.vue";
    import popup_remember_password_success from "@/view/Login/Popups/popup_remember_password_success.vue";
    import popup_remember_password_error from "@/view/Login/Popups/popup_remember_password_error.vue";

    export default {
        name: 'login_main',
        components: {
            GhLogin,
            GhInputLogin,
            GhButton
        },
        computed: {
            ...mapGetters(['getterErrorAuthentication'])
        },
        data(){
            return {
                refreshError: 0,
                component: {
                    popup_remember_password: popup_remember_password,
                    popup_remember_password_success: popup_remember_password_success,
                    popup_remember_password_error: popup_remember_password_error
                }
            }
        },
        methods: {
            async submit(){
                await this.$store.dispatch('doLogin', {
                    username: this.$refs.GhInputUser.$el.children[1].children[0].value,
                    password: this.$refs.GhInputPassword.$el.children[1].children[0].value
                },{root:true});
            },
            enterSubmit: function(e){
                if (e.keyCode === 13) {
                    this.submit();
                }
            }
        }
    }
</script>

<style>
    @import "~fe-gh-login-lib/dist/fe-gh-login-lib.css";
    @import "~fe-gh-input-login-lib/dist/fe-gh-input-login-lib.css";
    @import "~fe-gh-action-lib/dist/fe-gh-action-lib.css";
    @import "../../assets/css/login/style.css";

    .container-password {
        top: 265px;
    }
</style>