import LocationType from '@/view/Configuration/General/LocationType/Template/section.vue';

const route = [
    {
        path: '/configuration/general/location-type',
        name: 'config_general_location_type',
        props: {showMenu: true, showUser: true},
        component: LocationType,
        children: [
            {
                path: '/configuration/general/location-type/:id',
                name: 'config_general_location_type_modify',
                component: LocationType,
                props: {id: null}
            },
            {
                path: '/configuration/general/location-type/:id/:view',
                name: 'config_general_location_type_view',
                component: LocationType,
                props: {id: null, view: true}
            }
        ]
    }
];

const routes = [
    ...route,
];

export default routes;