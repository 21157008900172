<template>
    <LoaderTemplate :haveData="haveData">
        <template v-slot:content>
            <form id="form_data">
                <div class="gh-content-global">
                    <CodeNameDescription
                        :code="{
                            show: false,
                            required: false,
                            value: ''
                        }"
                        :name="{
                            show: true,
                            required: true,
                            value: getterGet.support !== undefined && getterGet.support['categories'] !== undefined ? getterGet.support['categories'].name : ''
                        }"
                        :description="{
                            show: false,
                            required: false,
                            value: ''
                        }"
                    />
                    <div class="gh-row-content">
                        <template v-if="!$root._route.params.view">
                            <GhDataSelector
                                :datablock="{
                                    id: 'data_select_flow',
                                    class: 'T15_b',
                                    subtitle: $t('select_flow') + ':',
                                    text: $t('flow'),
                                    label: $t('flow'),
                                    type: 'radio',
                                    columns: 3,
                                    style: '',
                                    required: false
                                }"
                                :datainput="{
                                    id: 'value_data_select_flow',
                                    name: 'data[flow]'
                                }"
                                :selected_input="getterGet.support !== undefined && getterGet.support['categories'] !== undefined ? getterGet.support['categories'].flow : []"
                                :feed="getterFeed['flows']"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('flow') + ':'}}</label>
                            <label class="gh_text_field-label T15_b">{{getterGet.support !== undefined && getterGet.support['categories'] !== undefined ? Object.values(getterGet.support['categories'].flow).join(', ') : ''}}</label>
                        </template>
                    </div>
                    <div class="gh-row-content">
                        <template v-if="!$root._route.params.view">
                            <GhDataSelector
                                :datablock="{
                                    id: 'data_select_priority',
                                    class: 'T15_b',
                                    subtitle: $t('select_priority') + ':',
                                    text: $t('priority'),
                                    label: $t('priority'),
                                    type: 'radio',
                                    columns: 3,
                                    style: '',
                                    required: false
                                }"
                                :datainput="{
                                    id: 'value_data_select_priority',
                                    name: 'data[priority]'
                                }"
                                :selected_input="getterGet.support !== undefined && getterGet.support['categories'] !== undefined ? getterGet.support['categories'].priority : []"
                                :feed="getterFeed['priorities']"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('priority') + ':'}}</label>
                            <label class="gh_text_field-label T15_b">{{getterGet.support !== undefined && getterGet.support['categories'] !== undefined ? Object.values(getterGet.support['categories'].priority).join(', ') : ''}}</label>
                        </template>
                    </div>
                </div>
            </form>
        </template>
    </LoaderTemplate>
</template>

<script>
    import LoaderTemplate from '@/view/Shared/Loader/loader.vue';
    import CodeNameDescription from '@/view/Shared/Form/code_name_description.vue';
    import GhDataSelector from 'fe-gh-data-selector-lib';

    import {mapGetters} from "vuex";

    export default {
        name: 'form_category',
        components: {
            LoaderTemplate,
            CodeNameDescription,
            GhDataSelector
        },
        computed: {
            ...mapGetters(['getterGet', 'getterFeed'])
        },
        data(){
            return {
                haveData: false
            }
        },
        async beforeDestroy(){
            if(!this.$root._route.params.view) {
                await this.$store.dispatch('destroyFeed', {
                    from: 'flows'
                }, {root: true});

                await this.$store.dispatch('destroyFeed', {
                    from: 'priorities'
                }, {root: true});
            }

            await this.$store.dispatch('destroyGetByType', {
                from: 'support'
            }, {root: true});
        },
        async beforeMount(){
            if(!this.$root._route.params.view){
                await this.$store.dispatch('getFeed', {
                    from: 'flows'
                }, {root: true});

                await this.$store.dispatch('getFeed', {
                    from: 'priorities'
                }, {root: true});
            }

            if(this.$root._route.params.id !== undefined) {
                await this.$store.dispatch('getByType', {
                    from: 'support',
                    type: 'categories',
                    id: this.$root._route.params.id
                }, {root: true});
            }

            this.$nextTick(() => {
                this.haveData = true;
                this.$parent.$parent.$parent.haveData = this.haveData;
            });
        }
    }
</script>

<style>
    #data_select_flow_selector_label, #data_select_priority_selector_label{
        width: 150px;
        text-align: right;
    }
</style>