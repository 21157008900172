var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"float_window"},[_c('div',{staticClass:"gh-content-global"},[_c('form',{key:'SEARCH_' + _vm.refreshSearch,attrs:{"id":"form_data"}},[_c(_vm.components.form,{tag:"component"})],1),_c('div',{staticClass:"centered_footer"},[_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_search',
                    text: _vm.$t('search')
                }},on:{"click":function($event){return _vm.doAction('search')}}}),_c('GhButton',{attrs:{"datainput":{
                    id: 'btn_clean',
                    text: _vm.$t('clean')
                }},on:{"click":function($event){return _vm.doAction('clean')}}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }