<template>
    <LoaderTemplate :haveData="haveData">
        <template v-slot:content>
            <GhTableCommon
                :extratable="{
                    id: 'group_department'
                }"
                :header="header"
                :data="getterList.department !== undefined && getterList.department['group-department'] !== undefined ? getterList.department['group-department'].data : []"
            >
                <template v-slot:actions="{itemProp}">
                    <template v-if="getterList.department['group-department'].actions.view">
                        <router-link :to="{name: 'config_department_group_department_view', params: {id: itemProp.id, view: true}}">
                            <GhAction
                                :dataction="{
                                    id: 'action_view_group_department_' + itemProp.id,
                                    text: $t('view'),
                                    icon: require('@/assets/gh_new_dessign/view.svg')
                                }"
                            />
                        </router-link>
                    </template>
                    <template v-if="getterList.department['group-department'].actions.modify">
                        <router-link :to="{name: 'config_department_group_department_modify', params: {id: itemProp.id}}">
                            <GhAction
                                :dataction="{
                                    id: 'action_edit_group_department_' + itemProp.id,
                                    text: $t('modify'),
                                    icon: require('@/assets/gh_new_dessign/edit.svg')
                                }"
                            />
                        </router-link>
                    </template>
                </template>
                <template v-slot:footer>
                    <template v-if="getterList.department['group-department'].actions.add">
                        <div class="centered">
                            <router-link :to="{name: 'config_department_group_department'}">
                                <GhButton
                                    :datainput="{
                                        id: 'btn_new_group_department',
                                        text: $t('add')
                                    }"
                                />
                            </router-link>
                        </div>
                    </template>
                </template>
            </GhTableCommon>
        </template>
    </LoaderTemplate>
</template>

<script>
    import LoaderTemplate from '@/view/Shared/Loader/loader.vue';
    import GhTableCommon from 'fe-gh-table-common-lib';
    import GhAction from "fe-gh-action-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapGetters} from "vuex";

    export default {
        name: 'list_group_department',
        components: {
            LoaderTemplate,
            GhTableCommon,
            GhAction,
            GhButton
        },
        computed: {
            ...mapGetters(['getterList'])
        },
        data(){
            return {
                header: [
                    {text: 'code', field: 'code', sorting: true, typeSort: 'code', style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                    {text: 'staff', field: 'staff', sorting: true, typeSort: 'string', style: 'min-width: 150px; max-width: 150px; width: 80%;'},
                    {text: '', field: 'actions', sorting: false}
                ],
                haveData: false
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getListByType', {
                from: 'department',
                type: 'group-department'
            }, {root: true});

            this.$nextTick(() => {
                this.haveData = true;
            });
        }
    }
</script>