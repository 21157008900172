<template>
    <div v-show="false" id="rememeber_password_success" class="modal-backdrop">
        <div class="ayuda_fondo_2_" style="position: static;">
            <div class="recordarnombre_t11 t11 text-inline" style="color: green; position: relative; white-space: inherit; margin-right: 50px;">
                {{$t('success_message_remember_password')}}
            </div>
            <div style="margin-top: 55px; z-index: 999; display: flex; flex-flow: row-reverse;">
                <GhAction
                    :dataction="{
                        id: 'close_remember_password_success',
                        text: $t('close'),
                        class: 't13'
                    }"
                    @click="Global.closePopUp('remember_password'); Global.closePopUp('rememeber_password_success')"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import GhAction from "fe-gh-action-lib";

    export default {
        name: 'popup_remember_password_success',
        components: {
            GhAction
        }
    }
</script>