var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LoaderTemplate',{attrs:{"haveData":_vm.haveData},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('form',{attrs:{"id":"form_data"}},[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                text: _vm.$t('code') + '*',
                                style: 'width: 150px; text-align: right;',
                                class: 'T15_b'
                            },"datainput":{
                                required: true,
                                id: 'data_code',
                                name: 'data[code]',
                                style: 'width: 200px',
                                value: _vm.getterGet.users !== undefined && _vm.getterGet.users['users'] !== undefined ? _vm.getterGet.users['users'].code : '',
                                type: 'text'
                            }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('code') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"width":"205px"}},[_vm._v(_vm._s(_vm.getterGet.users !== undefined && _vm.getterGet.users['users'] !== undefined ? _vm.getterGet.users['users'].code : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                text: _vm.$t('name') + '*',
                                style: 'width: 150px; text-align: right;',
                                class: 'T15_b'
                            },"datainput":{
                                required: true,
                                id: 'data_name',
                                name: 'data[name]',
                                style: 'width: 200px',
                                value: _vm.getterGet.users !== undefined && _vm.getterGet.users['users'] !== undefined ? _vm.getterGet.users['users'].name : '',
                                type: 'text'
                            }}}),_c('GhInputTextField',{attrs:{"datalabel":{
                                text: _vm.$t('surname') + '*',
                                style: 'width: 150px; text-align: right;',
                                class: 'T15_b'
                            },"datainput":{
                                required: true,
                                id: 'data_surname',
                                name: 'data[surname]',
                                style: 'width: 300px',
                                value: _vm.getterGet.users !== undefined && _vm.getterGet.users['users'] !== undefined ? _vm.getterGet.users['users'].surname : '',
                                type: 'text'
                            }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"width":"205px"}},[_vm._v(_vm._s(_vm.getterGet.users !== undefined && _vm.getterGet.users['users'] !== undefined ? _vm.getterGet.users['users'].name : ''))]),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('surname') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(_vm.getterGet.users !== undefined && _vm.getterGet.users['users'] !== undefined ? _vm.getterGet.users['users'].surname : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                text: _vm.$t('email') + '*',
                                style: 'width: 150px; text-align: right;',
                                class: 'T15_b'
                            },"datainput":{
                                required: true,
                                id: 'data_email',
                                name: 'data[email]',
                                style: 'width: 300px',
                                value: _vm.getterGet.users !== undefined && _vm.getterGet.users['users'] !== undefined ? _vm.getterGet.users['users'].email : '',
                                type: 'text'
                            }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('email') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(_vm.getterGet.users !== undefined && _vm.getterGet.users['users'] !== undefined ? _vm.getterGet.users['users'].email : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                                id: 'data_select_groups',
                                class: 'T15_b',
                                subtitle: _vm.$t('select_groups') + ':',
                                text: _vm.$t('groups') + '*',
                                label: _vm.$t('groups'),
                                type: 'checkbox',
                                columns: 3,
                                style: '',
                                required: true
                            },"datainput":{
                                id: 'value_data_select_groups',
                                name: 'data[groups][]'
                            },"selected_input":_vm.getterGet.users !== undefined && _vm.getterGet.users['users'] !== undefined ? _vm.getterGet.users['users'].groups : [],"feed":_vm.getterFeed['groups']}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('groups') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(_vm.getterGet.users !== undefined && _vm.getterGet.users['users'] !== undefined && _vm.getterGet.users['users'].groups !== undefined ? Object.values(_vm.getterGet.users['users'].groups).join(', ') : ''))])]],2)])])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }