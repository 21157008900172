<template>
    <LoaderTemplate :haveData="haveData">
        <template v-slot:content>
            <div>
                <GhSubTab v-bind:items="$router.options.GhSubTabsAdvertisements" :dataBlock="{id: 'advertisements_tab_', calldispatch: 'getTabActive'}"/>
                <transition name="fade" mode="out-in" v-on:after-enter="afterEnter" appear>
                    <component :is="viewLoad" :key="refreshView"/>
                </transition>
            </div>
        </template>
    </LoaderTemplate>
</template>

<script>
    import LoaderTemplate from "@/view/Shared/Loader/loader.vue";
    import GhSubTab from 'fe-gh-subtab-lib';

    import {mapState} from "vuex";

    export default {
        name: 'advertisements_main',
        components: {
            LoaderTemplate,
            GhSubTab
        },
        computed: {
            ...mapState(['SubTabs'])
        },
        watch: {
            '$i18n.locale'(){
                let that = this;
                that.haveData = false;
                setTimeout(function(){
                    that.haveData = true;
                },500);
            },
            'SubTabs.reload'(){
                this.viewLoad = this.SubTabs.subTabActive;
                this.refreshView++;
            }
        },
        data(){
            return {
                haveData: false,
                viewLoad: null,
                refreshView: 0
            }
        },
        async beforeMount(){
            let that = this;
            setTimeout(function(){
                that.haveData = true;
            },500);
        },
        methods:{
            afterEnter: function() {
                return true;
            }
        }
    }
</script>