<template>
    <div>
        <LoaderTemplate :haveData="haveData">
            <template v-slot:content>
                <DropSections :sections="sections" :result="getterCounters" :perm="getterPermission"/>
            </template>
        </LoaderTemplate>
    </div>
</template>

<script>
    import LoaderTemplate from "@/view/Shared/Loader/loader.vue";
    import DropSections from '@/view/Shared/Sections/DropSections.vue';

    import {mapGetters} from "vuex";

    import categories from './Categories/main.vue';
    import priorities from './Priorities/main.vue';
    import assignments from './Assignments/main.vue';
    import schedule from './Schedule/main.vue';
    import schedule_per_group from './SchedulePerGroup/main.vue';
    import general_schedule from './GeneralSchedule/main.vue';

    export default {
        name: 'configuration_support',
        components: {
            LoaderTemplate,
            DropSections
        },
        computed: {
            ...mapGetters(['getterCounters', 'getterPermission'])
        },
        data(){
            return {
                sections: [
                    {id: 1, name: 'categories', component: categories, result: 'categories', second_text: 'results'},
                    {id: 2, name: 'priorities', component: priorities, result: 'priorities', second_text: 'results'},
                    {id: 3, name: 'assignment', component: assignments, result: 'assignment', second_text: 'results'},
                    {id: 4, name: 'schedule', component: schedule, result: 'schedule', second_text: 'results'},
                    {id: 5, name: 'schedule_per_group', component: schedule_per_group, result: 'schedule_per_group', second_text: 'results'},
                    {id: 6, name: 'general_schedule', component: general_schedule, result: 'general_schedule', second_text: 'results'},
                    {id: 7, name: 'flows', component: '', result: 'flows', second_text: 'results'}
                ],
                haveData: false
            }
        },
        beforeDestroy(){
            this.$store.dispatch('destroyListByType', {
                from: 'support'
            }, {root: true});
        },
        async beforeMount(){
            await this.$store.dispatch('getPermissionByType', {type: 'support'}, {root: true});
            await this.$store.dispatch('getCountersByType', {type: 'support'}, {root: true});

            this.$nextTick(() => {
                this.haveData = true;
            });
        }
    }
</script>