<template>
    <div>
        <LoaderTemplate :haveData="haveData">
            <template v-slot:content>
                <NoDropSections :sections="sections" :result="getterCounters" :perm="getterPermission"/>
            </template>
        </LoaderTemplate>
    </div>
</template>

<script>
    import NoDropSections from "@/view/Shared/Sections/NoDropSections.vue";
    import LoaderTemplate from "@/view/Shared/Loader/loader.vue";

    import {mapGetters} from "vuex";

    import agendeds from './Agendeds/main.vue';

    export default {
        name: 'plan_agendeds',
        components: {
            LoaderTemplate,
            NoDropSections
        },
        computed: {
            ...mapGetters(['getterCounters', 'getterPermission'])
        },
        data(){
            return {
                sections: [
                    {id: 1, name: 'agendeds', component: agendeds, result: 'agendeds', second_text: 'results'}
                ],
                haveData: false
            }
        },
        beforeDestroy(){
            this.$store.dispatch('destroyListByType', {
                from: 'agendeds'
            }, {root: true});
        },
        async beforeMount(){
            await this.$store.dispatch('getPermissionByType', {type: 'agendeds'}, {root: true});
            await this.$store.dispatch('getCountersByType', {type: 'agendeds'}, {root: true});

            this.$nextTick(() => {
                this.haveData = true;
            });
        }
    }
</script>