<template>
    <LoaderTemplate :haveData="haveData">
        <template v-slot:content>
            <template v-if="($root._route.params.reschedule || $root._route.params.action) && getterGet.patients.patients !== undefined && getterGet.patients.patients['reschedule'] !== undefined && getterGet.patients.patients['reschedule'].historic !== undefined && getterGet.patients.patients['reschedule'].historic.length !== 0">
                <div v-for="(historic, keyHistoric) in getterGet.patients.patients['reschedule'].historic" :key="'HISTORIC_' + keyHistoric">
                    <div class="section_line" style="margin-top: 10px; margin-bottom: 10px;">
                        <div class="T15_b" style="padding-left:13px;">{{$t(historic.action) + ' ' + $t('by') + ' ' + historic.user + ' ' + $t('at') + ' ' + historic.date_action}}</div>
                    </div>
                    <div class="gh-content-global">
                        <div class="gh-row-content">
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('motive') + ':'}}</label>
                            <label class="gh_text_field-label T15_b">{{historic.motive}}</label>
                        </div>
                        <template v-for="(procedure, keyProcedure) in historic.procedures">
                            <div class="gh-row-content" :key="'PROCEDURE_' + keyHistoric + '_' + keyProcedure">
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('procedure') + ':'}}</label>
                                <label class="gh_text_field-label T15_b">{{procedure.procedure}}</label>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('date') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="width: 205px;">{{procedure.date}}</label>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('place') + ':'}}</label>
                                <label class="gh_text_field-label T15_b">{{procedure.place}}</label>
                            </div>
                        </template>
                    </div>
                </div>
            </template>
            <template v-if="$root._route.params.action === undefined || !$root._route.params.action">
                <form id="form_data_planification">
                    <template v-if="getterGet.patients.patients !== undefined && getterGet.patients.patients['planification'] !== undefined && getterGet.patients.patients['planification'].scheduled">
                        <div class="section_line" style="margin-top: 10px; margin-bottom: 10px;">
                            <div class="T15_b" style="padding-left:13px;">{{$t('the_intervention_has_been_provisionally_scheduled_in') + ' ' + getterGet.patients.patients['planification'].place + ', ' + $t('date').toLowerCase() + ' ' + getterGet.patients.patients['planification'].date + ', ' + $t('with').toLowerCase() + ' ' + getterGet.patients.patients['planification'].staff}}</div>
                        </div>
                    </template>
                    <template v-else>
                        <div class="section_line" style="margin-top: 10px; margin-bottom: 10px;">
                            <div class="T15_b" style="padding-left:13px;">{{$root._route.params.reschedule ? $t('reschedule') : $t('it_is_not_possible_to_schedule_the_intervention_with_the_defined_data') + ', ' + $t('below_are_programming_alternatives').toLowerCase()}}</div>
                        </div>

                        <div class="gh-content-global">
                            <template v-if="$root._route.params.reschedule">
                                <div class="gh-row-content">
                                    <GhTextarea
                                        :datalabel="{
                                            text: $t('motive') + '*',
                                            class: 'T15_b',
                                            styles: 'width: 150px; min-width: 150px !important; text-align: right; margin-right: 10px;'
                                        }"
                                        :datainput="{
                                            id: 'data_motive',
                                            name: 'data[motive]',
                                            value: '',
                                            required: true,
                                            label_required: $t('motive') + '*'
                                        }"
                                    />
                                </div>
                            </template>

                            <div class="gh-row-content">
                                <template v-if="Object.keys(getterGet.patients.patients[$root._route.params.reschedule ? 'reschedule' : 'planification'].feeds.procedure).length === 1">
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('procedure') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b">{{Object.values(procedures_selected)[0]}}</label>
                                </template>
                                <template v-else>
                                    <GhDataSelector
                                        :datablock="{
                                            id: 'data_select_procedures',
                                            class: 'T15_b',
                                            subtitle: $t('select_procedure') + ':',
                                            text: $t('procedure') + '*',
                                            label: $t('procedure'),
                                            type: 'checkbox',
                                            columns: 3,
                                            style: '',
                                            required: true
                                        }"
                                        :datainput="{
                                            id: 'value_data_select_procedures',
                                            name: 'data[procedures][]'
                                        }"
                                        :selected_input="procedures_selected"
                                        :feed="getterGet.patients.patients[$root._route.params.reschedule ? 'reschedule' : 'planification'].feeds.procedure"
                                        ref="refDataProcedures"
                                        @accept="updateProcedures"
                                    />
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="Object.keys(getterGet.patients.patients[$root._route.params.reschedule ? 'reschedule' : 'planification'].feeds.special_conditions).length === 1">
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('special_conditions') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b">{{Object.values(special_conditions_selected)[0]}}</label>
                                </template>
                                <template v-else>
                                    <GhDataSelector
                                        :datablock="{
                                            id: 'data_select_special_conditions',
                                            class: 'T15_b',
                                            subtitle: $t('select_special_conditions') + ':',
                                            text: $t('special_conditions') + '*',
                                            label: $t('special_conditions'),
                                            type: 'checkbox',
                                            columns: 3,
                                            style: '',
                                            required: true
                                        }"
                                        :datainput="{
                                            id: 'value_data_select_special_conditions',
                                            name: 'data[special_conditions][]'
                                        }"
                                        :selected_input="special_conditions_selected"
                                        :feed="getterGet.patients.patients[$root._route.params.reschedule ? 'reschedule' : 'planification'].feeds.special_conditions"
                                        ref="refDataSpecialConditions"
                                        @accept="updateProcedures"
                                    />
                                </template>
                            </div>
                        </div>
                        <div class="section_line" style="background-color: #e9f7ff;" :style="Object.values(procedures_selected).length === 0 ? '' : 'margin-top: 20px;'">
                            <div class="T15_b" style="padding-left:13px;">{{Object.values(procedures_selected).join(', ')}}</div>
                        </div>

                        <GhTableCommon
                            :key="'table_' + refreshTable"
                            style="margin-top: 10px;"
                            :extratable="{
                                id: 'patients'
                            }"
                            :header="header"
                            :data="casuistry_data"
                        >
                            <template v-slot:field_component="{itemProp}">
                                <GhChecks
                                    :datalabel="{
                                        text: ''
                                    }"
                                    :datainput="{
                                        required: true,
                                        label_required: $t('select_date_and_place_scheduled') + '*',
                                        type: 'radio',
                                        id: 'data_selected_' + itemProp.id,
                                        value: itemProp.id,
                                        name: 'data[selected]',
                                        selected: selected !== undefined && selected === itemProp.id,
                                        disabled: $root._route.params.view
                                    }"
                                    :ref="'RefSelected' + itemProp.id"
                                    @click="persistance('RefSelected',  itemProp.id)"
                                />
                            </template>
                        </GhTableCommon>
                    </template>
                </form>
            </template>
        </template>
    </LoaderTemplate>
</template>

<script>
    import LoaderTemplate from "@/view/Shared/Loader/loader.vue";
    import GhTextarea from "fe-gh-textarea-lib";
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhChecks from "fe-gh-checks-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";

    import {mapGetters} from "vuex";

    export default {
        name: 'form_planification',
        components: {
            LoaderTemplate,
            GhTextarea,
            GhTableCommon,
            GhDataSelector,
            GhChecks
        },
        computed: {
            ...mapGetters(['getterGet', 'getterCasuistry'])
        },
        data(){
            return {
                refreshTable: 0,
                selected: [],
                procedures_selected: [],
                special_conditions_selected: [],
                casuistry_data: [],
                header: [
                    {text: '', field: 'id', field_component: true, style: 'min-width: 20px; max-width: 20px; width: 20px;'},
                    {text: 'date', field: 'date', sorting: true, typeSort: 'date', style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                    {text: 'location', field: 'place', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 100%;'},
                ],
                allowSchedule: false,
                allowReschedule: false,
                haveData: false
            }
        },
        async beforeDestroy(){
            await this.$store.dispatch('destroyCasuistry', {
                from: 'alternatives'
            }, {root: true});
        },
        async beforeMount(){
            if(this.$root._route.params.id !== undefined) {
                await this.$store.dispatch('getByType', {
                    from: 'patients',
                    type: 'patients',
                    extra: this.$root._route.params.reschedule ? 'reschedule' : this.$root._route.params.action ? 'planification-action' : 'planification',
                    id: this.$root._route.params.id
                }, {root: true}).then(async (response) => {
                    if(response.status === 200 && (!response.data.data.scheduled || this.$root._route.params.reschedule)){
                        this.selected = this.$root._route.params.reschedule || this.$root._route.params.action ? response.data.data.data.selected : response.data.data.selected;
                    }
                });

                if(this.$root._route.params.action === undefined || !this.$root._route.params.action){
                    this.procedures_selected = this.getterGet.patients.patients[this.$root._route.params.reschedule ? 'reschedule' : 'planification'].feeds.procedure;
                    this.special_conditions_selected = this.getterGet.patients.patients[this.$root._route.params.reschedule ? 'reschedule' : 'planification'].feeds.special_conditions;

                    Object.keys(this.procedures_selected).forEach((i) => {
                        this.callCasuistry(i);
                    });

                    if(this.$root._route.params.reschedule === undefined && this.getterGet.patients.patients['planification'].buttons.schedule){
                        this.allowSchedule = true;
                        this.$parent.$parent.$parent.allowSchedule = this.allowSchedule;
                    }

                    if(this.$root._route.params.reschedule && this.getterGet.patients.patients['reschedule'].buttons.reschedule){
                        this.allowReschedule = true;
                        this.$parent.$parent.$parent.allowReschedule = this.allowReschedule;
                    }
                }
            }

            this.$nextTick(() => {
                this.haveData = true;
                this.$parent.$parent.$parent.haveData = this.haveData;
            });
        },
        methods: {
            persistance(ref, index, id){
                if(this.selected === undefined) this.selected = [];
                this.selected = this.$refs[ref + id][0].datainput.value;
            },
            async updateProcedures(){
                this.procedures_selected = this.$refs.refDataProcedures.checkdata;

                if(this.$refs.refDataSpecialConditions !== undefined) {
                    this.special_conditions_selected = this.$refs.refDataSpecialConditions.checkdata;
                }

                this.callCasuistry();
            },
            async callCasuistry(){
                let formData = new FormData();

                if(Object.keys(this.procedures_selected).length !== 0){
                    Object.keys(this.procedures_selected).forEach((k) => {
                        formData.append('data[procedures][]', k);
                    });
                }

                if(Object.keys(this.special_conditions_selected).length !== 0){
                    Object.keys(this.special_conditions_selected).forEach((k) => {
                        formData.append('data[special_conditions][]', k);
                    });
                }

                await this.$store.dispatch('setByType', {
                    from: 'patients',
                    type: 'patients',
                    id: this.$root._route.params.id,
                    extra_url: 'alternatives' + (this.$root._route.params.reschedule ? '-reschedule' : ''),
                    formData: [...formData]
                }, {root: true}).then(async (response) => {
                    if(response.status === 200){
                        this.casuistry_data = response.data.data;
                    }
                });

                this.refreshTable++;
            }
        }
    }
</script>

<style>
    #data_select_procedures_selector_label{
        width: 150px;
        text-align: right;
    }
</style>