import Agendeds from '@/view/Plan/Agendeds/main.vue';

const route = [
    {
        path: '/plan/agendeds',
        name: 'plan_agendeds',
        props: {showMenu: true, showUser: true},
        component: Agendeds
    }
];

const routes = [
    ...route
];

export default routes;