import Schedule from '@/view/Configuration/Support/Schedule/Template/section.vue';

const route = [
    {
        path: '/configuration/support/schedule',
        name: 'config_support_schedule',
        props: {showMenu: true, showUser: true},
        component: Schedule,
        children: [
            {
                path: '/configuration/support/schedule/:id',
                name: 'config_support_schedule_modify',
                component: Schedule,
                props: {id: null}
            },
            {
                path: '/configuration/support/schedule/:id/:view',
                name: 'config_support_schedule_view',
                component: Schedule,
                props: {id: null, view: true}
            }
        ]
    }
];

const routes = [
    ...route,
];

export default routes;