var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LoaderTemplate',{attrs:{"haveData":_vm.haveData},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('form',{attrs:{"id":"form_data"}},[_c('div',{staticClass:"gh-content-global"},[_c('CodeNameDescription',{attrs:{"code":{
                        show: true,
                        required: true,
                        value: _vm.getterGet.department !== undefined && _vm.getterGet.department['department'] !== undefined ? _vm.getterGet.department['department'].code : ''
                    },"name":{
                        show: true,
                        required: true,
                        value: _vm.getterGet.department !== undefined && _vm.getterGet.department['department'] !== undefined ? _vm.getterGet.department['department'].name : ''
                    },"description":{
                        show: false,
                        required: false,
                        value: ''
                    }}}),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                                id: 'data_select_group_department',
                                class: 'T15_b',
                                subtitle: _vm.$t('select_group_department') + ':',
                                text: _vm.$t('group_department') + '*',
                                label: _vm.$t('group_department'),
                                type: 'checkbox',
                                columns: 3,
                                style: '',
                                required: true
                            },"datainput":{
                                id: 'value_data_select_group_department',
                                name: 'data[group_department][]'
                            },"selected_input":_vm.getterGet.department !== undefined && _vm.getterGet.department['department'] !== undefined ? _vm.getterGet.department['department'].group_department : [],"feed":_vm.getterFeed['group-department']}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('group_department') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(_vm.getterGet.department !== undefined && _vm.getterGet.department['department'] !== undefined ? Object.values(_vm.getterGet.department['department'].group_department).join(', ') : ''))])]],2)],1),_c('div',{staticClass:"section_line",staticStyle:{"margin-top":"-5px"}},[_c('div',{staticClass:"T15_b",staticStyle:{"padding-left":"13px"}},[_vm._v(_vm._s(_vm.$t('point_of_contact')))])]),_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                text: _vm.$t('name') + '*',
                                style: 'width: 150px; text-align: right;',
                                class: 'T15_b'
                            },"datainput":{
                                required: true,
                                id: 'data_point_of_contact_name',
                                name: 'data[point_of_contact][name]',
                                style: 'width: 170px;',
                                value: _vm.getterGet.department !== undefined && _vm.getterGet.department['department'] !== undefined && _vm.getterGet.department['department']['point_of_contact'] !== undefined ? _vm.getterGet.department['department']['point_of_contact'].name : '',
                                type: 'text'
                            }}}),_c('GhInputTextField',{attrs:{"datalabel":{
                                text: _vm.$t('surname') + '*',
                                style: 'width: 100px; text-align: right;',
                                class: 'T15_b'
                            },"datainput":{
                                required: true,
                                id: 'data_point_of_contact_surname',
                                name: 'data[point_of_contact][surname]',
                                style: 'width: 170px;',
                                value: _vm.getterGet.department !== undefined && _vm.getterGet.department['department'] !== undefined && _vm.getterGet.department['department']['point_of_contact'] !== undefined ? _vm.getterGet.department['department']['point_of_contact'].surname : '',
                                type: 'text'
                            }}}),_c('GhInputTextField',{attrs:{"datalabel":{
                                text: _vm.$t('email') + '*',
                                style: 'width: 100px; text-align: right;',
                                class: 'T15_b'
                            },"datainput":{
                                required: true,
                                id: 'data_point_of_contact_email',
                                name: 'data[point_of_contact][email]',
                                style: 'width: 250px;',
                                value: _vm.getterGet.department !== undefined && _vm.getterGet.department['department'] !== undefined && _vm.getterGet.department['department']['point_of_contact'] !== undefined ? _vm.getterGet.department['department']['point_of_contact'].email : '',
                                type: 'text'
                            }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"width":"170px"}},[_vm._v(_vm._s(_vm.getterGet.department !== undefined && _vm.getterGet.department['department'] !== undefined && _vm.getterGet.department['department']['point_of_contact'] !== undefined ? _vm.getterGet.department['department']['point_of_contact'].name : ''))]),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"100px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('surname') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"width":"170px"}},[_vm._v(_vm._s(_vm.getterGet.department !== undefined && _vm.getterGet.department['department'] !== undefined && _vm.getterGet.department['department']['point_of_contact'] !== undefined ? _vm.getterGet.department['department']['point_of_contact'].surname : ''))]),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"100px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('email') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(_vm.getterGet.department !== undefined && _vm.getterGet.department['department'] !== undefined && _vm.getterGet.department['department']['point_of_contact'] !== undefined ? _vm.getterGet.department['department']['point_of_contact'].email : ''))])]],2)]),_c('div',{staticClass:"section_line",staticStyle:{"margin-top":"-5px"}},[_c('div',{staticClass:"T15_b",staticStyle:{"padding-left":"13px"}},[_vm._v(_vm._s(_vm.$t('leader')))])]),_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                text: _vm.$t('name'),
                                style: 'width: 150px; text-align: right;',
                                class: 'T15'
                            },"datainput":{
                                required: false,
                                id: 'data_leader_name',
                                name: 'data[leader][name]',
                                style: 'width: 170px;',
                                value: _vm.getterGet.department !== undefined && _vm.getterGet.department['department'] !== undefined && _vm.getterGet.department['department']['leader'] !== undefined ? _vm.getterGet.department['department']['leader'].name : '',
                                type: 'text'
                            }}}),_c('GhInputTextField',{attrs:{"datalabel":{
                                text: _vm.$t('surname'),
                                style: 'width: 100px; text-align: right;',
                                class: 'T15'
                            },"datainput":{
                                required: false,
                                id: 'data_leader_surname',
                                name: 'data[leader][surname]',
                                style: 'width: 170px;',
                                value: _vm.getterGet.department !== undefined && _vm.getterGet.department['department'] !== undefined && _vm.getterGet.department['department']['leader'] !== undefined ? _vm.getterGet.department['department']['leader'].surname : '',
                                type: 'text'
                            }}}),_c('GhInputTextField',{attrs:{"datalabel":{
                                text: _vm.$t('email'),
                                style: 'width: 100px; text-align: right;',
                                class: 'T15'
                            },"datainput":{
                                required: false,
                                id: 'data_leader_email',
                                name: 'data[leader][email]',
                                style: 'width: 250px;',
                                value: _vm.getterGet.department !== undefined && _vm.getterGet.department['department'] !== undefined && _vm.getterGet.department['department']['leader'] !== undefined ? _vm.getterGet.department['department']['leader'].email : '',
                                type: 'text'
                            }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"width":"170px"}},[_vm._v(_vm._s(_vm.getterGet.department !== undefined && _vm.getterGet.department['department'] !== undefined && _vm.getterGet.department['department']['leader'] !== undefined ? _vm.getterGet.department['department']['leader'].name : ''))]),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"100px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('surname') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"width":"170px"}},[_vm._v(_vm._s(_vm.getterGet.department !== undefined && _vm.getterGet.department['department'] !== undefined && _vm.getterGet.department['department']['leader'] !== undefined ? _vm.getterGet.department['department']['leader'].surname : ''))]),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"100px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('email') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(_vm.getterGet.department !== undefined && _vm.getterGet.department['department'] !== undefined && _vm.getterGet.department['department']['leader'] !== undefined ? _vm.getterGet.department['department']['leader'].email : ''))])]],2)])])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }