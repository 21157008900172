var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LoaderTemplate',{attrs:{"haveData":_vm.haveData},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('form',{attrs:{"id":"form_data"}},[_c('div',{staticClass:"gh-content-global"},[_c('CodeNameDescription',{attrs:{"code":{
                        show: false,
                        required: false,
                        value: ''
                    },"name":{
                        show: true,
                        required: true,
                        value: _vm.getterGet.groups !== undefined && _vm.getterGet.groups['groups'] !== undefined ? _vm.getterGet.groups['groups'].name : ''
                    },"description":{
                        show: true,
                        required: false,
                        value: _vm.getterGet.groups !== undefined && _vm.getterGet.groups['groups'] !== undefined ? _vm.getterGet.groups['groups'].description : ''
                    }}}),_c('div',{staticStyle:{"display":"flex","width":"100%","padding-bottom":"6px"}},[_c('div',{class:_vm.$root._route.params.view ? 'T15' : 'T15_b',staticStyle:{"width":"150px","min-width":"150px","text-align":"right","margin-right":"11px"}},[_vm._v(" "+_vm._s(_vm.$t('permissions') + (_vm.$root._route.params.view ? '' : '*'))+" ")]),_c('div',{staticStyle:{"display":"block","width":"100%","margin-right":"5px"}},_vm._l((_vm.getterFeed.permissions),function(item,index){return _c('div',{key:index,staticClass:"gh-row-content section-permissions",style:(index % 2 ? '' : 'background-color: #d9edfc;')},[_c('div',[_c('div',{staticStyle:{"display":"flex","flex-wrap":"wrap"}},_vm._l((item),function(itemChildren,indexChildren){return _c('div',{key:indexChildren,staticStyle:{"display":"flex","min-width":"280px","box-sizing":"border-box"},attrs:{"title":itemChildren.description}},[_c('GhChecks',{attrs:{"datalabel":{
                                                text: '',
                                                style: '',
                                                class: '',
                                                id: ''
                                            },"datainput":{
                                                disabled: _vm.$root._route.params.view,
                                                required: true,
                                                selected: _vm.getterGet.groups !== undefined && _vm.getterGet.groups['groups'] !== undefined && _vm.getterGet.groups['groups'].permissions !== undefined && _vm.getterGet.groups['groups'].permissions.length !== 0 ? Object.values(_vm.getterGet.groups['groups'].permissions).includes(itemChildren.id) : false,
                                                type: 'checkbox',
                                                id: itemChildren.id,
                                                value: itemChildren.id,
                                                label_required: _vm.$t('permissions') + '*',
                                                name: 'data[permissions][]'
                                            }}}),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(itemChildren.name))])],1)}),0)])])}),0)]),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                                id: 'data_select_profiles',
                                class: 'T15_b',
                                subtitle: _vm.$t('select_profiles') + ':',
                                text: _vm.$t('profiles') + '*',
                                label: _vm.$t('profiles'),
                                type: 'checkbox',
                                columns: 3,
                                style: '',
                                required: true
                            },"datainput":{
                                id: 'value_data_select_profiles',
                                name: 'data[profiles][]'
                            },"selected_input":_vm.getterGet.groups !== undefined && _vm.getterGet.groups['groups'] !== undefined ? _vm.getterGet.groups['groups'].profiles : [],"feed":_vm.getterFeed['profiles']}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('profiles') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(_vm.getterGet.groups !== undefined && _vm.getterGet.groups['groups'] !== undefined && _vm.getterGet.groups['groups'].profiles !== undefined ? Object.values(_vm.getterGet.groups['groups'].profiles).join(', ') : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhSearchWindow',{attrs:{"dataBlock":{
                                label: {
                                    style: 'margin-right: 10px; width: 150px; min-width: 150px; text-align: right;'
                                },
                                ResultSearch: 'search',
                                nameStore: 'Feed',
                                imagelocation: require('@/assets/gh_new_dessign/floating.svg'),
                                id: 'users',
                                text: _vm.$t('users') + '*',
                                textlocation: _vm.$t('add') + ' ' + _vm.$t('users'),
                                textuserselected: _vm.$t('selected_users'),
                                actionresult: 'loadSearch',
                                nameResult: 'data[users]',
                                supportPermision: false,
                                required: true,
                                loader: require('@/assets/gh_new_dessign/ajax-loader_blue.gif')
                            },"mainSelector":{
                                name: 'users_search',
                                formId: 'data',
                                actionsearch: 'setSearch'
                            },"tableResolt":{
                                header: _vm.header,
                                data: []
                            },"tableSelected":{
                                headersummary: _vm.summary,
                                selected: _vm.getterGet.groups !== undefined && _vm.getterGet.groups['groups'] !== undefined ? _vm.getterGet.groups['groups'].users : []
                            }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('users') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.users))])]],2)],1)])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }