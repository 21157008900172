import W2Priority from '@/view/Configuration/Procedure/W2Priority/Template/section.vue';

const route = [
    {
        path: '/configuration/procedure/w2-priority',
        name: 'config_procedure_w2_priority',
        props: {showMenu: true, showUser: true},
        component: W2Priority,
        children: [
            {
                path: '/configuration/procedure/w2-priority/:id',
                name: 'config_procedure_w2_priority_modify',
                component: W2Priority,
                props: {id: null}
            },
            {
                path: '/configuration/procedure/w2-priority/:id/:view',
                name: 'config_procedure_w2_priority_view',
                component: W2Priority,
                props: {id: null, view: true}
            }
        ]
    }
];

const routes = [
    ...route,
];

export default routes;