var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LoaderTemplate',{attrs:{"haveData":_vm.haveData},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('form',{attrs:{"id":"form_data"}},[_c('div',{staticClass:"gh-content-global"},[_c('CodeNameDescription',{attrs:{"code":{
                        show: true,
                        required: true,
                        read_only: _vm.$root._route.params.id !== undefined,
                        value: _vm.code
                    },"name":{
                        show: true,
                        required: true,
                        read_only: _vm.$root._route.params.id !== undefined,
                        value: _vm.name
                    },"description":{
                        show: true,
                        required: false,
                        read_only: _vm.$root._route.params.id !== undefined,
                        value: _vm.description
                    }}}),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view && _vm.$root._route.params.id === undefined)?[_c('GhDataSelector',{ref:"RefProcedureCategory",attrs:{"datablock":{
                                id: 'data_select_procedure_category',
                                class: 'T15_b',
                                subtitle: _vm.$t('select_procedure_category') + ':',
                                text: _vm.$t('procedure_category') + '*',
                                label: _vm.$t('procedure_category'),
                                type: 'radio',
                                columns: 3,
                                style: '',
                                required: true
                            },"datainput":{
                                id: 'value_data_select_procedure_category',
                                name: 'data[procedure_category]'
                            },"selected_input":_vm.procedure_category,"feed":_vm.getterFeed['procedure-category']},on:{"accept":function($event){return _vm.checkSelector('RefProcedureCategory')}}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('procedure_category') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(Object.values(_vm.procedure_category).join(', ')))])]],2),_c('div',{key:'REFRESH_PCATS_CODE_' + _vm.refresh,staticClass:"gh-row-content"},[(_vm.procedure_category.length === 0 || _vm.getterCasuistry['pcats-code'] === undefined || _vm.getterCasuistry['pcats-code'].length === 0)?[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('pcats_code')))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(_vm.procedure_category.length === 0 ? _vm.$t('first_select_procedure_category') : _vm.$t('procedure_category_no_contains_pcats_code')))])]:(_vm.$root._route.params.view)?[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('pcats_code') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(Object.values(_vm.pcats_code).join(', ')))])]:[_c('GhDataSelector',{ref:"RefPcatsCode",attrs:{"datablock":{
                                id: 'data_select_pcats_code',
                                class: 'T15_b',
                                subtitle: _vm.$t('select_pcats_code') + ':',
                                text: _vm.$t('pcats_code') + '*',
                                label: _vm.$t('pcats_code'),
                                type: 'radio',
                                columns: 3,
                                style: '',
                                required: true
                            },"datainput":{
                                id: 'value_data_select_pcats_code',
                                name: 'data[pcats_code]'
                            },"selected_input":_vm.pcats_code,"feed":_vm.getterCasuistry['pcats-code']},on:{"accept":function($event){return _vm.checkSelector('RefPcatsCode')}}})]],2)],1)])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }