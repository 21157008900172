<template>
    <div>
        <LoaderTemplate :haveData="haveData">
            <template v-slot:content>
                <NoDropSections :sections="sections" :result="getterCounters" :perm="getterPermission"/>
            </template>
        </LoaderTemplate>
    </div>
</template>

<script>
    import LoaderTemplate from "@/view/Shared/Loader/loader.vue";
    import location from './Location/main.vue'
    import {mapGetters} from "vuex";
    import NoDropSections from "@/view/Shared/Sections/NoDropSections.vue";

    export default {
        name: 'configuration_location',
        components: {
            NoDropSections,
            LoaderTemplate
        },
        computed: {
            ...mapGetters(['getterCounters', 'getterPermission'])
        },
        data(){
            return {
                sections: [
                    {id: 1, name: 'location', component: location, result: 'location', second_text: 'results'}
                ],
                haveData: false
            }
        },
        beforeDestroy(){
            this.$store.dispatch('destroyListByType', {
                from: 'location'
            }, {root: true});
        },
        async beforeMount(){
            await this.$store.dispatch('getPermissionByType', {type: 'location'}, {root: true});
            await this.$store.dispatch('getCountersByType', {type: 'location'}, {root: true});

            this.$nextTick(() => {
                this.haveData = true;
            });
        }
    }
</script>