<template>
    <LoaderTemplate :haveData="haveData">
        <template v-slot:content>
            <form id="form_data">
                <div class="gh-content-global">
                    <CodeNameDescription
                        :code="{
                            show: true,
                            required: true,
                            value: code,
                            read_only: $root._route.params.id !== undefined,
                        }"
                        :name="{
                            show: false,
                            required: false,
                            value: ''
                        }"
                        :description="{
                            show: false,
                            required: false,
                            value: ''
                        }"
                    />
                    <div class="gh-row-content">
                        <template v-if="!$root._route.params.view && $root._route.params.id === undefined">
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('name') + '*',
                                    style: 'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'data_name',
                                    name: 'data[name]',
                                    style: 'width: 200px',
                                    value: name,
                                    type: 'text'
                                }"
                                ref="RefName"
                            />
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('surname') + '*',
                                    style: 'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'data_surname',
                                    name: 'data[surname]',
                                    style: 'width: 200px',
                                    value: surname,
                                    type: 'text'
                                }"
                                ref="RefSurname"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="width: 200px;">{{name}}</label>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('surname') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{surname}}</label>
                        </template>
                    </div>
                    <div class="gh-row-content">
                        <template v-if="!$root._route.params.view && $root._route.params.id === undefined">
                            <GhDataSelector
                                :datablock="{
                                    id: 'data_select_staff_type',
                                    class: 'T15_b',
                                    subtitle: $t('select_staff_type') + ':',
                                    text: $t('staff_type') + '*',
                                    label: $t('staff_type'),
                                    type: 'radio',
                                    columns: 3,
                                    style: '',
                                    required: true
                                }"
                                :datainput="{
                                    id: 'value_data_select_staff_type',
                                    name: 'data[staff_type]'
                                }"
                                :selected_input="staff_type"
                                :feed="getterFeed['staff-type']"
                                ref="RefStaffType"
                                @accept="checkSelector('RefStaffType')"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('staff_type') + ':'}}</label>
                            <label class="gh_text_field-label T15_b">{{Object.values(staff_type).join(', ')}}</label>
                        </template>
                    </div>
                    <div class="gh-row-content" style="margin-left: 160px;">
                        <GhChecks
                            :datalabel="{
                                text: $t('shift')
                            }"
                            :datainput="{
                                required: false,
                                type: 'radio',
                                id: 'data_type_schedule_shift',
                                value: 1,
                                name: 'data[type_schedule]',
                                selected: type_schedule === 1,
                                disabled: $root._route.params.view
                            }"
                            ref="RefTypeScheduleShift"
                            @click="checkSelector('RefTypeScheduleShift')"
                        />
                        <GhChecks
                            :datalabel="{
                                text: $t('schedule')
                            }"
                            :datainput="{
                                required: false,
                                type: 'radio',
                                id: 'data_type_schedule_new_schedule',
                                value: 2,
                                name: 'data[type_schedule]',
                                selected: type_schedule === 2,
                                disabled: $root._route.params.view,
                                style: 'margin-left: 25px;'
                            }"
                            ref="RefTypeScheduleNewSchedule"
                            @click="checkSelector('RefTypeScheduleNewSchedule')"
                        />
                        <template v-if="!$root._route.params.view && type_schedule === 2">
                            <GhAction
                                style="margin-top: -1px; margin-left: 20px;"
                                :dataction="{
                                    id: 'action_modify_schedule',
                                    text: $t('modify'),
                                    icon: require('@/assets/gh_new_dessign/view.svg')
                                }"
                                @click="openNewSchedule"
                            />
                        </template>
                    </div>
                    <template v-if="type_schedule === 1">
                        <div class="gh-row-content" :key="'REFRESH_SCHEDULE_SHIFT_' + refresh">
                            <template v-if="!$root._route.params.view">
                                <GhDataSelector
                                    :datablock="{
                                        id: 'data_select_shift',
                                        class: 'T15_b',
                                        subtitle: $t('select_shift') + ':',
                                        text: $t('shift'),
                                        label: $t('shift'),
                                        type: 'checkbox',
                                        columns: 3,
                                        style: '',
                                        required: false
                                    }"
                                    :datainput="{
                                        id: 'value_data_select_shift',
                                        name: 'data[shift][]'
                                    }"
                                    :selected_input="shift"
                                    :feed="getterFeed['shift']"
                                    ref="RefShift"
                                    @accept="checkSelector('RefShift')"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('shift') + ':'}}</label>
                                <label class="gh_text_field-label T15_b">{{Object.values(shift).join(', ')}}</label>
                            </template>
                        </div>
                    </template>
                    <template v-else-if="type_schedule === 2">
                        <div class="gh-row-content" :key="'schedule_' + refreshSchedule">
                            <Schedule :scheduleData="getterCasuistry['schedule'] !== undefined ? getterCasuistry['schedule'] : []" :view="true"/>
                        </div>
                    </template>
                    <div class="gh-row-content">
                        <template v-if="!$root._route.params.view">
                            <GhDataSelector
                                :datablock="{
                                    id: 'data_select_department_specialty',
                                    class: 'T15_b',
                                    subtitle: $t('select_department_specialty') + ':',
                                    text: $t('department_specialty'),
                                    label: $t('department_specialty'),
                                    type: 'checkbox',
                                    columns: 3,
                                    style: '',
                                    required: false
                                }"
                                :datainput="{
                                    id: 'value_data_select_department_specialty',
                                    name: 'data[department_specialty][]'
                                }"
                                :selected_input="department_specialty"
                                :feed="getterFeed['department-specialty']"
                                ref="RefShift"
                                @accept="checkSelector('RefShift')"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('department_specialty') + ':'}}</label>
                            <label class="gh_text_field-label T15_b">{{Object.values(department_specialty).join(', ')}}</label>
                        </template>
                    </div>
                    <template v-if="$root._route.params.id !== undefined && !$root._route.params.view">
                        <GhDataSelector
                            :datablock="{
                                id: 'data_select_training',
                                class: 'T15_b',
                                subtitle: $t('select_training') + ':',
                                text: $t('training'),
                                label: $t('training'),
                                type: 'checkbox',
                                columns: 3,
                                style: 'display: none;',
                                required: false
                            }"
                            :datainput="{
                                id: 'value_data_select_training',
                                name: 'data[training][]'
                            }"
                            :selected_input="selected_training"
                            :disabled_input="disabled_training"
                            :feed="feed_training"
                            ref="RefTraining"
                            @accept="checkSelector('RefTraining')"
                        />
                    </template>
                </div>

                <template v-if="$root._route.params.id !== undefined || (getterCasuistry !== undefined && getterCasuistry['training'] && getterCasuistry['training'].length !== 0)">
                    <GhTableCommon
                        :extratable="{
                            id: 'training'
                        }"
                        :header="header"
                        :data="$root._route.params.id !== undefined ? training : getterCasuistry['training']"
                    >
                        <template v-slot:footer>
                            <template v-if="$root._route.params.id !== undefined && !$root._route.params.view">
                                <div class="centered">
                                    <GhButton
                                        :datainput="{
                                            id: 'btn_modify_training',
                                            text: $t('modify')
                                        }"
                                        @click="openModify()"
                                    />
                                </div>
                            </template>
                        </template>
                    </GhTableCommon>
                </template>
            </form>
        </template>
    </LoaderTemplate>
</template>

<script>
    import LoaderTemplate from '@/view/Shared/Loader/loader.vue';
    import CodeNameDescription from '@/view/Shared/Form/code_name_description.vue';
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhChecks from "fe-gh-checks-lib";
    import GhAction from "fe-gh-action-lib";
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapGetters} from "vuex";
    import Schedule from "@/view/Shared/Form/schedule.vue";

    export default {
        name: 'form_staff',
        components: {
            Schedule,
            LoaderTemplate,
            CodeNameDescription,
            GhInputTextField,
            GhDataSelector,
            GhChecks,
            GhAction,
            GhTableCommon,
            GhButton
        },
        computed: {
            ...mapGetters(['getterGet', 'getterFeed', 'getterCasuistry'])
        },
        data(){
            return {
                code: '',
                name: '',
                surname: '',
                staff_type: [],
                type_schedule: 0,
                shift: [],
                department_specialty: [],
                tempId: null,
                header: [
                    {text: 'code', field: 'code', sorting: true, typeSort: 'code', style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                    {text: 'training_type', field: 'training_type', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                    {text: 'description', field: 'description', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 40%;'},
                    {text: 'duration', field: 'duration', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                    {text: 'target_staff', field: 'target_staff', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'}
                ],
                training: [],
                feed_training: [],
                selected_training: [],
                disabled_training: [],
                emptySchedule: {
                    week_from: '',
                    week_to: '',
                    entrance_of: '',
                    entrance_to: '',
                    hours: '',
                    days: []
                },
                refresh: 0,
                refreshSchedule: 0,
                haveData: false
            }
        },
        async beforeDestroy(){
            if(!this.$root._route.params.view) {
                if(this.$root._route.params.id === undefined) {
                    await this.$store.dispatch('destroyFeed', {
                        from: 'staff-type'
                    }, {root: true});
                }

                if(this.getterFeed['shift'] !== undefined) {
                    await this.$store.dispatch('destroyFeed', {
                        from: 'shift'
                    }, {root: true});
                }

                await this.$store.dispatch('destroyFeed', {
                    from: 'department-specialty'
                }, {root: true});

                if(this.getterCasuistry['training'] !== undefined) {
                    await this.$store.dispatch('destroyCasuistry', {
                        from: 'training'
                    }, {root: true});
                }

                await this.$store.dispatch('removeByType', {
                    from: 'staff',
                    type: 'staff',
                    id: this.$root._route.params.id !== undefined ? this.$root._route.params.id : this.tempId
                }, {root: true});
            }

            await this.$store.dispatch('destroyGetByType', {
                from: 'staff'
            }, {root: true});

            if(this.type_schedule === 2){
                await this.$store.dispatch('destroyCasuistry', {
                    from: 'schedule'
                }, {root: true});
            }
        },
        async beforeMount(){
            if(!this.$root._route.params.view){
                if(this.$root._route.params.id === undefined) {
                    await this.$store.dispatch('getFeed', {
                        from: 'staff-type'
                    }, {root: true});
                }

                await this.$store.dispatch('getFeed', {
                    from: 'department-specialty'
                }, {root: true});

                if(this.$root._route.params.id !== undefined){
                    await this.$store.dispatch('getFeed', {
                        from: 'training'
                    }, {root: true});

                    if(Object.keys(this.getterFeed['training']).length !== 0){
                        Object.values(this.getterFeed['training']).forEach((values) => {
                            this.feed_training[values.id] = values.name;
                        })
                    }
                }
            }

            if(this.$root._route.params.id !== undefined) {
                await this.$store.dispatch('getByType', {
                    from: 'staff',
                    type: 'staff',
                    id: this.$root._route.params.id
                }, {root: true});


                this.code = this.getterGet.staff['staff'].code;
                this.name = this.getterGet.staff['staff'].name;
                this.surname = this.getterGet.staff['staff'].surname;
                this.staff_type = this.getterGet.staff['staff'].staff_type;
                this.training = this.getterGet.staff['staff'].training;

                if(this.training.length !== 0){
                    this.training.forEach((i) => {
                        this.selected_training[i.id] = i.name;

                        if(i.disabled){
                            this.disabled_training[i.id] = i.name;
                        }
                    });
                }

                this.type_schedule = this.getterGet.staff['staff'].type_schedule;

                if(this.type_schedule === 1){
                    await this.$store.dispatch('getFeed', {
                        from: 'shift'
                    }, {root: true});

                    this.shift = this.getterGet.staff['staff'].shift;
                }else if(this.type_schedule === 2){
                    await this.$store.dispatch('getCasuistry', {
                        from: 'schedule',
                        url: 'staff/staff/' + (this.$root._route.params.id === undefined ? this.tempId : this.$root._route.params.id) + '/schedule'
                    }, {root: true});
                }

                this.department_specialty = this.getterGet.staff['staff'].department_specialty;

                await this.$store.dispatch('getFeed', {
                    from: 'department-specialty'
                }, {root: true});


            }

            if(this.$root._route.params.id === undefined){
                await this.$store.dispatch('setByType', {
                    from: 'staff',
                    type: 'staff',
                    extra_url: 'temp-id',
                    formData: []
                }, {root: true}).then((response) => {
                    if(response.status === 200) {
                        this.tempId = response.data.data;
                    }
                });
            }

            this.$nextTick(() => {
                this.haveData = true;
                this.$parent.$parent.$parent.haveData = this.haveData;
            });
        },
        methods: {
            async checkSelector(ref){
                if(this.$root._route.params.id === undefined) {
                    this.code = this.$children[0].$children[0].$refs['RefCode'].datainput.value;
                    this.name = this.$refs['RefName'].datainput.value;
                    this.surname = this.$refs['RefSurname'].datainput.value;
                }

                let selected = '';
                var from = '';
                var url = '';
                var dispatch = false;

                if(this.$refs[ref].checkdata !== undefined){
                    selected = Object.entries(this.$refs[ref].checkdata);
                }

                switch (ref){
                    case 'RefStaffType':
                        this.staff_type = this.$refs[ref].checkdata;
                        from = 'training';
                        url = from + '/feed/by-staff-type/' + selected[0][0];
                        dispatch = true;
                        break;
                    case 'RefTypeScheduleShift':
                        if(this.$refs[ref].selected){
                            this.type_schedule = this.$refs[ref].datainput.value;

                            await this.$store.dispatch('getFeed', {
                                from: 'shift'
                            }, {root: true});
                        }
                        break;
                    case 'RefTypeScheduleNewSchedule':
                        if(this.$refs[ref].selected){
                            this.type_schedule = this.$refs[ref].datainput.value;
                            this.openNewSchedule();
                        }
                        break;
                    case 'RefShift':
                        this.shift = this.$refs[ref].checkdata;
                        break;
                    case 'RefTraining':
                        var training = this.$refs[ref].checkdata;

                        if(Object.keys(training).length !== 0) {
                            this.training = [];
                            this.getterFeed['training'].forEach((find) => {
                                Object.keys(training).forEach((train) => {
                                    if(find.id === train){
                                        this.training.push(find);
                                    }
                                });
                            });
                        }
                        break;
                }

                if(selected.length !== 0 && dispatch){
                    await this.$store.dispatch('getCasuistry', {
                        from: from,
                        url: url,
                    }, {root: true});
                }

                this.$nextTick(() => {
                    this.refresh++;
                });
            },
            openNewSchedule(){
                this.Global.windowOpen(this.Global.openSecondWindow('schedule_by_staff', {
                    id: this.$root._route.params.id !== undefined ? this.$root._route.params.id : this.tempId,
                }),'window_new_schedule');

                var that = this;
                window.UpdatedSelect = async function () {
                    await that.$store.dispatch('getCasuistry', {
                        from: 'schedule',
                        url: 'staff/staff/' + (that.$root._route.params.id === undefined ? that.tempId : that.$root._route.params.id) + '/schedule'
                    }, {root: true});

                    that.refreshSchedule++;
                }
            },
            openModify(){
                this.Global.openPopUp('data_select_training_data_selector_popup');
            }
        }
    }
</script>

<style>
    #data_select_staff_type_selector_label, #data_select_shift_selector_label, #data_select_department_specialty_selector_label {
        text-align: right;
        width: 150px;
    }

    #data_select_training_data_select_SUMMARY{
        display: none !important;
    }
</style>