import { render, staticRenderFns } from "./NoDropSections.vue?vue&type=template&id=771c809a"
import script from "./NoDropSections.vue?vue&type=script&lang=js"
export * from "./NoDropSections.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports