<template>
    <LoaderTemplate :haveData="haveData">
        <template v-slot:content>
            <form id="form_data">
                <div class="gh-content-global">
                    <CodeNameDescription
                        :code="{
                            show: false,
                            required: false,
                            value: ''
                        }"
                        :name="{
                            show: true,
                            required: true,
                            value: getterGet.groups !== undefined && getterGet.groups['groups'] !== undefined ? getterGet.groups['groups'].name : ''
                        }"
                        :description="{
                            show: true,
                            required: false,
                            value: getterGet.groups !== undefined && getterGet.groups['groups'] !== undefined ? getterGet.groups['groups'].description : ''
                        }"
                    />
                    <div style="display: flex; width: 100%; padding-bottom: 6px">
                        <div :class="$root._route.params.view ? 'T15' : 'T15_b'" style="width: 150px; min-width: 150px;text-align: right;margin-right: 11px;">
                            {{$t('permissions') + ($root._route.params.view ? '' : '*')}}
                        </div>
                        <div style="display: block; width: 100%; margin-right: 5px;">
                            <div class="gh-row-content section-permissions" v-for="(item, index) in getterFeed.permissions" :key="index" :style="index % 2 ? '' : 'background-color: #d9edfc;'">
                                <div>
                                    <div style="display: flex; flex-wrap: wrap;">
                                        <div v-for="(itemChildren,indexChildren) in item" :key="indexChildren" :title="itemChildren.description"  style="display: flex; min-width: 280px; box-sizing: border-box;">
                                            <GhChecks
                                                :datalabel="{
                                                    text: '',
                                                    style: '',
                                                    class: '',
                                                    id: ''
                                                }"
                                                :datainput="{
                                                    disabled: $root._route.params.view,
                                                    required: true,
                                                    selected: getterGet.groups !== undefined && getterGet.groups['groups'] !== undefined && getterGet.groups['groups'].permissions !== undefined && getterGet.groups['groups'].permissions.length !== 0 ? Object.values(getterGet.groups['groups'].permissions).includes(itemChildren.id) : false,
                                                    type: 'checkbox',
                                                    id: itemChildren.id,
                                                    value: itemChildren.id,
                                                    label_required: $t('permissions') + '*',
                                                    name: 'data[permissions][]'
                                                }"
                                            />
                                            <label class="gh_text_field-label T15" style="margin-left: 10px;">{{itemChildren.name}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="gh-row-content">
                        <template v-if="!$root._route.params.view">
                            <GhDataSelector
                                :datablock="{
                                    id: 'data_select_profiles',
                                    class: 'T15_b',
                                    subtitle: $t('select_profiles') + ':',
                                    text: $t('profiles') + '*',
                                    label: $t('profiles'),
                                    type: 'checkbox',
                                    columns: 3,
                                    style: '',
                                    required: true
                                }"
                                :datainput="{
                                    id: 'value_data_select_profiles',
                                    name: 'data[profiles][]'
                                }"
                                :selected_input="getterGet.groups !== undefined && getterGet.groups['groups'] !== undefined ? getterGet.groups['groups'].profiles : []"
                                :feed="getterFeed['profiles']"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('profiles') + ':'}}</label>
                            <label class="gh_text_field-label T15_b">{{getterGet.groups !== undefined && getterGet.groups['groups'] !== undefined && getterGet.groups['groups'].profiles !== undefined ? Object.values(getterGet.groups['groups'].profiles).join(', ') : ''}}</label>
                        </template>
                    </div>
                    <div class="gh-row-content">
                        <template v-if="!$root._route.params.view">
                            <GhSearchWindow
                                :dataBlock="{
                                    label: {
                                        style: 'margin-right: 10px; width: 150px; min-width: 150px; text-align: right;'
                                    },
                                    ResultSearch: 'search',
                                    nameStore: 'Feed',
                                    imagelocation: require('@/assets/gh_new_dessign/floating.svg'),
                                    id: 'users',
                                    text: $t('users') + '*',
                                    textlocation: $t('add') + ' ' + $t('users'),
                                    textuserselected: $t('selected_users'),
                                    actionresult: 'loadSearch',
                                    nameResult: 'data[users]',
                                    supportPermision: false,
                                    required: true,
                                    loader: require('@/assets/gh_new_dessign/ajax-loader_blue.gif')
                                }"
                                :mainSelector="{
                                    name: 'users_search',
                                    formId: 'data',
                                    actionsearch: 'setSearch'
                                }"
                                :tableResolt="{
                                    header: header,
                                    data: []
                                }"
                                :tableSelected="{
                                    headersummary: summary,
                                    selected: getterGet.groups !== undefined && getterGet.groups['groups'] !== undefined ? getterGet.groups['groups'].users : []
                                }"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('users') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{users}}</label>
                        </template>
                    </div>
                </div>
            </form>
        </template>
    </LoaderTemplate>
</template>

<script>
    import LoaderTemplate from '@/view/Shared/Loader/loader.vue';
    import CodeNameDescription from '@/view/Shared/Form/code_name_description.vue';
    import GhChecks from "fe-gh-checks-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhSearchWindow from "fe-gh-float-window-lib";

    import {mapGetters} from "vuex";

    export default {
        name: 'form_groups',
        components: {
            LoaderTemplate,
            CodeNameDescription,
            GhChecks,
            GhDataSelector,
            GhSearchWindow
        },
        computed: {
            ...mapGetters(['getterGet', 'getterFeed'])
        },
        data(){
            return {
                users: '',
                header: [
                    {text: '', field: 'check', checkall: true, typeCheck: 'checkbox', sorting: false, style: 'min-width: 16px; width: 16px;'},
                    {text: 'code', field: 'code', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 30%;'},
                    {text: 'surname', field: 'surname', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 70%;'},
                ],
                summary: {
                    0: 'name',
                    1: 'surname'
                },
                haveData: false
            }
        },
        async beforeDestroy(){
            await this.$store.dispatch('destroyFeed', {
                from: 'permissions'
            }, {root: true});

            if(!this.$root._route.params.view) {
                await this.$store.dispatch('destroyFeed', {
                    from: 'profiles'
                }, {root: true});
            }

            await this.$store.dispatch('destroyGetByType', {
                from: 'groups'
            }, {root: true});
        },
        async beforeMount(){
            await this.$store.dispatch('getFeed', {
                from: 'permissions'
            }, {root: true});

            await this.$store.dispatch('getFeed', {
                from: 'profiles'
            }, {root: true});

            if(this.$root._route.params.id !== undefined) {
                await this.$store.dispatch('getByType', {
                    from: 'groups',
                    type: 'groups',
                    id: this.$root._route.params.id
                }, {root: true});

                if(this.$root._route.params.view){
                    this.users = '';
                    this.getterGet.groups['groups'].users.forEach((i) => {
                        this.users += i.name + ' ' + i.surname + ', ';
                    });

                    this.users = this.users.substring(0, this.users.length - 2);
                }
            }

            this.$nextTick(() => {
                this.haveData = true;
                this.$parent.$parent.$parent.haveData = this.haveData;
            });
        }
    }
</script>

<style>
    .section-permissions {
        padding-bottom: 3px !important;
        width: 100%;
        flex-wrap: wrap;
        padding-left: 5px;
        padding-top: 2px;
    }

    #data_select_profiles_selector_label{
        width: 150px;
        text-align: right;
    }
</style>