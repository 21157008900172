import GroupDepartment from '@/view/Configuration/Department/Department/Template/section.vue';

const route = [
    {
        path: '/configuration/department/department',
        name: 'config_department_department',
        props: {showMenu: true, showUser: true},
        component: GroupDepartment,
        children: [
            {
                path: '/configuration/department/department/:id',
                name: 'config_department_department_modify',
                component: GroupDepartment,
                props: {id: null}
            },
            {
                path: '/configuration/department/department/:id/:view',
                name: 'config_department_department_view',
                component: GroupDepartment,
                props: {id: null, view: true}
            }
        ]
    }
];

const routes = [
    ...route,
];

export default routes;