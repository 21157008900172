import Pending from '@/view/Plan/Pending/main.vue';

const route = [
    {
        path: '/plan/pending',
        name: 'plan_pending',
        props: {showMenu: true, showUser: true},
        component: Pending
    }
];

const routes = [
    ...route
];

export default routes;