<template>
    <LoaderTemplate :haveData="haveData">
        <template v-slot:content>
            <form id="form_data">
                <div class="gh-content-global">
                    <div class="gh-row-content">
                        <template v-if="!$root._route.params.view">
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('priority') + '*',
                                    style: 'width: 150px; min-width: 150px; text-align: right;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'data_priority',
                                    name: 'data[priority]',
                                    style: 'width: 200px',
                                    value: getterGet.procedure !== undefined && getterGet.procedure['w2-priority'] !== undefined ? getterGet.procedure['w2-priority'].priority : '',
                                    type: 'text'
                                }"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('priority') + ':'}}</label>
                            <label class="gh_text_field-label T15_b">{{getterGet.procedure !== undefined && getterGet.procedure['w2-priority'] !== undefined ? getterGet.procedure['w2-priority'].priority : ''}}</label>
                        </template>
                    </div>
                    <div class="gh-row-content">
                        <template v-if="!$root._route.params.view">
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('deadline') + '*',
                                    style: 'width: 150px; min-width: 150px; text-align: right;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'data_deadline',
                                    name: 'data[deadline]',
                                    style: 'width: 200px',
                                    value: getterGet.procedure !== undefined && getterGet.procedure['w2-priority'] !== undefined ? getterGet.procedure['w2-priority'].deadline : '',
                                    type: 'text'
                                }"
                            />
                            <label class="gh_text_field-label T15_b">&nbsp;{{$t('days')}}</label>
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('deadline') + ':'}}</label>
                            <label class="gh_text_field-label T15_b">{{getterGet.procedure !== undefined && getterGet.procedure['w2-priority'] !== undefined ? getterGet.procedure['w2-priority'].deadline : ''}}</label>
                            <label class="gh_text_field-label T15_b">&nbsp;{{$t('days')}}</label>
                        </template>
                    </div>
                </div>
            </form>
        </template>
    </LoaderTemplate>
</template>

<script>
    import LoaderTemplate from '@/view/Shared/Loader/loader.vue';
    import GhInputTextField from "fe-gh-inputtextfield-lib";

    import {mapGetters} from "vuex";

    export default {
        name: 'form_staff_type',
        components: {
            GhInputTextField,
            LoaderTemplate
        },
        computed: {
            ...mapGetters(['getterGet'])
        },
        data(){
            return {
                haveData: false
            }
        },
        async beforeDestroy(){
            await this.$store.dispatch('destroyGetByType', {
                from: 'procedure'
            }, {root: true});
        },
        async beforeMount(){
            if(this.$root._route.params.id !== undefined) {
                await this.$store.dispatch('getByType', {
                    from: 'procedure',
                    type: 'w2-priority',
                    id: this.$root._route.params.id
                }, {root: true});
            }

            this.$nextTick(() => {
                this.haveData = true;
                this.$parent.$parent.$parent.haveData = this.haveData;
            });
        }
    }
</script>