<template>
    <LoaderTemplate :haveData="haveData">
        <template v-slot:content>
            <form id="form_data">
                <div class="gh-content-global">
                    <div class="gh-row-content">
                        <label class="gh_text_field-label T15" style="width: 150px; text-align: right;">{{$t('group_assignment') + ':'}}</label>
                        <label class="gh_text_field-label T15_b" style="margin-left: 10px;">{{getterGet.support !== undefined && getterGet.support['schedule'] !== undefined ? getterGet.support['schedule'].group_assignment : ''}}</label>
                    </div>
                    <div class="gh-row-content" style="margin-left: 160px; padding-bottom: unset;">
                        <div>
                            <div v-for="(day, keyDay) in days" class="centered" style="width: 120px;" :style="keyDay % 2 === 0 ? 'background-color: #e9f7ff;' : ''" :key="'DAYS_' + keyDay">
                                <label class="gh_text_field-label T15_b">{{$t(day)}}</label>
                            </div>
                        </div>
                    </div>
                    <div class="gh-row-content" style="padding-bottom: unset;">
                        <label class="gh_text_field-label T15_b" style="width: 150px; text-align: right; margin-right: 10px; padding-top: 4px; padding-bottom: 4px;">{{$t('start_time')}}</label>
                        <div>
                            <div v-for="(day, keyDay) in days" class="centered" style="width: 120px; padding-top: 4px; padding-bottom: 4px;" :style="keyDay % 2 === 0 ? 'background-color: #e9f7ff;' : ''" :key="'START_TIME_' + keyDay">
                                <template v-if="!$root._route.params.view">
                                    <GhInputHour
                                        :datalabel="{
                                            style: '',
                                            text: ''
                                        }"
                                        :datainput="{
                                            actualHour: false,
                                            id: 'date_start_time_' + day,
                                            name: 'data[start_time][' + day + ']',
                                            required: true,
                                            hour: getterGet.support !== undefined && getterGet.support['schedule'] !== undefined && getterGet.support['schedule'].start_time !== undefined && getterGet.support['schedule'].start_time[day] !== undefined ? getterGet.support['schedule'].start_time[day].hour : '',
                                            minute: getterGet.support !== undefined && getterGet.support['schedule'] !== undefined && getterGet.support['schedule'].start_time !== undefined && getterGet.support['schedule'].start_time[day] !== undefined ? getterGet.support['schedule'].start_time[day].minute : '',
                                            style: 'max-width: 40px;'
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15_b">{{getterGet.support !== undefined && getterGet.support['schedule'] !== undefined && getterGet.support['schedule'].start_time !== undefined && getterGet.support['schedule'].start_time[day] !== undefined ? getterGet.support['schedule'].start_time[day].hour + ':' + getterGet.support['schedule'].start_time[day].minute : ''}}</label>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div class="gh-row-content">
                        <label class="gh_text_field-label T15_b" style="width: 150px; text-align: right; margin-right: 10px; padding-top: 4px; padding-bottom: 4px;">{{$t('end_time')}}</label>
                        <div>
                            <div v-for="(day, keyDay) in days" class="centered" style="width: 120px; padding-top: 4px; padding-bottom: 4px;" :style="keyDay % 2 === 0 ? 'background-color: #e9f7ff;' : ''" :key="'END_TIME_' + keyDay">
                                <template v-if="!$root._route.params.view">
                                    <GhInputHour
                                        :datalabel="{
                                            style: '',
                                            text: ''
                                        }"
                                        :datainput="{
                                            actualHour: false,
                                            id: 'date_end_time_' + day,
                                            name: 'data[end_time][' + day + ']',
                                            required: true,
                                            hour: getterGet.support !== undefined && getterGet.support['schedule'] !== undefined && getterGet.support['schedule'].end_time !== undefined && getterGet.support['schedule'].end_time[day] !== undefined ? getterGet.support['schedule'].end_time[day].hour : '',
                                            minute: getterGet.support !== undefined && getterGet.support['schedule'] !== undefined && getterGet.support['schedule'].end_time !== undefined && getterGet.support['schedule'].end_time[day] !== undefined ? getterGet.support['schedule'].end_time[day].minute : '',
                                            style: 'max-width: 40px;'
                                        }"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15_b">{{getterGet.support !== undefined && getterGet.support['schedule'] !== undefined && getterGet.support['schedule'].end_time !== undefined && getterGet.support['schedule'].end_time[day] !== undefined ? getterGet.support['schedule'].end_time[day].hour + ':' + getterGet.support['schedule'].end_time[day].minute : ''}}</label>
                                </template>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </template>
    </LoaderTemplate>
</template>

<script>
    import LoaderTemplate from '@/view/Shared/Loader/loader.vue';
    import GhInputHour from "fe-gh-input-hour-lib";

    import {mapGetters} from "vuex";

    export default {
        name: 'form_schedule',
        components: {
            LoaderTemplate,
            GhInputHour
        },
        computed: {
            ...mapGetters(['getterGet'])
        },
        data(){
            return {
                days: [
                    'monday',
                    'tuesday',
                    'wednesday',
                    'thursday',
                    'friday',
                ],
                haveData: false
            }
        },
        async beforeDestroy(){
            await this.$store.dispatch('destroyGetByType', {
                from: 'support'
            }, {root: true});
        },
        async beforeMount(){
            if(this.$root._route.params.id !== undefined) {
                await this.$store.dispatch('getByType', {
                    from: 'support',
                    type: 'schedule',
                    id: this.$root._route.params.id
                }, {root: true});
            }

            this.$nextTick(() => {
                this.haveData = true;
                this.$parent.$parent.$parent.haveData = this.haveData;
            });
        }
    }
</script>