var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LoaderTemplate',{attrs:{"haveData":_vm.haveData},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('GhTableCommon',{staticStyle:{"margin-top":"10px"},attrs:{"extratable":{
                id: 'nullified'
            },"header":_vm.header,"data":_vm.getterList['nullified'] !== undefined && _vm.getterList['nullified']['nullified'] !== undefined ? _vm.getterList['nullified']['nullified'].data : []},scopedSlots:_vm._u([{key:"field_redirect",fn:function({itemProp, labelProp}){return [_c('span',{style:(itemProp.flag === 1 ? 'color: red; font-weight: bold;' : (itemProp.flag === 2 ? 'color: red;' : ''))},[_vm._v(" "+_vm._s(itemProp[labelProp])+" ")])]}},{key:"actions",fn:function({itemProp}){return [(_vm.getterList['nullified']['nullified'].actions.view)?[_c('GhAction',{attrs:{"dataction":{
                            id: 'action_view_patients_' + itemProp.id,
                            text: _vm.$t('view'),
                            icon: require('@/assets/gh_new_dessign/view.svg')
                        }},on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('patients_patients_view', {
                            id: itemProp.id,
                            view: true,
                        }),'window_patient')}}})]:_vm._e(),(_vm.getterList['nullified']['nullified'].actions.action)?[_c('router-link',{attrs:{"to":{name: 'patients_patients_action', params: {id: itemProp.id, action: true}}}},[_c('GhAction',{attrs:{"dataction":{
                                id: 'action_action_' + itemProp.id,
                                text: _vm.$t('action'),
                                icon: require('@/assets/gh_new_dessign/tick.svg')
                            }}})],1)]:_vm._e()]}}])})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }