import Patients from '../../view/Patients/main.vue';

import ViewPatient from './Patients/router';

const route = [
    {
        path: '/patients',
        name: 'patients',
        props: {showMenu: true, showUser: true},
        component: Patients
    }
];

const routes = [
    ...route,
    ...ViewPatient
];

export default routes;