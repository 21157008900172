var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LoaderTemplate',{attrs:{"haveData":_vm.haveData},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('form',{attrs:{"id":"form_data"}},[_c('div',{staticClass:"gh-content-global"},[_c('CodeNameDescription',{attrs:{"code":{
                        show: false,
                        required: false,
                        value: ''
                    },"name":{
                        show: true,
                        required: true,
                        value: _vm.getterGet.groups !== undefined && _vm.getterGet.groups['profiles'] !== undefined ? _vm.getterGet.groups['profiles'].name : ''
                    },"description":{
                        show: true,
                        required: false,
                        value: _vm.getterGet.groups !== undefined && _vm.getterGet.groups['profiles'] !== undefined ? _vm.getterGet.groups['profiles'].description : ''
                    }}}),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                                id: 'data_select_groups',
                                class: 'T15_b',
                                subtitle: _vm.$t('select_groups') + ':',
                                text: _vm.$t('groups') + '*',
                                label: _vm.$t('groups'),
                                type: 'checkbox',
                                columns: 3,
                                style: '',
                                required: true
                            },"datainput":{
                                id: 'value_data_select_groups',
                                name: 'data[groups][]'
                            },"selected_input":_vm.getterGet.groups !== undefined && _vm.getterGet.groups['profiles'] !== undefined ? _vm.getterGet.groups['profiles'].groups : [],"feed":_vm.getterFeed['groups']}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('groups') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(_vm.getterGet.groups !== undefined && _vm.getterGet.groups['profiles'] !== undefined && _vm.getterGet.groups['profiles'].groups !== undefined ? Object.values(_vm.getterGet.groups['profiles'].groups).join(', ') : ''))])]],2)],1)])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }