import General from '@/view/Configuration/General/main.vue';

import StaffType from './StaffType/router';
import Shift from './Shift/router';
import TrainingType from './TrainingType/router';
import LocationType from './LocationType/router';
import DepartmentSpecialty from './DepartmentSpecialty/router';
import EquipmentType from './EquipmentType/router';
import ConstraintCategory from './ConstraintCategory/router';
import ConstraintType from './ConstraintType/router';
import Gender from './Gender/router';
import Relation from './Relation/router';

const route = [
    {
        path: '/configuration/general',
        name: 'config_general',
        props: {showMenu: true, showUser: true},
        component: General
    }
];

const routes = [
    ...route,
    ...StaffType,
    ...Shift,
    ...TrainingType,
    ...LocationType,
    ...DepartmentSpecialty,
    ...EquipmentType,
    ...ConstraintCategory,
    ...ConstraintType,
    ...Gender,
    ...Relation
];

export default routes;