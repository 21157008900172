import InProgress from '@/view/Plan/InProgress/main.vue';

const route = [
    {
        path: '/plan/in_progress',
        name: 'plan_in_progress',
        props: {showMenu: true, showUser: true},
        component: InProgress
    }
];

const routes = [
    ...route
];

export default routes;