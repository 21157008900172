<template>
    <LoaderTemplate :haveData="haveData">
        <template v-slot:content>
            <form id="form_data">
                <div class="gh-content-global">
                    <CodeNameDescription
                        :code="{
                            show: true,
                            required: true,
                            value: getterGet.department !== undefined && getterGet.department['group-department'] !== undefined ? getterGet.department['group-department'].code : ''
                        }"
                        :name="{
                            show: true,
                            required: true,
                            value: getterGet.department !== undefined && getterGet.department['group-department'] !== undefined ? getterGet.department['group-department'].name : ''
                        }"
                        :description="{
                            show: false,
                            required: false,
                            value: ''
                        }"
                    />
                    <div class="gh-row-content">
                        <template v-if="!$root._route.params.view">
                            <GhSearchWindow
                                :dataBlock="{
                                    label: {
                                        style: 'margin-right: 10px; width: 150px; min-width: 150px; text-align: right;'
                                    },
                                    ResultSearch: 'search',
                                    nameStore: 'Feed',
                                    imagelocation: require('@/assets/gh_new_dessign/floating.svg'),
                                    id: 'staff',
                                    text: $t('staff'),
                                    textlocation: $t('add') + ' ' + $t('staff'),
                                    textuserselected: $t('selected_staff'),
                                    actionresult: 'loadSearch',
                                    nameResult: 'data[staff]',
                                    supportPermision: false,
                                    loader: require('@/assets/gh_new_dessign/ajax-loader_blue.gif')
                                }"
                                :mainSelector="{
                                    name: 'staff_search',
                                    formId: 'data',
                                    actionsearch: 'setSearch'
                                }"
                                :tableResolt="{
                                    header: header,
                                    data: []
                                }"
                                :tableSelected="{
                                    headersummary: summary,
                                    selected: getterGet.department !== undefined && getterGet.department['group-department'] !== undefined ? getterGet.department['group-department'].staff : []
                                }"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('staff') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{staff}}</label>
                        </template>
                    </div>
                </div>
            </form>
        </template>
    </LoaderTemplate>
</template>

<script>
    import LoaderTemplate from '@/view/Shared/Loader/loader.vue';
    import CodeNameDescription from '@/view/Shared/Form/code_name_description.vue';
    import GhSearchWindow from "fe-gh-float-window-lib";

    import {mapGetters} from "vuex";

    export default {
        name: 'form_group_department',
        components: {
            LoaderTemplate,
            CodeNameDescription,
            GhSearchWindow
        },
        computed: {
            ...mapGetters(['getterGet'])
        },
        data(){
            return {
                staff: '',
                header: [
                    {text: '', field: 'check', checkall: true, typeCheck: 'checkbox', sorting: false, style: 'min-width: 16px; width: 16px;'},
                    {text: 'code', field: 'code', sorting: true, typeSort: 'code', style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 10%;'},
                    {text: 'surname', field: 'surname', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                    {text: 'schedule', field: 'schedule', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 30%;'},
                    {text: 'department_specialty', field: 'department_specialty', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                    {text: 'training', field: 'training', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                ],
                summary: {
                    0: 'name',
                    1: 'surname'
                },
                haveData: false
            }
        },
        async beforeDestroy(){
            await this.$store.dispatch('destroyGetByType', {
                from: 'department'
            }, {root: true});
        },
        async beforeMount(){
            if(this.$root._route.params.id !== undefined) {
                await this.$store.dispatch('getByType', {
                    from: 'department',
                    type: 'group-department',
                    id: this.$root._route.params.id
                }, {root: true});
            }

            if(this.$root._route.params.view){
                this.staff = '';
                this.getterGet.department['group-department'].staff.forEach((i) => {
                    this.staff += i.name + ' ' + i.surname + ', ';
                });

                this.staff = this.staff.substring(0, this.staff.length - 2);
            }

            this.$nextTick(() => {
                this.haveData = true;
                this.$parent.$parent.$parent.haveData = this.haveData;
            });
        }
    }
</script>