<template>
    <LoaderTemplate :haveData="haveData">
        <template v-slot:content>
            <form id="form_data">
                <div class="gh-content-global">
                    <CodeNameDescription
                        :code="{
                            show: false,
                            required: false,
                            value: ''
                        }"
                        :name="{
                            show: true,
                            required: true,
                            value: getterGet.support !== undefined && getterGet.support['priorities'] !== undefined ? getterGet.support['priorities'].name : ''
                        }"
                        :description="{
                            show: true,
                            required: true,
                            value: getterGet.support !== undefined && getterGet.support['priorities'] !== undefined ? getterGet.support['priorities'].description : ''
                        }"
                    />
                </div>
                <template v-for="(assignment, keyAssignment) in getterFeed['assignations']">
                    <div class="section_line" style="margin-top: -5px;" :key="'ASSIGNMENT_TITLE_' + keyAssignment">
                        <div class="T15_b" style="padding-left:13px;">{{assignment}}</div>
                    </div>
                    <div class="gh-content-global" :key="'ASSIGNMENT_' + keyAssignment">
                        <div class="gh-row-content">
                            <template v-if="!$root._route.params.view">
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('response_time') + ' (' + $t('abbreviation_hour') + ')*'}}</label>
                                <GhInputTextField
                                    :datalabel="{
                                        text: '',
                                        style: '',
                                        class: ''
                                    }"
                                    :datainput="{
                                        required: true,
                                        label_required: assignment + ' ' + $t('response_time') + ' (' + $t('abbreviation_hour') + ')*',
                                        id: 'data_assignments_' + keyAssignment + '_response_time',
                                        name: 'data[assignments][' + keyAssignment + '][response_time]',
                                        style: 'width: 150px',
                                        value: getterGet.support !== undefined && getterGet.support['priorities'] !== undefined && getterGet.support['priorities'].assignments !== undefined && getterGet.support['priorities'].assignments[keyAssignment] !== undefined ? getterGet.support['priorities'].assignments[keyAssignment].response_time : '',
                                        type: 'int',
                                        limitNumMax: 999
                                    }"
                                />
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('resolution_time') + ' (' + $t('abbreviation_hour') + ')*'}}</label>
                                <GhInputTextField
                                    :datalabel="{
                                        text: '',
                                        style: '',
                                        class: '',
                                    }"
                                    :datainput="{
                                        required: true,
                                        label_required: assignment + ' ' + $t('resolution_time') + ' (' + $t('abbreviation_hour') + ')*',
                                        id: 'data_assignments_' + keyAssignment + '_resolution_time',
                                        name: 'data[assignments][' + keyAssignment + '][resolution_time]',
                                        style: 'width: 150px',
                                        value: getterGet.support !== undefined && getterGet.support['priorities'] !== undefined && getterGet.support['priorities'].assignments !== undefined && getterGet.support['priorities'].assignments[keyAssignment] !== undefined ? getterGet.support['priorities'].assignments[keyAssignment].resolution_time : '',
                                        type: 'int',
                                        limitNumMax: 999
                                    }"
                                />
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('percentage')}}</label>
                                <GhInputTextField
                                    :datalabel="{
                                        text: '',
                                        style: '',
                                        class: ''
                                    }"
                                    :datainput="{
                                        required: false,
                                        id: 'data_assignments_' + keyAssignment + '_percentage',
                                        name: 'data[assignments][' + keyAssignment + '][percentage]',
                                        style: 'width: 150px',
                                        value: getterGet.support !== undefined && getterGet.support['priorities'] !== undefined && getterGet.support['priorities'].assignments !== undefined && getterGet.support['priorities'].assignments[keyAssignment] !== undefined ? getterGet.support['priorities'].assignments[keyAssignment].percentage : '',
                                        type: 'int',
                                        limitNumMax: 100
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('response_time') + ' (' + $t('abbreviation_hour') + '):'}}</label>
                                <label class="gh_text_field-label T15_b" style="width: 155px; margin-right: 10px;">{{getterGet.support !== undefined && getterGet.support['priorities'] !== undefined && getterGet.support['priorities'].assignments !== undefined && getterGet.support['priorities'].assignments[keyAssignment] !== undefined ? getterGet.support['priorities'].assignments[keyAssignment].response_time : ''}}</label>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('resolution_time') + ' (' + $t('abbreviation_hour') + '):'}}</label>
                                <label class="gh_text_field-label T15_b" style="width: 155px; margin-right: 10px;">{{getterGet.support !== undefined && getterGet.support['priorities'] !== undefined && getterGet.support['priorities'].assignments !== undefined && getterGet.support['priorities'].assignments[keyAssignment] !== undefined ? getterGet.support['priorities'].assignments[keyAssignment].resolution_time : ''}}</label>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('percentage') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="width: 155px; margin-right: 10px;">{{getterGet.support !== undefined && getterGet.support['priorities'] !== undefined && getterGet.support['priorities'].assignments !== undefined && getterGet.support['priorities'].assignments[keyAssignment] !== undefined ? getterGet.support['priorities'].assignments[keyAssignment].percentage : ''}}</label>
                            </template>
                        </div>
                    </div>
                </template>
            </form>
        </template>
    </LoaderTemplate>
</template>

<script>
    import LoaderTemplate from '@/view/Shared/Loader/loader.vue';
    import CodeNameDescription from '@/view/Shared/Form/code_name_description.vue';
    import GhInputTextField from "fe-gh-inputtextfield-lib";

    import {mapGetters} from "vuex";

    export default {
        name: 'form_category',
        components: {
            LoaderTemplate,
            CodeNameDescription,
            GhInputTextField
        },
        computed: {
            ...mapGetters(['getterGet', 'getterFeed'])
        },
        data(){
            return {
                haveData: false
            }
        },
        async beforeDestroy(){
            await this.$store.dispatch('destroyFeed', {
                from: 'assignations'
            }, {root: true});

            await this.$store.dispatch('destroyGetByType', {
                from: 'support'
            }, {root: true});
        },
        async beforeMount(){
            await this.$store.dispatch('getFeed', {
                from: 'assignations'
            }, {root: true});

            if(this.$root._route.params.id !== undefined) {
                await this.$store.dispatch('getByType', {
                    from: 'support',
                    type: 'priorities',
                    id: this.$root._route.params.id
                }, {root: true});
            }

            this.$nextTick(() => {
                this.haveData = true;
                this.$parent.$parent.$parent.haveData = this.haveData;
            });
        }
    }
</script>