<template>
    <component :is="components.general" :title="dataPopup.title" :type="dataPopup.type" :stylePopup="dataPopup.stylePopup" :subTitle="dataPopup.subTitle" :bodyText="dataPopup.bodyText" :showButton="dataPopup.showButton" @accept="dataPopup.allowEmit ? $parent.doEmmit(dataPopup.typeEmit) : ''">
        <template v-slot:content>
            <template v-if="dataPopup.showSlot">
                <form id="form_data_action" :key="'REFRESH_ACTION_' + refreshContent">
                    <div class="gh-content-global" style="padding-bottom: unset;">
                        <div class="gh-row-content" style="max-height: 350px !important; overflow-y: auto;">
                            <label class="gh_text_field-label T15_b" style="text-align: right; width: 150px;">{{$t('realized_action') + '*'}}</label>
                            <GhChecks
                                :datainput="{
                                    id: 'data_type_call',
                                    value: 1,
                                    type: 'radio',
                                    name: 'data[type]',
                                    style: 'margin-left: 10px;',
                                    required: true,
                                    selected: typeAction === 1,
                                    label_required: $t('realized_action') + '*'
                                }"
                                :datalabel="{
                                    style: '',
                                    class: '',
                                    id: '',
                                    text: ''
                                }"
                                ref="RefRealizedActionCall"
                                @click="typeRealizedAction('RefRealizedActionCall')"
                            />
                            <label class="gh_text_field-label T15" style="margin-left: 10px;">{{$t('telephone_call')}}</label>
                            <GhChecks
                                :datainput="{
                                    id: 'data_type_call',
                                    value: 2,
                                    type: 'radio',
                                    name: 'data[type]',
                                    style: 'margin-left: 10px;',
                                    required: true,
                                    selected: typeAction === 2,
                                    label_required: $t('realized_action') + '*'
                                }"
                                :datalabel="{
                                    style: '',
                                    class: '',
                                    id: '',
                                    text: ''
                                }"
                                ref="RefRealizedActionMail"
                                @click="typeRealizedAction('RefRealizedActionMail')"
                            />
                            <label class="gh_text_field-label T15" style="margin-left: 10px;">{{$t('mail_send')}}</label>
                        </div>
                        <template v-if="typeAction === 1">
                            <div class="gh-row-content">
                                <GhDataSelector
                                    :datablock="{
                                        id: 'data_type_call_result',
                                        class: 'T15_b',
                                        subtitle: $t('select_call_result') + ':',
                                        text: $t('call_result') + '*',
                                        label: $t('call_result'),
                                        type: 'radio',
                                        columns: 3,
                                        required: true,
                                        style: '',
                                        popupStyle: 'min-width: 900px;width: 55%;'
                                    }"
                                    :datainput="{
                                        id: 'value_data_type_call_result',
                                        name: 'data[call_result]'
                                    }"
                                    :feed="getterFeed['call-result']"
                                    :selected_input="[]"
                                />
                            </div>
                            <div class="gh-row-content">
                                <GhTextarea
                                    :datalabel="{
                                        text: $t('comment') + '*',
                                        class: 'gh_text_field-label T15_b',
                                        styles: 'text-align: right; width: 150px !important; min-width: 150px !important; margin-right: 10px;',
                                    }"
                                    :datainput="{
                                        id: 'data_comment',
                                        name: 'data[comment]',
                                        value: '',
                                        styles: 'width: 100%',
                                        required: true
                                    }"
                                    minlength="10"
                                />
                            </div>
                        </template>
                        <template v-else>
                            <div class="gh-row-content">
                                <GhTextarea
                                    :datalabel="{
                                        text: $t('comment') + '*',
                                        class: 'gh_text_field-label T15_b',
                                        styles: 'text-align: right; width: 150px !important; min-width: 150px !important; margin-right: 10px;',
                                    }"
                                    :datainput="{
                                        id: 'data_comment',
                                        name: 'data[comment]',
                                        value: '',
                                        styles: 'width: 100%',
                                        required: true
                                    }"
                                    minlength="10"
                                />
                            </div>
                            <div class="gh-row-content">
                                <GhEasyUpload
                                    :extrablock="{
                                        id: 'data_attach',
                                        class: '',
                                        name_hidden: 'data[attach]',
                                        id_hidden: 'data_attach',
                                        icon_attach: require('@/assets/gh_new_dessign/attach.svg')
                                    }"
                                    :datalabel="{
                                        id: '',
                                        style: 'margin-right: -15px'
                                    }"
                                    :datainput="{
                                        view: false,
                                        text: $t('attach_send_mail') + '*',
                                        accept: '.pdf, .eml',
                                        show_image: false,
                                        delete: false,
                                        href: 'easyUpload',
                                        downloadHref: 'easyDownload',
                                        file_name: '',
                                        file_path: '',
                                        file_download: '',
                                        required: true
                                    }"
                                />
                            </div>
                        </template>
                    </div>
                </form>
            </template>
        </template>
    </component>
</template>

<script>
    import general from "@/view/Shared/Popups/general.vue";
    import GhChecks from 'fe-gh-checks-lib';
    import GhDataSelector from 'fe-gh-data-selector-lib';
    import GhTextarea from 'fe-gh-textarea-lib';
    import GhEasyUpload from "fe-gh-easy-upload-lib";

    import {mapGetters} from "vuex";

    export default {
        name: 'popup_action',
        props: ['dataPopup', 'refreshContent'],
        components: {
            GhChecks,
            GhDataSelector,
            GhTextarea,
            GhEasyUpload
        },
        computed: {
            ...mapGetters(['getterFeed'])
        },
        data(){
            return {
                typeAction: 1,
                components: {
                    general: general
                }
            }
        },
        watch: {
            'refreshContent'(){
                this.typeAction = 1;
            }
        },
        methods: {
            typeRealizedAction(ref){
                this.typeAction = this.$refs[ref].datainput.value
            }
        }
    }
</script>

<style>
    #data_type_call_result_selector_label, #btn_attach_data_attach_attach_file{
        width: 150px;
        text-align: right;
    }

    #btn_attach_data_attach_attach_file {
        margin-left: 8px;
    }

    #btn_attach_data_attach_attach_file > span{
        margin-right: 8px;
    }
</style>