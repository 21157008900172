var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"-3px"}},[(_vm.$route.name !== 'patient_view')?[_c('GhLocation',{attrs:{"dataLocation":{
                showButton: false,
                secondWindow: true,
                showLoader: 'OK',
                text: _vm.$t('patient'),
                img: require('@/assets/gh_new_dessign/floating.svg')
            },"dataButton":{id: '', text: 'Boton', style: ''}}})]:_vm._e(),_c('transition',{attrs:{"name":"fade","mode":"out-in","appear":""},on:{"after-enter":_vm.afterEnter}},[_c('div',{staticClass:"content_page"},[_c(_vm.component.patients,{tag:"component"})],1)])],2)
}
var staticRenderFns = []

export { render, staticRenderFns }