var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"gh-row-content"},[_c('input',{attrs:{"type":"hidden","name":"data[type]","value":"patients"}}),_c('input',{attrs:{"type":"hidden","name":"data[extra_from]","value":"patients"}}),_c('input',{attrs:{"type":"hidden","name":"data[no_check]","value":"true"}}),_c('GhInputTextField',{attrs:{"datalabel":{
                text: _vm.$t('id_number'),
                style: 'width: 22px; text-align: right;',
                class: 'T15_b'
            },"datainput":{
                required: false,
                id: 'data_id_number',
                name: 'data[id_number]',
                style: 'width: 100px',
                value: '',
                type: 'text'
            }}}),_c('GhInputTextField',{attrs:{"datalabel":{
                text: _vm.$t('name'),
                style: 'width: 100px; text-align: right;',
                class: 'T15_b'
            },"datainput":{
                required: false,
                id: 'data_name',
                name: 'data[name]',
                style: 'width: 200px',
                value: '',
                type: 'text'
            }}}),_c('GhInputTextField',{attrs:{"datalabel":{
                text: _vm.$t('surname'),
                style: 'width: 100px; text-align: right;',
                class: 'T15_b'
            },"datainput":{
                required: false,
                id: 'data_surname',
                name: 'data[surname]',
                style: 'width: 200px',
                value: '',
                type: 'text'
            }}}),_c('GhCalendar',{attrs:{"datalabel":{
                id: 'label_data_date_of_birth',
                text: _vm.$t('dob'),
                style: 'width: 100px; min-width: 100px; text-align: right; margin-top: 2px;'
            },"datainput":{
                id: 'data_date_of_birth',
                name: 'data[dob]',
                value: '',
                disabled_date: 'after',
                range: new Date().getDate() + '/' + (new Date().getMonth() + 1 < 10 ? '0' : '') + (new Date().getMonth() + 1)  + '/' + new Date().getFullYear(),
                required: false
            }}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhChecks',{attrs:{"datainput":{
                id: 'radio_and',
                value: 'and',
                type: 'radio',
                name: 'data[logic]',
                style: 'margin-left: 30px;',
                selected: true
            },"datalabel":{
                style: '',
                class: 'T15 input_label',
                id: 'label_and',
                text: _vm.$t('meet_all_search_criteria_entered')
            }}}),_c('GhChecks',{attrs:{"datainput":{
                id: 'radio_or',
                value: 'or',
                type: 'radio',
                style: 'margin-left: 30px;',
                name: 'data[logic]',
            },"datalabel":{
                style: 'margin-left: 4px;',
                class: 'T15 input_label',
                id: 'label_or',
                text: _vm.$t('meet_any_of_the_search_criteria_entered')
            }}})],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }