var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"next-level"},[_c('LoaderTemplate',{attrs:{"haveData":_vm.haveData},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('input',{attrs:{"type":"hidden","name":"data[type]","value":"users"}}),_c('div',{staticClass:"gh-row-content"},[_c('GhInputTextField',{attrs:{"datalabel":{
                        text: _vm.$t('name'),
                        style: 'width: 150px; text-align: right;',
                        class: 'T15_b'
                    },"datainput":{
                        required: false,
                        id: 'data_name',
                        name: 'data[name]',
                        style: 'width: 200px',
                        value: '',
                        type: 'text'
                    }}}),_c('GhInputTextField',{attrs:{"datalabel":{
                        text: _vm.$t('surname'),
                        style: 'width: 150px; text-align: right;',
                        class: 'T15_b'
                    },"datainput":{
                        required: false,
                        id: 'data_surname',
                        name: 'data[surname]',
                        style: 'width: 200px',
                        value: '',
                        type: 'text'
                    }}})],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }