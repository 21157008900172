var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LoaderTemplate',{attrs:{"haveData":_vm.haveData},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('form',{attrs:{"id":"form_data"}},[_c('div',{staticClass:"gh-content-global"},[_c('CodeNameDescription',{attrs:{"code":{
                        show: true,
                        required: true,
                        value: _vm.getterGet.training !== undefined && _vm.getterGet.training['training'] !== undefined ? _vm.getterGet.training['training'].code : '',
                        read_only: _vm.$root._route.params.id !== undefined,
                    },"name":{
                        show: true,
                        required: true,
                        value: _vm.getterGet.training !== undefined && _vm.getterGet.training['training'] !== undefined ? _vm.getterGet.training['training'].name : '',
                        read_only: _vm.$root._route.params.id !== undefined,
                    },"description":{
                        show: true,
                        required: false,
                        value: _vm.getterGet.training !== undefined && _vm.getterGet.training['training'] !== undefined ? _vm.getterGet.training['training'].description : '',
                        read_only: _vm.$root._route.params.id !== undefined,
                    }}}),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view && _vm.$root._route.params.id === undefined)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                text: _vm.$t('duration') + '*',
                                style: 'width: 150px; text-align: right;',
                                class: 'T15_b'
                            },"datainput":{
                                required: true,
                                id: 'data_duration',
                                name: 'data[duration]',
                                style: 'width: 100px',
                                value: _vm.getterGet.training !== undefined && _vm.getterGet.training['training'] !== undefined ? _vm.getterGet.training['training'].duration : '',
                                type: 'float'
                            }}}),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(" "+_vm._s(_vm.$t('hours')))])]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('duration') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(_vm.getterGet.training !== undefined && _vm.getterGet.training['training'] !== undefined ? _vm.getterGet.training['training'].duration : ''))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(" "+_vm._s(_vm.$t('hours')))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view && _vm.$root._route.params.id === undefined)?[_c('GhDataSelector',{attrs:{"datablock":{
                                id: 'data_select_training_type',
                                class: 'T15_b',
                                subtitle: _vm.$t('select_training_type') + ':',
                                text: _vm.$t('training_type') + '*',
                                label: _vm.$t('training_type'),
                                type: 'radio',
                                columns: 3,
                                style: '',
                                required: true
                            },"datainput":{
                                id: 'value_data_select_training_type',
                                name: 'data[training_type]'
                            },"selected_input":_vm.getterGet.training !== undefined && _vm.getterGet.training['training'] !== undefined ? _vm.getterGet.training['training'].training_type : [],"feed":_vm.getterFeed['training-type']}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('training_type') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(_vm.getterGet.training !== undefined && _vm.getterGet.training['training'] !== undefined ? Object.values(_vm.getterGet.training['training'].training_type).join(', ') : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                                id: 'data_select_target_staff',
                                class: 'T15_b',
                                subtitle: _vm.$t('select_target_staff') + ':',
                                text: _vm.$t('target_staff') + '*',
                                label: _vm.$t('target_staff'),
                                type: 'checkbox',
                                columns: 3,
                                style: '',
                                required: true
                            },"datainput":{
                                id: 'value_data_select_target_staff',
                                name: 'data[target_staff][]'
                            },"selected_input":_vm.getterGet.training !== undefined && _vm.getterGet.training['training'] !== undefined ? _vm.getterGet.training['training'].target_staff : [],"feed":_vm.getterFeed['staff-type']}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('target_staff') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(_vm.getterGet.training !== undefined && _vm.getterGet.training['training'] !== undefined ? Object.values(_vm.getterGet.training['training'].target_staff).join(', ') : ''))])]],2)],1)])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }