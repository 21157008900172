import Configuration from '../../view/Configuration/main.vue';

import General from './General/router';
import Staff from './Staff/router';
import Training from './Training/router';
import Location from './Location/router';
import Procedure from './Procedure/router';
import Equipment from './Equipment/router';
import Department from './Department/router';
import Actions from './Actions/router';
import Support from './Support/router';


const route = [
    {
        path: '/configuration',
        name: 'configuration',
        props: {showMenu: true, showUser: true},
        component: Configuration
    }
];

const routes = [
    ...route,
    ...General,
    ...Staff,
    ...Training,
    ...Location,
    ...Procedure,
    ...Equipment,
    ...Department,
    ...Actions,
    ...Support
];

export default routes;