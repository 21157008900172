import Historic from '@/view/Plan/Historic/main.vue';

const route = [
    {
        path: '/plan/historic',
        name: 'plan_historic',
        props: {showMenu: true, showUser: true},
        component: Historic
    }
];

const routes = [
    ...route
];

export default routes;