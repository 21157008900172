import Vue from "vue";
import vuex from "vuex";
import axios from "axios";

import {API_URL} from "@/common/config";
import Global from "@/store/Global";

Vue.use(vuex);

export default{
    state: {
        casuistry: [],
        flagCasuistry: false
    },
    mutations: {
        loadCasuistry(state, payload) {
            if(payload.destroy){
                state.casuistry[payload.from] = [];
            }else if(payload.from !== undefined){
                if(state.casuistry[payload.from] === undefined){
                    state.casuistry[payload.from] = [];
                }

                state.casuistry[payload.from] = payload.data;
            }

            state.flagCasuistry = !state.flagCasuistry;
        }
    },
    getters: {
        getterCasuistry(state){
            const dummy = state.flagCasuistry; // eslint-disable-line no-unused-vars
            return state.casuistry;
        }
    },
    actions: {
        async getCasuistry(state, item){
            return await axios.get(
                API_URL + item.url
            ).then(
                response => {
                    if(response.status === 200) {
                        state.commit('loadCasuistry', {
                            from: item.from,
                            data: response.data.data
                        });
                    }

                    return response;
                },
                error => {
                    if(error.status !== ''){
                        Global.loadError('error_popup', error.response.data.data);
                    }
                }
            )
        },
        destroyCasuistry(state, item){
            state.commit('loadCasuistry', {
                from: item.from,
                destroy: true
            });
        }
    }
};