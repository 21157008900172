var render = function render(){var _vm=this,_c=_vm._self._c;return _c(_vm.components.general,{tag:"component",attrs:{"title":_vm.dataPopup.title,"type":_vm.dataPopup.type,"stylePopup":_vm.dataPopup.stylePopup,"subTitle":_vm.dataPopup.subTitle,"bodyText":_vm.dataPopup.bodyText,"showButton":_vm.dataPopup.showButton},on:{"accept":function($event){_vm.dataPopup.allowEmit ? _vm.$parent.doEmmit(_vm.dataPopup.typeEmit) : ''}},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.dataPopup.showSlot)?[_c('form',{key:'REFRESH_ACTION_' + _vm.refreshContent,attrs:{"id":"form_data_action"}},[_c('div',{staticClass:"gh-content-global",staticStyle:{"padding-bottom":"unset"}},[_c('div',{staticClass:"gh-row-content",staticStyle:{"max-height":"350px !important","overflow-y":"auto"}},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"text-align":"right","width":"150px"}},[_vm._v(_vm._s(_vm.$t('realized_action') + '*'))]),_c('GhChecks',{ref:"RefRealizedActionCall",attrs:{"datainput":{
                                id: 'data_type_call',
                                value: 1,
                                type: 'radio',
                                name: 'data[type]',
                                style: 'margin-left: 10px;',
                                required: true,
                                selected: _vm.typeAction === 1,
                                label_required: _vm.$t('realized_action') + '*'
                            },"datalabel":{
                                style: '',
                                class: '',
                                id: '',
                                text: ''
                            }},on:{"click":function($event){return _vm.typeRealizedAction('RefRealizedActionCall')}}}),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.$t('telephone_call')))]),_c('GhChecks',{ref:"RefRealizedActionMail",attrs:{"datainput":{
                                id: 'data_type_call',
                                value: 2,
                                type: 'radio',
                                name: 'data[type]',
                                style: 'margin-left: 10px;',
                                required: true,
                                selected: _vm.typeAction === 2,
                                label_required: _vm.$t('realized_action') + '*'
                            },"datalabel":{
                                style: '',
                                class: '',
                                id: '',
                                text: ''
                            }},on:{"click":function($event){return _vm.typeRealizedAction('RefRealizedActionMail')}}}),_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.$t('mail_send')))])],1),(_vm.typeAction === 1)?[_c('div',{staticClass:"gh-row-content"},[_c('GhDataSelector',{attrs:{"datablock":{
                                    id: 'data_type_call_result',
                                    class: 'T15_b',
                                    subtitle: _vm.$t('select_call_result') + ':',
                                    text: _vm.$t('call_result') + '*',
                                    label: _vm.$t('call_result'),
                                    type: 'radio',
                                    columns: 3,
                                    required: true,
                                    style: '',
                                    popupStyle: 'min-width: 900px;width: 55%;'
                                },"datainput":{
                                    id: 'value_data_type_call_result',
                                    name: 'data[call_result]'
                                },"feed":_vm.getterFeed['call-result'],"selected_input":[]}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhTextarea',{attrs:{"datalabel":{
                                    text: _vm.$t('comment') + '*',
                                    class: 'gh_text_field-label T15_b',
                                    styles: 'text-align: right; width: 150px !important; min-width: 150px !important; margin-right: 10px;',
                                },"datainput":{
                                    id: 'data_comment',
                                    name: 'data[comment]',
                                    value: '',
                                    styles: 'width: 100%',
                                    required: true
                                },"minlength":"10"}})],1)]:[_c('div',{staticClass:"gh-row-content"},[_c('GhTextarea',{attrs:{"datalabel":{
                                    text: _vm.$t('comment') + '*',
                                    class: 'gh_text_field-label T15_b',
                                    styles: 'text-align: right; width: 150px !important; min-width: 150px !important; margin-right: 10px;',
                                },"datainput":{
                                    id: 'data_comment',
                                    name: 'data[comment]',
                                    value: '',
                                    styles: 'width: 100%',
                                    required: true
                                },"minlength":"10"}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhEasyUpload',{attrs:{"extrablock":{
                                    id: 'data_attach',
                                    class: '',
                                    name_hidden: 'data[attach]',
                                    id_hidden: 'data_attach',
                                    icon_attach: require('@/assets/gh_new_dessign/attach.svg')
                                },"datalabel":{
                                    id: '',
                                    style: 'margin-right: -15px'
                                },"datainput":{
                                    view: false,
                                    text: _vm.$t('attach_send_mail') + '*',
                                    accept: '.pdf, .eml',
                                    show_image: false,
                                    delete: false,
                                    href: 'easyUpload',
                                    downloadHref: 'easyDownload',
                                    file_name: '',
                                    file_path: '',
                                    file_download: '',
                                    required: true
                                }}})],1)]],2)])]:_vm._e()]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }