<template>
    <div v-if="haveData">
        <slot name="content"/>
    </div>
    <div v-else-if="!haveData">
        <template v-if="startShowLoader">
            <div class="centered" style="padding-bottom: 10px; padding-top: 10px">
                <img :src="require('@/assets/gh_new_dessign/ajax-loader_blue.gif')" alt="">
            </div>
        </template>
    </div>
</template>

<script>
    export default {
        name: 'template_loader',
        props: ['haveData'],
        data(){
            return {
                startShowLoader: false
            }
        },
        beforeMount() {
            let that = this;
            setTimeout(() => {
                that.startShowLoader = true;
            },200);
        }
    }
</script>