<template>
    <div class="float_window">
        <NoDropSections :sections="sections"/>
        <div class="centered_footer">
            <GhButton v-if="!$root._route.params.view && haveData"
                :datainput="{
                    id: 'btn_save',
                    text: $t('save')
                }"
                @click="doSet"
            />
            <GhButton
                :datainput="{
                    id: 'btn_close',
                    text: $t('close')
                }"
                @click="Global.windowClose()"
            />
        </div>
    </div>
</template>

<script>
    import NoDropSections from "@/view/Shared/Sections/NoDropSections.vue";
    import GhButton from "fe-gh-button-lib";

    import form from './form.vue';

    export default {
        name: 'section_staff',
        components: {
            NoDropSections,
            GhButton
        },
        data(){
            return {
                sections: [{id: 'staff', name: 'schedule', component: form}],
                haveData: false
            }
        },
        methods: {
            doSet(){
                if(this.Global.checkRequired('form_data') === 'OK') {
                    const formData = new FormData(document.getElementById('form_data'));
                    this.$store.dispatch('setByType', {
                        formData: [...formData],
                        id: this.$root._route.params.id,
                        from: this.$root._route.params.from !== undefined ? this.$root._route.params.from : 'staff',
                        type: this.$root._route.params.from !== undefined ? (this.$root._route.params.type !== undefined ? this.$root._route.params.type : '') : 'staff',
                        extra_url: 'schedule'
                    }, {root: true}).then((response) => {
                        if(response.status === 200){
                            window.opener.UpdatedSelect();
                            this.Global.windowClose();
                        }
                    });
                }
            }
        }
    }
</script>