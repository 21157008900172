import Vue from 'vue';
import App from './App.vue';

import router from './routes/router';
import store from './store';
import Global from './store/Global';
import axios from 'axios';

import VueI18n from "vue-i18n";
import defaultLangEN from "@/languages/en.json";
import defaultLangES from "@/languages/es.json";

Vue.config.productionTip = false;
Vue.prototype.router = router;
Vue.prototype.Global = Global;

Vue.use(VueI18n);

const actual_lang = JSON.parse(localStorage.getItem('Language-selected-select_lang'));

var defLang = 'en';
var language = {
    'en': defaultLangEN,
    'es': {},
}

if(actual_lang !== null && actual_lang.id === 'es'){
    defLang = 'es';
    language = {
        'en': {},
        'es': defaultLangES,
    }
}

const i18n = new VueI18n({
    locale: defLang,
    fallbackLocale: defLang,
    messages: language,
    silentTranslationWarn: true
});

const loadedLanguages = [defLang];

function setI18nLanguage (lang) {
    i18n.locale = lang;
    axios.defaults.headers.common['Accept-Language'] = lang;
    document.querySelector('html').setAttribute('lang', lang);
    return lang;
}

export function loadLanguageAsync(lang) {
    if (i18n.locale === lang) {
        return Promise.resolve(setI18nLanguage(lang));
    }

    if (loadedLanguages.includes(lang)) {
        return Promise.resolve(setI18nLanguage(lang));
    }

    return import(`@/languages/${lang}.json`).then(
        messages => {
            i18n.setLocaleMessage(lang, messages.default);
            loadedLanguages.push(lang);
            return setI18nLanguage(lang);
        }
    )
}

Vue.prototype.loadedLang = loadLanguageAsync;

axios.interceptors.request.use(
    config => {
        const token = localStorage.getItem("token");
        if (token) {
            config.headers['Authorization'] = `Bearer ${token}`;
        }
        return config;
    },
    error => {
        return Promise.reject(error);
    }
);

axios.interceptors.response.use(function (response) {
    return response
}, function (error) {
    if (error.response.status === 401) {
        store.dispatch('set401');
    }
    return Promise.reject(error)
});

new Vue({
    router,
    store,
    i18n,
    render: h => h(App),
}).$mount('#app')
