var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],staticClass:"modal-backdrop",attrs:{"id":"remember_password"}},[_c('div',{staticClass:"modal fondo_dropdown_2_",staticStyle:{"width":"440px","height":"auto","overflow":"hidden","position":"fixed"},attrs:{"role":"dialog"}},[_c('div',{staticClass:"recordarnombre_t11 t11 text-inline"},[_vm._v(_vm._s(_vm.$t('text_remember_password')))]),_c('div',{staticClass:"introduzcalosdatos_t12 t12",staticStyle:{"padding-top":"10px","padding-bottom":"20px"}},[_vm._v(_vm._s(_vm.$t('extra_text_remember_password')))]),_c('div',{staticStyle:{"width":"313px","margin-left":"24px","margin-top":"20px","padding-top":"20px"}},[_c('GhInputLogin',{ref:"GhInputCif",attrs:{"datalabel":{
                    id: 'label_nif',
                    text: _vm.$t('cif')
                },"datainput":{
                    id: 'input_nif',
                    type: 'text',
                    name: 'nif',
                    class: 'username',
                    error: '',
                    show_message: true
                }}})],1),_c('div',{staticStyle:{"width":"313px","margin-left":"24px","margin-top":"20px"}},[_c('GhInputLogin',{ref:"GhInputEmail",attrs:{"datalabel":{
                    id: 'label_email',
                    text: _vm.$t('email')
                },"datainput":{
                    id: 'input_email',
                    type: 'text',
                    name: 'email',
                    class: 'email',
                    error: '',
                    show_message: true
                }}})],1),_c('div',{staticStyle:{"margin-top":"30px","margin-bottom":"15px","flex-flow":"row-reverse","display":"flex"}},[_c('GhAction',{attrs:{"dataction":{
                    id: 'close_remember_password',
                    text: _vm.$t('close'),
                    class: 't13 alink'
                }},on:{"click":function($event){return _vm.Global.closePopUp('remember_password')}}}),_c('GhAction',{attrs:{"dataction":{
                    id: 'push_remember_password',
                    text: _vm.$t('remember_password'),
                    class: 't13 alink'
                }},on:{"click":function($event){return _vm.doClickRememberPwd()}}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }