import Patient from '@/view/Patients/Patients/Template/section.vue';
import InfoPatient from '@/view/Shared/Window/Patients/main.vue';

const route = [
    {
        path: '/patients/patients',
        name: 'patients_patients',
        props: {showMenu: true, showUser: true},
        component: Patient,
        children: [
            {
                path: '/patients/patients/:id',
                name: 'patients_patients_modify',
                component: Patient,
                props: {id: null}
            },
            {
                path: '/patients/patients/:id/:reschedule',
                name: 'patients_patients_reschedule',
                component: Patient,
                props: {id: null, reschedule: true}
            },
            {
                path: '/patients/patients/:id/action/:action',
                name: 'patients_patients_action',
                component: Patient,
                props: {id: null, action: true}
            }
        ]
    },
    {
        path: '/patients/patients/:id/view/:view',
        name: 'patients_patients_view',
        component: InfoPatient,
        props: {showMenu: false, showUser: false, background: true, id: null, view: true}
    },
    {
        path: '/patient/:id/:view',
        name: 'patient_view',
        component: InfoPatient,
        props: {showMenu: true, showUser: true, background: true, id: null, view: true}
    }
];

const routes = [
    ...route,
];

export default routes;