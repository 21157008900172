<template>
    <LoaderTemplate :haveData="haveData">
        <template v-slot:content>
            <form id="form_data">
                <div class="gh-content-global">
                    <CodeNameDescription
                        :code="{
                            show: true,
                            required: true,
                            value: getterGet.actions !== undefined && getterGet.actions['procedures'] !== undefined ? getterGet.actions['procedures'].code : '',
                            read_only: $root._route.params.id !== undefined
                        }"
                        :name="{
                            show: true,
                            required: true,
                            value: getterGet.actions !== undefined && getterGet.actions['procedures'] !== undefined ? getterGet.actions['procedures'].name : '',
                            read_only: $root._route.params.id !== undefined
                        }"
                        :description="{
                            show: false,
                            required: false,
                            value: ''
                        }"
                    />
                    <div class="gh-row-content">
                        <template v-if="!$root._route.params.view">
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('if') + '*',
                                    style: 'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'data_if',
                                    name: 'data[if]',
                                    style: 'width: 50px',
                                    value: getterGet.actions !== undefined && getterGet.actions['procedures'] !== undefined ? getterGet.actions['procedures'].if : '',
                                    type: 'int',
                                    limitNumMin: 1,
                                    limitNumMax: 10
                                }"
                            />
                            <GhDataSelector
                                :datablock="{
                                    id: 'data_select_incidences',
                                    class: 'T15_b',
                                    subtitle: $t('select_incidence') + ':',
                                    text: $t('incidences') + '*',
                                    label: $t('incidences'),
                                    type: 'radio',
                                    columns: 3,
                                    style: 'margin-left: 10px;',
                                    required: true
                                }"
                                :datainput="{
                                    id: 'value_data_select_incidences',
                                    name: 'data[incidences][]'
                                }"
                                :selected_input="getterGet.actions !== undefined && getterGet.actions['procedures'] !== undefined ? getterGet.actions['procedures'].incidences : []"
                                :feed="getterFeed['incidences']"
                            />
                            <GhDataSelector
                                :datablock="{
                                    id: 'data_select_ambit',
                                    class: 'T15_b',
                                    subtitle: $t('select_ambit') + ':',
                                    text: $t('ambit') + '*',
                                    label: $t('ambit'),
                                    type: 'checkbox',
                                    columns: 3,
                                    style: 'margin-left: 10px;',
                                    required: true
                                }"
                                :datainput="{
                                    id: 'value_data_select_ambit',
                                    name: 'data[ambit][]'
                                }"
                                :selected_input="getterGet.actions !== undefined && getterGet.actions['procedures'] !== undefined ? getterGet.actions['procedures'].ambit : []"
                                :feed="getterFeed['ambits']"
                            />
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('in_period') + '*',
                                    style: 'margin-left: 10px; white-space: nowrap;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'data_in_period',
                                    name: 'data[in_period]',
                                    style: 'width: 50px',
                                    value: getterGet.actions !== undefined && getterGet.actions['procedures'] !== undefined ? getterGet.actions['procedures'].in_period : '',
                                    type: 'int',
                                    limitNumMin: 1,
                                    limitNumMax: 24
                                }"
                            />
                            <label class="gh_text_field-label T15_b" style="margin-left: 10px;">{{$t('months')}}</label>
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px; margin-left: 160px">
                                {{$t('if') + ' ' + (getterGet.actions['procedures'].if !== undefined ? getterGet.actions['procedures'].if : '') + ' ' + $t('incidences').toLowerCase() + ' ' + Object.values(getterGet.actions['procedures'].incidences).join(', ') + ' ' + $t('ambit').toLowerCase() + ' ' + Object.values(getterGet.actions['procedures'].ambit).join(', ') + ' ' + $t('in_period').toLowerCase() + ' ' + (getterGet.actions['procedures'].in_period !== undefined ? getterGet.actions['procedures'].in_period : '') + ' ' + $t('months').toLowerCase()}}
                            </label>
                        </template>
                    </div>
                    <div class="gh-row-content">
                        <template v-if="!$root._route.params.view">
                            <GhDataSelector
                                :datablock="{
                                    id: 'data_select_actions',
                                    class: 'T15_b',
                                    subtitle: $t('select_actions') + ':',
                                    text: $t('actions') + '*',
                                    label: $t('actions'),
                                    type: 'checkbox',
                                    columns: 3,
                                    style: '',
                                    required: true
                                }"
                                :datainput="{
                                    id: 'value_data_select_actions',
                                    name: 'data[actions][]'
                                }"
                                :selected_input="getterGet.actions !== undefined && getterGet.actions['procedures'] !== undefined ? getterGet.actions['procedures'].actions : []"
                                :feed="getterFeed['actions']"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('actions') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{Object.values(getterGet.actions['procedures'].actions).join(', ')}}</label>
                        </template>
                    </div>
                </div>
            </form>
        </template>
    </LoaderTemplate>
</template>

<script>
    import LoaderTemplate from '@/view/Shared/Loader/loader.vue';
    import CodeNameDescription from '@/view/Shared/Form/code_name_description.vue';
    import GhInputTextField from 'fe-gh-inputtextfield-lib';

    import {mapGetters} from "vuex";
    import GhDataSelector from "fe-gh-data-selector-lib";

    export default {
        name: 'form_actions',
        components: {
            GhDataSelector,
            LoaderTemplate,
            CodeNameDescription,
            GhInputTextField
        },
        computed: {
            ...mapGetters(['getterGet', 'getterFeed'])
        },
        data(){
            return {
                haveData: false
            }
        },
        async beforeDestroy(){
            if(!this.$root._route.params.view){
                await this.$store.dispatch('destroyFeed', {
                    from: 'incidences'
                }, {root: true});

                await this.$store.dispatch('destroyFeed', {
                    from: 'ambits'
                }, {root: true});

                await this.$store.dispatch('destroyFeed', {
                    from: 'actions'
                }, {root: true});
            }

            await this.$store.dispatch('destroyGetByType', {
                from: 'actions'
            }, {root: true});
        },
        async beforeMount(){
            if(!this.$root._route.params.view){
                await this.$store.dispatch('getFeed', {
                    from: 'incidences'
                }, {root: true});

                await this.$store.dispatch('getFeed', {
                    from: 'ambits'
                }, {root: true});

                await this.$store.dispatch('getFeed', {
                    from: 'actions'
                }, {root: true});
            }

            if(this.$root._route.params.id !== undefined) {
                await this.$store.dispatch('getByType', {
                    from: 'actions',
                    type: 'procedures',
                    id: this.$root._route.params.id
                }, {root: true});
            }

            this.$nextTick(() => {
                this.haveData = true;
                this.$parent.$parent.$parent.haveData = this.haveData;
            });
        }
    }
</script>

<style>
    #data_select_actions_selector_label{
        width: 150px;
        text-align: end;
    }

    #data_select_incidences_data_select_SUMMARY{
        width: 80px;
    }

    #data_select_ambit_data_select_SUMMARY{
        min-width: 160px;
    }
</style>