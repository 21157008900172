<template>
    <LoaderTemplate :haveData="haveData">
        <template v-slot:content>
            <div class="centered" style="min-width: 1024px;">
                <div class="gh-content-global" style="display: flex; min-width: 1024px; max-width: 1600px">
                    <div style="width: 33.33%; margin-right: 10px">
                        <div class="box-patient">
                            <div style="display: flex;">
                                <div style="width: 60%; position: relative">
                                    <div class="gh-row-content-info" style="position: absolute;bottom: 0;">
                                        <label class="gh_text_field-label T32B">{{getterGet.patients.patients['view'].patient_data.name.toUpperCase()}}</label>
                                    </div>
                                </div>
                                <div style="width: 40%; text-align: right;">
                                    <img class="img-rounded" :src="getterGet.patients.patients['view'].patient_data.image === undefined || getterGet.patients.patients['view'].patient_data.image === '' ? require('@/assets/patient/user.svg') : getterGet.patients.patients['view'].patient_data.image" alt="">
                                </div>
                            </div>
                            <div class="gh-row-content-info" style="margin-top: -10px;">
                                <label class="gh_text_field-label T32R">{{getterGet.patients.patients['view'].patient_data.surname.toUpperCase()}}</label>
                            </div>
                            <div class="gh-row-content-info">
                                <label class="gh_text_field-label T15_b">{{getterGet.patients.patients['view'].patient_data.gender + ' ' + getterGet.patients.patients['view'].patient_data.age + ' ' + $t('y_o')}}</label>
                            </div>
                            <div class="gh-row-content-info">
                                <label class="gh_text_field-label T15">{{$t('mrn') + ' #'}}</label>
                                <label class="gh_text_field-label T15_b">&nbsp;{{getterGet.patients.patients['view'].patient_data.mrn}}</label>
                            </div>
                            <template v-if="showPatient">
                                <div>
                                    <div class="gh-row-content-info">
                                        <label class="gh_text_field-label T15">{{$t('ID') + ' #'}}</label>
                                        <label class="gh_text_field-label T15_b">&nbsp;{{getterGet.patients.patients['view'].patient_data.id_number}}</label>
                                    </div>
                                    <div class="gh-row-content-info">
                                        <label class="gh_text_field-label T15">{{$t('dob') + ': '}}</label>
                                        <label class="gh_text_field-label T15_b">&nbsp;{{getterGet.patients.patients['view'].patient_data.dob}}</label>
                                    </div>
                                    <div class="section_line" style="background-color: #e9f7ff; margin-left: -20px; margin-right: -20px; margin-top: 10px; margin-bottom: 10px;">
                                        <div class="T15_b" style="padding-left:13px;">{{getterGet.patients.patients['view'].patient_data.is_adult ? $t('responsible_person') : $t('contact_person')}}</div>
                                    </div>
                                    <div class="gh-row-content-info" v-if="getterGet.patients.patients['view'].responsible_person.name !== ''">
                                        <label class="gh_text_field-label T15">{{$t('name') + ': '}}</label>
                                        <label class="gh_text_field-label T15_b">&nbsp;{{getterGet.patients.patients['view'].responsible_person.name}}</label>
                                    </div>
                                    <div class="gh-row-content-info" v-if="getterGet.patients.patients['view'].responsible_person.surname !== ''">
                                        <label class="gh_text_field-label T15">{{$t('surname') + ': '}}</label>
                                        <label class="gh_text_field-label T15_b">&nbsp;{{getterGet.patients.patients['view'].responsible_person.surname}}</label>
                                    </div>
                                    <div class="gh-row-content-info" v-if="getterGet.patients.patients['view'].responsible_person.telephone !== ''">
                                        <label class="gh_text_field-label T15">{{$t('telephone') + ': '}}</label>
                                        <label class="gh_text_field-label T15_b">&nbsp;{{getterGet.patients.patients['view'].responsible_person.telephone}}</label>
                                    </div>
                                    <div class="gh-row-content-info" v-if="getterGet.patients.patients['view'].responsible_person.email !== ''">
                                        <label class="gh_text_field-label T15">{{$t('email') + ': '}}</label>
                                        <label class="gh_text_field-label T15_b">&nbsp;{{getterGet.patients.patients['view'].responsible_person.email}}</label>
                                    </div>
                                    <div class="gh-row-content-info" v-if="getterGet.patients.patients['view'].responsible_person.relation !== ''">
                                        <label class="gh_text_field-label T15">{{$t('relation') + ': '}}</label>
                                        <label class="gh_text_field-label T15_b">&nbsp;{{getterGet.patients.patients['view'].responsible_person.relation}}</label>
                                    </div>
                                </div>
                            </template>
                            <div class="centered" style="padding-top: 20px;">
                                <GhAction
                                    :dataction="{
                                        id: 'patient_information_action',
                                        text: showPatient ? $t('show_less_patient_information') : $t('show_more_patient_information'),
                                        icon: showPatient ? require('@/assets/gh_new_dessign/add_less.svg') : require('@/assets/gh_new_dessign/add_plus.svg')
                                    }"
                                    @click="showPatient = !showPatient"
                                />
                            </div>
                        </div>
                        <div class="box-patient" style="margin-top: 10px;">
                            <div class="gh-row-content-info">
                                <img :src="require('@/assets/patient/calendar.svg')" alt="" width="30">
                                <label class="gh_text_field-label T20B">{{$t('upcoming_and_past_appointments')}}</label>
                            </div>
                            <div class="centered" style="margin-top: 20px;">
                                <div style="display: flex; background-color: #e9f7ff; border-radius: 50px;">
                                    <div class="tab-upcoming gh_text_field-label T15_b centered" :style="showUpcoming ? 'background-color: #0b4683; color: #ffffff;' : ''" @click="showUpcoming = true">
                                        {{$t('upcoming').toUpperCase()}}
                                    </div>
                                    <div class="tab-past gh_text_field-label T15_b centered" :style="!showUpcoming ? 'background-color: #0b4683; color: #ffffff;' : ''" @click="showUpcoming = false">
                                        {{$t('past').toUpperCase()}}
                                    </div>
                                </div>
                            </div>
                            <div style="margin-top: 20px;">
                                <template v-if="showUpcoming">
                                    <template v-if="getterGet.patients.patients['view'].appointments.upcoming.length === 0">
                                        <div class="gh-row-content-info">
                                            <label class="gh_text_field-label T15_b">{{$t('without_upcoming_appointments')}}</label>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div v-for="(upcoming, keyUpcoming) in getterGet.patients.patients['view'].appointments.upcoming" :key="'UPCOMING_' + keyUpcoming" :style="keyUpcoming % 2 === 0 ? '' : 'background-color: #e9f7ff'" style="padding: 5px; padding-right: 10px; border-radius: 4px;">
                                            <div class="gh-row-content-info">
                                                <label class="gh_text_field-label T15_b">{{upcoming.date}}</label>
                                                <span style="width: 100%; margin-left: 10px; margin-right: 10px; border-bottom: 1px dotted; margin-top: 4px; align-self: center;"></span>
                                                <label class="gh_text_field-label T15" style="white-space: nowrap;">{{upcoming.main_leader}}</label>
                                            </div>
                                            <div class="gh-row-content-info centered">
                                                <template v-if="upcoming.actions.postpone">
                                                    <GhAction
                                                        style="display: flex"
                                                        :dataction="{
                                                            id: 'action_postpone_upcoming_' + upcoming.id,
                                                            text: $t('postpone'),
                                                            icon: require('@/assets/gh_new_dessign/return.svg')
                                                        }"
                                                        @click="doAction('postpone', upcoming.id)"
                                                    />
                                                </template>
                                                <template v-if="upcoming.actions.confirm">
                                                    <GhAction
                                                        style="display: flex"
                                                        :dataction="{
                                                            id: 'action_confirm_upcoming_' + upcoming.id,
                                                            text: $t('confirm'),
                                                            icon: require('@/assets/gh_new_dessign/tick.svg')
                                                        }"
                                                        @click="doAction('confirm', upcoming.id)"
                                                    />
                                                </template>
                                                <template v-if="upcoming.actions.cancel">
                                                    <GhAction
                                                        style="display: flex"
                                                        :dataction="{
                                                            id: 'action_cancel_upcoming_' + upcoming.id,
                                                            text: $t('cancel'),
                                                            icon: require('@/assets/gh_new_dessign/denied.svg')
                                                        }"
                                                        @click="doAction('cancel', upcoming.id)"
                                                    />
                                                </template>
                                            </div>
                                        </div>
                                    </template>
                                </template>
                                <template v-else>
                                    <template v-if="getterGet.patients.patients['view'].appointments.past.length === 0">
                                        <div class="gh-row-content-info">
                                            <label class="gh_text_field-label T15_b">{{$t('without_past_appointments')}}</label>
                                        </div>
                                    </template>
                                    <template v-else>
                                        <div v-for="(past, keyPast) in getterGet.patients.patients['view'].appointments.past" :key="'UPCOMING_' + keyPast" :style="keyPast % 2 === 0 ? '' : 'background-color: #e9f7ff'" style="padding: 5px; padding-right: 10px; border-radius: 4px;">
                                            <div class="gh-row-content-info">
                                                <label class="gh_text_field-label T15_b">{{past.date}}</label>
                                                <span style="width: 100%; margin-left: 10px; margin-right: 10px; border-bottom: 1px dotted; margin-top: 4px; align-self: center;"></span>
                                                <label class="gh_text_field-label T15" style="white-space: nowrap;">{{past.main_leader}}</label>
                                            </div>
                                        </div>
                                    </template>
                                </template>
                            </div>
                        </div>
                    </div>
                    <div style="width: 66.66%">
                        <div class="box-patient">
                            <div class="gh-row-content-info">
                                <img :src="require('@/assets/patient/alert.svg')" alt="" width="30">
                                <label class="gh_text_field-label T20B">{{$t('intervention')}}</label>
                            </div>
                            <div v-for="(intervention, keyIntervention) in getterGet.patients.patients['view'].intervention" :key="'INTERVENTION_' + keyIntervention">
                                <template v-if="keyIntervention === 0">
                                    <div class="section_line" style="background-color: #e9f7ff; margin-left: -20px; margin-right: -20px; margin-top: 10px; margin-bottom: 10px;">
                                        <div class="T15_b" style="padding-left:13px;">{{$t('intervention') + ' ' + intervention.date}}</div>
                                    </div>
                                    <div class="gh-row-content-info">
                                        <label class="gh_text_field-label T15">{{intervention.intervention}}</label>
                                    </div>
                                    <div class="gh-row-content-info" style="padding-top: 10px;">
                                        <label class="gh_text_field-label T15">{{$t('main_leader') + ': '}}</label>
                                        <label class="gh_text_field-label T15_b">&nbsp;{{intervention.main_leader}}</label>
                                    </div>
                                    <div class="gh-row-content-info" style="padding-top: 10px;">
                                        <label class="gh_text_field-label T17B">{{$t('procedures')}}</label>
                                    </div>
                                    <div class="grid-container-2">
                                        <template v-for="(procedure, keyProcedure) in intervention.procedure">
                                            <div class="gh-row-content-info" style="margin-left: 10px;" :key="'PROCEDURE_NAME_' + keyProcedure">
                                                <div style="display: grid">
                                                    <label class="gh_text_field-label T15_b">{{'• ' + procedure.name}}</label>
                                                    <label class="gh_text_field-label T12" style="margin-left: 10px;">{{procedure.total_procedure_time + ' ' + $t('min')}}</label>
                                                </div>
                                            </div>
                                        </template>
                                    </div>
                                </template>
                                <template v-else>
                                    <template v-if="showIntervention">
                                        <div class="section_line" style="background-color: #e9f7ff; margin-left: -20px; margin-right: -20px; margin-top: 10px; margin-bottom: 10px;">
                                            <div class="T15_b" style="padding-left:13px;">{{$t('intervention') + ' ' + intervention.date}}</div>
                                        </div>
                                        <div class="gh-row-content-info">
                                            <label class="gh_text_field-label T15">{{intervention.intervention}}</label>
                                        </div>
                                        <div class="gh-row-content-info" style="padding-top: 10px;">
                                            <label class="gh_text_field-label T15">{{$t('main_leader') + ': '}}</label>
                                            <label class="gh_text_field-label T15_b">&nbsp;{{intervention.main_leader}}</label>
                                        </div>
                                        <div class="gh-row-content-info" style="padding-top: 10px;">
                                            <label class="gh_text_field-label T17B">{{$t('procedures')}}</label>
                                        </div>
                                        <div class="grid-container-2">
                                            <template v-for="(procedure, keyProcedure) in intervention.procedure">
                                                <div class="gh-row-content-info" style="margin-left: 10px;" :key="'PROCEDURE_NAME_' + keyProcedure">
                                                    <div style="display: grid">
                                                        <label class="gh_text_field-label T15_b">{{'• ' + procedure.name}}</label>
                                                        <label class="gh_text_field-label T12" style="margin-left: 10px;">{{procedure.total_procedure_time + ' ' + $t('min')}}</label>
                                                    </div>
                                                </div>
                                            </template>
                                        </div>
                                    </template>
                                </template>
                            </div>
                            <template v-if="getterGet.patients.patients['view'].intervention.length !== 1">
                                <div class="centered" style="padding-top: 20px;">
                                    <GhAction
                                        :dataction="{
                                            id: 'patient_information_action',
                                            text: showIntervention ? $t('show_less_intervention') : $t('show_more_intervention'),
                                            icon: showIntervention ? require('@/assets/gh_new_dessign/add_less.svg') : require('@/assets/gh_new_dessign/add_plus.svg')
                                        }"
                                        @click="showIntervention = !showIntervention"
                                    />
                                </div>
                            </template>
                        </div>
                    </div>
                </div>
            </div>
            <component :is="components.general" :title="dataPopup.title" :type="dataPopup.type" :stylePopup="dataPopup.stylePopup" :subTitle="dataPopup.subTitle" :bodyText="dataPopup.bodyText" :showButton="dataPopup.showButton" @accept="dataPopup.allowEmit ? doEmmit(dataPopup.typeEmit) : ''">
                <template v-slot:content>
                    <template v-if="dataPopup.showSlot">
                        <form id="form_data" :key="'MOTIVE_' + refreshMotive">
                            <div class="gh-content-global" style="padding-bottom: unset;">
                                <div class="gh-row-content" style="max-height: 350px !important; overflow-y: auto;">
                                    <GhTextarea
                                        :datalabel="{
                                            text: ''
                                        }"
                                        :datainput="{
                                            id: 'data_motive',
                                            name: 'data[motive]',
                                            value: '',
                                            required: true,
                                            label_required: $t('motive') + '*'
                                        }"
                                    />
                                </div>
                            </div>
                        </form>
                    </template>
                </template>
            </component>
        </template>
    </LoaderTemplate>
</template>

<script>
    import LoaderTemplate from "@/view/Shared/Loader/loader.vue";
    import GhTextarea from "fe-gh-textarea-lib";
    import GhAction from "fe-gh-action-lib";

    import {mapGetters} from "vuex";

    import general from "@/view/Shared/Popups/general.vue";

    export default {
        name: 'info_patient',
        components: {
            LoaderTemplate,
            GhTextarea,
            GhAction
        },
        computed: {
            ...mapGetters(['getterGet'])
        },
        data(){
            return {
                showPatient: false,
                showUpcoming: true,
                showIntervention: false,
                dataPopup: {
                    id: null,
                    type: '',
                    title: '',
                    stylePopup: '',
                    subTitle: '',
                    bodyText: '',
                    showButton: false,
                    showSlot: false,
                    allowEmit: false,
                    typeEmit: ''
                },
                components: {
                    general: general
                },
                refreshMotive: 0,
                haveData: false
            }
        },
        async beforeDestroy(){
            await this.$store.dispatch('destroyGetByType', {
                from: 'patients'
            }, {root: true});
        },
        async beforeMount(){
            await this.$store.dispatch('getByType', {
                from: 'patients',
                type: 'patients',
                extra: 'view',
                id: this.$root._route.params.id
            }, {root: true});

            this.$nextTick(() => {
                this.haveData = true;
            });
        },
        methods: {
            async doAction(type, id){
                switch(type){
                    case 'postpone':
                    case 'cancel':
                        this.dataPopup = {
                            id: id,
                            type: 'warning',
                            title: type,
                            stylePopup: 'width: 650px, min-width: 650px, max-width: 650px,',
                            subTitle: '',
                            bodyText: 'to_' + type + '_the_visit_please_indicate_the_reason',
                            showSlot: true,
                            showButton: true,
                            allowEmit: true,
                            typeEmit: type
                        };

                        this.Global.openPopUp('popup_general');
                        break;
                    case 'confirm':
                        var formData = new FormData();
                        formData.append('data[id]', id);

                        await this.$store.dispatch('setByType', {
                            formData: [...formData],
                            id: this.$root._route.params.id,
                            from: 'patients',
                            type: 'patients',
                            extra_url: 'confirm'
                        }, {root: true}).then(async (response) => {
                            if(response.status === 200){
                                this.dataPopup = {
                                    type: 'info',
                                    title: 'confirm',
                                    stylePopup: 'width: 400px, min-width: 400px, max-width: 400px,',
                                    subTitle: '',
                                    bodyText: 'your_visit_has_been_confirmed',
                                    showSlot: false,
                                    showButton: false,
                                    allowEmit: false,
                                    typeEmit: ''
                                }

                                this.Global.openPopUp('popup_general');

                                await this.$store.dispatch('getByType', {
                                    from: 'patients',
                                    type: 'patients',
                                    extra: 'view',
                                    id: this.$root._route.params.id
                                }, {root: true});
                            }
                        });
                        break;
                }
            },
            async doEmmit(type){
                if(this.Global.checkRequired('form_data') === 'OK') {
                    const formData = new FormData(document.getElementById('form_data'));
                    formData.append('data[id]', this.dataPopup.id);

                    await this.$store.dispatch('setByType', {
                        formData: [...formData],
                        id: this.$root._route.params.id,
                        from: 'patients',
                        type: 'patients',
                        extra_url: type
                    }, {root: true}).then(async (response) => {
                        if(response.status === 200){
                            this.refreshMotive++;
                            await this.$store.dispatch('getByType', {
                                from: 'patients',
                                type: 'patients',
                                extra: 'view',
                                id: this.$root._route.params.id
                            }, {root: true});

                            this.Global.closePopUp('popup_general');
                        }
                    });
                }
            }
        }
    }
</script>

<style>
    .box-patient{
        position: relative;
        padding: 20px;
        background-color: white;
        box-shadow: rgb(61, 61, 61) 0px 0.5px 3px;
        border-color: rgb(171, 164, 164);
        border-style: solid;
        border-width: 0px;
        border-radius: 4px;
        margin-top: 6px;
        margin-right: 3px;
    }

    .gh-row-content-info{
        padding-bottom: 4px;
        padding-left: 4px;
        display: flex;
    }

    .grid-container-2 {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
    }

    .img-rounded {
        border-radius: 500px;
        max-width: 100%;
    }

    .tab-upcoming{
        width:130px;
        padding-top: 5px;
        padding-bottom: 5px;
        cursor: pointer;
        border-radius: 50px;
        margin-top: unset !important;
    }

    .tab-past{
        width:130px;
        padding-top: 5px;
        padding-bottom: 5px;
        cursor: pointer;
        border-radius: 50px;
        margin-top: unset !important;
    }

    .T12{
        font-family: IBMPlexSans-Regular !important;
        font-size: 12px !important;
        color: black;
        line-height: 1.1;
    }

    .T17B{
        font-family: IBMPlexSans-Bold !important;
        font-size: 17px !important;
        color: black;
        line-height: 1.1;
    }

    .T20B{
        font-family: IBMPlexSans-Bold !important;
        font-size: 20px !important;
        color: black;
        margin-left: 10px;
        align-content: center;
        line-height: 1.1;
    }

    .T32B{
        font-family: IBMPlexSans-Bold !important;
        font-size: 38px !important;
        color: #0b4683;
    }

    .T32R{
        font-family: IBMPlexSans-Regular !important;
        font-size: 38px !important;
        color: #0b4683;
    }

    #patient_information_action > img{
        margin-top: unset;
    }

    [id^="action_postpone_upcoming_"], [id^="action_confirm_upcoming_"], [id^="action_cancel_upcoming_"] > img{
        margin-top: 0.5px;
    }

    @media (max-width: 1500px) {
        .T32B {
            font-size: 36px !important;
        }
        .T32R {
            font-size: 36px !important;
        }
    }

    @media (max-width: 1400px) {
        .T32B {
            font-size: 34px !important;
        }
        .T32R {
            font-size: 34px !important;
        }
    }

    @media (max-width: 1200px) {
        .T32B {
            font-size: 32px !important;
        }
        .T32R {
            font-size: 32px !important;
        }
    }

    @media (max-width: 1100px) {
        .T32B {
            font-size: 30px !important;
        }
        .T32R {
            font-size: 30px !important;
        }
    }
</style>