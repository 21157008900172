var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LoaderTemplate',{attrs:{"haveData":_vm.haveData},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('GhTableCommon',{attrs:{"extratable":{
                id: 'assignments'
            },"header":_vm.header,"data":_vm.getterList.support !== undefined && _vm.getterList.support['assignments'] !== undefined ? _vm.getterList.support['assignments'].data : []},scopedSlots:_vm._u([{key:"actions",fn:function({itemProp}){return [(_vm.getterList.support['assignments'].actions.view)?[_c('router-link',{attrs:{"to":{name: 'config_support_assignments_view', params: {id: itemProp.id, view: true}}}},[_c('GhAction',{attrs:{"dataction":{
                                id: 'action_view_assignments_' + itemProp.id,
                                text: _vm.$t('view'),
                                icon: require('@/assets/gh_new_dessign/view.svg')
                            }}})],1)]:_vm._e(),(_vm.getterList.support['assignments'].actions.modify)?[_c('router-link',{attrs:{"to":{name: 'config_support_assignments_modify', params: {id: itemProp.id}}}},[_c('GhAction',{attrs:{"dataction":{
                                id: 'action_edit_assignments_' + itemProp.id,
                                text: _vm.$t('modify'),
                                icon: require('@/assets/gh_new_dessign/edit.svg')
                            }}})],1)]:_vm._e(),(_vm.getterList.support['assignments'].actions.delete && itemProp.delete)?[_c('GhAction',{attrs:{"dataction":{
                            id: 'action_delete_assignments_' + itemProp.id,
                            text: _vm.$t('delete'),
                            icon: require('@/assets/gh_new_dessign/trash.svg')
                        }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','removeByType', {
                            from: 'support',
                            type: 'assignments',
                            id: itemProp.id
                        },{
                            'group_assignment': itemProp.name,
                            'description': itemProp.description,
                            'users': itemProp.users
                        }, _vm.$t('delete_assignment'), _vm.$t('preparing_delete_assignment'), {
                            'getListByType': [
                                {
                                    from: 'support',
                                    type: 'assignments'
                                },
                                {
                                    from: 'support',
                                    type: 'priorities'
                                },
                                {
                                    from: 'support',
                                    type: 'schedule'
                                },
                                {
                                    from: 'support',
                                    type: 'schedule-per-group'
                                }
                            ],
                            'getCountersByType': {
                                type: 'support'
                            }
                        })}}})]:_vm._e()]}},{key:"footer",fn:function(){return [(_vm.getterList.support['assignments'].actions.add)?[_c('div',{staticClass:"centered"},[_c('router-link',{attrs:{"to":{name: 'config_support_assignments'}}},[_c('GhButton',{attrs:{"datainput":{
                                    id: 'btn_new_assignments',
                                    text: _vm.$t('add')
                                }}})],1)],1)]:_vm._e()]},proxy:true}])})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }