import Vue from "vue";
import vuex from "vuex";
import axios from "axios";

import {API_URL} from "@/common/config";
import Global from "@/store/Global";

Vue.use(vuex);

export default{
    state: {
        counters: [],
        flagCounters: false
    },
    mutations: {
        loadCounters(state, payload) {
            state.flagCounters = !state.flagCounters;
            state.counters = payload;
        }
    },
    getters: {
        getterCounters(state){
            const dummy = state.flagCounters; // eslint-disable-line no-unused-vars
            return state.counters;
        }
    },
    actions: {
        async getCountersByType(state, item){
            await axios.get(
                API_URL + item.type + "/count"
            ).then(
                response => {
                    if(response.status === 200) {
                        state.commit('loadCounters', response.data.data);
                    }
                },
                error => {
                    if(error.status !== ''){
                        Global.loadError('error_popup', error.response.data.data);
                    }
                }
            )
        },
        setCountersByType(state, item){
            state.commit('loadCounters', item.counters);
        }
    }
};