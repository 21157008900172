var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticStyle:{"margin-top":"-3px"}},[_c('GhLocation',{attrs:{"dataLocation":{
            showButton: false,
            secondWindow: true,
            showLoader: 'OK',
            text: _vm.$t('schedule'),
            img: require('@/assets/gh_new_dessign/floating.svg')
        },"dataButton":{id: '', text: 'Boton', style: ''}}}),_c('transition',{attrs:{"name":"fade","mode":"out-in","appear":""},on:{"after-enter":_vm.afterEnter}},[_c('div',{staticClass:"content_page"},[_c(_vm.component.SectionSchedule,{tag:"component"})],1)])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }