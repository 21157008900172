var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{key:'REFRESH_TEMPLATE' + _vm.refreshTemplate,staticClass:"float_window"},[(_vm.$root._route.params.action)?[_c('DropSections',{attrs:{"sections":_vm.sectionPatientData}}),_c('DropSections',{staticStyle:{"margin-top":"-9px"},attrs:{"sections":_vm.sectionPlanificated}}),_c('DropSections',{staticStyle:{"margin-top":"-9px"},attrs:{"sections":_vm.sectionHistoric}})]:(_vm.planificated)?[_c('DropSections',{attrs:{"sections":_vm.sectionPatientData}}),_c('NoDropSections',{staticStyle:{"margin-top":"-9px"},attrs:{"sections":_vm.sectionPlanificated}})]:[_c('NoDropSections',{attrs:{"sections":_vm.sectionPatientData}})],_c('div',{staticClass:"centered_footer"},[(!_vm.$root._route.params.view && _vm.haveData && _vm.allowSave && _vm.allowFinish)?_c('GhButton',{attrs:{"datainput":{
                id: 'btn_finish',
                text: _vm.$t('finish')
            }},on:{"click":function($event){return _vm.doSet('finish')}}}):_vm._e(),(!_vm.$root._route.params.view && _vm.haveData && _vm.allowSave)?_c('GhButton',{attrs:{"datainput":{
                id: 'btn_save',
                text: _vm.$t('save')
            }},on:{"click":function($event){return _vm.doSet('save')}}}):_vm._e(),(!_vm.$root._route.params.view && _vm.haveData && _vm.allowSchedule)?_c('GhButton',{attrs:{"datainput":{
                id: 'btn_agend',
                text: _vm.$t('agend')
            }},on:{"click":function($event){return _vm.doSet('agend')}}}):_vm._e(),(!_vm.$root._route.params.view && _vm.haveData && _vm.allowReschedule)?_c('GhButton',{attrs:{"datainput":{
                id: 'btn_reschedule',
                text: _vm.$t('reschedule')
            }},on:{"click":function($event){return _vm.doSet('reschedule')}}}):_vm._e(),(!_vm.$root._route.params.view && _vm.allowAction)?_c('GhButton',{attrs:{"datainput":{
                id: 'btn_action',
                text: _vm.$t('action')
            }},on:{"click":function($event){return _vm.doSet('action')}}}):_vm._e(),_c('GhButton',{attrs:{"datainput":{
                id: 'btn_return',
                text: _vm.$t('back')
            }},on:{"click":function($event){_vm.$root._route.params.reschedule ? _vm.router.push({name: 'plan'}) : _vm.$root._route.params.action ? _vm.router.push({name: 'advertisements'}) : _vm.router.push({name: 'patients'})}}})],1),(_vm.$root._route.params.action)?[_c(_vm.components.action,{tag:"component",attrs:{"dataPopup":_vm.dataPopup,"refreshContent":_vm.refreshContent}})]:_vm._e()],2)
}
var staticRenderFns = []

export { render, staticRenderFns }