<template>
    <div style="margin-top: -3px;">
        <template v-if="$route.name !== 'patient_view'">
            <GhLocation
                :dataLocation="{
                    showButton: false,
                    secondWindow: true,
                    showLoader: 'OK',
                    text: $t('patient'),
                    img: require('@/assets/gh_new_dessign/floating.svg')
                }"
                :dataButton="{id: '', text: 'Boton', style: ''}"
            />
        </template>

        <transition name="fade" mode="out-in" v-on:after-enter="afterEnter" appear>
            <div class="content_page">
                <component :is="component.patients"/>
            </div>
        </transition>
    </div>
</template>

<script>
    import GhLocation from "fe-gh-location-lib";

    import patients from "@/view/Shared/Form/patients.vue";

    export default {
        name: 'window_patient',
        components: {
            GhLocation,
        },
        data(){
            return {
                component: {
                    patients: patients
                }
            }
        },
        methods:{
            afterEnter: function() {
                return true;
            },
        }
    }
</script>