import ConstraintCategory from '@/view/Configuration/General/ConstraintCategory/Template/section.vue';

const route = [
    {
        path: '/configuration/general/constraint-category',
        name: 'config_general_constraint_category',
        props: {showMenu: true, showUser: true},
        component: ConstraintCategory,
        children: [
            {
                path: '/configuration/general/constraint-category/:id',
                name: 'config_general_constraint_category_modify',
                component: ConstraintCategory,
                props: {id: null}
            },
            {
                path: '/configuration/general/constraint-category/:id/:view',
                name: 'config_general_constraint_category_view',
                component: ConstraintCategory,
                props: {id: null, view: true}
            }
        ]
    }
];

const routes = [
    ...route,
];

export default routes;