import Location from '@/view/Configuration/Location/Location/Template/section.vue';
// import Schedule from '@/view/Shared/Window/Schedule/main.vue';

const route = [
    {
        path: '/configuration/location/location',
        name: 'config_location_location',
        props: {showMenu: true, showUser: true},
        component: Location,
        children: [
            {
                path: '/configuration/location/location/:id',
                name: 'config_location_location_modify',
                component: Location,
                props: {id: null}
            },
            {
                path: '/configuration/location/location/:id/:view',
                name: 'config_location_location_view',
                component: Location,
                props: {id: null, view: true}
            }
        ]
    },
    // {
    //     path: '/schedule/by-location/:id',
    //     name: 'schedule_by_location',
    //     component: Schedule,
    //     props: {showMenu: false, showUser: false, background: true, id: null},
    //     children: [
    //         {
    //             path: '/schedule/by-location/:id/:view',
    //             name: 'schedule_by_location_view',
    //             component: Schedule,
    //             props: {view: true}
    //         }
    //     ]
    // },
];

const routes = [
    ...route,
];

export default routes;