<template>
    <div>
        <div v-for="(label, labelIndex) in sections" :key="'NO_DROP_SECTION_' + labelIndex" :style="labelIndex === sections.length - 1 ? 'padding-bottom: 5px;' : ''">
            <template v-if="perm === undefined && label.show === undefined || perm !== undefined && perm[label.result] || label.show === true">
                <GhNoDropSelector
                    :datablock="{
                        id: 'no_drop_section_' + label.id,
                        title: $t(label.name),
                        label: label.second_text === undefined ? '' : (result !== undefined && Object.keys(result).length !== 0 ? result[label.result] + ' ' : '') + $t(label.second_text)
                    }"
                >
                    <template v-slot:content>
                        <component v-bind:is="label.component"/>
                    </template>
                </GhNoDropSelector>
            </template>
        </div>
    </div>
</template>

<script>
    import GhNoDropSelector from 'fe-gh-no-dropselector-lib';

    export default {
        name: 'template_no_drop_sections',
        props: ['sections', 'result', 'perm'],
        components: {
            GhNoDropSelector
        }
    }
</script>