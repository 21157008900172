var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LoaderTemplate',{attrs:{"haveData":_vm.haveData},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('form',{attrs:{"id":"form_data"}},[_c('div',{staticClass:"gh-content-global"},[_c('CodeNameDescription',{attrs:{"code":{
                        show: true,
                        required: true,
                        value: _vm.code
                    },"name":{
                        show: true,
                        required: true,
                        value: _vm.name
                    },"description":{
                        show: false,
                        required: false,
                        value: ''
                    }}}),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{ref:"RefTime",attrs:{"datablock":{
                                id: 'data_select_time',
                                class: 'T15_b',
                                subtitle: _vm.$t('select_time') + ':',
                                text: _vm.$t('time') + '*',
                                label: _vm.$t('time'),
                                type: 'radio',
                                columns: 3,
                                style: '',
                                required: true
                            },"datainput":{
                                id: 'value_data_select_time',
                                name: 'data[time]'
                            },"selected_input":_vm.getterGet.general !== undefined && _vm.getterGet.general['constraint-category'] !== undefined ? _vm.getterGet.general['constraint-category'].time : [],"feed":_vm.getterFeed.time},on:{"accept":_vm.getTypeTiem}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('time') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(_vm.getterGet.general !== undefined && _vm.getterGet.general['constraint-category'] !== undefined ? Object.values(_vm.getterGet.general['constraint-category'].time).join(', ') : ''))])]],2),(_vm.getterCasuistry.time !== undefined && _vm.getterCasuistry.time.flag !== undefined && _vm.getterCasuistry.time.flag !== 0)?_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputHour',{attrs:{"datalabel":{
                                style: 'text-align: right; min-width: 150px;',
                                text: _vm.type
                            },"datainput":{
                                actualHour: false,
                                id: 'date_hour',
                                name: 'data[hour][from]',
                                required: true,
                                label_required: _vm.type + '*',
                                hour: _vm.getterGet.general !== undefined && _vm.getterGet.general['constraint-category'] !== undefined ? _vm.getterGet.general['constraint-category'].hour.from.hour : '',
                                minute: _vm.getterGet.general !== undefined && _vm.getterGet.general['constraint-category'] !== undefined ? _vm.getterGet.general['constraint-category'].hour.from.minute : '',
                                style: 'max-width: 40px;'
                            }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.type + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(_vm.getterGet.general !== undefined && _vm.getterGet.general['constraint-category'] !== undefined ? _vm.getterGet.general['constraint-category'].hour.from.hour + ':' + _vm.getterGet.general['constraint-category'].hour.from.minute : ''))])],(_vm.getterCasuistry.time.flag === 2)?[(!_vm.$root._route.params.view)?[_c('GhInputHour',{attrs:{"datalabel":{
                                    style: 'margin-left: 10px;',
                                    text: _vm.$t('to').toLowerCase()
                                },"datainput":{
                                    actualHour: false,
                                    id: 'date_hour',
                                    name: 'data[hour][to]',
                                    required: true,
                                    label_required: _vm.type + '*',
                                    hour: _vm.getterGet.general !== undefined && _vm.getterGet.general['constraint-category'] !== undefined ? _vm.getterGet.general['constraint-category'].hour.to.hour : '',
                                    minute: _vm.getterGet.general !== undefined && _vm.getterGet.general['constraint-category'] !== undefined ? _vm.getterGet.general['constraint-category'].hour.to.minute : '',
                                    style: 'max-width: 40px;'
                                }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-left":"10px","margin-right":"10px"}},[_vm._v(_vm._s(_vm.$t('to').toLowerCase()))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(_vm.getterGet.general !== undefined && _vm.getterGet.general['constraint-category'] !== undefined ? _vm.getterGet.general['constraint-category'].hour.to.hour + ':' + _vm.getterGet.general['constraint-category'].hour.to.minute : ''))])]]:_vm._e()],2):_vm._e()],1)])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }