import ProcedureService from '@/view/Configuration/Procedure/ProcedureService/Template/section.vue';

const route = [
    {
        path: '/configuration/procedure/procedure-service',
        name: 'config_procedure_procedure_service',
        props: {showMenu: true, showUser: true},
        component: ProcedureService,
        children: [
            {
                path: '/configuration/procedure/procedure-service/:id',
                name: 'config_procedure_procedure_service_modify',
                component: ProcedureService,
                props: {id: null}
            },
            {
                path: '/configuration/procedure/procedure-service/:id/:view',
                name: 'config_procedure_procedure_service_view',
                component: ProcedureService,
                props: {id: null, view: true}
            }
        ]
    }
];

const routes = [
    ...route,
];

export default routes;