<template>
    <LoaderTemplate :haveData="haveData">
        <template v-slot:content>
            <form id="form_data">
                <div class="gh-content-global">
                    <CodeNameDescription
                        :code="{
                            show: true,
                            required: true,
                            value: code
                        }"
                        :name="{
                            show: true,
                            required: true,
                            value: name
                        }"
                        :description="{
                            show: true,
                            required: false,
                            value: description
                        }"
                        @persist="persistance"
                    />
                    <Schedule :scheduleData="schedule"/>
                </div>
            </form>
        </template>
    </LoaderTemplate>
</template>

<script>
    import LoaderTemplate from '@/view/Shared/Loader/loader.vue';
    import CodeNameDescription from '@/view/Shared/Form/code_name_description.vue';
    import Schedule from '@/view/Shared/Form/schedule.vue';

    import {mapGetters} from "vuex";

    export default {
        name: 'form_shift',
        components: {
            LoaderTemplate,
            CodeNameDescription,
            Schedule
        },
        computed: {
            ...mapGetters(['getterGet'])
        },
        data(){
            return {
                code: '',
                name: '',
                description: '',
                emptySchedule: {
                    week_from: '',
                    week_to: '',
                    entrance_of: '',
                    entrance_to: '',
                    hours: '',
                    days: []
                },
                schedule: [],
                haveData: false
            }
        },
        async beforeDestroy(){
            await this.$store.dispatch('destroyGetByType', {
                from: 'general'
            }, {root: true});
        },
        async beforeMount(){
            if(this.$root._route.params.id !== undefined) {
                await this.$store.dispatch('getByType', {
                    from: 'general',
                    type: 'shift',
                    id: this.$root._route.params.id
                }, {root: true});

                this.code = this.getterGet.general !== undefined && this.getterGet.general['shift'] !== undefined && this.$root._route.params.id !== undefined ? this.getterGet.general['shift'].code : '';
                this.name = this.getterGet.general !== undefined && this.getterGet.general['shift'] !== undefined && this.$root._route.params.id !== undefined ? this.getterGet.general['shift'].name : '';
                this.description = this.getterGet.general !== undefined && this.getterGet.general['shift'] !== undefined && this.$root._route.params.id !== undefined ? this.getterGet.general['shift'].description : '';

                if(this.getterGet.general['shift'].schedule !== undefined && this.getterGet.general['shift'].schedule.length !== 0){
                    this.schedule = this.getterGet.general['shift'].schedule;
                }else{
                    this.schedule.push(this.emptySchedule);
                }
            }else{
                this.schedule.push(this.emptySchedule);
            }

            this.$nextTick(() => {
                this.haveData = true;
                this.$parent.$parent.$parent.haveData = this.haveData;
            });
        },
        methods: {
            persistance(){
                this.code = this.$children[0].$children[0].$refs['RefCode'].datainput.value;
                this.name = this.$children[0].$children[0].$refs['RefName'].datainput.value;
                this.description = this.$children[0].$children[0].$refs['RefDescription'].datainput.value;
            }
        }
    }
</script>