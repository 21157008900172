import Vue from "vue";
import vuex from "vuex";
import axios from "axios";

import {API_URL} from "@/common/config";
import Global from "@/store/Global";

Vue.use(vuex);

export default{
    state: {
        get: [],
        flagGet: false
    },
    mutations: {
        loadGet(state, payload) {
            if(payload.destroy){
                state.get[payload.from] = [];
            }else if(payload.from !== undefined){
                if(state.get[payload.from] === undefined){
                    state.get[payload.from] = [];
                }

                if(payload.type !== undefined){
                    if(state.get[payload.from][payload.type] === undefined){
                        state.get[payload.from][payload.type] = [];
                    }

                    if(payload.extra !== undefined){
                        if(state.get[payload.from][payload.type][payload.extra] === undefined){
                            state.get[payload.from][payload.type][payload.extra] = [];
                        }

                        state.get[payload.from][payload.type][payload.extra] = payload.data;
                    }else {
                        state.get[payload.from][payload.type] = payload.data;
                    }
                }else{
                    state.get[payload.from] = payload.data;
                }
            }

            state.flagGet = !state.flagGet;
        }
    },
    getters: {
        getterGet(state){
            const dummy = state.flagGet; // eslint-disable-line no-unused-vars
            return state.get;
        }
    },
    actions: {
        async getByType(state, item){
            return await axios.get(
                API_URL + (item.from !== undefined ? item.from + '/' : '') + item.type + '/' + item.id + (item.extra !== undefined ? '/' + item.extra : '')
            ).then(
                response => {
                    if(response.status === 200) {
                        state.commit('loadGet', {
                            from: item.from,
                            type: item.type,
                            extra: item.extra,
                            data: response.data.data
                        });
                    }

                    return response;
                },
                error => {
                    if(error.status !== ''){
                        Global.loadError('error_popup', error.response.data.data);
                    }
                }
            )
        },
        destroyGetByType(state, item){
            state.commit('loadGet', {
                from: item.from,
                destroy: true
            });
        }
    }
};