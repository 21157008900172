import Vue from "vue";
import vuex from "vuex";
import axios from "axios";

import {API_URL} from "@/common/config";
import Global from "@/store/Global";

Vue.use(vuex);

export default{
    state: {
        feed: [],
        search: [],
        flagFeed: false
    },
    mutations: {
        loadFeed(state, payload) {
            if(payload.destroy){
                state.feed[payload.from] = [];
            }else if(payload.from !== undefined){
                if(state.feed[payload.from] === undefined){
                    state.feed[payload.from] = [];
                }

                state.feed[payload.from] = payload.data;
            }

            state.flagFeed = !state.flagFeed;
        },
        loadSearch(state, payload){
            state.search = payload;
        }
    },
    getters: {
        getterFeed(state){
            const dummy = state.flagFeed; // eslint-disable-line no-unused-vars
            return state.feed;
        }
    },
    actions: {
        async getFeed(state, item){
            await axios.get(
                API_URL + item.from + '/feed'
            ).then(
                response => {
                    if(response.status === 200) {
                        state.commit('loadFeed', {
                            from: item.from,
                            data: response.data.data
                        });
                    }

                    return response;
                },
                error => {
                    if(error.status !== ''){
                        Global.loadError('error_popup', error.response.data.data);
                    }
                }
            )
        },
        async setSearch(state, item){
            let formData = new FormData();
            let extra_from = undefined;
            let from = '';
            let no_check = false;
            let id = null;

            item.formData.forEach((item) => {
                if(item[0] === 'data[type]'){
                    from = item[1];
                }else if(item[0] === 'data[url_id]'){
                    id = item[1];
                }else if(item[0] === 'data[extra_from]'){
                    extra_from = item[1];
                }else if(item[0] === 'data[no_check]'){
                    no_check = true;
                }else {
                    formData.append(item[0], item[1]);
                }
            });

            return await axios({
                method: "post",
                url: API_URL + (extra_from !== undefined ? extra_from + '/' : '') + (id !== null ? id + '/' : '') + from + '/search',
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if(response.status === 200) {
                        state.commit('loadSearch', response.data.data);

                        if(!no_check){
                            response.data.data.forEach((data)=> {
                                if(Array.isArray(item.selected) === true) {
                                    if (item.selected.findIndex((item) => item.id === data.id) !== -1) {
                                        data['check'] = true
                                    }else{
                                        if(data['check']){
                                            delete data['check']
                                        }
                                    }
                                }else{
                                    data['check'] = data.id === item.selected.id;
                                }
                            });
                        }
                    }

                    return response;
                },
                error => {
                    if(error.status !== ''){
                        Global.loadError('error_popup', error.response.data.data);
                    }
                }
            )
        },
        destroyFeed(state, item){
            state.commit('loadFeed', {
                from: item.from,
                destroy: true
            });
        },
        destroySearch(state){
            state.commit('loadSearch', []);
        }
    }
};