<template>
    <LoaderTemplate :haveData="haveData">
        <template v-slot:content>
            <GhTableCommon
                style="margin-top: 10px;"
                :extratable="{
                    id: 'schedule_per_group'
                }"
                :header="header"
                :data="schedule"
            >
                <template v-slot:actions="{itemProp}">
                    <GhAction
                        :dataction="{
                            id: 'action_delete_schedule_' + itemProp.id,
                            text: $t('delete'),
                            icon: require('@/assets/gh_new_dessign/trash.svg')
                        }"
                        @click="doAction('delete', itemProp.id)"
                    />
                </template>
                <template v-slot:footer>
                    <template v-if="!$root._route.params.view">
                        <div class="centered">
                            <GhButton
                                :datainput="{
                                    id: 'btn_new_priorities',
                                    text: $t('add')
                                }"
                                @click="doAction('add')"
                            />
                        </div>
                    </template>
                </template>
            </GhTableCommon>
            <form id="form_data">
                <div style="display: none" v-for="(item, key) in schedule" :key="'SCHEDULED_' + key">
                    <input type="hidden" :name="'data[schedule][' + key + '][description]'" :value="item.description">
                    <input type="hidden" :name="'data[schedule][' + key + '][day]'" :value="item.day">
                    <input type="hidden" :name="'data[schedule][' + key + '][month]'" :value="item.month">
                </div>
            </form>
            <component :is="components.general" :title="dataPopup.title" :type="dataPopup.type" :stylePopup="dataPopup.stylePopup" :subTitle="dataPopup.subTitle" :bodyText="dataPopup.bodyText" :showButton="dataPopup.showButton" @accept="dataPopup.allowEmit ? doEmmit(dataPopup.typeEmit) : ''">
                <template v-slot:content>
                    <template v-if="dataPopup.showSlot">
                        <form id="form_data_holiday" :key="'REFRESH_NEW_SCHEDULE_' + refreshPopup">
                            <div class="gh-content-global" style="padding-bottom: unset;">
                                <div class="gh-row-content">
                                    <GhTextarea
                                        :datalabel="{
                                            text: $t('description') + '*',
                                            styles: 'min-width: 100px; text-align: right; margin-right: 10px;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            id: 'data_description',
                                            name: 'data[description]',
                                            value: '',
                                            required: true
                                        }"
                                        ref="RefDescription"
                                    />
                                </div>
                                <div class="gh-row-content">
                                    <GhCalendar
                                        :datalabel="{
                                            id: 'label_date_schedule',
                                            text: $t('date'),
                                            style: 'width: 100px; min-width: 100px; text-align: right; margin-right: 12px;'
                                        }"
                                        :datainput="{
                                            id:'data_date',
                                            name:'data[date]',
                                            value: '',
                                            required: true,
                                            format: 'DD/MM',
                                            placeholder: 'DD/MM'
                                        }"
                                        ref="RefDate"
                                    />
                                </div>
                            </div>
                        </form>
                    </template>
                </template>
            </component>
        </template>
    </LoaderTemplate>
</template>

<script>
    import LoaderTemplate from '@/view/Shared/Loader/loader.vue';
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhTextarea from "fe-gh-textarea-lib";
    import GhCalendar from "fe-gh-input-calendar-lib";
    import GhAction from "fe-gh-action-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapGetters} from "vuex";

    import general from "@/view/Shared/Popups/general.vue";

    export default {
        name: 'form_schedule_per_group',
        components: {
            LoaderTemplate,
            GhTableCommon,
            GhTextarea,
            GhCalendar,
            GhAction,
            GhButton
        },
        computed: {
            ...mapGetters(['getterGet'])
        },
        data(){
            return {
                components: {
                    general: general
                },
                header: [
                    {text: 'day', field: 'day', sorting: true, typeSort: 'int', style: 'min-width: 50px; max-width: 50px; width: 50px;'},
                    {text: 'month', field: 'month', sorting: true, typeSort: 'int', style: 'min-width: 50px; max-width: 50px; width: 50px;'},
                    {text: 'description', field: 'description', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 100%;'},
                    {text: '', field: 'actions', sorting: false}
                ],
                schedule: [],
                dataPopup: {
                    id: null,
                    type: '',
                    title: '',
                    stylePopup: '',
                    subTitle: '',
                    bodyText: '',
                    showButton: false,
                    showSlot: false,
                    allowEmit: false,
                    typeEmit: ''
                },
                refreshPopup: 0,
                haveData: false
            }
        },
        async beforeDestroy(){
            await this.$store.dispatch('destroyGetByType', {
                from: 'support'
            }, {root: true});
        },
        async beforeMount(){
            if(this.$root._route.params.id !== undefined) {
                await this.$store.dispatch('getByType', {
                    from: 'support',
                    type: 'schedule-per-group',
                    id: this.$root._route.params.id
                }, {root: true});

                this.schedule = this.getterGet.support['schedule-per-group'].schedule;
                this.schedule.forEach((i, k) => {
                    this.schedule[k].id = k;
                });

                if(this.$root._route.params.view){
                    delete this.header[3];
                    this.header = this.header.filter(n => n);
                }
            }

            this.$nextTick(() => {
                this.haveData = true;
                this.$parent.$parent.$parent.haveData = this.haveData;
            });
        },
        methods: {
            doAction(type, key){
                switch(type){
                    case 'add':
                        this.refreshPopup++;
                        this.dataPopup = {
                            id: null,
                            type: 'add',
                            title: 'new_holiday',
                            stylePopup: 'width: 650px, min-width: 650px, max-width: 650px,',
                            subTitle: '',
                            bodyText: 'describe_the_holiday_and_assign_date',
                            showSlot: true,
                            showButton: true,
                            allowEmit: true,
                            typeEmit: 'save'
                        };

                        this.Global.openPopUp('popup_general');
                        break;
                    case 'delete':
                        delete this.schedule[key];
                        this.schedule = this.schedule.filter(n => n);
                        this.schedule.forEach((i, k) => {
                            this.schedule[k].id = k;
                        });
                        break;
                }
            },
            doEmmit(type){
                switch (type){
                    case 'save':
                        if(this.Global.checkRequired('form_data_holiday') === 'OK') {
                            this.schedule.push({
                                id: this.schedule.length,
                                description: this.$refs['RefDescription'].hiddenValue,
                                day: new Date(this.$refs['RefDate'].value1).getDate() < 10 ? '0' + new Date(this.$refs['RefDate'].value1).getDate() : new Date(this.$refs['RefDate'].value1).getDate(),
                                month: new Date(this.$refs['RefDate'].value1).getMonth() + 1
                            });

                            this.Global.closePopUp('popup_general');
                        }
                        break;
                }
            }
        }
    }
</script>