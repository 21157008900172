<template>
    <div class="next-level">
        <LoaderTemplate :haveData="haveData">
            <template v-slot:content>
                <input type="hidden" name="data[type]" value="staff"/>
                <template v-if="id !== null">
                    <input type="hidden" name="data[url_id]" :value="id"/>
                </template>
                <template v-if="extra_from !== undefined">
                    <input type="hidden" name="data[extra_from]" :value="extra_from"/>
                </template>
                <div class="gh-row-content">
                    <GhInputTextField
                        :datalabel="{
                            text: $t('name'),
                            style: 'width: 150px; text-align: right;',
                            class: 'T15_b'
                        }"
                        :datainput="{
                            required: false,
                            id: 'data_name',
                            name: 'data[name]',
                            style: 'width: 200px',
                            value: '',
                            type: 'text'
                        }"
                    />
                    <GhInputTextField
                        :datalabel="{
                            text: $t('surname'),
                            style: 'width: 150px; text-align: right;',
                            class: 'T15_b'
                        }"
                        :datainput="{
                            required: false,
                            id: 'data_surname',
                            name: 'data[surname]',
                            style: 'width: 200px',
                            value: '',
                            type: 'text'
                        }"
                    />
                </div>
                <div class="gh-row-content">
                    <GhDataSelector
                        :datablock="{
                            id: 'data_select_staff_type',
                            class: 'T15_b',
                            subtitle: $t('select_staff_type') + ':',
                            text: $t('staff_type'),
                            label: $t('staff_type'),
                            type: 'checkbox',
                            columns: 3,
                            style: '',
                            required: false
                        }"
                        :datainput="{
                            id: 'value_data_select_staff_type',
                            name: 'data[staff_type][]'
                        }"
                        :selected_input="[]"
                        :feed="getterFeed['staff-type']"
                    />
                </div>
            </template>
        </LoaderTemplate>
    </div>
</template>

<script>
    import LoaderTemplate from "@/view/Shared/Loader/loader.vue";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";

    import {mapGetters} from "vuex";

    export default {
        name: 'search_staff',
        components: {
            LoaderTemplate,
            GhInputTextField,
            GhDataSelector
        },
        computed: {
            ...mapGetters(['getterFeed'])
        },
        data(){
            return {
                id: null,
                extra_from: undefined,
                haveData: false
            }
        },
        async beforeDestroy(){
            await this.$store.dispatch('destroyFeed', {
                from: 'staff-type'
            }, {root: true});

            await this.$store.dispatch('destroySearch', '', {root: true});
        },
        async beforeMount(){
            if(this.$root._route.params.idblock !== undefined){
                if(this.$root._route.params.idblock.includes('main-')){
                    this.id = this.$root._route.params.idblock.split('main-')[1];
                    this.extra_from = 'department'
                }

                if(this.$root._route.params.idblock.includes('assistant-')){
                    this.id = this.$root._route.params.idblock.split('assistant-')[1];
                    this.extra_from = 'department'
                }
            }

            await this.$store.dispatch('getFeed', {
                from: 'staff-type'
            }, {root: true});

            this.$nextTick(() => {
                this.haveData = true;
            })
        }
    }
</script>

<style>
    #form_search > div > .gh-row-content{
        margin-left: 124px;
    }

    #data_select_staff_type_selector_label{
        width: 150px;
        text-align: right;
    }
</style>