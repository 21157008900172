<template>
    <LoaderTemplate :haveData="haveData">
        <template v-slot:content>
            <form id="form_data">
                <div class="gh-content-global">
                    <CodeNameDescription
                        :code="{
                            show: true,
                            required: true,
                            value: code
                        }"
                        :name="{
                            show: true,
                            required: true,
                            value: name
                        }"
                        :description="{
                            show: false,
                            required: false,
                            value: ''
                        }"
                    />
                    <div class="gh-row-content">
                        <template v-if="!$root._route.params.view">
                            <GhDataSelector
                                :datablock="{
                                    id: 'data_select_time',
                                    class: 'T15_b',
                                    subtitle: $t('select_time') + ':',
                                    text: $t('time') + '*',
                                    label: $t('time'),
                                    type: 'radio',
                                    columns: 3,
                                    style: '',
                                    required: true
                                }"
                                :datainput="{
                                    id: 'value_data_select_time',
                                    name: 'data[time]'
                                }"
                                :selected_input="getterGet.general !== undefined && getterGet.general['constraint-category'] !== undefined ? getterGet.general['constraint-category'].time : []"
                                :feed="getterFeed.time"
                                ref="RefTime"
                                @accept="getTypeTiem"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('time') + ':'}}</label>
                            <label class="gh_text_field-label T15_b">{{getterGet.general !== undefined && getterGet.general['constraint-category'] !== undefined ? Object.values(getterGet.general['constraint-category'].time).join(', ') : ''}}</label>
                        </template>
                    </div>
                    <div class="gh-row-content" v-if="getterCasuistry.time !== undefined && getterCasuistry.time.flag !== undefined && getterCasuistry.time.flag !== 0">
                        <template v-if="!$root._route.params.view">
                            <GhInputHour
                                :datalabel="{
                                    style: 'text-align: right; min-width: 150px;',
                                    text: type
                                }"
                                :datainput="{
                                    actualHour: false,
                                    id: 'date_hour',
                                    name: 'data[hour][from]',
                                    required: true,
                                    label_required: type + '*',
                                    hour: getterGet.general !== undefined && getterGet.general['constraint-category'] !== undefined ? getterGet.general['constraint-category'].hour.from.hour : '',
                                    minute: getterGet.general !== undefined && getterGet.general['constraint-category'] !== undefined ? getterGet.general['constraint-category'].hour.from.minute : '',
                                    style: 'max-width: 40px;'
                                }"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{type + ':'}}</label>
                            <label class="gh_text_field-label T15_b">{{getterGet.general !== undefined && getterGet.general['constraint-category'] !== undefined ? getterGet.general['constraint-category'].hour.from.hour + ':' + getterGet.general['constraint-category'].hour.from.minute : ''}}</label>
                        </template>
                        <template v-if="getterCasuistry.time.flag === 2">
                            <template v-if="!$root._route.params.view">
                                <GhInputHour
                                    :datalabel="{
                                        style: 'margin-left: 10px;',
                                        text: $t('to').toLowerCase()
                                    }"
                                    :datainput="{
                                        actualHour: false,
                                        id: 'date_hour',
                                        name: 'data[hour][to]',
                                        required: true,
                                        label_required: type + '*',
                                        hour: getterGet.general !== undefined && getterGet.general['constraint-category'] !== undefined ? getterGet.general['constraint-category'].hour.to.hour : '',
                                        minute: getterGet.general !== undefined && getterGet.general['constraint-category'] !== undefined ? getterGet.general['constraint-category'].hour.to.minute : '',
                                        style: 'max-width: 40px;'
                                    }"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-left: 10px; margin-right: 10px;">{{$t('to').toLowerCase()}}</label>
                                <label class="gh_text_field-label T15_b">{{getterGet.general !== undefined && getterGet.general['constraint-category'] !== undefined ? getterGet.general['constraint-category'].hour.to.hour + ':' + getterGet.general['constraint-category'].hour.to.minute : ''}}</label>
                            </template>
                        </template>
                    </div>
                </div>
            </form>
        </template>
    </LoaderTemplate>
</template>

<script>
    import LoaderTemplate from '@/view/Shared/Loader/loader.vue';
    import CodeNameDescription from '@/view/Shared/Form/code_name_description.vue';
    import GhDataSelector from 'fe-gh-data-selector-lib';
    import GhInputHour from "fe-gh-input-hour-lib";

    import {mapGetters} from "vuex";

    export default {
        name: 'form_constraint_category',
        components: {
            LoaderTemplate,
            CodeNameDescription,
            GhDataSelector,
            GhInputHour
        },
        computed: {
            ...mapGetters(['getterGet', 'getterFeed', 'getterCasuistry'])
        },
        data(){
            return {
                code: '',
                name: '',
                type: '',
                haveData: false
            }
        },
        async beforeDestroy(){
            if(!this.$root._route.params.view) {
                await this.$store.dispatch('destroyFeed', {
                    from: 'time'
                }, {root: true});
            }

            if(this.getterCasuistry.time !== undefined) {
                await this.$store.dispatch('destroyCasuistry', {
                    from: 'time'
                }, {root: true});
            }

            await this.$store.dispatch('destroyGetByType', {
                from: 'general'
            }, {root: true});
        },
        async beforeMount(){
            if(!this.$root._route.params.view){
                await this.$store.dispatch('getFeed', {
                    from: 'time'
                }, {root: true});
            }

            if(this.$root._route.params.id !== undefined) {
                await this.$store.dispatch('getByType', {
                    from: 'general',
                    type: 'constraint-category',
                    id: this.$root._route.params.id
                }, {root: true});

                this.code = this.getterGet.general !== undefined && this.getterGet.general['constraint-category'] !== undefined ? this.getterGet.general['constraint-category'].code : ''
                this.name = this.getterGet.general !== undefined && this.getterGet.general['constraint-category'] !== undefined ? this.getterGet.general['constraint-category'].name : ''

                if(this.getterGet.general !== undefined && this.getterGet.general['constraint-category'] !== undefined && this.getterGet.general['constraint-category'].time !== undefined){
                    let selected = Object.entries(this.getterGet.general['constraint-category'].time);

                    if(selected.length !== 0){
                        this.type = selected[0][1];
                        await this.$store.dispatch('getCasuistry', {
                            from: 'time',
                            url: 'time/feed/' + selected[0][0],
                        }, {root: true});
                    }
                }
            }

            this.$nextTick(() => {
                this.haveData = true;
                this.$parent.$parent.$parent.haveData = this.haveData;
            });
        },
        methods: {
            persistance(){
                this.code = this.$children[0].$children[0].$refs['RefCode'].datainput.value;
                this.name = this.$children[0].$children[0].$refs['RefName'].datainput.value;
            },
            async getTypeTiem(){
                this.persistance();

                let selected = Object.entries(this.$refs['RefTime'].checkdata);

                if(selected.length !== 0){
                    this.type = selected[0][1];
                    await this.$store.dispatch('getCasuistry', {
                        from: 'time',
                        url: 'time/feed/' + selected[0][0],
                    }, {root: true});
                }
            }
        }
    }
</script>

<style>
    #data_select_time_selector_label{
        width: 150px;
        text-align: right;
    }
</style>