import Nullified from '@/view/Advertisements/Nullified/main.vue';

const route = [
    {
        path: '/advertisements/nullified',
        name: 'advertisements_nullified',
        props: {showMenu: true, showUser: true},
        component: Nullified
    }
];

const routes = [
    ...route
];

export default routes;