<template>
    <LoaderTemplate :haveData="haveData">
        <template v-slot:content>
            <div>
                <GhSubTab v-bind:items="$router.options.GhSubTabsPlan" :dataBlock="{id: 'plan_tab_', calldispatch: 'getTabActive'}"/>
                <transition name="fade" mode="out-in" v-on:after-enter="afterEnter" appear>
                    <component :is="viewLoad" :key="refreshView"/>
                </transition>
            </div>

            <component :is="components.general" :title="dataPopup.title" :type="dataPopup.type" :stylePopup="dataPopup.stylePopup" :subTitle="dataPopup.subTitle" :bodyText="dataPopup.bodyText" :showButton="dataPopup.showButton" @accept="dataPopup.allowEmit ? doEmmit(dataPopup.typeEmit) : ''"/>
        </template>
    </LoaderTemplate>
</template>

<script>
    import LoaderTemplate from "@/view/Shared/Loader/loader.vue";
    import GhSubTab from 'fe-gh-subtab-lib';
    import GhTextarea from "fe-gh-textarea-lib";

    import {mapState} from "vuex";

    import general from "@/view/Shared/Popups/general.vue";

    export default {
        name: 'plan_main',
        components: {
            LoaderTemplate,
            GhSubTab,
            GhTextarea
        },
        computed: {
            ...mapState(['SubTabs'])
        },
        watch: {
            '$i18n.locale'(){
                let that = this;
                that.haveData = false;
                setTimeout(function(){
                    that.haveData = true;
                },500);
            },
            'SubTabs.reload'(){
                this.viewLoad = this.SubTabs.subTabActive;
                this.refreshView++;
            }
        },
        data(){
            return {
                haveData: false,
                viewLoad: null,
                refreshView: 0,
                dataPopup: {
                    id: null,
                    type: '',
                    title: '',
                    stylePopup: '',
                    subTitle: '',
                    bodyText: '',
                    showButton: false,
                    showSlot: false,
                    allowEmit: false,
                    typeEmit: ''
                },
                components: {
                    general: general
                },
                refreshMotive: 0
            }
        },
        async beforeMount(){
            let that = this;
            setTimeout(function(){
                that.haveData = true;
            },500);

        },
        methods: {
            afterEnter: function() {
                return true;
            },
            async doAction(from, type, id){
                switch(type){
                    case 'nullify':
                        this.$router.push({name: 'patients_patients_reschedule', params: {id: id, reschedule: true}});
                        break;
                    case 'confirm':
                        this.dataPopup = {
                            id: id,
                            from: from,
                            type: 'warning',
                            title: type,
                            stylePopup: 'width: 650px, min-width: 650px, max-width: 650px,',
                            subTitle: '',
                            bodyText: 'once_confirmed_the_patient_is_notified_by_email',
                            showSlot: false,
                            showButton: true,
                            allowEmit: true,
                            typeEmit: type
                        };

                        this.Global.openPopUp('popup_general');
                        break;
                }
            },
            async doEmmit(type){
                await this.$store.dispatch('setByType', {
                    id: this.dataPopup.id,
                    from: this.dataPopup.from,
                    type: this.dataPopup.from,
                    extra_url: type
                }, {root: true}).then(async (response) => {
                    if(response.status === 200){
                        this.refreshMotive++;

                        await this.$store.dispatch('getCountersByType', {
                            type: this.dataPopup.from
                        }, {root: true});

                        await this.$store.dispatch('getListByType', {
                            from: this.dataPopup.from,
                            type: this.dataPopup.from
                        }, {root: true});

                        this.Global.closePopUp('popup_general');
                    }
                });
            }
        }
    }
</script>