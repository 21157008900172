import Groups from '@/view/Permissions/Groups/main.vue';

import ListGroups from './Groups/router';
import Profiles from './Profiles/router';

const route = [
    {
        path: '/permissions/groups',
        name: 'permissions_groups',
        props: {showMenu: true, showUser: true},
        component: Groups
    }
];

const routes = [
    ...route,
    ...ListGroups,
    ...Profiles
];

export default routes;