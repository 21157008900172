import Actions from '@/view/Configuration/Actions/main.vue';

import Action from './Actions/router';
import Procedure from './Procedures/router';

const route = [
    {
        path: '/configuration/actions',
        name: 'config_actions',
        props: {showMenu: true, showUser: true},
        component: Actions
    }
];

const routes = [
    ...route,
    ...Action,
    ...Procedure
];

export default routes;