import Vue from "vue";
import vuex from "vuex";
import axios from "axios";
import {API_URL} from "@/common/config";
import Global from "@/store/Global";

Vue.use(vuex);

export default{
    state: {
        data: [],
    },
    mutations: {
        loadLastResponse(state,payload){
            state.data = payload;
        },
    },
    actions: {
        async easyDownload(state,item){
            await axios({
                method: "get",
                url: item.url,
                responseType: 'blob'
            }).then(
                response => {
                    let filename = 'downloaded-file.pdf'; // Default filename

                    const contentDisposition = response.headers['content-disposition'];
                    if (contentDisposition) {
                        const match = contentDisposition.match(/filename="(.+)"/);
                        if (match && match[1]) {
                            filename = match[1];
                        }
                    }

                    const blob = new Blob([response.data]);

                    const url = window.URL.createObjectURL(blob);
                    const link = document.createElement('a');
                    link.href = url;

                    link.setAttribute('download', filename);
                    document.body.appendChild(link);
                    link.click();

                    window.URL.revokeObjectURL(url);
                },
                error => {
                    if(error.status !== ''){
                        Global.loadError('error_popup', error.response.data.data);
                    }
                }
            )
        },
        async easyUpload(state, item){
            let requestLogin = API_URL +'files';
            let params = new FormData();

            var test = {};
            item.formData.forEach((item) => {
                test = item;
            });
            params.append('mis_archivos',test);

            return await axios({
                method: "post",
                url: requestLogin,
                data: params,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    response.data.url = response.data.url + '?hash=' + response.data.path;
                    state.commit('loadLastResponse', response.data);
                    return response;
                },
                error => {
                    if(error.status !== ''){
                        Global.loadError('error_popup', error.response.data.data);
                    }
                }
            )
        },
    }
};
