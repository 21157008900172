var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LoaderTemplate',{attrs:{"haveData":_vm.haveData},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('form',{attrs:{"id":"form_data"}},[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                text: _vm.$t('priority') + '*',
                                style: 'width: 150px; min-width: 150px; text-align: right;',
                                class: 'T15_b'
                            },"datainput":{
                                required: true,
                                id: 'data_priority',
                                name: 'data[priority]',
                                style: 'width: 200px',
                                value: _vm.getterGet.procedure !== undefined && _vm.getterGet.procedure['w2-priority'] !== undefined ? _vm.getterGet.procedure['w2-priority'].priority : '',
                                type: 'text'
                            }}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('priority') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(_vm.getterGet.procedure !== undefined && _vm.getterGet.procedure['w2-priority'] !== undefined ? _vm.getterGet.procedure['w2-priority'].priority : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                text: _vm.$t('deadline') + '*',
                                style: 'width: 150px; min-width: 150px; text-align: right;',
                                class: 'T15_b'
                            },"datainput":{
                                required: true,
                                id: 'data_deadline',
                                name: 'data[deadline]',
                                style: 'width: 200px',
                                value: _vm.getterGet.procedure !== undefined && _vm.getterGet.procedure['w2-priority'] !== undefined ? _vm.getterGet.procedure['w2-priority'].deadline : '',
                                type: 'text'
                            }}}),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(" "+_vm._s(_vm.$t('days')))])]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('deadline') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(_vm.getterGet.procedure !== undefined && _vm.getterGet.procedure['w2-priority'] !== undefined ? _vm.getterGet.procedure['w2-priority'].deadline : ''))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(" "+_vm._s(_vm.$t('days')))])]],2)])])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }