import Search from '@/view/Plan/Search/main.vue';

// import TrainingView from './Training/router';

const route = [
    {
        path: '/plan/search',
        name: 'plan_search',
        props: {showMenu: true, showUser: true},
        component: Search
    }
];

const routes = [
    ...route,
    // ...TrainingView
];

export default routes;