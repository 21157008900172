var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"next-level"},[_c('LoaderTemplate',{attrs:{"haveData":_vm.haveData},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('input',{attrs:{"type":"hidden","name":"data[type]","value":"staff"}}),(_vm.id !== null)?[_c('input',{attrs:{"type":"hidden","name":"data[url_id]"},domProps:{"value":_vm.id}})]:_vm._e(),(_vm.extra_from !== undefined)?[_c('input',{attrs:{"type":"hidden","name":"data[extra_from]"},domProps:{"value":_vm.extra_from}})]:_vm._e(),_c('div',{staticClass:"gh-row-content"},[_c('GhInputTextField',{attrs:{"datalabel":{
                        text: _vm.$t('name'),
                        style: 'width: 150px; text-align: right;',
                        class: 'T15_b'
                    },"datainput":{
                        required: false,
                        id: 'data_name',
                        name: 'data[name]',
                        style: 'width: 200px',
                        value: '',
                        type: 'text'
                    }}}),_c('GhInputTextField',{attrs:{"datalabel":{
                        text: _vm.$t('surname'),
                        style: 'width: 150px; text-align: right;',
                        class: 'T15_b'
                    },"datainput":{
                        required: false,
                        id: 'data_surname',
                        name: 'data[surname]',
                        style: 'width: 200px',
                        value: '',
                        type: 'text'
                    }}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhDataSelector',{attrs:{"datablock":{
                        id: 'data_select_staff_type',
                        class: 'T15_b',
                        subtitle: _vm.$t('select_staff_type') + ':',
                        text: _vm.$t('staff_type'),
                        label: _vm.$t('staff_type'),
                        type: 'checkbox',
                        columns: 3,
                        style: '',
                        required: false
                    },"datainput":{
                        id: 'value_data_select_staff_type',
                        name: 'data[staff_type][]'
                    },"selected_input":[],"feed":_vm.getterFeed['staff-type']}})],1)]},proxy:true}])})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }