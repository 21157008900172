<template>
    <div style="margin-top: -3px;">
        <GhLocation
            :dataLocation="{
                showButton: false,
                secondWindow: true,
                showLoader: 'OK',
                text: $t('schedule'),
                img: require('@/assets/gh_new_dessign/floating.svg')
            }"
            :dataButton="{id: '', text: 'Boton', style: ''}"
        />

        <transition name="fade" mode="out-in" v-on:after-enter="afterEnter" appear>
            <div class="content_page">
                <component :is="component.SectionSchedule"/>
            </div>
        </transition>
    </div>
</template>

<script>
    import GhLocation from "fe-gh-location-lib";

    import SectionSchedule from './Template/section.vue';

    export default {
        name: 'window_schedule',
        components: {
            GhLocation,
        },
        data(){
            return {
                component: {
                    SectionSchedule: SectionSchedule
                }
            }
        },
        methods:{
            afterEnter: function() {
                return true;
            },
        }
    }
</script>