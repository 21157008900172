<template>
    <LoaderTemplate :haveData="haveData">
        <template v-slot:content>
            <GhTableCommon
                :extratable="{
                    id: 'schedule_per_group'
                }"
                :header="header"
                :data="getterList.support !== undefined && getterList.support['schedule-per-group'] !== undefined ? getterList.support['schedule-per-group'].data : []"
            >
                <template v-slot:actions="{itemProp}">
                    <template v-if="getterList.support['schedule-per-group'].actions.view">
                        <router-link :to="{name: 'config_support_schedule_per_group_view', params: {id: itemProp.id, view: true}}">
                            <GhAction
                                :dataction="{
                                    id: 'action_view_schedule_per_group_' + itemProp.id,
                                    text: $t('view'),
                                    icon: require('@/assets/gh_new_dessign/view.svg')
                                }"
                            />
                        </router-link>
                    </template>
                    <template v-if="getterList.support['schedule-per-group'].actions.modify">
                        <router-link :to="{name: 'config_support_schedule_per_group_modify', params: {id: itemProp.id}}">
                            <GhAction
                                :dataction="{
                                    id: 'action_edit_schedule_per_group_' + itemProp.id,
                                    text: $t('modify'),
                                    icon: require('@/assets/gh_new_dessign/edit.svg')
                                }"
                            />
                        </router-link>
                    </template>
                </template>
            </GhTableCommon>
        </template>
    </LoaderTemplate>
</template>

<script>
    import LoaderTemplate from '@/view/Shared/Loader/loader.vue';
    import GhTableCommon from 'fe-gh-table-common-lib';
    import GhAction from "fe-gh-action-lib";

    import {mapGetters} from "vuex";

    export default {
        name: 'list_schedule_per_group',
        components: {
            LoaderTemplate,
            GhTableCommon,
            GhAction
        },
        computed: {
            ...mapGetters(['getterList'])
        },
        data(){
            return {
                header: [
                    {text: 'group_assignment', field: 'group_assignment', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                    {text: 'business_hours', field: 'business_hours', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 80%;'},
                    {text: '', field: 'actions', sorting: false}
                ],
                haveData: false
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getListByType', {
                from: 'support',
                type: 'schedule-per-group'
            }, {root: true});

            this.$nextTick(() => {
                this.haveData = true;
            });
        }
    }
</script>