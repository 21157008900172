<template>
    <LoaderTemplate :haveData="haveData">
        <template v-slot:content>
            <GhTableCommon
                style="margin-top: 10px;"
                :extratable="{
                    id: 'historic'
                }"
                :header="header"
                :data="getterList['historic'] !== undefined && getterList['historic']['historic'] !== undefined ? getterList['historic']['historic'].data : []"
            >
                <template v-slot:actions="{itemProp}">
                    <template v-if="getterList['historic']['historic'].actions.view">
                        <GhAction
                            :dataction="{
                                id: 'action_view_patients_' + itemProp.id,
                                text: $t('view'),
                                icon: require('@/assets/gh_new_dessign/view.svg')
                            }"
                            @click="Global.windowOpen(Global.openSecondWindow('patients_patients_view', {
                                id: itemProp.id,
                                view: true,
                            }),'window_patient')"
                        />
                    </template>
                </template>
            </GhTableCommon>
        </template>
    </LoaderTemplate>
</template>

<script>
    import LoaderTemplate from "@/view/Shared/Loader/loader.vue";
    import GhTableCommon from "fe-gh-table-common-lib";
    import GhAction from "fe-gh-action-lib";

    import {mapGetters} from "vuex";

    export default {
        name: 'list_historic',
        components: {
            LoaderTemplate,
            GhTableCommon,
            GhAction
        },
        computed: {
            ...mapGetters(['getterList'])
        },
        data(){
            return {
                header: [
                    {text: 'mrn', field: 'mrn', sorting: true, typeSort: 'code', style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                    {text: 'dob', field: 'dob', sorting: true, typeSort: 'date', style: 'min-width: 80px; max-width: 80px; max-width: 80px;'},
                    {text: 'intervention', field: 'intervention', sorting: true, typeSort: 'string', style: 'min-width: 10px; max-width: 50px; width: 40%;'},
                    {text: 'constraints', field: 'constraints', sorting: true, typeSort: 'string', style: 'min-width: 10px; max-width: 50px; width: 15%;'},
                    {text: 'main_surgeon', field: 'main_surgeon', sorting: true, typeSort: 'string', style: 'min-width: 10px; max-width: 50px; width: 10%;'},
                    {text: 'location', field: 'location', sorting: true, typeSort: 'string', style: 'min-width: 10px; max-width: 50px; width: 15%;'},
                    {text: 'duration', field: 'duration', sorting: true, typeSort: 'string', style: 'min-width: 60px; max-width: 60px; width: 60px;'},
                    {text: 'scheduled_date', field: 'scheduled_date', sorting: true, typeSort: 'date', style: 'min-width: 10px; max-width: 50px; width: 10%;'},
                    {text: 'target_date', field: 'target_date', sorting: true, typeSort: 'date', style: 'min-width: 10px; max-width: 80px; width: 10%;'},
                    {text: '', field: 'actions', sorting: false}
                ],
                haveData: false
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getListByType', {
                from: 'historic',
                type: 'historic'
            }, {root: true});

            this.$nextTick(() => {
                this.haveData = true;
            });
        }
    }
</script>