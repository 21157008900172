<template>
    <LoaderTemplate :haveData="haveData">
        <template v-slot:content>
            <form id="form_data">
                <div class="gh-content-global">
                    <CodeNameDescription
                        :code="{
                            show: true,
                            required: true,
                            value: getterGet.training !== undefined && getterGet.training['training'] !== undefined ? getterGet.training['training'].code : '',
                            read_only: $root._route.params.id !== undefined,
                        }"
                        :name="{
                            show: true,
                            required: true,
                            value: getterGet.training !== undefined && getterGet.training['training'] !== undefined ? getterGet.training['training'].name : '',
                            read_only: $root._route.params.id !== undefined,
                        }"
                        :description="{
                            show: true,
                            required: false,
                            value: getterGet.training !== undefined && getterGet.training['training'] !== undefined ? getterGet.training['training'].description : '',
                            read_only: $root._route.params.id !== undefined,
                        }"
                    />
                    <div class="gh-row-content">
                        <template v-if="!$root._route.params.view && $root._route.params.id === undefined">
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('duration') + '*',
                                    style: 'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'data_duration',
                                    name: 'data[duration]',
                                    style: 'width: 100px',
                                    value: getterGet.training !== undefined && getterGet.training['training'] !== undefined ? getterGet.training['training'].duration : '',
                                    type: 'float'
                                }"
                            />
                            <label class="gh_text_field-label T15_b">&nbsp;{{$t('hours')}}</label>
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('duration') + ':'}}</label>
                            <label class="gh_text_field-label T15_b">{{getterGet.training !== undefined && getterGet.training['training'] !== undefined ? getterGet.training['training'].duration : ''}}</label>
                            <label class="gh_text_field-label T15_b">&nbsp;{{$t('hours')}}</label>
                        </template>
                    </div>
                    <div class="gh-row-content">
                        <template v-if="!$root._route.params.view && $root._route.params.id === undefined">
                            <GhDataSelector
                                :datablock="{
                                    id: 'data_select_training_type',
                                    class: 'T15_b',
                                    subtitle: $t('select_training_type') + ':',
                                    text: $t('training_type') + '*',
                                    label: $t('training_type'),
                                    type: 'radio',
                                    columns: 3,
                                    style: '',
                                    required: true
                                }"
                                :datainput="{
                                    id: 'value_data_select_training_type',
                                    name: 'data[training_type]'
                                }"
                                :selected_input="getterGet.training !== undefined && getterGet.training['training'] !== undefined ? getterGet.training['training'].training_type : []"
                                :feed="getterFeed['training-type']"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('training_type') + ':'}}</label>
                            <label class="gh_text_field-label T15_b">{{getterGet.training !== undefined && getterGet.training['training'] !== undefined ? Object.values(getterGet.training['training'].training_type).join(', ') : ''}}</label>
                        </template>
                    </div>
                    <div class="gh-row-content">
                        <template v-if="!$root._route.params.view">
                            <GhDataSelector
                                :datablock="{
                                    id: 'data_select_target_staff',
                                    class: 'T15_b',
                                    subtitle: $t('select_target_staff') + ':',
                                    text: $t('target_staff') + '*',
                                    label: $t('target_staff'),
                                    type: 'checkbox',
                                    columns: 3,
                                    style: '',
                                    required: true
                                }"
                                :datainput="{
                                    id: 'value_data_select_target_staff',
                                    name: 'data[target_staff][]'
                                }"
                                :selected_input="getterGet.training !== undefined && getterGet.training['training'] !== undefined ? getterGet.training['training'].target_staff : []"
                                :feed="getterFeed['staff-type']"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('target_staff') + ':'}}</label>
                            <label class="gh_text_field-label T15_b">{{getterGet.training !== undefined && getterGet.training['training'] !== undefined ? Object.values(getterGet.training['training'].target_staff).join(', ') : ''}}</label>
                        </template>
                    </div>
                </div>
            </form>
        </template>
    </LoaderTemplate>
</template>

<script>
    import LoaderTemplate from '@/view/Shared/Loader/loader.vue';
    import CodeNameDescription from '@/view/Shared/Form/code_name_description.vue';
    import GhInputTextField from "fe-gh-inputtextfield-lib";

    import {mapGetters} from "vuex";
    import GhDataSelector from "fe-gh-data-selector-lib";

    export default {
        name: 'form_training',
        components: {
            GhDataSelector,
            LoaderTemplate,
            CodeNameDescription,
            GhInputTextField
        },
        computed: {
            ...mapGetters(['getterGet', 'getterFeed'])
        },
        data(){
            return {
                haveData: false
            }
        },
        async beforeDestroy(){
            if(!this.$root._route.params.view){
                await this.$store.dispatch('destroyFeed', {
                    from: 'training-type'
                }, {root: true});

                await this.$store.dispatch('destroyFeed', {
                    from: 'staff-type'
                }, {root: true});
            }

            await this.$store.dispatch('destroyGetByType', {
                from: 'training'
            }, {root: true});
        },
        async beforeMount(){
            if(!this.$root._route.params.view){
                await this.$store.dispatch('getFeed', {
                    from: 'training-type'
                }, {root: true});

                await this.$store.dispatch('getFeed', {
                    from: 'staff-type'
                }, {root: true});
            }

            if(this.$root._route.params.id !== undefined) {
                await this.$store.dispatch('getByType', {
                    from: 'training',
                    type: 'training',
                    id: this.$root._route.params.id
                }, {root: true});
            }

            this.$nextTick(() => {
                this.haveData = true;
                this.$parent.$parent.$parent.haveData = this.haveData;
            });
        }
    }
</script>

<style>
    #data_select_training_type_selector_label, #data_select_target_staff_selector_label {
        width: 150px;
        text-align: right;
    }
</style>