<template>
    <div class="next-level">
        <LoaderTemplate :haveData="haveData">
            <template v-slot:content>
                <input type="hidden" name="data[type]" value="users"/>
                <div class="gh-row-content">
                    <GhInputTextField
                        :datalabel="{
                            text: $t('name'),
                            style: 'width: 150px; text-align: right;',
                            class: 'T15_b'
                        }"
                        :datainput="{
                            required: false,
                            id: 'data_name',
                            name: 'data[name]',
                            style: 'width: 200px',
                            value: '',
                            type: 'text'
                        }"
                    />
                    <GhInputTextField
                        :datalabel="{
                            text: $t('surname'),
                            style: 'width: 150px; text-align: right;',
                            class: 'T15_b'
                        }"
                        :datainput="{
                            required: false,
                            id: 'data_surname',
                            name: 'data[surname]',
                            style: 'width: 200px',
                            value: '',
                            type: 'text'
                        }"
                    />
                </div>
            </template>
        </LoaderTemplate>
    </div>
</template>

<script>
    import LoaderTemplate from "@/view/Shared/Loader/loader.vue";
    import GhInputTextField from "fe-gh-inputtextfield-lib";

    export default {
        name: 'search_users',
        components: {
            LoaderTemplate,
            GhInputTextField,
        },
        data(){
            return {
                id: null,
                haveData: false
            }
        },
        async beforeDestroy(){
            await this.$store.dispatch('destroySearch', '', {root: true});
        },
        async beforeMount(){

            this.$nextTick(() => {
                this.haveData = true;
            })
        }
    }
</script>