<template>
    <LoaderTemplate :haveData="haveData">
        <template v-slot:content>
            <form id="form_data">
                <div class="gh-content-global">
                    <CodeNameDescription
                        :code="{
                            show: true,
                            required: true,
                            read_only: $root._route.params.id !== undefined,
                            value: code
                        }"
                        :name="{
                            show: true,
                            required: true,
                            read_only: $root._route.params.id !== undefined,
                            value: name
                        }"
                        :description="{
                            show: true,
                            required: false,
                            read_only: $root._route.params.id !== undefined,
                            value: description
                        }"
                    />
                    <div class="gh-row-content">
                        <template v-if="!$root._route.params.view && $root._route.params.id === undefined">
                            <GhDataSelector
                                :datablock="{
                                    id: 'data_select_procedure_category',
                                    class: 'T15_b',
                                    subtitle: $t('select_procedure_category') + ':',
                                    text: $t('procedure_category') + '*',
                                    label: $t('procedure_category'),
                                    type: 'radio',
                                    columns: 3,
                                    style: '',
                                    required: true
                                }"
                                :datainput="{
                                    id: 'value_data_select_procedure_category',
                                    name: 'data[procedure_category]'
                                }"
                                :selected_input="procedure_category"
                                :feed="getterFeed['procedure-category']"
                                ref="RefProcedureCategory"
                                @accept="checkSelector('RefProcedureCategory')"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('procedure_category') + ':'}}</label>
                            <label class="gh_text_field-label T15_b">{{Object.values(procedure_category).join(', ')}}</label>
                        </template>
                    </div>
                    <div class="gh-row-content" :key="'REFRESH_PCATS_CODE_' + refresh">
                        <template v-if="procedure_category.length === 0 || getterCasuistry['pcats-code'] === undefined || getterCasuistry['pcats-code'].length === 0">
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('pcats_code')}}</label>
                            <label class="gh_text_field-label T15_b">{{procedure_category.length === 0 ? $t('first_select_procedure_category') : $t('procedure_category_no_contains_pcats_code')}}</label>
                        </template>
                        <template v-else-if="$root._route.params.view">
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('pcats_code') + ':'}}</label>
                            <label class="gh_text_field-label T15_b">{{Object.values(pcats_code).join(', ')}}</label>
                        </template>
                        <template v-else>
                            <GhDataSelector
                                :datablock="{
                                    id: 'data_select_pcats_code',
                                    class: 'T15_b',
                                    subtitle: $t('select_pcats_code') + ':',
                                    text: $t('pcats_code') + '*',
                                    label: $t('pcats_code'),
                                    type: 'radio',
                                    columns: 3,
                                    style: '',
                                    required: true
                                }"
                                :datainput="{
                                    id: 'value_data_select_pcats_code',
                                    name: 'data[pcats_code]'
                                }"
                                :selected_input="pcats_code"
                                :feed="getterCasuistry['pcats-code']"
                                ref="RefPcatsCode"
                                @accept="checkSelector('RefPcatsCode')"
                            />
                        </template>
                    </div>
                </div>
            </form>
        </template>
    </LoaderTemplate>
</template>

<script>
    import LoaderTemplate from '@/view/Shared/Loader/loader.vue';
    import CodeNameDescription from '@/view/Shared/Form/code_name_description.vue';
    import GhDataSelector from "fe-gh-data-selector-lib";


    import {mapGetters} from "vuex";

    export default {
        name: 'form_procedure',
        components: {
            LoaderTemplate,
            CodeNameDescription,
            GhDataSelector
        },
        computed: {
            ...mapGetters(['getterGet', 'getterFeed', 'getterCasuistry'])
        },
        data(){
            return {
                code: '',
                name: '',
                description: '',
                procedure_category: [],
                pcats_code: [],
                refresh: 0,
                haveData: false
            }
        },
        async beforeDestroy(){
            if(!this.$root._route.params.view) {
                await this.$store.dispatch('destroyFeed', {
                    from: 'procedure-category'
                }, {root: true});

                if(this.getterCasuistry['pcats-code'] !== undefined) {
                    await this.$store.dispatch('destroyCasuistry', {
                        from: 'pcats-code'
                    }, {root: true});
                }
            }

            await this.$store.dispatch('destroyGetByType', {
                from: 'procedure'
            }, {root: true});
        },
        async beforeMount(){
            if(!this.$root._route.params.view){
                await this.$store.dispatch('getFeed', {
                    from: 'procedure-category'
                }, {root: true});
            }

            if(this.$root._route.params.id !== undefined) {
                await this.$store.dispatch('getByType', {
                    from: 'procedure',
                    type: 'procedure',
                    id: this.$root._route.params.id
                }, {root: true});

                this.code = this.getterGet.procedure['procedure'].code;
                this.name = this.getterGet.procedure['procedure'].name;
                this.description = this.getterGet.procedure['procedure'].description;
                this.procedure_category = this.getterGet.procedure['procedure'].procedure_category;

                if(Object.keys(this.procedure_category).length !== 0) {
                    await this.$store.dispatch('getCasuistry', {
                        from: 'pcats-code',
                        url: 'pcats-code/feed/by-procedure-category/' + Object.keys(this.procedure_category)[0],
                    }, {root: true});
                }

                this.pcats_code = this.getterGet.procedure['procedure'].pcats_code;
            }

            this.$nextTick(() => {
                this.haveData = true;
                this.$parent.$parent.$parent.haveData = this.haveData;
            });
        },
        methods: {
            async checkSelector(ref){
                if(this.$root._route.params.id === undefined) {
                    this.code = this.$children[0].$children[0].$refs['RefCode'].datainput.value;
                    this.name = this.$children[0].$children[0].$refs['RefName'].datainput.value;
                    this.description = this.$children[0].$children[0].$refs['RefDescription'].datainput.value;
                }

                let selected = '';
                var from = '';
                var url = '';
                var dispatch = false;

                if(this.$refs[ref].checkdata !== undefined){
                    selected = Object.entries(this.$refs[ref].checkdata);
                }

                switch (ref){
                    case 'RefProcedureCategory':
                        this.procedure_category = this.$refs[ref].checkdata;
                        from = 'pcats-code';
                        url = from + '/feed/by-procedure-category/' + selected[0][0];
                        dispatch = true;
                        break;
                    case 'RefPcatsCode':
                        this.pcats_code = this.$refs[ref].checkdata;
                        break;
                }

                if(selected.length !== 0 && dispatch){
                    await this.$store.dispatch('getCasuistry', {
                        from: from,
                        url: url,
                    }, {root: true});
                }

                this.$nextTick(() => {
                    this.refresh++;
                });
            }
        }
    }
</script>

<style>
    #data_select_pcats_code_selector_label{
        width: 150px;
        text-align: right;
    }
</style>