var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LoaderTemplate',{attrs:{"haveData":_vm.haveData},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('form',{attrs:{"id":"form_data"}},[_c('div',{staticClass:"gh-content-global"},[_c('CodeNameDescription',{attrs:{"code":{
                        show: true,
                        required: true,
                        value: _vm.code
                    },"name":{
                        show: true,
                        required: true,
                        value: _vm.name
                    },"description":{
                        show: true,
                        required: false,
                        value: _vm.description
                    }},on:{"persist":_vm.persistance}}),_c('Schedule',{attrs:{"scheduleData":_vm.schedule}})],1)])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }