import ConstraintType from '@/view/Configuration/General/ConstraintType/Template/section.vue';

const route = [
    {
        path: '/configuration/general/constraint-type',
        name: 'config_general_constraint_type',
        props: {showMenu: true, showUser: true},
        component: ConstraintType,
        children: [
            {
                path: '/configuration/general/constraint-type/:id',
                name: 'config_general_constraint_type_modify',
                component: ConstraintType,
                props: {id: null}
            },
            {
                path: '/configuration/general/constraint-type/:id/:view',
                name: 'config_general_constraint_type_view',
                component: ConstraintType,
                props: {id: null, view: true}
            }
        ]
    }
];

const routes = [
    ...route,
];

export default routes;