var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LoaderTemplate',{attrs:{"haveData":_vm.haveData},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('GhTableCommon',{attrs:{"extratable":{
                id: 'profiles'
            },"header":_vm.header,"data":_vm.getterList.groups !== undefined && _vm.getterList.groups.profiles !== undefined ? _vm.getterList.groups.profiles.data : []},scopedSlots:_vm._u([{key:"actions",fn:function({itemProp}){return [(_vm.getterList.groups.profiles.actions.view)?[_c('router-link',{attrs:{"to":{name: 'permissions_groups_profiles_view', params: {id: itemProp.id, view: true}}}},[_c('GhAction',{attrs:{"dataction":{
                                id: 'action_view_profiles_' + itemProp.id,
                                text: _vm.$t('view'),
                                icon: require('@/assets/gh_new_dessign/view.svg')
                            }}})],1)]:_vm._e(),(_vm.getterList.groups.profiles.actions.modify && itemProp.show.modify)?[_c('router-link',{attrs:{"to":{name: 'permissions_groups_profiles_modify', params: {id: itemProp.id}}}},[_c('GhAction',{attrs:{"dataction":{
                                id: 'action_edit_profiles_' + itemProp.id,
                                text: _vm.$t('modify'),
                                icon: require('@/assets/gh_new_dessign/edit.svg')
                            }}})],1)]:_vm._e(),(_vm.getterList.groups.profiles.actions.delete && itemProp.show.delete)?[_c('GhAction',{attrs:{"dataction":{
                            id: 'action_delete_profiles_' + itemProp.id,
                            text: _vm.$t('delete'),
                            icon: require('@/assets/gh_new_dessign/trash.svg')
                        }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','removeByType', {
                            from: 'groups',
                            type: 'profiles',
                            id: itemProp.id
                        },{
                            'name': itemProp.name,
                            'description': itemProp.description,
                            'groups': itemProp.groups
                        }, _vm.$t('delete_profile'), _vm.$t('preparing_delete_profile'), {
                            'getListByType': {
                                from: 'groups',
                                type: 'profiles'
                            },
                            'getListByType': {
                                from: 'groups',
                                type: 'groups'
                            },
                            'getCountersByType': {
                                type: 'groups'
                            }
                        })}}})]:_vm._e()]}},{key:"footer",fn:function(){return [(_vm.getterList.groups.profiles.actions.add)?[_c('div',{staticClass:"centered"},[_c('router-link',{attrs:{"to":{name: 'permissions_groups_profiles'}}},[_c('GhButton',{attrs:{"datainput":{
                                    id: 'btn_new_profiles',
                                    text: _vm.$t('add')
                                }}})],1)],1)]:_vm._e()]},proxy:true}])})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }