import Advertisements from '../../view/Advertisements/main.vue';

import Accept from './Accept/router';
import Confirm from './Confirm/router';
import Nullified from './Nullified/router';

const route = [
    {
        path: '/advertisements',
        name: 'advertisements',
        props: {showMenu: true, showUser: true},
        component: Advertisements
    }
];

const routes = [
    ...route,
    ...Accept,
    ...Confirm,
    ...Nullified
];

export default routes;