var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LoaderTemplate',{attrs:{"haveData":_vm.haveData},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('GhTableCommon',{staticStyle:{"margin-top":"10px"},attrs:{"extratable":{
                id: 'schedule_per_group'
            },"header":_vm.header,"data":_vm.schedule},scopedSlots:_vm._u([{key:"actions",fn:function({itemProp}){return [_c('GhAction',{attrs:{"dataction":{
                        id: 'action_delete_schedule_' + itemProp.id,
                        text: _vm.$t('delete'),
                        icon: require('@/assets/gh_new_dessign/trash.svg')
                    }},on:{"click":function($event){return _vm.doAction('delete', itemProp.id)}}})]}},{key:"footer",fn:function(){return [(!_vm.$root._route.params.view)?[_c('div',{staticClass:"centered"},[_c('GhButton',{attrs:{"datainput":{
                                id: 'btn_new_priorities',
                                text: _vm.$t('add')
                            }},on:{"click":function($event){return _vm.doAction('add')}}})],1)]:_vm._e()]},proxy:true}])}),_c('form',{attrs:{"id":"form_data"}},_vm._l((_vm.schedule),function(item,key){return _c('div',{key:'SCHEDULED_' + key,staticStyle:{"display":"none"}},[_c('input',{attrs:{"type":"hidden","name":'data[schedule][' + key + '][description]'},domProps:{"value":item.description}}),_c('input',{attrs:{"type":"hidden","name":'data[schedule][' + key + '][day]'},domProps:{"value":item.day}}),_c('input',{attrs:{"type":"hidden","name":'data[schedule][' + key + '][month]'},domProps:{"value":item.month}})])}),0),_c(_vm.components.general,{tag:"component",attrs:{"title":_vm.dataPopup.title,"type":_vm.dataPopup.type,"stylePopup":_vm.dataPopup.stylePopup,"subTitle":_vm.dataPopup.subTitle,"bodyText":_vm.dataPopup.bodyText,"showButton":_vm.dataPopup.showButton},on:{"accept":function($event){_vm.dataPopup.allowEmit ? _vm.doEmmit(_vm.dataPopup.typeEmit) : ''}},scopedSlots:_vm._u([{key:"content",fn:function(){return [(_vm.dataPopup.showSlot)?[_c('form',{key:'REFRESH_NEW_SCHEDULE_' + _vm.refreshPopup,attrs:{"id":"form_data_holiday"}},[_c('div',{staticClass:"gh-content-global",staticStyle:{"padding-bottom":"unset"}},[_c('div',{staticClass:"gh-row-content"},[_c('GhTextarea',{ref:"RefDescription",attrs:{"datalabel":{
                                        text: _vm.$t('description') + '*',
                                        styles: 'min-width: 100px; text-align: right; margin-right: 10px;',
                                        class: 'T15_b'
                                    },"datainput":{
                                        id: 'data_description',
                                        name: 'data[description]',
                                        value: '',
                                        required: true
                                    }}})],1),_c('div',{staticClass:"gh-row-content"},[_c('GhCalendar',{ref:"RefDate",attrs:{"datalabel":{
                                        id: 'label_date_schedule',
                                        text: _vm.$t('date'),
                                        style: 'width: 100px; min-width: 100px; text-align: right; margin-right: 12px;'
                                    },"datainput":{
                                        id:'data_date',
                                        name:'data[date]',
                                        value: '',
                                        required: true,
                                        format: 'DD/MM',
                                        placeholder: 'DD/MM'
                                    }}})],1)])])]:_vm._e()]},proxy:true}])})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }