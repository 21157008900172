<template>
    <LoaderTemplate :haveData="haveData">
        <template v-slot:content>
            <input type="hidden" name="data[type]" value="plan"/>
            <input type="hidden" name="data[no_check]" value="true"/>
            <div class="gh-row-content" :key="'SEARCH_1_' + refreshSearch">
                <div>
                    <div class="no-min-width width-50">
                        <GhDataSelector
                            :datablock="{
                                id: 'data_select_department_specialty',
                                class: 'T15_b',
                                subtitle: $t('select_department_specialty') + ':',
                                text: $t('department_specialty'),
                                label: $t('department_specialty'),
                                type: 'checkbox',
                                columns: 3,
                                style: '',
                                required: false
                            }"
                            :datainput="{
                                id: 'value_data_select_department_specialty',
                                name: 'data[department_specialty][]'
                            }"
                            :selected_input="[]"
                            :feed="getterFeed['department-specialty']"
                        />
                    </div>
                    <div class="no-min-width width-50">
                        <GhDataSelector
                            :datablock="{
                                id: 'data_select_staff',
                                class: 'T15_b',
                                subtitle: $t('select_staff') + ':',
                                text: $t('staff'),
                                label: $t('staff'),
                                type: 'checkbox',
                                columns: 3,
                                style: '',
                                required: false
                            }"
                            :datainput="{
                                id: 'value_data_select_staff',
                                name: 'data[staff][]'
                            }"
                            :selected_input="[]"
                            :feed="getterFeed['staff']"
                        />
                    </div>
                </div>
            </div>
            <div class="gh-row-content" :key="'SEARCH_2_' + refreshSearch">
                <div>
                    <div class="no-min-width width-50">
                        <GhDataSelector
                            :datablock="{
                                id: 'data_select_pcats_code',
                                class: 'T15_b',
                                subtitle: $t('select_pcats_code') + ':',
                                text: $t('pcats_code'),
                                label: $t('pcats_code'),
                                type: 'checkbox',
                                columns: 3,
                                style: '',
                                required: false
                            }"
                            :datainput="{
                                id: 'value_data_select_pcats_code',
                                name: 'data[pcats_code][]'
                            }"
                            :selected_input="[]"
                            :feed="getterFeed['pcats-code']"
                        />
                    </div>
                    <div class="no-min-width width-50">
                        <GhDataSelector
                            :datablock="{
                                id: 'data_select_procedure',
                                class: 'T15_b',
                                subtitle: $t('select_procedure') + ':',
                                text: $t('procedure'),
                                label: $t('procedure'),
                                type: 'checkbox',
                                columns: 3,
                                style: '',
                                required: false
                            }"
                            :datainput="{
                                id: 'value_data_select_procedure',
                                name: 'data[procedure][]'
                            }"
                            :selected_input="[]"
                            :feed="getterFeed['procedure']"
                        />
                    </div>
                </div>
            </div>
            <div class="gh-row-content" :key="'SEARCH_3_' + refreshSearch">
                <div>
                    <div class="no-min-width width-50">
                        <GhDataSelector
                            :datablock="{
                                id: 'data_select_procedure_category',
                                class: 'T15_b',
                                subtitle: $t('select_procedure_category') + ':',
                                text: $t('procedure_category'),
                                label: $t('procedure_category'),
                                type: 'checkbox',
                                columns: 3,
                                style: '',
                                required: false
                            }"
                            :datainput="{
                                id: 'value_data_select_procedure_category',
                                name: 'data[procedure_category][]'
                            }"
                            :selected_input="[]"
                            :feed="getterFeed['procedure-category']"
                        />
                    </div>
                    <div class="no-min-width width-50" :key="'SEARCH_4_' + refreshSearch">
                        <GhDataSelector
                            :datablock="{
                                id: 'data_select_w2_priority',
                                class: 'T15_b',
                                subtitle: $t('select_w2_priority') + ':',
                                text: $t('w2_priority'),
                                label: $t('w2_priority'),
                                type: 'checkbox',
                                columns: 3,
                                style: '',
                                required: false
                            }"
                            :datainput="{
                                id: 'value_data_select_w2_priority',
                                name: 'data[w2_priority][]'
                            }"
                            :selected_input="[]"
                            :feed="getterFeed['w2-priority']"
                        />
                    </div>
                </div>
            </div>
            <div class="gh-row-content" :key="'SEARCH_5_' + refreshSearch">
                <div>
                    <div class="no-min-width width-50">
                        <GhDataSelector
                            :datablock="{
                                id: 'data_select_location',
                                class: 'T15_b',
                                subtitle: $t('select_location') + ':',
                                text: $t('location'),
                                label: $t('location'),
                                type: 'checkbox',
                                columns: 3,
                                style: '',
                                required: false
                            }"
                            :datainput="{
                                id: 'value_data_select_location',
                                name: 'data[location][]'
                            }"
                            :selected_input="[]"
                            :feed="getterFeed['location']"
                        />
                    </div>
                    <div class="no-min-width width-50">
                        <GhDataSelector
                            :datablock="{
                                id: 'data_select_equipment',
                                class: 'T15_b',
                                subtitle: $t('select_equipment') + ':',
                                text: $t('equipment'),
                                label: $t('equipment'),
                                type: 'checkbox',
                                columns: 3,
                                style: '',
                                required: false
                            }"
                            :datainput="{
                                id: 'value_data_select_equipment',
                                name: 'data[equipment][]'
                            }"
                            :selected_input="[]"
                            :feed="getterFeed['equipment']"
                        />
                    </div>
                </div>
            </div>
            <div class="gh-row-content" :key="'SEARCH_6_' + refreshSearch">
                <div>
                    <div class="no-min-width">
                        <GhDataSelector
                            :datablock="{
                                id: 'data_select_constraint',
                                class: 'T15_b',
                                subtitle: $t('select_constraint') + ':',
                                text: $t('constraint'),
                                label: $t('constraint'),
                                type: 'checkbox',
                                columns: 3,
                                style: '',
                                required: false
                            }"
                            :datainput="{
                                id: 'value_data_select_constraint',
                                name: 'data[constraint][]'
                            }"
                            :selected_input="[]"
                            :feed="getterFeed['constraint-type']"
                        />
                    </div>
                </div>
            </div>
            <div class="gh-row-content">
                <GhChecks
                    :datainput="{
                        id: 'radio_and',
                        value: 'and',
                        type: 'radio',
                        name: 'data[logic]',
                        style: 'margin-left: 30px;',
                        selected: true
                    }"
                    :datalabel="{
                        style: '',
                        class: 'T15 input_label',
                        id: 'label_and',
                        text: $t('meet_all_search_criteria_entered')
                    }"
                />
                <GhChecks
                    :datainput="{
                        id: 'radio_or',
                        value: 'or',
                        type: 'radio',
                        style: 'margin-left: 30px;',
                        name: 'data[logic]',
                    }"
                    :datalabel="{
                        style: 'margin-left: 4px;',
                        class: 'T15 input_label',
                        id: 'label_or',
                        text: $t('meet_any_of_the_search_criteria_entered')
                    }"
                />
            </div>
        </template>
    </LoaderTemplate>
</template>

<script>
    import LoaderTemplate from "@/view/Shared/Loader/loader.vue";
    import GhDataSelector from 'fe-gh-data-selector-lib';
    import GhChecks from "fe-gh-checks-lib";

    import {mapGetters} from "vuex";

    export default {
        name: 'filter_form',
        components: {
            LoaderTemplate,
            GhDataSelector,
            GhChecks
        },
        computed: {
            ...mapGetters(['getterFeed'])
        },
        data(){
            return {
                refreshSearch: 0,
                haveData: false
            }
        },
        async beforeDestroy(){
            await this.$store.dispatch('destroyFeed', {
                from: 'department-specialty'
            }, {root: true});

            await this.$store.dispatch('destroyFeed', {
                from: 'staff'
            }, {root: true});

            await this.$store.dispatch('destroyFeed', {
                from: 'pcats-code'
            }, {root: true});

            await this.$store.dispatch('destroyFeed', {
                from: 'procedure'
            }, {root: true});

            await this.$store.dispatch('destroyFeed', {
                from: 'procedure-category'
            }, {root: true});

            await this.$store.dispatch('destroyFeed', {
                from: 'w2-priority'
            }, {root: true});

            await this.$store.dispatch('destroyFeed', {
                from: 'location'
            }, {root: true});

            await this.$store.dispatch('destroyFeed', {
                from: 'equipment'
            }, {root: true});

            await this.$store.dispatch('destroyFeed', {
                from: 'constraint-type'
            }, {root: true});
        },
        async beforeMount(){
            await this.$store.dispatch('getFeed', {
                from: 'department-specialty'
            }, {root: true});

            await this.$store.dispatch('getFeed', {
                from: 'staff'
            }, {root: true});

            await this.$store.dispatch('getFeed', {
                from: 'pcats-code'
            }, {root: true});

            await this.$store.dispatch('getFeed', {
                from: 'procedure'
            }, {root: true});

            await this.$store.dispatch('getFeed', {
                from: 'procedure-category'
            }, {root: true});

            await this.$store.dispatch('getFeed', {
                from: 'w2-priority'
            }, {root: true});

            await this.$store.dispatch('getFeed', {
                from: 'location'
            }, {root: true});

            await this.$store.dispatch('getFeed', {
                from: 'equipment'
            }, {root: true});

            await this.$store.dispatch('getFeed', {
                from: 'constraint-type'
            }, {root: true});

            this.$nextTick(() => {
                this.haveData = true;
            });
        }
    }
</script>

<style>
.no-min-width > div{
    min-width: unset !important;
}
.no-min-width > div > div{
    margin-bottom: unset !important;
}

.width-50{
    width: 50% !important;
}

#data_select_staff_selector_label, #data_select_procedure_selector_label, #data_select_location_selector_label, #data_select_equipment_selector_label, #data_select_constraint_selector_label {
    min-width: 150px;
    text-align: right;
}
</style>