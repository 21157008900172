<template>
    <div>
        <div v-for="(label, labelIndex) in sections" :key="'DROP_SECTION_' + labelIndex" :style="labelIndex === sections.length - 1 ? 'padding-bottom: 5px;' : ''">
            <template v-if="perm === undefined || perm[label.result]">
                <GhDropSelector
                    :datablock="{
                        id: 'drop_section_' + label.id,
                        title: $t(label.name),
                        second_title: result !== undefined && Object.keys(result).length !== 0 && result[label.result] !== 0 ? $t(label.second_text) : '',
                        num_results: result !== undefined && Object.keys(result).length !== 0 && result[label.result] !== 0 ? result[label.result] : ''
                    }"
                >
                    <template v-slot:content>
                        <component v-bind:is="label.component"/>
                    </template>
                </GhDropSelector>
            </template>
        </div>
    </div>
</template>

<script>
    import GhDropSelector from 'fe-gh-dropselector-lib';

    export default {
        name: 'template_drop_sections',
        props: ['sections', 'result', 'perm'],
        components: {
            GhDropSelector
        }
    }
</script>