var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LoaderTemplate',{attrs:{"haveData":_vm.haveData},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('GhTableCommon',{staticStyle:{"margin-top":"10px"},attrs:{"extratable":{
                id: 'historic'
            },"header":_vm.header,"data":_vm.getterList['historic'] !== undefined && _vm.getterList['historic']['historic'] !== undefined ? _vm.getterList['historic']['historic'].data : []},scopedSlots:_vm._u([{key:"actions",fn:function({itemProp}){return [(_vm.getterList['historic']['historic'].actions.view)?[_c('GhAction',{attrs:{"dataction":{
                            id: 'action_view_patients_' + itemProp.id,
                            text: _vm.$t('view'),
                            icon: require('@/assets/gh_new_dessign/view.svg')
                        }},on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('patients_patients_view', {
                            id: itemProp.id,
                            view: true,
                        }),'window_patient')}}})]:_vm._e()]}}])})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }