import Equipment from '@/view/Configuration/Equipment/Equipment/Template/section.vue';
import EquipmentSearch from '@/view/Shared/Window/Search/Equipment/main.vue';

const route = [
    {
        path: '/configuration/equipment/equipment',
        name: 'config_equipment_equipment',
        props: {showMenu: true, showUser: true},
        component: Equipment,
        children: [
            {
                path: '/configuration/equipment/equipment/:id',
                name: 'config_equipment_equipment_modify',
                component: Equipment,
                props: {id: null}
            },
            {
                path: '/configuration/equipment/equipment/:id/:view',
                name: 'config_equipment_equipment_view',
                component: Equipment,
                props: {id: null, view: true}
            }
        ]
    },
    {
        path: '/equipment/content/search',
        name: 'equipment_search',
        component: EquipmentSearch,
        props: {showMenu: false}
    }
];

const routes = [
    ...route,
];

export default routes;