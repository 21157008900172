import Staff from '@/view/Configuration/Staff/Staff/Template/section.vue';
import Schedule from '@/view/Shared/Window/Schedule/main.vue';
import StaffSearch from '@/view/Shared/Window/Search/Staff/main.vue';

const route = [
    {
        path: '/configuration/staff/staff',
        name: 'config_staff_staff',
        props: {showMenu: true, showUser: true},
        component: Staff,
        children: [
            {
                path: '/configuration/staff/staff/:id',
                name: 'config_staff_staff_modify',
                component: Staff,
                props: {id: null}
            },
            {
                path: '/configuration/staff/staff/:id/:view',
                name: 'config_staff_staff_view',
                component: Staff,
                props: {id: null, view: true}
            }
        ]
    },
    {
        path: '/schedule/by-staff/:id',
        name: 'schedule_by_staff',
        component: Schedule,
        props: {showMenu: false, showUser: false, background: true, id: null},
        children: [
            {
                path: '/schedule/by-staff/:id/:view',
                name: 'schedule_by_staff_view',
                component: Schedule,
                props: {view: true}
            }
        ]
    },
    {
        path: '/staff/content/search',
        name: 'staff_search',
        component: StaffSearch,
        props: {showMenu: false}
    }
];

const routes = [
    ...route,
];

export default routes;