var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('GhMenu',{ref:"refMenu",scopedSlots:_vm._u([{key:"cabezera",fn:function(){return [_c('div',{staticClass:"logo_cabecera_square",staticStyle:{"margin-left":"8px"}},[_c('img',{staticStyle:{"max-width":"95px !important","margin-top":"40px"},attrs:{"alt":"","src":require('@/assets/menu/sickkids-logo.png')}})])]},proxy:true},{key:"header-platform_info",fn:function(){return [(_vm.$route.matched[0] !== undefined && _vm.$route.matched[0].props.default.showUser)?[_c('GhUserIcon',{attrs:{"generalConfiguration":{
                        class: 'flex-row-textt',
                        icon: require('@/assets/gh_new_dessign/user.svg'),
                        title: '',
                        style: 'top: 53px; right: 20px; position:fixed;',
                        id: 'icon_user'
                    },"bodyConfiguration":{
                        class: 'flex-row-text',
                        title: '',
                        style: '',
                        id: 'icon_user_body'
                    }}})]:_vm._e(),_c('GhHelpIcon',{attrs:{"dataBlock":{
                    icon: require('@/assets/gh_new_dessign/help.svg')
                }}}),_c('GhLanguage',{attrs:{"dataBlock":{
                    title: _vm.$t('select_language'),
                    id:'select_lang',
                    icon: require('@/assets/gh_new_dessign/arrowdown.svg')
                },"language":_vm.languages,"selected":_vm.selectedLanguage}}),_c('div',{staticClass:"header-phone T4"},[_vm._v(" "+_vm._s('935 950 764')+" ")]),_c('div',{staticClass:"header-email T4"},[_vm._v(" "+_vm._s('info@globalholdings2000.es')+" ")])]},proxy:true}])}),_c('div',{staticStyle:{"margin-top":"93px"}}),_c('GhLocation',{key:_vm.refreshLocation,attrs:{"dataLocation":{
            showButton: false,
            secondWindow: false,
            showLoader: 'OK',
            text: _vm.$route.name === 'register' || _vm.$route.name === 'register_type' ? _vm.$t('registration_application') : (_vm.$route.matched[0].props.default.otherTitle !== undefined ? _vm.$t(_vm.$route.matched[0].props.default.otherTitle) : _vm.$t(_vm.Tabs.TabActive))
        },"dataButton":{id: '', text: 'Boton', style: ''}}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }