var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LoaderTemplate',{attrs:{"haveData":_vm.haveData},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('form',{attrs:{"id":"form_data"}},[_c('div',{staticClass:"gh-content-global"},[_c('CodeNameDescription',{attrs:{"code":{
                        show: true,
                        required: true,
                        read_only: _vm.$root._route.params.id !== undefined,
                        value: _vm.code
                    },"name":{
                        show: true,
                        required: true,
                        read_only: _vm.$root._route.params.id !== undefined,
                        other_name: 'diagnosis',
                        value: _vm.diagnosis
                    },"description":{
                        show: false,
                        required: false,
                        value: ''
                    }}}),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view && _vm.$root._route.params.id === undefined)?[_c('GhDataSelector',{ref:"RefProcedureService",attrs:{"datablock":{
                                id: 'data_select_procedure_service',
                                class: 'T15_b',
                                subtitle: _vm.$t('select_procedure_service') + ':',
                                text: _vm.$t('procedure_service') + '*',
                                label: _vm.$t('procedure_service'),
                                type: 'radio',
                                columns: 3,
                                style: '',
                                required: true
                            },"datainput":{
                                id: 'value_data_select_procedure_service',
                                name: 'data[procedure_service]'
                            },"selected_input":_vm.procedure_service,"feed":_vm.getterFeed['procedure-service']},on:{"accept":function($event){return _vm.checkSelector('RefProcedureService')}}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('procedure_service') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(Object.values(_vm.procedure_service).join(', ')))])]],2),_c('div',{key:'REFRESH_PROCEDURE_CATEGORY_' + _vm.refresh,staticClass:"gh-row-content"},[(_vm.procedure_service.length === 0 || _vm.getterCasuistry['procedure-category'] === undefined || _vm.getterCasuistry['procedure-category'].length === 0)?[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('procedure_category')))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(_vm.procedure_service.length === 0 ? _vm.$t('first_select_procedure_service') : _vm.$t('procedure_service_no_contains_procedure_category')))])]:(_vm.$root._route.params.view)?[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('procedure_category') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(Object.values(_vm.procedure_category).join(', ')))])]:[_c('GhDataSelector',{ref:"RefProcedureCategory",attrs:{"datablock":{
                                id: 'data_select_procedure_category',
                                class: 'T16_b',
                                subtitle: _vm.$t('select_procedure_category') + ':',
                                text: _vm.$t('procedure_category'),
                                label: _vm.$t('procedure_category'),
                                type: 'radio',
                                columns: 3,
                                style: '',
                                required: false
                            },"datainput":{
                                id: 'value_data_select_procedure_category',
                                name: 'data[procedure_category]'
                            },"selected_input":_vm.procedure_category,"feed":_vm.getterCasuistry['procedure-category']},on:{"accept":function($event){return _vm.checkSelector('RefProcedureCategory')}}})]],2),_c('div',{key:'REFRESH_PROCEDURE_SUBCATEGORY_' + _vm.refresh,staticClass:"gh-row-content"},[(_vm.procedure_category.length === 0 || _vm.getterCasuistry['procedure-subcategory'] === undefined || _vm.getterCasuistry['procedure-subcategory'].length === 0)?[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('subcategory')))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(_vm.procedure_category.length === 0 ? _vm.$t('first_select_procedure_category') : _vm.$t('procedure_category_no_contains_procedure_subcategory')))])]:(_vm.$root._route.params.view)?[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('subcategory') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",style:(_vm.na ? '' : 'min-width: 500px;')},[_vm._v(_vm._s(Object.values(_vm.procedure_subcategory).join(', ')))])]:[(!_vm.na)?[_c('GhDataSelector',{ref:"RefProcedureSubcategory",attrs:{"datablock":{
                                    id: 'data_select_procedure_subcategory',
                                    class: 'T16_b',
                                    subtitle: _vm.$t('select_procedure_subcategory') + ':',
                                    text: _vm.$t('subcategory'),
                                    label: _vm.$t('procedure_subcategory'),
                                    type: 'checkbox',
                                    columns: 3,
                                    style: '',
                                    required: false
                                },"datainput":{
                                    id: 'value_data_select_procedure_subcategory',
                                    name: 'data[procedure_subcategory][]'
                                },"selected_input":_vm.procedure_subcategory,"feed":_vm.getterCasuistry['procedure-subcategory']},on:{"accept":function($event){return _vm.checkSelector('RefProcedureSubcategory')}}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('subcategory')))])]],(_vm.procedure_category.length !== 0 && _vm.getterCasuistry['procedure-subcategory'] !== undefined && _vm.getterCasuistry['procedure-subcategory'].length !== 0)?[_c('GhChecks',{ref:"RefProcedureSubcategoryNa",attrs:{"datalabel":{
                                text: _vm.$t('na')
                            },"datainput":{
                                required: false,
                                type: 'checkbox',
                                id: 'data_na_select_procedure_subcategory',
                                value: 1,
                                name: 'data[na]',
                                selected: _vm.na,
                                disabled: _vm.$root._route.params.view
                            }},on:{"click":function($event){return _vm.checkSelector('RefProcedureSubcategoryNa')}}})]:_vm._e()],2),_c('div',{staticClass:"gh-row-content"},[(_vm.$root._route.params.view)?[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('w2_priority') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(Object.values(_vm.w2_priority).join(', ')))])]:[_c('GhDataSelector',{ref:"RefW2Prioirty",attrs:{"datablock":{
                                id: 'data_select_w2_priority',
                                class: 'T16_b',
                                subtitle: _vm.$t('select_w2_priority') + ':',
                                text: _vm.$t('w2_priority'),
                                label: _vm.$t('w2_priority'),
                                type: 'radio',
                                columns: 3,
                                style: '',
                                required: false
                            },"datainput":{
                                id: 'value_data_select_w2_priority',
                                name: 'data[w2_priority]'
                            },"selected_input":_vm.w2_priority,"feed":_vm.getterFeed['w2-priority']},on:{"accept":function($event){return _vm.checkSelector('RefW2Prioirty')}}})]],2)],1)])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }