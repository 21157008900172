var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LoaderTemplate',{attrs:{"haveData":_vm.haveData},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('GhTableCommon',{attrs:{"extratable":{
                id: 'constraint_type'
            },"header":_vm.header,"data":_vm.getterList.general !== undefined && _vm.getterList.general['constraint-type'] !== undefined ? _vm.getterList.general['constraint-type'].data : []},scopedSlots:_vm._u([{key:"actions",fn:function({itemProp}){return [(_vm.getterList.general['constraint-type'].actions.view)?[_c('router-link',{attrs:{"to":{name: 'config_general_constraint_type_view', params: {id: itemProp.id, view: true}}}},[_c('GhAction',{attrs:{"dataction":{
                                id: 'action_view_constraint_type_' + itemProp.id,
                                text: _vm.$t('view'),
                                icon: require('@/assets/gh_new_dessign/view.svg')
                            }}})],1)]:_vm._e(),(_vm.getterList.general['constraint-type'].actions.modify)?[_c('router-link',{attrs:{"to":{name: 'config_general_constraint_type_modify', params: {id: itemProp.id}}}},[_c('GhAction',{attrs:{"dataction":{
                                id: 'action_edit_constraint_type_' + itemProp.id,
                                text: _vm.$t('modify'),
                                icon: require('@/assets/gh_new_dessign/edit.svg')
                            }}})],1)]:_vm._e()]}},{key:"footer",fn:function(){return [(_vm.getterList.general['constraint-type'].actions.add)?[_c('div',{staticClass:"centered"},[_c('router-link',{attrs:{"to":{name: 'config_general_constraint_type'}}},[_c('GhButton',{attrs:{"datainput":{
                                    id: 'btn_new_constraint_type',
                                    text: _vm.$t('add')
                                }}})],1)],1)]:_vm._e()]},proxy:true}])})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }