var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LoaderTemplate',{attrs:{"haveData":_vm.haveData},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('form',{attrs:{"id":"form_data"}},[_c('div',{staticClass:"gh-content-global"},[_c('CodeNameDescription',{attrs:{"code":{
                        show: true,
                        required: true,
                        value: _vm.getterGet.actions !== undefined && _vm.getterGet.actions['procedures'] !== undefined ? _vm.getterGet.actions['procedures'].code : '',
                        read_only: _vm.$root._route.params.id !== undefined
                    },"name":{
                        show: true,
                        required: true,
                        value: _vm.getterGet.actions !== undefined && _vm.getterGet.actions['procedures'] !== undefined ? _vm.getterGet.actions['procedures'].name : '',
                        read_only: _vm.$root._route.params.id !== undefined
                    },"description":{
                        show: false,
                        required: false,
                        value: ''
                    }}}),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhInputTextField',{attrs:{"datalabel":{
                                text: _vm.$t('if') + '*',
                                style: 'width: 150px; text-align: right;',
                                class: 'T15_b'
                            },"datainput":{
                                required: true,
                                id: 'data_if',
                                name: 'data[if]',
                                style: 'width: 50px',
                                value: _vm.getterGet.actions !== undefined && _vm.getterGet.actions['procedures'] !== undefined ? _vm.getterGet.actions['procedures'].if : '',
                                type: 'int',
                                limitNumMin: 1,
                                limitNumMax: 10
                            }}}),_c('GhDataSelector',{attrs:{"datablock":{
                                id: 'data_select_incidences',
                                class: 'T15_b',
                                subtitle: _vm.$t('select_incidence') + ':',
                                text: _vm.$t('incidences') + '*',
                                label: _vm.$t('incidences'),
                                type: 'radio',
                                columns: 3,
                                style: 'margin-left: 10px;',
                                required: true
                            },"datainput":{
                                id: 'value_data_select_incidences',
                                name: 'data[incidences][]'
                            },"selected_input":_vm.getterGet.actions !== undefined && _vm.getterGet.actions['procedures'] !== undefined ? _vm.getterGet.actions['procedures'].incidences : [],"feed":_vm.getterFeed['incidences']}}),_c('GhDataSelector',{attrs:{"datablock":{
                                id: 'data_select_ambit',
                                class: 'T15_b',
                                subtitle: _vm.$t('select_ambit') + ':',
                                text: _vm.$t('ambit') + '*',
                                label: _vm.$t('ambit'),
                                type: 'checkbox',
                                columns: 3,
                                style: 'margin-left: 10px;',
                                required: true
                            },"datainput":{
                                id: 'value_data_select_ambit',
                                name: 'data[ambit][]'
                            },"selected_input":_vm.getterGet.actions !== undefined && _vm.getterGet.actions['procedures'] !== undefined ? _vm.getterGet.actions['procedures'].ambit : [],"feed":_vm.getterFeed['ambits']}}),_c('GhInputTextField',{attrs:{"datalabel":{
                                text: _vm.$t('in_period') + '*',
                                style: 'margin-left: 10px; white-space: nowrap;',
                                class: 'T15_b'
                            },"datainput":{
                                required: true,
                                id: 'data_in_period',
                                name: 'data[in_period]',
                                style: 'width: 50px',
                                value: _vm.getterGet.actions !== undefined && _vm.getterGet.actions['procedures'] !== undefined ? _vm.getterGet.actions['procedures'].in_period : '',
                                type: 'int',
                                limitNumMin: 1,
                                limitNumMax: 24
                            }}}),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.$t('months')))])]:[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px","margin-left":"160px"}},[_vm._v(" "+_vm._s(_vm.$t('if') + ' ' + (_vm.getterGet.actions['procedures'].if !== undefined ? _vm.getterGet.actions['procedures'].if : '') + ' ' + _vm.$t('incidences').toLowerCase() + ' ' + Object.values(_vm.getterGet.actions['procedures'].incidences).join(', ') + ' ' + _vm.$t('ambit').toLowerCase() + ' ' + Object.values(_vm.getterGet.actions['procedures'].ambit).join(', ') + ' ' + _vm.$t('in_period').toLowerCase() + ' ' + (_vm.getterGet.actions['procedures'].in_period !== undefined ? _vm.getterGet.actions['procedures'].in_period : '') + ' ' + _vm.$t('months').toLowerCase())+" ")])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                                id: 'data_select_actions',
                                class: 'T15_b',
                                subtitle: _vm.$t('select_actions') + ':',
                                text: _vm.$t('actions') + '*',
                                label: _vm.$t('actions'),
                                type: 'checkbox',
                                columns: 3,
                                style: '',
                                required: true
                            },"datainput":{
                                id: 'value_data_select_actions',
                                name: 'data[actions][]'
                            },"selected_input":_vm.getterGet.actions !== undefined && _vm.getterGet.actions['procedures'] !== undefined ? _vm.getterGet.actions['procedures'].actions : [],"feed":_vm.getterFeed['actions']}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('actions') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(Object.values(_vm.getterGet.actions['procedures'].actions).join(', ')))])]],2)],1)])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }