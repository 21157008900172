import Plan from '../../view/Plan/main.vue';

import Agendeds from './Agendeds/router';
import Historic from './Historic/router';
import InProgress from './InProgress/router';
import Pending from './Pending/router';
import Search from './Search/router';


const route = [
    {
        path: '/plan',
        name: 'plan',
        props: {showMenu: true, showUser: true},
        component: Plan
    }
];

const routes = [
    ...route,
    ...Agendeds,
    ...Historic,
    ...InProgress,
    ...Pending,
    ...Search,
];

export default routes;