import PcatsCode from '@/view/Configuration/Procedure/PcatsCode/Template/section.vue';

const route = [
    {
        path: '/configuration/procedure/pcats-code',
        name: 'config_procedure_pcats_code',
        props: {showMenu: true, showUser: true},
        component: PcatsCode,
        children: [
            {
                path: '/configuration/procedure/pcats-code/:id',
                name: 'config_procedure_pcats_code_modify',
                component: PcatsCode,
                props: {id: null}
            },
            {
                path: '/configuration/procedure/pcats-code/:id/:view',
                name: 'config_procedure_pcats_code_view',
                component: PcatsCode,
                props: {id: null, view: true}
            }
        ]
    }
];

const routes = [
    ...route,
];

export default routes;