var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[(_vm.code.show)?_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view && !_vm.code.read_only)?[_c('GhInputTextField',{ref:"RefCode",attrs:{"datalabel":{
                    text: _vm.$t('code') + (_vm.code.required ? '*' : ''),
                    style: 'width: 150px; text-align: right;',
                    class: _vm.code.required ? 'T15_b' : 'T15'
                },"datainput":{
                    required: _vm.code.required,
                    id: 'data_code',
                    name: 'data[code]',
                    style: 'width: 200px',
                    value: _vm.code.value,
                    type: 'text'
                }},on:{"keyup":function($event){return _vm.$emit('persist')}}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('code') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.code.value))])]],2):_vm._e(),(_vm.name.show)?_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view && !_vm.name.read_only)?[_c('GhInputTextField',{ref:"RefName",attrs:{"datalabel":{
                    text: (_vm.name.other_name ? _vm.$t(_vm.name.other_name) : _vm.$t('name')) + (_vm.name.required ? '*' : ''),
                    style: 'width: 150px; text-align: right;',
                    class: _vm.name.required ? 'T15_b' : 'T15'
                },"datainput":{
                    required: _vm.name.required,
                    id: 'data_' + (_vm.name.other_name ? _vm.name.other_name : 'name'),
                    name: 'data[' + (_vm.name.other_name ? _vm.name.other_name : 'name') + ']',
                    style: 'min-width: 400px; width: 35%',
                    value: _vm.name.value,
                    type: 'text'
                }},on:{"keyup":function($event){return _vm.$emit('persist')}}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('name') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.name.value))])]],2):_vm._e(),(_vm.description.show)?_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view && !_vm.description.read_only)?[_c('GhTextarea',{ref:"RefDescription",attrs:{"datalabel":{
                    text: _vm.$t('description') + (_vm.description.required ? '*' : ''),
                    styles: 'width: 150px; min-width: 150px !important; text-align: right; margin-right: 10px;',
                    class: _vm.description.required ? 'T15_b' : 'T15'
                },"datainput":{
                    id: 'data_description',
                    name: 'data[description]',
                    value: _vm.description.value,
                    required: _vm.description.required
                }},on:{"keyup":function($event){return _vm.$emit('persist')}}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('description') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-right":"10px"}},[_vm._v(_vm._s(_vm.description.value))])]],2):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }