import Groups from '@/view/Permissions/Groups/Groups/Template/section.vue';

const route = [
    {
        path: '/permissions/groups/groups',
        name: 'permissions_groups_groups',
        props: {showMenu: true, showUser: true},
        component: Groups,
        children: [
            {
                path: '/permissions/groups/groups/:id',
                name: 'permissions_groups_groups_modify',
                component: Groups,
                props: {id: null}
            },
            {
                path: '/permissions/groups/groups/:id/:view',
                name: 'permissions_groups_groups_view',
                component: Groups,
                props: {id: null, view: true}
            }
        ]
    }
];

const routes = [
    ...route,
];

export default routes;