import Profiles from '@/view/Permissions/Groups/Profiles/Template/section.vue';

const route = [
    {
        path: '/permissions/groups/profiles',
        name: 'permissions_groups_profiles',
        props: {showMenu: true, showUser: true},
        component: Profiles,
        children: [
            {
                path: '/permissions/groups/profiles/:id',
                name: 'permissions_groups_profiles_modify',
                component: Profiles,
                props: {id: null}
            },
            {
                path: '/permissions/groups/profiles/:id/:view',
                name: 'permissions_groups_profiles_view',
                component: Profiles,
                props: {id: null, view: true}
            }
        ]
    }
];

const routes = [
    ...route,
];

export default routes;