<template>
    <LoaderTemplate :haveData="haveData">
        <template v-slot:content>
            <form id="form_data">
                <div class="gh-content-global">
                    <CodeNameDescription
                        :code="{
                            show: false,
                            required: false,
                            value: ''
                        }"
                        :name="{
                            show: false,
                            required: false,
                            value: ''
                        }"
                        :description="{
                            show: true,
                            required: true,
                            value: getterGet.support !== undefined && getterGet.support['general-schedule'] !== undefined ? getterGet.support['general-schedule'].description : ''
                        }"
                    />
                    <div class="gh-row-content">
                        <template v-if="!$root._route.params.view">
                            <GhCalendar
                                :datalabel="{
                                    id: 'label_date_schedule',
                                    text: $t('date'),
                                    style: 'width: 150px; min-width: 150px; text-align: right; margin-right: 12px;'
                                }"
                                :datainput="{
                                    id:'data_date',
                                    name:'data[date]',
                                    value: getterGet.support !== undefined && getterGet.support['general-schedule'] !== undefined ? getterGet.support['general-schedule'].date : '',
                                    required: true,
                                    format: 'DD/MM',
                                    placeholder: 'DD/MM'
                                }"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('date') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="width: 155px; margin-right: 10px;">{{getterGet.support !== undefined && getterGet.support['general-schedule'] !== undefined && getterGet.support['general-schedule'].date !== '' ? getterGet.support['general-schedule'].date.split('/')[0] + '/' + getterGet.support['general-schedule'].date.split('/')[1]: ''}}</label>
                        </template>
                    </div>
                </div>
            </form>
        </template>
    </LoaderTemplate>
</template>

<script>
    import LoaderTemplate from '@/view/Shared/Loader/loader.vue';
    import CodeNameDescription from '@/view/Shared/Form/code_name_description.vue';
    import GhCalendar from "fe-gh-input-calendar-lib";

    import {mapGetters} from "vuex";

    export default {
        name: 'form_general_schedule',
        components: {
            LoaderTemplate,
            CodeNameDescription,
            GhCalendar
        },
        computed: {
            ...mapGetters(['getterGet'])
        },
        data(){
            return {
                haveData: false
            }
        },
        async beforeDestroy(){
            await this.$store.dispatch('destroyGetByType', {
                from: 'support'
            }, {root: true});
        },
        async beforeMount(){
            if(this.$root._route.params.id !== undefined) {
                await this.$store.dispatch('getByType', {
                    from: 'support',
                    type: 'general-schedule',
                    id: this.$root._route.params.id
                }, {root: true});
            }

            this.$nextTick(() => {
                this.haveData = true;
                this.$parent.$parent.$parent.haveData = this.haveData;
            });
        }
    }
</script>