import Permissions from '../../view/Permissions/main.vue';

import Groups from './Groups/router';
import Users from './Users/router';

const route = [
    {
        path: '/permissions',
        name: 'permissions',
        props: {showMenu: true, showUser: true},
        component: Permissions
    }
];

const routes = [
    ...route,
    ...Groups,
    ...Users
];

export default routes;