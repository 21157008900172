<template>
    <div class="float_window" :key="'REFRESH_TEMPLATE' + refreshTemplate">
        <template v-if="$root._route.params.action">
            <DropSections :sections="sectionPatientData"/>
            <DropSections :sections="sectionPlanificated" style="margin-top: -9px;"/>
            <DropSections :sections="sectionHistoric" style="margin-top: -9px;"/>
        </template>
        <template v-else-if="planificated">
            <DropSections :sections="sectionPatientData"/>
            <NoDropSections :sections="sectionPlanificated" style="margin-top: -9px;"/>
        </template>
        <template v-else>
            <NoDropSections :sections="sectionPatientData"/>
        </template>
        <div class="centered_footer">
            <GhButton v-if="!$root._route.params.view && haveData && allowSave && allowFinish"
                :datainput="{
                    id: 'btn_finish',
                    text: $t('finish')
                }"
                @click="doSet('finish')"
            />
            <GhButton v-if="!$root._route.params.view && haveData && allowSave"
                :datainput="{
                    id: 'btn_save',
                    text: $t('save')
                }"
                @click="doSet('save')"
            />
            <GhButton v-if="!$root._route.params.view && haveData && allowSchedule"
                :datainput="{
                    id: 'btn_agend',
                    text: $t('agend')
                }"
                @click="doSet('agend')"
            />
            <GhButton v-if="!$root._route.params.view && haveData && allowReschedule"
                :datainput="{
                    id: 'btn_reschedule',
                    text: $t('reschedule')
                }"
                @click="doSet('reschedule')"
            />
            <GhButton v-if="!$root._route.params.view && allowAction"
                :datainput="{
                    id: 'btn_action',
                    text: $t('action')
                }"
                @click="doSet('action')"
            />
            <GhButton
                :datainput="{
                    id: 'btn_return',
                    text: $t('back')
                }"
                @click="$root._route.params.reschedule ? router.push({name: 'plan'}) : $root._route.params.action ? router.push({name: 'advertisements'}) : router.push({name: 'patients'})"
            />
        </div>
        <template v-if="$root._route.params.action">
            <component :is="components.action" :dataPopup="dataPopup" :refreshContent="refreshContent"/>
        </template>
    </div>
</template>

<script>
    import DropSections from "@/view/Shared/Sections/DropSections.vue";
    import NoDropSections from "@/view/Shared/Sections/NoDropSections.vue";
    import GhButton from "fe-gh-button-lib";

    import router from "@/routes/router";

    import {mapGetters} from "vuex";

    import form_patient_data from './form_patient_data.vue';
    import form_planification from "./form_planification.vue";
    import historic_action from "./historic_action.vue";
    import action from '../Popup/action.vue';

    export default {
        name: 'section_patients',
        components: {
            DropSections,
            NoDropSections,
            GhButton
        },
        computed: {
            ...mapGetters(['getterGet'])
        },
        data(){
            return {
                planificated: false,
                sectionPatientData: [{id: 'patient_data', name: 'patient_data', component: form_patient_data}],
                sectionPlanificated: [{id: 'planification', name: 'planification', component: form_planification}],
                sectionHistoric: [{id: 'historic', name: 'historic', component: historic_action}],
                allowSave: false,
                allowFinish: false,
                allowSchedule: false,
                allowReschedule: false,
                allowAction: false,
                haveData: false,
                refreshTemplate: 0,
                components: {
                    action: action
                },
                refreshContent: 0,
                dataPopup: {
                    id: null,
                    type: '',
                    title: '',
                    stylePopup: '',
                    subTitle: '',
                    bodyText: '',
                    showButton: false,
                    showSlot: false,
                    allowEmit: false,
                    typeEmit: ''
                }
            }
        },
        async beforeDestroy(){
            await this.$store.dispatch('destroyGetByType', {
                from: 'patients'
            }, {root: true});

            if(this.$root._route.params.action){
                await this.$store.dispatch('destroyFeed', {
                    from: 'call-result'
                }, {root: true});
            }
        },
        async beforeMount(){
            if(this.$root._route.params.id !== undefined){
                await this.$store.dispatch('getByType', {
                    from: 'patients',
                    type: 'patients',
                    extra: 'type',
                    id: this.$root._route.params.id
                }, {root: true});

                this.planificated = this.getterGet.patients.patients['type'].planificated;

                if(this.$root._route.params.action){
                    await this.$store.dispatch('getFeed', {
                        from: 'call-result'
                    }, {root: true});

                    this.allowAction = true;
                }
            }
        },
        methods: {
            doSet(type){
                switch (type){
                    case 'save':
                        this.methodSet(type);
                        break;
                    case 'finish':
                    case 'agend':
                    case 'reschedule':
                        if(this.Global.checkRequired('form_data' + (this.planificated ? '_planification' : '')) === 'OK') {
                            this.methodSet(type);
                        }
                        break;
                    case 'action':
                        this.refreshContent++;
                        this.dataPopup = {
                            type: 'add',
                            title: type,
                            stylePopup: 'width: 650px, min-width: 650px, max-width: 650px,',
                            subTitle: '',
                            bodyText: 'specify_the_' + type + '_taken',
                            showSlot: true,
                            showButton: true,
                            allowEmit: true,
                            typeEmit: type
                        };

                        this.Global.openPopUp('popup_general');
                        break;
                }
            },
            methodSet(type){
                const formData = new FormData(document.getElementById('form_data' + (this.planificated ? '_planification' : '')));
                formData.append('data[type_save]', type);
                this.$store.dispatch('setByType', {
                    formData: [...formData],
                    id: this.$root._route.params.id,
                    from: 'patients',
                    type: 'patients',
                }, {root: true}).then(async (response) => {
                    if(response.status === 200 && type === 'save'){
                        this.refreshTemplate++;
                        if(this.$root._route.params.id === undefined) {
                            console.log(response.data.data);
                            router.push({
                                name: 'patients_patients_modify',
                                params: {
                                    id: response.data.data.id
                                }
                            });
                        }
                    }else if(response.status === 200 && type === 'finish'){
                        localStorage.setItem('drop_section_patient_data_Drop_Selector', false);
                        this.refreshTemplate++;
                        await this.$store.dispatch('getByType', {
                            from: 'patients',
                            type: 'patients',
                            extra: 'type',
                            id: this.$root._route.params.id
                        }, {root: true});

                        this.planificated = this.getterGet.patients.patients['type'].planificated;
                    }else if(response.status === 200 && type === 'agend'){
                        router.push({name: 'patients'});
                    }else if(response.status === 200 && type === 'reschedule'){
                        router.push({name: 'plan'});
                    }
                });
            },
            doEmmit(type){
                if(this.Global.checkRequired('form_data_' + type) === 'OK') {
                    const formData = new FormData(document.getElementById('form_data_' + type));
                    this.$store.dispatch('setByType', {
                        formData: [...formData],
                        id: this.$root._route.params.id,
                        from: 'patients',
                        type: 'patients',
                        extra_url: 'action'
                    }, {root: true}).then(async (response) => {
                        if(response.status === 200){
                            await this.$store.dispatch('getByType', {
                                from: 'patients',
                                type: 'patients',
                                extra: 'action',
                                id: this.$root._route.params.id
                            }, {root: true});
                        }
                    });

                    this.Global.closePopUp('popup_general');
                }

            }
        }
    }
</script>