<template>
    <GhPopUp v-show="false"
        :generalConfiguration="{
            id: 'warning_confirm',
            title: $t('required_fields'),
            type: 'warning',
            style: 'width: 35%; min-width: 430px'
        }"
        :bodyConfiguration="{
            id: '',
            class: '',
            style: 'padding:2px;',
            text: $t('the_following_fields_are_missing') + ':'
        }"
        :footerConfiguration="{
            id: '',
            class: '',
            style: '',
            content: ''
        }"
    >
        <template v-slot:body>
            <div class="gh_delete_confirm-content" style="overflow: auto;max-height: 800px; width: 100%; padding-bottom: 15px;" >
                <div class="T15_b" v-for="(item, index) in globalState.alert_required.requireds" :key="index" style="padding-left: 20px;">
                    {{item}}
                </div>
            </div>
        </template>
        <template v-slot:footer>
            <GhButton
                :datainput="{
                    id: 'button',
                    text: $t('close'),
                    class: 'T19 container-md_button_content',
                    style: ' display: flex;'
                }"
                @click="Global.closePopUp('warning_confirm');"
            />
        </template>
    </GhPopUp>
</template>

<script>
    import GhPopUp from 'fe-gh-popup-lib';
    import GhButton from "fe-gh-button-lib";

    export default {
        name: 'warning_confirm',
        components: {
            GhPopUp,
            GhButton
        },
        data(){
            return {
                globalState: this.Global.state,
            }
        }
    }
</script>