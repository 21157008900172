import Vue from "vue";
import vuex from "vuex";
import axios from "axios";

import {API_URL} from "@/common/config";

import router from "@/routes/router";
import Global from "@/store/Global";

Vue.use(vuex);

export default{
    actions: {
        async setByType(state, item){
            let formData = new FormData();

            if(item.formData !== undefined) {
                item.formData.forEach((item) => {
                    formData.append(item[0], item[1]);
                });
            }

            return await axios({
                method: "post",
                url: API_URL + (item.from !== undefined ? item.from + '/' : '') + item.type + (item.id !== undefined ? '/' + item.id : '') + (item.extra_url !== undefined ? '/' + item.extra_url : ''),
                data: formData,
                headers: { "Content-Type" : "multipart/form-data"}
            }).then(
                response => {
                    if(response.status === 200) {
                        if (item.redirect !== undefined) {
                            router.push(item.params !== undefined ? {name: item.redirect, params: item.params} : {name: item.redirect});
                        }
                    }

                    return response;
                },
                error => {
                    if(error.status !== ''){
                        Global.loadError('error_popup', error.response.data.data);
                    }
                }
            )
        }
    }
};