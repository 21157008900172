var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LoaderTemplate',{attrs:{"haveData":_vm.haveData},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('GhTableCommon',{attrs:{"extratable":{
                id: 'general_schedule'
            },"header":_vm.header,"data":_vm.getterList.support !== undefined && _vm.getterList.support['general-schedule'] !== undefined ? _vm.getterList.support['general-schedule'].data : []},scopedSlots:_vm._u([{key:"actions",fn:function({itemProp}){return [(_vm.getterList.support['general-schedule'].actions.view)?[_c('router-link',{attrs:{"to":{name: 'config_support_general_schedule_view', params: {id: itemProp.id, view: true}}}},[_c('GhAction',{attrs:{"dataction":{
                                id: 'action_view_general_schedule_' + itemProp.id,
                                text: _vm.$t('view'),
                                icon: require('@/assets/gh_new_dessign/view.svg')
                            }}})],1)]:_vm._e(),(_vm.getterList.support['general-schedule'].actions.modify)?[_c('router-link',{attrs:{"to":{name: 'config_support_general_schedule_modify', params: {id: itemProp.id}}}},[_c('GhAction',{attrs:{"dataction":{
                                id: 'action_edit_general_schedule_' + itemProp.id,
                                text: _vm.$t('modify'),
                                icon: require('@/assets/gh_new_dessign/edit.svg')
                            }}})],1)]:_vm._e(),(_vm.getterList.support['general-schedule'].actions.delete)?[_c('GhAction',{attrs:{"dataction":{
                            id: 'action_delete_general_schedule_' + itemProp.id,
                            text: _vm.$t('delete'),
                            icon: require('@/assets/gh_new_dessign/trash.svg')
                        }},on:{"click":function($event){_vm.Global.deleteConfirm('delete_confirm','removeByType', {
                            from: 'support',
                            type: 'general-schedule',
                            id: itemProp.id
                        },{
                            'description': itemProp.description,
                            'date': itemProp.day + '/' + itemProp.month
                        }, _vm.$t('delete_general_schedule'), _vm.$t('preparing_delete_general_schedule'), {
                            'getListByType': {
                                from: 'support',
                                type: 'general-schedule'
                            },
                            'getCountersByType': {
                                type: 'support'
                            }
                        })}}})]:_vm._e()]}},{key:"footer",fn:function(){return [(_vm.getterList.support['general-schedule'].actions.add)?[_c('div',{staticClass:"centered"},[_c('router-link',{attrs:{"to":{name: 'config_support_general_schedule'}}},[_c('GhButton',{attrs:{"datainput":{
                                    id: 'btn_new_general_schedule',
                                    text: _vm.$t('add')
                                }}})],1)],1)]:_vm._e()]},proxy:true}])})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }