import Vue from "vue";
import vuex from "vuex";
import axios from "axios";

import {API_URL} from "@/common/config";
import Global from "@/store/Global";

Vue.use(vuex);

export default{
    state: {
        list: [],
        flagList: false
    },
    mutations: {
        loadList(state, payload) {
            if(payload.destroy){
                state.list[payload.from] = [];
            }else if(payload.from !== undefined){
                if(state.list[payload.from] === undefined){
                    state.list[payload.from] = [];
                }

                if(payload.type !== undefined){
                    if(state.list[payload.from][payload.type] === undefined){
                        state.list[payload.from][payload.type] = [];
                    }

                    state.list[payload.from][payload.type] = payload.data;
                }
            }

            state.flagList = !state.flagList;
        }
    },
    getters: {
        getterList(state){
            const dummy = state.flagList; // eslint-disable-line no-unused-vars
            return state.list;
        }
    },
    actions: {
        async getListByType(state, item){
            await axios.get(
                API_URL + (item.from !== undefined ? item.from + '/' : '') + item.type
            ).then(
                response => {
                    if(response.status === 200) {
                        state.commit('loadList', {
                            from: item.from,
                            type: item.type,
                            data: response.data.data
                        });
                    }

                    return response;
                },
                error => {
                    if(error.status !== ''){
                        Global.loadError('error_popup', error.response.data.data);
                    }
                }
            )
        },
        setListByType(state, item){
            state.commit('loadList', {
                from: item.from,
                type: item.type,
                data: item.data
            });
        },
        destroyListByType(state, item){
            state.commit('loadList', {
                from: item.from,
                destroy: true
            });
        }
    }
};