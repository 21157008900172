<template>
    <div>
        <div class="gh-row-content" v-if="code.show">
            <template v-if="!$root._route.params.view && !code.read_only">
                <GhInputTextField
                    :datalabel="{
                        text: $t('code') + (code.required ? '*' : ''),
                        style: 'width: 150px; text-align: right;',
                        class: code.required ? 'T15_b' : 'T15'
                    }"
                    :datainput="{
                        required: code.required,
                        id: 'data_code',
                        name: 'data[code]',
                        style: 'width: 200px',
                        value: code.value,
                        type: 'text'
                    }"
                    ref="RefCode"
                    @keyup="$emit('persist')"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('code') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{code.value}}</label>
            </template>
        </div>
        <div class="gh-row-content" v-if="name.show">
            <template v-if="!$root._route.params.view && !name.read_only">
                <GhInputTextField
                    :datalabel="{
                        text: (name.other_name ? $t(name.other_name) : $t('name')) + (name.required ? '*' : ''),
                        style: 'width: 150px; text-align: right;',
                        class: name.required ? 'T15_b' : 'T15'
                    }"
                    :datainput="{
                        required: name.required,
                        id: 'data_' + (name.other_name ? name.other_name : 'name'),
                        name: 'data[' + (name.other_name ? name.other_name : 'name') + ']',
                        style: 'min-width: 400px; width: 35%',
                        value: name.value,
                        type: 'text'
                    }"
                    ref="RefName"
                    @keyup="$emit('persist')"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{name.value}}</label>
            </template>
        </div>
        <div class="gh-row-content" v-if="description.show">
            <template v-if="!$root._route.params.view && !description.read_only">
                <GhTextarea
                    :datalabel="{
                        text: $t('description') + (description.required ? '*' : ''),
                        styles: 'width: 150px; min-width: 150px !important; text-align: right; margin-right: 10px;',
                        class: description.required ? 'T15_b' : 'T15'
                    }"
                    :datainput="{
                        id: 'data_description',
                        name: 'data[description]',
                        value: description.value,
                        required: description.required
                    }"
                    ref="RefDescription"
                    @keyup="$emit('persist')"
                />
            </template>
            <template v-else>
                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('description') + ':'}}</label>
                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{description.value}}</label>
            </template>
        </div>
    </div>
</template>

<script>
    import GhInputTextField from 'fe-gh-inputtextfield-lib';
    import GhTextarea from 'fe-gh-textarea-lib';

    export default {
        name: 'code_name_description',
        props: ['code', 'name', 'description'],
        components: {
            GhInputTextField,
            GhTextarea
        }
    }
</script>