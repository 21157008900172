var render = function render(){var _vm=this,_c=_vm._self._c;return _c('GhPopUp',{directives:[{name:"show",rawName:"v-show",value:(false),expression:"false"}],attrs:{"generalConfiguration":{
        id: 'warning_confirm',
        title: _vm.$t('required_fields'),
        type: 'warning',
        style: 'width: 35%; min-width: 430px'
    },"bodyConfiguration":{
        id: '',
        class: '',
        style: 'padding:2px;',
        text: _vm.$t('the_following_fields_are_missing') + ':'
    },"footerConfiguration":{
        id: '',
        class: '',
        style: '',
        content: ''
    }},scopedSlots:_vm._u([{key:"body",fn:function(){return [_c('div',{staticClass:"gh_delete_confirm-content",staticStyle:{"overflow":"auto","max-height":"800px","width":"100%","padding-bottom":"15px"}},_vm._l((_vm.globalState.alert_required.requireds),function(item,index){return _c('div',{key:index,staticClass:"T15_b",staticStyle:{"padding-left":"20px"}},[_vm._v(" "+_vm._s(item)+" ")])}),0)]},proxy:true},{key:"footer",fn:function(){return [_c('GhButton',{attrs:{"datainput":{
                id: 'button',
                text: _vm.$t('close'),
                class: 'T19 container-md_button_content',
                style: ' display: flex;'
            }},on:{"click":function($event){return _vm.Global.closePopUp('warning_confirm');}}})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }