<template>
    <LoaderTemplate :haveData="haveData">
        <template v-slot:content>
            <form id="form_data">
                <div class="gh-content-global">
                    <Schedule :scheduleData="schedule"/>
                </div>
            </form>
        </template>
    </LoaderTemplate>
</template>

<script>
    import LoaderTemplate from '@/view/Shared/Loader/loader.vue';
    import Schedule from '@/view/Shared/Form/schedule.vue';

    import {mapGetters} from "vuex";

    export default {
        name: 'form_schedule',
        components: {
            LoaderTemplate,
            Schedule
        },
        computed: {
            ...mapGetters(['getterCasuistry'])
        },
        data(){
            return {
                emptySchedule: {
                    week_from: '',
                    week_to: '',
                    entrance_of: '',
                    entrance_to: '',
                    hours: '',
                    days: []
                },
                schedule: [],
                haveData: false
            }
        },
        async beforeDestroy(){
            await this.$store.dispatch('destroyCasuistry', {
                from: 'schedule'
            }, {root: true});
        },
        async beforeMount(){
            await this.$store.dispatch('getCasuistry', {
                from: 'schedule',
                url: (this.$root._route.params.from !== undefined ? (this.$root._route.params.from + '/' + (this.$root._route.params.type !== undefined ? (this.$root._route.params.type + '/') : '')) :'staff/staff/') + this.$root._route.params.id + '/schedule'
            }, {root: true});

            if(this.getterCasuistry['schedule'] !== undefined && this.getterCasuistry['schedule'].length !== 0){
                this.schedule = this.getterCasuistry['schedule'];
            }else{
                this.schedule.push(this.emptySchedule);
            }

            this.$nextTick(() => {
                this.haveData = true;
                this.$parent.$parent.$parent.haveData = this.haveData;
            });
        }
    }
</script>