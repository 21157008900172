<template>
    <div class="float_window">
        <div class="gh-content-global">
            <form id="form_data">
                <component :is="components.form"/>
            </form>
            <div class="centered_footer">
                <GhButton
                    :datainput="{
                        id: 'btn_search',
                        text: $t('search')
                    }"
                    @click="doAction('search')"
                />
                <GhButton
                    :datainput="{
                        id: 'btn_clean',
                        text: $t('clean')
                    }"
                    @click="doAction('clean')"
                />
            </div>
        </div>
    </div>
</template>

<script>
    import GhButton from "fe-gh-button-lib";

    import form from './Template/form.vue';

    export default {
        name: 'filter_patients',
        components: {
            GhButton
        },
        data(){
            return {
                components: {
                    form: form
                }
            }
        },
        methods: {
            async doAction(type){
                switch (type){
                    case 'search':
                        this.$parent.$parent.sections[1].show = true;
                        this.$parent.$parent.sections[1].name = 'searching_results';

                        this.$store.dispatch('setListByType', {
                            from: 'plan',
                            type: 'historic',
                            data: {'loader': true}
                        }, {root: true});

                        if(this.Global.checkRequired('form_data') === 'OK'){
                            const formData = new FormData(document.getElementById('form_data'));
                            await this.$store.dispatch('setSearch', {
                                formData: [...formData]
                            }, {root: true}).then((response) => {
                                if(response.status === 200){
                                    this.$parent.$parent.sections[1].name = response.data.data.data.length + ' ' + this.$t('results');
                                    this.$store.dispatch('setListByType', {
                                        from: 'plan',
                                        type: 'historic',
                                        data: response.data.data
                                    }, {root: true});
                                }
                            });
                        }
                        break;
                    case 'clean':
                        this.$parent.$parent.sections[1].show = false;
                        this.$parent.$parent.sections[1].name = 'searching_results';
                        this.$children[0].refreshSearch++;
                        break;
                }
            }
        }
    }
</script>