<template>
    <LoaderTemplate :haveData="haveData">
        <template v-slot:content>
            <GhTableCommon
                :extratable="{
                    id: 'general_schedule'
                }"
                :header="header"
                :data="getterList.support !== undefined && getterList.support['general-schedule'] !== undefined ? getterList.support['general-schedule'].data : []"
            >
                <template v-slot:actions="{itemProp}">
                    <template v-if="getterList.support['general-schedule'].actions.view">
                        <router-link :to="{name: 'config_support_general_schedule_view', params: {id: itemProp.id, view: true}}">
                            <GhAction
                                :dataction="{
                                    id: 'action_view_general_schedule_' + itemProp.id,
                                    text: $t('view'),
                                    icon: require('@/assets/gh_new_dessign/view.svg')
                                }"
                            />
                        </router-link>
                    </template>
                    <template v-if="getterList.support['general-schedule'].actions.modify">
                        <router-link :to="{name: 'config_support_general_schedule_modify', params: {id: itemProp.id}}">
                            <GhAction
                                :dataction="{
                                    id: 'action_edit_general_schedule_' + itemProp.id,
                                    text: $t('modify'),
                                    icon: require('@/assets/gh_new_dessign/edit.svg')
                                }"
                            />
                        </router-link>
                    </template>
                    <template v-if="getterList.support['general-schedule'].actions.delete">
                        <GhAction
                            :dataction="{
                                id: 'action_delete_general_schedule_' + itemProp.id,
                                text: $t('delete'),
                                icon: require('@/assets/gh_new_dessign/trash.svg')
                            }"
                            @click="Global.deleteConfirm('delete_confirm','removeByType', {
                                from: 'support',
                                type: 'general-schedule',
                                id: itemProp.id
                            },{
                                'description': itemProp.description,
                                'date': itemProp.day + '/' + itemProp.month
                            }, $t('delete_general_schedule'), $t('preparing_delete_general_schedule'), {
                                'getListByType': {
                                    from: 'support',
                                    type: 'general-schedule'
                                },
                                'getCountersByType': {
                                    type: 'support'
                                }
                            })"
                        />
                    </template>
                </template>
                <template v-slot:footer>
                    <template v-if="getterList.support['general-schedule'].actions.add">
                        <div class="centered">
                            <router-link :to="{name: 'config_support_general_schedule'}">
                                <GhButton
                                    :datainput="{
                                        id: 'btn_new_general_schedule',
                                        text: $t('add')
                                    }"
                                />
                            </router-link>
                        </div>
                    </template>
                </template>
            </GhTableCommon>
        </template>
    </LoaderTemplate>
</template>

<script>
    import LoaderTemplate from '@/view/Shared/Loader/loader.vue';
    import GhTableCommon from 'fe-gh-table-common-lib';
    import GhAction from "fe-gh-action-lib";
    import GhButton from "fe-gh-button-lib";

    import {mapGetters} from "vuex";

    export default {
        name: 'list_general_schedule',
        components: {
            LoaderTemplate,
            GhTableCommon,
            GhAction,
            GhButton
        },
        computed: {
            ...mapGetters(['getterList'])
        },
        data(){
            return {
                header: [
                    {text: 'day', field: 'day', sorting: true, typeSort: 'int', style: 'min-width: 50px; max-width: 50px; width: 50px;'},
                    {text: 'month', field: 'month', sorting: true, typeSort: 'int', style: 'min-width: 50px; max-width: 50px; width: 50px;'},
                    {text: 'description', field: 'description', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 100%;'},
                    {text: '', field: 'actions', sorting: false}
                ],
                haveData: false
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getListByType', {
                from: 'support',
                type: 'general-schedule'
            }, {root: true});

            this.$nextTick(() => {
                this.haveData = true;
            });
        }
    }
</script>