<template>
    <div style="max-width: 100%; margin-right: auto; margin-left: auto;">
        <template v-if="$route.matched[0] !== undefined && $route.matched[0].props.default.showMenu">
            <component :key="'ALL_REFRESH_' + refresh" :is="component.menu"/>
            <div id="content_page" :class="$route.path === '/' ? '' : 'content_page'">
                <transition name="fade" mode="out-in" v-on:after-enter="afterEnter" appear>
                    <router-view/>
                </transition>
            </div>
        </template>
        <template v-else>
            <router-view/>
        </template>

        <component :is="component.warning_confirm"/>
        <component :is="component.error"/>
        <GhDeleteConfirm
            :visible_fields="Global.state.state_confirm.item_confirm"
            :id_popup="'delete_confirm'"
            :content_warning="Global.state.state_confirm.subheader_confirm"
            v-on:function="doDelete"
        />
    </div>
</template>

<script>
    import Menu from '@/view/Shared/Menu/main.vue';
    import GhDeleteConfirm from 'fe-gh-delete-confirm-lib';

    import warning_confirm from '@/view/Shared/Popups/warning_confirm.vue';
    import error from '@/view/Shared/Popups/error.vue';

    export default {
        components:{
            GhDeleteConfirm
        },
        data(){
            return{
                component: {
                    menu: Menu,
                    warning_confirm: warning_confirm,
                    error: error
                },
                transitionName: '',
                refresh: 0
            }
        },
        watch: {
            '$i18n.locale'(){
                let that = this;
                setTimeout(function() {
                    document.title = 'SickKids • ' + that.$t('the_hospital_for_sick_children');
                    that.refresh++;
                }, 10);
            }
        },
        mounted() {
            document.title = 'SickKids • ' + this.$t('the_hospital_for_sick_children');
            this.updateBackColor();
        },
        beforeUpdate() {
            this.updateBackColor();
        },
        methods: {
            afterEnter: function() {
                return true;
            },
            updateBackColor(){
                var r = document.querySelector(':root');
                var color = '#FFFFFF';

                if(this.$route.matched[0].props.default.showMenu || this.$route.matched[0].props.default.background){
                    color = '#DDDDDD';
                }

                r.style.setProperty('--body_color',color);
            },
            doDelete(){
                this.$store.dispatch(this.Global.state.state_confirm.where_confirm,{
                    from: this.Global.state.state_confirm.params.from,
                    type: this.Global.state.state_confirm.params.type,
                    id: this.Global.state.state_confirm.params.id
                },{root:true}).then(async (response) => {
                    if(response.status === 200){
                        await Object.keys(this.Global.state.state_confirm.dispatch).forEach((dispatch) => {
                            if(this.Global.state.state_confirm.dispatch[dispatch].length !== undefined){
                                this.Global.state.state_confirm.dispatch[dispatch].forEach((params) => {
                                    this.$store.dispatch(dispatch, params, {root: true});
                                });
                            }else{
                                this.$store.dispatch(dispatch, this.Global.state.state_confirm.dispatch[dispatch], {root: true});
                            }
                        });

                        this.Global.closePopUp('delete_confirm');
                    }
                });
            }
        }
    }
</script>

<style>
    @import "./assets/css/styles.css";
    @import "~fe-gh-tabs-lib/dist/fe-gh-tabs-lib.css";
    @import "~fe-gh-subtab-lib/dist/fe-gh-subtab-lib.css";
    @import "~fe-gh-button-lib/dist/fe-gh-button-lib.css";
    @import "~fe-gh-popup-lib/dist/fe-gh-popup-lib.css";
    @import "~fe-gh-checks-lib/dist/fe-gh-checks-lib.css";
    @import "~fe-gh-location-lib/dist/fe-gh-location-lib.css";
    @import "~fe-gh-no-dropselector-lib/dist/fe-gh-no-dropselector-lib.css";
    @import "~fe-gh-dropselector-lib/dist/fe-gh-dropselector-lib.css";
    @import "~fe-gh-table-common-lib/dist/fe-gh-table-common-lib.css";
    @import "~fe-gh-inputtextfield-lib/dist/fe-gh-inputtextfield-lib.css";
    @import "~fe-gh-textarea-lib/dist/fe-gh-textarea-lib.css";
    @import "~fe-gh-input-hour-lib/dist/fe-gh-input-hour-lib.css";
    @import "~fe-gh-data-selector-lib/dist/fe-gh-data-selector-lib.css";
    @import "~fe-gh-input-calendar-lib/dist/fe-gh-input-calendar-lib.css";
    @import "~fe-gh-easy-upload-lib/dist/fe-gh-easy-upload-lib.css";

    * {
        padding: 0;
        margin: 0;
    }

    :root{
        --body_color: #FFFFFF;
    }

    body{
        background-color: var( --body_color);
    }

    .content_page{
        padding-left: 12px;
        padding-right: 12px;
        padding-bottom: 20px;
    }

    .modal {
        border-radius: 4px;
    }

    textarea {
        padding-top: 4px;
    }

    input[type="color"]{
        display: flex !important;
        margin-top: -3px;
    }

    .fade-enter-active, .fade-leave-active {
        transition: opacity 0.3s
    }

    .fade-enter, .fade-leave-active {
        opacity: 0
    }
</style>
