<template>
    <div>
        <transition name="fade" mode="out-in" v-on:after-enter="afterEnter" appear>
            <LoaderTemplate :haveData="haveData">
                <template v-slot:content>
                    <NoDropSections :sections="sections" :result="getterCounters" :perm="getterPermission"/>
                </template>
            </LoaderTemplate>
        </transition>
    </div>
</template>

<script>
    import LoaderTemplate from "@/view/Shared/Loader/loader.vue";
    import NoDropSections from "@/view/Shared/Sections/NoDropSections.vue";

    import {mapGetters} from "vuex";

    import filter from './Filter/main.vue';
    import patients from './Patients/main.vue';

    export default {
        name: 'configuration_patients',
        components: {
            LoaderTemplate,
            NoDropSections
        },
        computed: {
            ...mapGetters(['getterCounters', 'getterPermission'])
        },
        data(){
            return {
                sections: [
                    {id: 1, name: 'filter', component: filter, result: 'filter'},
                    {id: 2, name: 'patients', component: patients, result: 'patients', second_text: 'patients'}
                ],
                haveData: false
            }
        },
        beforeDestroy(){
            this.$store.dispatch('destroyListByType', {
                from: 'patients'
            }, {root: true});
        },
        async beforeMount(){
            await this.$store.dispatch('getPermissionByType', {type: 'patients'}, {root: true});
            await this.$store.dispatch('getCountersByType', {type: 'patients'}, {root: true});

            this.$nextTick(() => {
                this.haveData = true;
            });
        },
        methods: {
            afterEnter: function() {
                return true;
            }
        }
    }
</script>