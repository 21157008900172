<template>
    <LoaderTemplate :haveData="haveData">
        <template v-slot:content>
            <form id="form_data">
                <div style="display: flex;">
                    <div class="gh-content-global" style="width: 100%">
                        <div class="gh-row-content">
                            <template v-if="!planificated">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('id_number') + '*',
                                        style: 'width: 150px; min-width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'data_patient_data_id_number',
                                        name: 'data[patient_data][id_number]',
                                        style: 'width: 200px',
                                        value: patient_data.id_number,
                                        type: 'text'
                                    }"
                                    ref="RefPatientDataIdNumber"
                                    @keyup="checkSelector('RefPatientDataIdNumber')"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('id_number') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="width: 205px;">{{patient_data.id_number}}</label>
                            </template>
                            <template v-if="$root._route.params.id !== undefined">
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('mrn') + ':'}}</label>
                                <label class="gh_text_field-label T15_b">{{patient_data.mrn}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content">
                            <template v-if="!planificated">
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('name') + '*',
                                        style: 'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'data_patient_data_name',
                                        name: 'data[patient_data][name]',
                                        style: 'width: 200px',
                                        value: patient_data.name,
                                        type: 'text'
                                    }"
                                    ref="RefPatientDataName"
                                    @keyup="checkSelector('RefPatientDataName')"
                                />
                                <GhInputTextField
                                    :datalabel="{
                                        text: $t('surname') + '*',
                                        style: 'width: 150px; text-align: right;',
                                        class: 'T15_b'
                                    }"
                                    :datainput="{
                                        required: true,
                                        id: 'data_patient_data_surname',
                                        name: 'data[patient_data][surname]',
                                        style: 'width: 200px',
                                        value: patient_data.surname,
                                        type: 'text'
                                    }"
                                    ref="RefPatientDataSurname"
                                    @keyup="checkSelector('RefPatientDataSurname')"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="width: 205px;">{{patient_data.name}}</label>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('surname') + ':'}}</label>
                                <label class="gh_text_field-label T15_b">{{patient_data.surname}}</label>
                            </template>
                        </div>
                        <div class="gh-row-content">
                            <template v-if="!planificated">
                                <GhCalendar
                                    :datalabel="{
                                        id: 'label_data_date_of_birth',
                                        text: $t('dob'),
                                        style: 'width: 150px; min-width: 150px; text-align: right;'
                                    }"
                                    :datainput="{
                                        id: 'data_patient_data_date_of_birth',
                                        name: 'data[patient_data][dob]',
                                        value: patient_data.dob,
                                        disabled_date: 'after',
                                        range: new Date().getDate() + '/' + (new Date().getMonth() + 1 < 10 ? '0' : '') + (new Date().getMonth() + 1)  + '/' + new Date().getFullYear(),
                                        required: true
                                    }"
                                    ref="RefPatientDataDOB"
                                    @change="checkSelector('RefPatientDataDOB')"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('dob') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="width: 130px;">{{patient_data.dob}}</label>
                            </template>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 225px; min-width: 225px; text-align: right;">{{$t('age') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{patient_data.age + (patient_data.age !== '' ? ' ' + $t('y_o') : '')}}</label>
                        </div>
                        <div class="gh-row-content">
                            <template v-if="!planificated">
                                <GhDataSelector
                                    :datablock="{
                                        id: 'data_select_patient_data_gender',
                                        class: 'T15_b',
                                        subtitle: $t('select_gender') + ':',
                                        text: $t('gender') + '*',
                                        label: $t('gender'),
                                        type: 'radio',
                                        columns: 3,
                                        style: '',
                                        required: true
                                    }"
                                    :datainput="{
                                        id: 'value_data_select_patient_data_gender',
                                        name: 'data[patient_data][gender]'
                                    }"
                                    :selected_input="patient_data.gender"
                                    :feed="getterFeed['gender']"
                                    ref="RefPatientDataGender"
                                    @accept="checkSelector('RefPatientDataGender')"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('gender') + ':'}}</label>
                                <label class="gh_text_field-label T15_b">{{Object.values(patient_data.gender).join(', ')}}</label>
                            </template>
                        </div>
                    </div>
                    <div class="gh-content-global" style="width: 200px; display: grid;">
                        <div style="width: 90px; height: 90px; justify-self: right;">
                            <img :src="patient_data.image.image === '' ? require('@/assets/patient/user.svg') : patient_data.image.image" width="90" style="border-radius: 6px; max-height: 90px;" alt="">
                        </div>
                        <template v-if="!planificated">
                            <div class="other-image" style="flex-direction: row-reverse; display: flex;">
                                <GhEasyUpload
                                    :extrablock="{
                                        id: 'data_patient_data_image',
                                        class: '',
                                        name_hidden: 'data[patient_data][image]',
                                        id_hidden: 'data_patient_data_image',
                                        icon_attach: require('@/assets/gh_new_dessign/attach.svg')
                                    }"
                                    :datalabel="{
                                        id: 'patient_data_image',
                                        style: 'margin-right: -15px'
                                    }"
                                    :datainput="{
                                        view: $root._route.params.view,
                                        text: $t('attach_image'),
                                        show_image: false,
                                        delete: false,
                                        href: 'easyUpload',
                                        downloadHref: 'easyDownload',
                                        file_name: patient_data.image.file_name,
                                        file_path: patient_data.image.file_path,
                                        file_download: patient_data.image.file_download,
                                        required: false
                                    }"
                                />
                            </div>
                        </template>
                    </div>
                </div>
                <div class="section_line" style="margin-top: -5px;">
                    <div class="T15_b" style="padding-left:13px;">{{!is_adult ? $t('contact_person') : $t('responsible_person')}}</div>
                </div>
                <div class="gh-content-global">
                    <div class="gh-row-content">
                        <template v-if="!planificated">
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('name') + (is_adult ? '*' : ''),
                                    style: 'width: 150px; text-align: right;',
                                    class: is_adult ? 'T15_b' : 'T15'
                                }"
                                :datainput="{
                                    required: is_adult,
                                    id: 'data_responsible_person_name',
                                    name: 'data[responsible_person][name]',
                                    style: 'width: 200px',
                                    value: responsible_person.name,
                                    type: 'text'
                                }"
                                ref="RefResponsiblePersonName"
                            />
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('surname') + (is_adult ? '*' : ''),
                                    style: 'width: 150px; text-align: right;',
                                    class: is_adult ? 'T15_b' : 'T15'
                                }"
                                :datainput="{
                                    required: is_adult,
                                    id: 'data_responsible_person_surname',
                                    name: 'data[responsible_person][surname]',
                                    style: 'width: 200px',
                                    value: responsible_person.surname,
                                    type: 'text'
                                }"
                                ref="RefResponsiblePersonSurname"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="width: 205px;">{{responsible_person.name}}</label>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('surname') + ':'}}</label>
                            <label class="gh_text_field-label T15_b">{{responsible_person.surname}}</label>
                        </template>
                    </div>
                    <div class="gh-row-content">
                        <template v-if="!planificated">
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('telephone') + '*',
                                    style: 'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'data_responsible_person_telephone',
                                    name: 'data[responsible_person][telephone]',
                                    style: 'width: 200px',
                                    value: responsible_person.telephone,
                                    type: 'text'
                                }"
                                ref="RefResponsiblePersonTelephone"
                            />
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('email') + '*',
                                    style: 'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'data_responsible_person_email',
                                    name: 'data[responsible_person][email]',
                                    style: 'width: 350px',
                                    value: responsible_person.email,
                                    type: 'text'
                                }"
                                ref="RefResponsiblePersonEmail"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('telephone') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="width: 205px;">{{responsible_person.telephone}}</label>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('email') + ':'}}</label>
                            <label class="gh_text_field-label T15_b">{{responsible_person.email}}</label>
                        </template>
                    </div>
                    <template v-if="is_adult">
                        <div class="gh-row-content">
                            <template v-if="!planificated">
                                <GhDataSelector
                                    :datablock="{
                                        id: 'data_select_responsible_person_relation',
                                        class: 'T15_b',
                                        subtitle: $t('select_relation') + ':',
                                        text: $t('relation') + '*',
                                        label: $t('relation'),
                                        type: 'radio',
                                        columns: 3,
                                        style: '',
                                        required: true
                                    }"
                                    :datainput="{
                                        id: 'value_data_select_responsible_person_gender',
                                        name: 'data[responsible_person][relation]'
                                    }"
                                    :selected_input="responsible_person.relation"
                                    :feed="getterFeed['relation']"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('relation') + ':'}}</label>
                                <label class="gh_text_field-label T15_b">{{Object.values(responsible_person.relation).join(', ')}}</label>
                            </template>
                        </div>
                    </template>
                </div>
                <div class="section_line" style="margin-top: -5px;">
                    <div class="T15_b" style="padding-left:13px;">{{$t('intervention')}}</div>
                </div>
                <div class="gh-content-global">
                    <div class="gh-row-content">
                        <template v-if="!planificated">
                            <GhTextarea
                                :datalabel="{
                                    text: $t('intervention') + '*',
                                    class: 'T15_b',
                                    styles: 'width: 150px; min-width: 150px !important; text-align: right; margin-right: 10px;'
                                }"
                                :datainput="{
                                    id: 'data_intervention_intervention',
                                    name: 'data[intervention][intervention]',
                                    value: intervention.intervention,
                                    required: true
                                }"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('intervention') + ':'}}</label>
                            <label class="gh_text_field-label T15_b">{{intervention.intervention}}</label>
                        </template>
                    </div>
                    <div class="gh-row-content">
                        <template v-if="!planificated">
                            <GhDataSelector
                                :datablock="{
                                    id: 'data_select_intervention_location',
                                    class: 'T15_b',
                                    subtitle: $t('select_location') + ':',
                                    text: $t('location') + '*',
                                    label: $t('location'),
                                    type: 'radio',
                                    columns: 3,
                                    style: '',
                                    required: true
                                }"
                                :datainput="{
                                    id: 'value_data_select_intervention_location',
                                    name: 'data[intervention][location]'
                                }"
                                :selected_input="intervention.location"
                                :feed="getterFeed['location']"
                                ref="RefInterventionLocation"
                                @accept="checkSelector('RefInterventionLocation')"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('location') + ':'}}</label>
                            <label class="gh_text_field-label T15_b">{{Object.values(intervention.location).join(', ')}}</label>
                        </template>
                    </div>
                    <div class="gh-row-content">
                        <template v-if="!planificated">
                            <GhDataSelector
                                :datablock="{
                                    id: 'data_select_intervention_procedures',
                                    class: 'T15_b',
                                    subtitle: $t('select_procedure') + ':',
                                    text: $t('procedure') + '*',
                                    label: $t('procedure'),
                                    type: 'checkbox',
                                    columns: 3,
                                    style: '',
                                    required: true
                                }"
                                :datainput="{
                                    id: 'value_data_select_intervention_procedures',
                                    name: 'data[intervention][procedures][]'
                                }"
                                :selected_input="intervention.procedures"
                                :feed="getterFeed['procedure']"
                                ref="RefInterventionProcedures"
                                @accept="checkSelector('RefInterventionProcedures')"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('procedure') + ':'}}</label>
                            <label class="gh_text_field-label T15_b">{{Object.values(intervention.procedures).join(', ')}}</label>
                        </template>
                    </div>
                    <template v-if="Object.keys(intervention.procedure).length !== 0">
                        <div v-for="(item, keyItem) in intervention.procedure" :key="'PROCEDURE_' + keyItem + refreshProcedure">
                            <div class="section_line" style="margin-left: 160px; background-color: #e9f7ff;">
                                <div class="T15_b" style="padding-left:13px;">{{item.name}}</div>
                            </div>
                            <div class="gh-row-content" style="margin-top: 10px;">
                                <template v-if="!planificated">
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('setup_length') + '*',
                                            style: 'width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: true,
                                            id: 'data_intervention_procedure_' + keyItem + '_setup_length',
                                            name: 'data[intervention][procedure][' + keyItem + '][setup_length]',
                                            style: 'width: 30px',
                                            value: item.setup_length,
                                            type: 'int'
                                        }"
                                        maxlength="3"
                                        :ref="'RefInterventionProcedure' + keyItem + 'SetupLength'"
                                        @keyup="checkSelector('RefInterventionProcedure', keyItem, 'SetupLength')"
                                    />
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('patient_prep') + '*',
                                            style: 'width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: true,
                                            id: 'data_intervention_procedure_' + keyItem + '_patient_prep',
                                            name: 'data[intervention][procedure][' + keyItem + '][patient_prep]',
                                            style: 'width: 30px',
                                            value: item.patient_prep,
                                            type: 'int'
                                        }"
                                        maxlength="3"
                                        :ref="'RefInterventionProcedure' + keyItem + 'PatientPrep'"
                                        @keyup="checkSelector('RefInterventionProcedure', keyItem, 'PatientPrep')"
                                    />
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('procedure') + '*',
                                            style: 'width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: true,
                                            id: 'data_intervention_procedure_' + keyItem + '_procedure',
                                            name: 'data[intervention][procedure][' + keyItem + '][procedure]',
                                            style: 'width: 30px',
                                            value: item.procedure,
                                            type: 'int'
                                        }"
                                        maxlength="3"
                                        :ref="'RefInterventionProcedure' + keyItem + 'Procedure'"
                                        @keyup="checkSelector('RefInterventionProcedure', keyItem, 'Procedure')"
                                    />
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('wrap_up') + '*',
                                            style: 'width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: true,
                                            id: 'data_intervention_procedure_' + keyItem + '_wrap_up',
                                            name: 'data[intervention][procedure][' + keyItem + '][wrap_up]',
                                            style: 'width: 30px',
                                            value: item.wrap_up,
                                            type: 'int'
                                        }"
                                        maxlength="3"
                                        :ref="'RefInterventionProcedure' + keyItem + 'WrapUp'"
                                        @keyup="checkSelector('RefInterventionProcedure', keyItem, 'WrapUp')"
                                    />
                                    <GhInputTextField
                                        :datalabel="{
                                            text: $t('clean_up') + '*',
                                            style: 'width: 150px; text-align: right;',
                                            class: 'T15_b'
                                        }"
                                        :datainput="{
                                            required: true,
                                            id: 'data_intervention_procedure_' + keyItem + '_clean_up',
                                            name: 'data[intervention][procedure][' + keyItem + '][clean_up]',
                                            style: 'width: 30px',
                                            value: item.clean_up,
                                            type: 'int'
                                        }"
                                        maxlength="3"
                                        :ref="'RefInterventionProcedure' + keyItem + 'CleanUp'"
                                        @keyup="checkSelector('RefInterventionProcedure', keyItem, 'CleanUp')"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('setup_length') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="width: 30px;">{{item.setup_length}}</label>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('patient_prep') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="width: 30px;">{{item.patient_prep}}</label>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('procedure') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="width: 30px;">{{item.procedure}}</label>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('wrap_up') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="width: 30px;">{{item.wrap_up}}</label>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('clean_up') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="width: 30px;">{{item.clean_up}}</label>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('diagnosis') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{item.diagnosis}}</label>
                            </div>
                            <div class="gh-row-content">
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('department') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{item.department.name}}</label>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!planificated">
                                    <GhSearchWindow
                                        :dataBlock="{
                                            label: {
                                                style: 'margin-right: 10px; width: 150px; min-width: 150px; text-align: right;'
                                            },
                                            ResultSearch: 'search',
                                            nameStore: 'Feed',
                                            imagelocation: require('@/assets/gh_new_dessign/floating.svg'),
                                            id: 'main-' + item.department.id,
                                            text: $t('main') + '*',
                                            textlocation: $t('add') + ' ' + $t('main').toLowerCase() + ' ' + $t('to').toLowerCase() + ' ' + item.name,
                                            textuserselected: $t('selected_main'),
                                            actionresult: 'loadSearch',
                                            nameResult: 'data[intervention][procedure][' + keyItem + '][main]',
                                            supportPermision: false,
                                            required: true,
                                            type: 'radio',
                                            loader: require('@/assets/gh_new_dessign/ajax-loader_blue.gif')
                                        }"
                                        :mainSelector="{
                                            name: 'staff_search',
                                            formId: 'data',
                                            actionsearch: 'setSearch'
                                        }"
                                        :tableResolt="{
                                            header: headerMain,
                                            data: []
                                        }"
                                        :tableSelected="{
                                            headersummary: summary,
                                            selected: item.main
                                        }"
                                        :ref="'RefInterventionProcedure' + keyItem + 'Main'"
                                        @acept="checkSelector('RefInterventionProcedure', keyItem, 'Main')"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('main') + ':'}}</label>
                                    <div v-for="(i, k) in item.main" :key="'MAIN_' + keyItem + k">
                                        <label class="gh_text_field-label T15_b" :style="k !== item.main.length - 1 ? 'margin-right: 5px;' : ''">{{i.name + ' ' + i.surname + (k !== item.main.length - 1 ? ', ' : '')}}</label>
                                    </div>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="!planificated">
                                    <GhSearchWindow
                                        :dataBlock="{
                                            label: {
                                                style: 'margin-right: 10px; width: 150px; min-width: 150px; text-align: right;'
                                            },
                                            ResultSearch: 'search',
                                            nameStore: 'Feed',
                                            imagelocation: require('@/assets/gh_new_dessign/floating.svg'),
                                            id: 'assistant-' + item.department.id,
                                            text: $t('assistant') + '*',
                                            textlocation: $t('add') + ' ' + $t('assistant').toLowerCase() + ' ' + $t('to').toLowerCase() + ' ' + item.name,
                                            textuserselected: $t('selected_assistant'),
                                            actionresult: 'loadSearch',
                                            nameResult: 'data[intervention][procedure][' + keyItem + '][assistant]',
                                            supportPermision: false,
                                            required: true,
                                            loader: require('@/assets/gh_new_dessign/ajax-loader_blue.gif')
                                        }"
                                        :mainSelector="{
                                            name: 'staff_search',
                                            formId: 'data',
                                            actionsearch: 'setSearch'
                                        }"
                                        :tableResolt="{
                                            header: headerAssistant,
                                            data: []
                                        }"
                                        :tableSelected="{
                                            headersummary: summary,
                                            selected: item.assistant
                                        }"
                                        :ref="'RefInterventionProcedure' + keyItem + 'Assistant'"
                                        @acept="checkSelector('RefInterventionProcedure', keyItem, 'Assistant')"
                                    />
                                </template>
                                <template v-else>
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('assistant') + ':'}}</label>
                                    <div v-for="(i, k) in item.assistant" :key="'ASSISTANT_' + keyItem + k">
                                        <label class="gh_text_field-label T15_b" :style="k !== item.assistant.length - 1 ? 'margin-right: 5px;' : ''">{{i.name + ' ' + i.surname + (k !== item.assistant.length - 1 ? ', ' : '')}}</label>
                                    </div>
                                </template>
                            </div>
                            <div class="gh-row-content">
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('urgency') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{item.urgency}}</label>
                            </div>
                            <div class="gh-row-content">
                                <template v-if="Object.keys(intervention.location).length === 0">
                                    <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('equipment_needed') + ':'}}</label>
                                    <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{$t('to_add_equipment_needed_you_must_first_select_the_location')}}</label>
                                </template>
                                <template v-else>
                                    <template v-if="!planificated">
                                        <GhSearchWindow
                                            :dataBlock="{
                                                label: {
                                                    style: 'margin-right: 10px; width: 150px; min-width: 150px; text-align: right;'
                                                },
                                                ResultSearch: 'search',
                                                nameStore: 'Feed',
                                                imagelocation: require('@/assets/gh_new_dessign/floating.svg'),
                                                id: 'equipment-' + Object.keys(intervention.location)[0],
                                                text: $t('equipment_needed') + '*',
                                                textlocation: $t('add') + ' ' + $t('equipment').toLowerCase() + ' ' + $t('to').toLowerCase() + ' ' + item.name,
                                                textuserselected: $t('selected_equipment'),
                                                actionresult: 'loadSearch',
                                                nameResult: 'data[intervention][procedure][' + keyItem + '][equipment_needed]',
                                                supportPermision: false,
                                                required: true,
                                                loader: require('@/assets/gh_new_dessign/ajax-loader_blue.gif')
                                            }"
                                            :mainSelector="{
                                                name: 'equipment_search',
                                                formId: 'data',
                                                actionsearch: 'setSearch'
                                            }"
                                            :tableResolt="{
                                                header: headerEquipment,
                                                data: []
                                            }"
                                            :tableSelected="{
                                                headersummary: summaryEquipment,
                                                selected: item.equipment_needed
                                            }"
                                            :ref="'RefInterventionProcedure' + keyItem + 'EquipmentNeeded'"
                                            @acept="checkSelector('RefInterventionProcedure', keyItem, 'EquipmentNeeded')"
                                        />
                                    </template>
                                    <template v-else>
                                        <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('equipment_needed') + ':'}}</label>
                                        <div v-for="(i, k) in item.equipment_needed" :key="'EQUIPMENT_NEEDED_' + keyItem + k">
                                            <label class="gh_text_field-label T15_b" :style="k !== item.equipment_needed.length - 1 ? 'margin-right: 5px;' : ''">{{i.code + ' ' + i.name + (k !== item.equipment_needed.length - 1 ? ', ' : '')}}</label>
                                        </div>
                                    </template>
                                </template>
                            </div>
                            <div class="gh-row-content" :key="'PROCEDURE_TOTAL_TIME_' + keyItem + refreshTotalProcedureTime">
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('total_procedure_time') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{item.total_procedure_time}}</label>
                            </div>
                        </div>
                        <div class="section_line" style="margin-left: 160px; background-color: #e9f7ff; padding-top: 2px; padding-bottom: 2px; margin-bottom: 5px;">
                            <div class="T15_b" style="padding-left:13px;">{{''}}</div>
                        </div>
                    </template>
                    <div class="gh-row-content">
                        <template v-if="!planificated">
                            <GhDataSelector
                                :datablock="{
                                    id: 'data_select_special_conditions',
                                    class: 'T16_b',
                                    subtitle: $t('select_special_conditions') + ':',
                                    text: $t('special_conditions'),
                                    label: $t('special_conditions'),
                                    type: 'checkbox',
                                    columns: 3,
                                    style: '',
                                    required: false
                                }"
                                :datainput="{
                                    id: 'value_data_select_special_conditions',
                                    name: 'data[intervention][special_conditions][]'
                                }"
                                :selected_input="intervention.special_conditions"
                                :feed="getterFeed['constraint-type']"
                                ref="RefInterventionSpacialConditions"
                                @accept="checkSelector('RefInterventionSpacialConditions')"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('special_conditions') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{Object.values(intervention.special_conditions).join(', ')}}</label>
                        </template>
                    </div>
                    <div class="gh-row-content" :key="'REFRESH_MAIN_LEADER_' + refreshMainLeader">
                        <template v-if="Object.keys(main_leader).length === 0">
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('main_leader') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{$t('first_select_a_procedure_and_assign_a_procedure_leader')}}</label>
                        </template>
                        <template v-else-if="Object.keys(main_leader).length === 1">
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('main_leader') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{main_leader[Object.keys(main_leader)[0]]}}</label>
                            <input type="hidden" name="data[intervention][main_leader]" :value="Object.keys(main_leader)[0]"/>
                        </template>
                        <template v-else>
                            <template v-if="!planificated">
                                <GhDataSelector
                                    :datablock="{
                                        id: 'data_select_main_leader',
                                        class: 'T15_b',
                                        subtitle: $t('select_main_leader') + ':',
                                        text: $t('main_leader') + '*',
                                        label: $t('main_leader'),
                                        type: 'radio',
                                        columns: 3,
                                        style: '',
                                        required: true
                                    }"
                                    :datainput="{
                                        id: 'value_data_select_main_leader',
                                        name: 'data[intervention][main_leader]'
                                    }"
                                    :selected_input="intervention.main_leader"
                                    :feed="main_leader"
                                    ref="RefInterventionMainLeader"
                                    @accept="checkSelector('RefInterventionMainLeader')"
                                />
                            </template>
                            <template v-else>
                                <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('main_leader') + ':'}}</label>
                                <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{Object.values(intervention.main_leader).join(', ')}}</label>
                            </template>
                        </template>
                    </div>
                </div>
            </form>
        </template>
    </LoaderTemplate>
</template>

<script>
    import LoaderTemplate from "@/view/Shared/Loader/loader.vue";
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhCalendar from "fe-gh-input-calendar-lib";
    import GhDataSelector from "fe-gh-data-selector-lib";
    import GhEasyUpload from "fe-gh-easy-upload-lib";
    import GhTextarea from "fe-gh-textarea-lib";
    import GhSearchWindow from "fe-gh-float-window-lib";

    import {mapGetters, mapState} from "vuex";

    export default {
        name: 'form_patient_data',
        components: {
            LoaderTemplate,
            GhInputTextField,
            GhCalendar,
            GhDataSelector,
            GhEasyUpload,
            GhTextarea,
            GhSearchWindow
        },
        computed: {
            ...mapGetters(['getterGet', 'getterFeed']),
            ...mapState(['Upload'])
        },
        data(){
            return {
                is_adult: false,
                patient_data: {
                    id_number: '',
                    mrn: '',
                    name: '',
                    surname: '',
                    dob: '',
                    age: '',
                    gender: [],
                    image: {
                        file_path: '',
                        file_name: '',
                        file_download: '',
                        image: ''
                    }
                },
                responsible_person: {
                    name: '',
                    surname: '',
                    telephone: '',
                    email: '',
                    relation: []
                },
                intervention: {
                    intervention: '',
                    location: [],
                    procedures: [],
                    procedure: {},
                    special_conditions: [],
                    main_leader: []
                },
                headerMain: [
                    {text: '', field: 'check', checkall: true, typeCheck: 'radio', sorting: false, style: 'min-width: 16px; width: 16px;'},
                    {text: 'code', field: 'code', sorting: true, typeSort: 'code', style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 10%;'},
                    {text: 'surname', field: 'surname', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                    {text: 'schedule', field: 'schedule', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 30%;'},
                    {text: 'department_specialty', field: 'department_specialty', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                    {text: 'training', field: 'training', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                ],
                headerAssistant: [
                    {text: '', field: 'check', checkall: true, typeCheck: 'checkbox', sorting: false, style: 'min-width: 16px; width: 16px;'},
                    {text: 'code', field: 'code', sorting: true, typeSort: 'code', style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 10%;'},
                    {text: 'surname', field: 'surname', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                    {text: 'schedule', field: 'schedule', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 30%;'},
                    {text: 'department_specialty', field: 'department_specialty', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                    {text: 'training', field: 'training', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                ],
                headerEquipment: [
                    {text: '', field: 'check', checkall: true, typeCheck: 'checkbox', sorting: false, style: 'min-width: 16px; width: 16px;'},
                    {text: 'code', field: 'code', sorting: true, typeSort: 'code', style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 10%;'},
                    {text: 'description', field: 'description', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 30%;'},
                    {text: 'equipment_type', field: 'equipment_type', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                    {text: 'location', field: 'location', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                    {text: 'availability', field: 'availability', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                    {text: 'training', field: 'training', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                ],
                summary: {
                    0: 'name',
                    1: 'surname'
                },
                summaryEquipment: {
                    0: 'code',
                    1: 'name'
                },
                main_leader: {},
                planificated: false,
                refreshProcedure: 0,
                refreshTotalProcedureTime: 0,
                refreshMainLeader: 0,
                allowSave: false,
                allowFinish: false,
                haveData: false
            }
        },
        async beforeDestroy(){
            await this.$store.dispatch('destroyFeed', {
                from: 'gender'
            }, {root: true});

            await this.$store.dispatch('destroyFeed', {
                from: 'relation'
            }, {root: true});

            await this.$store.dispatch('destroyFeed', {
                from: 'location'
            }, {root: true});

            await this.$store.dispatch('destroyFeed', {
                from: 'procedure'
            }, {root: true});

            await this.$store.dispatch('destroyFeed', {
                from: 'constraint-type'
            }, {root: true});
        },
        async beforeMount(){
            await this.$store.dispatch('getFeed', {
                from: 'gender'
            }, {root: true});

            await this.$store.dispatch('getFeed', {
                from: 'relation'
            }, {root: true});

            await this.$store.dispatch('getFeed', {
                from: 'location'
            }, {root: true});

            await this.$store.dispatch('getFeed', {
                from: 'procedure'
            }, {root: true});

            await this.$store.dispatch('getFeed', {
                from: 'constraint-type'
            }, {root: true});

            if(this.$root._route.params.id !== undefined){
                await this.$store.dispatch('getByType', {
                    from: 'patients',
                    type: 'patients',
                    extra: 'patient-data',
                    id: this.$root._route.params.id
                }, {root: true});

                this.is_adult = this.getterGet.patients.patients['patient-data'].patient_data.is_adult;
                this.patient_data.id_number = this.getterGet.patients.patients['patient-data'].patient_data.id_number;
                this.patient_data.mrn = this.getterGet.patients.patients['patient-data'].patient_data.mrn;
                this.patient_data.name = this.getterGet.patients.patients['patient-data'].patient_data.name;
                this.patient_data.surname = this.getterGet.patients.patients['patient-data'].patient_data.surname;
                console.log(this.getterGet.patients.patients['patient-data'].patient_data.dob);
                this.patient_data.dob = this.getterGet.patients.patients['patient-data'].patient_data.dob;
                this.patient_data.gender = this.getterGet.patients.patients['patient-data'].patient_data.gender;
                this.patient_data.image.image = this.getterGet.patients.patients['patient-data'].patient_data.image.image;
                this.patient_data.image.file_path = this.getterGet.patients.patients['patient-data'].patient_data.image.file_path;
                this.patient_data.image.file_name = this.getterGet.patients.patients['patient-data'].patient_data.image.file_name;
                this.patient_data.image.file_download = this.getterGet.patients.patients['patient-data'].patient_data.image.file_download;
                this.calcBirthday('', this.patient_data.dob);

                this.responsible_person.name = this.getterGet.patients.patients['patient-data'].responsible_person.name;
                this.responsible_person.surname = this.getterGet.patients.patients['patient-data'].responsible_person.surname;
                this.responsible_person.telephone = this.getterGet.patients.patients['patient-data'].responsible_person.telephone;
                this.responsible_person.email = this.getterGet.patients.patients['patient-data'].responsible_person.email;
                this.responsible_person.relation = this.getterGet.patients.patients['patient-data'].responsible_person.relation;

                this.intervention.intervention = this.getterGet.patients.patients['patient-data'].intervention.intervention;
                this.intervention.location = this.getterGet.patients.patients['patient-data'].intervention.location;
                this.intervention.procedures = this.getterGet.patients.patients['patient-data'].intervention.procedures;

                if(Object.keys(this.getterGet.patients.patients['patient-data'].intervention.procedure).length !== 0) {
                    Object.keys(this.getterGet.patients.patients['patient-data'].intervention.procedure).forEach((item) => {
                        this.intervention.procedure[item] = {
                            'name':                 this.getterGet.patients.patients['patient-data'].intervention.procedure[item].name,
                            'setup_length':         this.getterGet.patients.patients['patient-data'].intervention.procedure[item].setup_length,
                            'patient_prep':         this.getterGet.patients.patients['patient-data'].intervention.procedure[item].patient_prep,
                            'procedure':            this.getterGet.patients.patients['patient-data'].intervention.procedure[item].procedure,
                            'wrap_up':              this.getterGet.patients.patients['patient-data'].intervention.procedure[item].wrap_up,
                            'clean_up':             this.getterGet.patients.patients['patient-data'].intervention.procedure[item].clean_up,
                            'diagnosis':            this.getterGet.patients.patients['patient-data'].intervention.procedure[item].diagnosis,
                            'department':           this.getterGet.patients.patients['patient-data'].intervention.procedure[item].department,
                            'main':                 this.getterGet.patients.patients['patient-data'].intervention.procedure[item].main,
                            'assistant':            this.getterGet.patients.patients['patient-data'].intervention.procedure[item].assistant,
                            'urgency':              this.getterGet.patients.patients['patient-data'].intervention.procedure[item].urgency,
                            'equipment_needed':     this.getterGet.patients.patients['patient-data'].intervention.procedure[item].equipment_needed,
                            'total_procedure_time': this.getterGet.patients.patients['patient-data'].intervention.procedure[item].total_procedure_time
                        };

                        if(this.main_leader[this.intervention.procedure[item].main[0].id] === undefined){
                            this.main_leader[this.intervention.procedure[item].main[0].id] = this.intervention.procedure[item].main[0].name + ' ' + this.intervention.procedure[item].main[0].surname
                            this.refreshMainLeader++;
                        }
                    });
                }

                this.intervention.special_conditions = this.getterGet.patients.patients['patient-data'].intervention.special_conditions;
                this.intervention.main_leader = this.getterGet.patients.patients['patient-data'].intervention.main_leader;

                this.planificated = this.$parent.$parent.$parent.planificated;
                if(!this.planificated) {
                    this.allowFinish = this.getterGet.patients.patients['patient-data'].buttons.finish;
                    this.$parent.$parent.$parent.allowFinish = this.allowFinish;
                    this.checkAllowSave();
                }else{
                    this.allowSave = false;
                    this.allowFinish = false;
                    this.$parent.$parent.$parent.allowSave = this.allowSave;
                    this.$parent.$parent.$parent.allowFinish = this.allowFinish;
                }
            }

            this.$nextTick(() => {
                this.haveData = true;
                this.$parent.$parent.$parent.haveData = this.haveData;
            });
        },
        watch:{
            'Upload.data'(data){
                this.persistance();
                this.patient_data.image.image = data.url;
            }
        },
        methods: {
            async checkSelector(ref, id, extra_ref){
                this.persistance();

                switch (extra_ref === undefined ? ref : extra_ref){
                    case 'RefPatientDataName':
                        if(!this.is_adult) {
                            this.responsible_person.name = this.$refs[ref].datainput.value;
                        }
                        break;
                    case 'RefPatientDataSurname':
                        if(!this.is_adult) {
                            this.responsible_person.surname = this.$refs[ref].datainput.value;
                        }
                        break;
                    case 'RefPatientDataDOB':
                        this.calcBirthday(ref);
                        break;
                    case 'RefInterventionLocation':
                        if(Object.keys(this.$refs[ref].checkdata).length !== 0){
                            this.intervention.location = this.$refs[ref].checkdata
                        }else{
                            this.intervention.location = [];
                        }

                        Object.keys(this.intervention.procedure).forEach((id) => {
                            this.intervention.procedure[id].equipment_needed = [];
                        });

                        this.$nextTick(() => {
                            this.refreshProcedure++;
                        });

                        break;
                    case 'RefInterventionProcedures':
                        if(Object.keys(this.$refs[ref].checkdata).length !== 0){
                            let formData = new FormData();
                            Object.keys(this.$refs[ref].checkdata).forEach((item) => {
                                formData.append('data[procedure][]', item);
                            });

                            var procedures = this.intervention.procedure;

                            await this.$store.dispatch('setByType', {
                                from: 'procedure',
                                type: 'procedure',
                                extra_url: 'data-intervention',
                                formData: [...formData]
                            }, {root: true}).then((response) => {
                                if(response.status === 200){
                                    this.intervention.procedure = {};
                                    if(Object.keys(response.data.data).length !== 0){
                                        Object.keys(response.data.data).forEach((item) => {
                                            this.intervention.procedure[item] = {
                                                'name':                 response.data.data[item] !== undefined && response.data.data[item].name !== undefined ? response.data.data[item].name : '',
                                                'setup_length':         procedures[item] !== undefined && procedures[item].setup_length !== undefined ? procedures[item].setup_length : null,
                                                'patient_prep':         procedures[item] !== undefined && procedures[item].patient_prep !== undefined ? procedures[item].patient_prep : null,
                                                'procedure':            procedures[item] !== undefined && procedures[item].procedure !== undefined ? procedures[item].procedure : null,
                                                'wrap_up':              procedures[item] !== undefined && procedures[item].wrap_up !== undefined ? procedures[item].wrap_up : null,
                                                'clean_up':             procedures[item] !== undefined && procedures[item].clean_up !== undefined ? procedures[item].clean_up : null,
                                                'diagnosis':            response.data.data[item] !== undefined && response.data.data[item].diagnosis !== undefined ? response.data.data[item].diagnosis : '',
                                                'department':           response.data.data[item] !== undefined && response.data.data[item].department !== undefined ? response.data.data[item].department : '',
                                                'main':                 procedures[item] !== undefined && procedures[item].main !== undefined ? procedures[item].main : [],
                                                'assistant':            procedures[item] !== undefined && procedures[item].assistant !== undefined ? procedures[item].assistant : [],
                                                'urgency':              response.data.data[item] !== undefined && response.data.data[item].urgency !== undefined ? response.data.data[item].urgency : null,
                                                'equipment_needed':     procedures[item] !== undefined && procedures[item].equipment_needed !== undefined ? procedures[item].equipment_needed : [],
                                                'total_procedure_time': procedures[item] !== undefined && procedures[item].total_procedure_time !== undefined ? procedures[item].total_procedure_time : null
                                            };

                                            this.totalProcedureTime(item);
                                        });
                                    }else{
                                        this.intervention.procedure = [];
                                    }

                                    this.$nextTick(() => {
                                        this.refreshProcedure++;
                                    });
                                }
                            });
                        }else{
                            this.intervention.procedure = [];
                        }
                        break;
                    case 'SetupLength':
                        this.intervention.procedure[id].setup_length = this.$refs[ref + id + extra_ref][0].datainput.value;
                        this.totalProcedureTime(id);
                        break;
                    case 'PatientPrep':
                        this.intervention.procedure[id].patient_prep = this.$refs[ref + id + extra_ref][0].datainput.value;
                        this.totalProcedureTime(id);
                        break;
                    case 'Procedure':
                        this.intervention.procedure[id].procedure = this.$refs[ref + id + extra_ref][0].datainput.value;
                        this.totalProcedureTime(id);
                        break;
                    case 'WrapUp':
                        this.intervention.procedure[id].wrap_up = this.$refs[ref + id + extra_ref][0].datainput.value;
                        this.totalProcedureTime(id);
                        break;
                    case 'CleanUp':
                        this.intervention.procedure[id].clean_up = this.$refs[ref + id + extra_ref][0].datainput.value;
                        this.totalProcedureTime(id);
                        break;
                    case 'Main':
                        this.intervention.procedure[id].main = this.$refs[ref + id + extra_ref][0].selectedData;
                        if(this.intervention.procedure[id].main[0] !== undefined){
                            if(this.main_leader[this.intervention.procedure[id].main[0].id] === undefined){
                                this.main_leader[this.intervention.procedure[id].main[0].id] = this.intervention.procedure[id].main[0].name + ' ' + this.intervention.procedure[id].main[0].surname
                                this.refreshMainLeader++;
                            }
                        }
                        break;
                    case 'Assistant':
                        this.intervention.procedure[id].assistant = this.$refs[ref + id + extra_ref][0].selectedData;
                        break;
                    case 'EquipmentNeeded':
                        this.intervention.procedure[id].equipment_needed = this.$refs[ref + id + extra_ref][0].selectedData;
                        break;
                }
            },
            persistance(){
                this.patient_data.id_number = this.$refs['RefPatientDataIdNumber'].datainput.value;
                this.patient_data.name = this.$refs['RefPatientDataName'].datainput.value;
                this.patient_data.surname = this.$refs['RefPatientDataSurname'].datainput.value;
                this.patient_data.dob = this.$refs['RefPatientDataDOB'].value1;
                this.responsible_person.name = this.$refs['RefResponsiblePersonName'].datainput.value;
                this.responsible_person.surname = this.$refs['RefResponsiblePersonSurname'].datainput.value;
                this.responsible_person.telephone = this.$refs['RefResponsiblePersonTelephone'].datainput.value;
                this.responsible_person.email = this.$refs['RefResponsiblePersonEmail'].datainput.value;
                this.checkAllowSave();
            },
            calcBirthday(ref, date){
                let DOB = new Date();
                let DT = new Date();

                let calc = true;

                if(date !== undefined){
                    DOB = new Date(date);
                }else {
                    if(this.$refs[ref].value1 === null){
                        calc = false;
                    }else {
                        DOB = new Date(this.$refs[ref] !== undefined ? this.$refs[ref].value1 : '');
                    }
                }

                if(calc) {
                    var ynew = DT.getFullYear();
                    var mnew = DT.getMonth();
                    var dnew = DT.getDate();
                    var yold = DOB.getFullYear();
                    var mold = DOB.getMonth();
                    var dold = DOB.getDate();
                    var diff = ynew - yold;

                    if (mold > mnew) {
                        diff--;
                    } else {
                        if (mold === mnew) {
                            if (dold > dnew) {
                                diff--;
                            }
                        }
                    }
                }else{
                    diff = '';
                }

                this.patient_data.age = diff;
                this.is_adult = this.patient_data.age <= 17;
            },
            totalProcedureTime(id){
                this.intervention.procedure[id].total_procedure_time =
                    parseInt(this.intervention.procedure[id].setup_length !== null && this.intervention.procedure[id].setup_length !== '' ? this.intervention.procedure[id].setup_length : 0) +
                    parseInt(this.intervention.procedure[id].patient_prep !== null && this.intervention.procedure[id].patient_prep !== '' ? this.intervention.procedure[id].patient_prep : 0) +
                    parseInt(this.intervention.procedure[id].procedure !== null && this.intervention.procedure[id].procedure !== '' ? this.intervention.procedure[id].procedure : 0) +
                    parseInt(this.intervention.procedure[id].wrap_up !== null && this.intervention.procedure[id].wrap_up !== '' ? this.intervention.procedure[id].wrap_up : 0) +
                    parseInt(this.intervention.procedure[id].clean_up !== null && this.intervention.procedure[id].clean_up !== '' ? this.intervention.procedure[id].clean_up : 0);

                this.refreshTotalProcedureTime++;
            },
            checkAllowSave(){
                this.allowSave = this.patient_data.id_number !== '' && this.patient_data.name !== '' && this.patient_data.surname !== '';
                this.$parent.$parent.$parent.allowSave = this.allowSave;
            }
        }
    }
</script>

<style>
    #data_select_patient_data_gender_selector_label, #data_select_responsible_person_relation_selector_label, #data_select_intervention_location_selector_label, #data_select_intervention_procedures_selector_label, #data_select_special_conditions_selector_label, #data_select_main_leader_selector_label{
        width: 150px;
        text-align: right;
    }

    .other-image > .easy_upload_block{
        flex-direction: initial;
    }

    .other-image > div > #data_patient_data_image_easy_download_block{
        display: none !important;
    }
</style>