<template>
    <GhPopUp v-show="false"
        :generalConfiguration="{
            id: 'popup_general',
            title: title !== undefined ? $t(title) : $t('warning'),
            type: type,
            style: stylePopup !== '' && stylePopup !== undefined ? stylePopup : 'width: 650px; min-width: 650px; max-width: 650px;'
        }"
        :bodyConfiguration="{
            id: '',
            class: '',
            style: 'padding:2px;',
            text: subTitle !== undefined ? $t(subTitle) : ''
        }"
        :footerConfiguration="{
            id: '',
            class: '',
            style: '',
            content: ''
        }"
    >
        <template v-slot:body>
            <div class="gh-content-global" style="padding: unset">
                <div class="gh_delete_confirm-content" style="overflow: auto;max-height: 800px; width: 100%; padding-bottom: 15px;">
                    <div class="T15_b" style="margin-left: 20px;">
                        {{$t(bodyText)}}
                    </div>
                </div>
            </div>
            <slot name="content"/>
        </template>
        <template v-slot:footer>
            <template v-if="showButton">
                <GhButton
                    :datainput="{
                        id: 'button_accept_popup_warning_content_text',
                        text: $t('accept'),
                        class: 'T19 container-md_button_content',
                        style: ' display: flex;'
                    }"
                    @click="$emit('accept');"
                />
            </template>
            <GhButton
                :datainput="{
                    id: 'button_close_popup_warning_content_text',
                    text: $t('close'),
                    class: 'T19 container-md_button_content',
                    style: ' display: flex;'
                }"
                @click="Global.closePopUp('popup_general');"
            />
        </template>
    </GhPopUp>
</template>

<script>
    import GhPopUp from "fe-gh-popup-lib";
    import GhButton from "fe-gh-button-lib";

    export default {
        name: 'warning_information',
        props: ['title', 'type', 'stylePopup', 'subTitle', 'bodyText', 'showButton'],
        components: {
            GhPopUp,
            GhButton
        }
    }
</script>