import Users from '@/view/Permissions/Users/Users/Template/section.vue';
import UsersSearch from "@/view/Shared/Window/Search/Users/main.vue";

const route = [
    {
        path: '/permissions/users/users',
        name: 'permissions_users_users',
        props: {showMenu: true, showUser: true},
        component: Users,
        children: [
            {
                path: '/permissions/users/users/:id',
                name: 'permissions_users_users_modify',
                component: Users,
                props: {id: null}
            },
            {
                path: '/permissions/users/users/:id/:view',
                name: 'permissions_users_users_view',
                component: Users,
                props: {id: null, view: true}
            }
        ]
    },
    {
        path: '/users/content/search',
        name: 'users_search',
        component: UsersSearch,
        props: {showMenu: false}
    }
];

const routes = [
    ...route,
];

export default routes;