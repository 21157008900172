<template>
    <LoaderTemplate :haveData="haveData">
        <template v-slot:content>
            <form id="form_data">
                <div class="gh-content-global">
                    <CodeNameDescription
                        :code="{
                            show: true,
                            required: true,
                            value: getterGet.department !== undefined && getterGet.department['department'] !== undefined ? getterGet.department['department'].code : ''
                        }"
                        :name="{
                            show: true,
                            required: true,
                            value: getterGet.department !== undefined && getterGet.department['department'] !== undefined ? getterGet.department['department'].name : ''
                        }"
                        :description="{
                            show: false,
                            required: false,
                            value: ''
                        }"
                    />
                    <div class="gh-row-content">
                        <template v-if="!$root._route.params.view">
                            <GhDataSelector
                                :datablock="{
                                    id: 'data_select_group_department',
                                    class: 'T15_b',
                                    subtitle: $t('select_group_department') + ':',
                                    text: $t('group_department') + '*',
                                    label: $t('group_department'),
                                    type: 'checkbox',
                                    columns: 3,
                                    style: '',
                                    required: true
                                }"
                                :datainput="{
                                    id: 'value_data_select_group_department',
                                    name: 'data[group_department][]'
                                }"
                                :selected_input="getterGet.department !== undefined && getterGet.department['department'] !== undefined ? getterGet.department['department'].group_department : []"
                                :feed="getterFeed['group-department']"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('group_department') + ':'}}</label>
                            <label class="gh_text_field-label T15_b">{{getterGet.department !== undefined && getterGet.department['department'] !== undefined ? Object.values(getterGet.department['department'].group_department).join(', ') : ''}}</label>
                        </template>
                    </div>
                </div>
                <div class="section_line" style="margin-top: -5px;">
                    <div class="T15_b" style="padding-left:13px;">{{$t('point_of_contact')}}</div>
                </div>
                <div class="gh-content-global">
                    <div class="gh-row-content">
                        <template v-if="!$root._route.params.view">
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('name') + '*',
                                    style: 'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'data_point_of_contact_name',
                                    name: 'data[point_of_contact][name]',
                                    style: 'width: 170px;',
                                    value: getterGet.department !== undefined && getterGet.department['department'] !== undefined && getterGet.department['department']['point_of_contact'] !== undefined ? getterGet.department['department']['point_of_contact'].name : '',
                                    type: 'text'
                                }"
                            />
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('surname') + '*',
                                    style: 'width: 100px; text-align: right;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'data_point_of_contact_surname',
                                    name: 'data[point_of_contact][surname]',
                                    style: 'width: 170px;',
                                    value: getterGet.department !== undefined && getterGet.department['department'] !== undefined && getterGet.department['department']['point_of_contact'] !== undefined ? getterGet.department['department']['point_of_contact'].surname : '',
                                    type: 'text'
                                }"
                            />
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('email') + '*',
                                    style: 'width: 100px; text-align: right;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'data_point_of_contact_email',
                                    name: 'data[point_of_contact][email]',
                                    style: 'width: 250px;',
                                    value: getterGet.department !== undefined && getterGet.department['department'] !== undefined && getterGet.department['department']['point_of_contact'] !== undefined ? getterGet.department['department']['point_of_contact'].email : '',
                                    type: 'text'
                                }"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="width: 170px;">{{getterGet.department !== undefined && getterGet.department['department'] !== undefined && getterGet.department['department']['point_of_contact'] !== undefined ? getterGet.department['department']['point_of_contact'].name : ''}}</label>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 100px; min-width: 150px; text-align: right;">{{$t('surname') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="width: 170px;">{{getterGet.department !== undefined && getterGet.department['department'] !== undefined && getterGet.department['department']['point_of_contact'] !== undefined ? getterGet.department['department']['point_of_contact'].surname : ''}}</label>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 100px; min-width: 150px; text-align: right;">{{$t('email') + ':'}}</label>
                            <label class="gh_text_field-label T15_b">{{getterGet.department !== undefined && getterGet.department['department'] !== undefined && getterGet.department['department']['point_of_contact'] !== undefined ? getterGet.department['department']['point_of_contact'].email : ''}}</label>
                        </template>
                    </div>
                </div>
                <div class="section_line" style="margin-top: -5px;">
                    <div class="T15_b" style="padding-left:13px;">{{$t('leader')}}</div>
                </div>
                <div class="gh-content-global">
                    <div class="gh-row-content">
                        <template v-if="!$root._route.params.view">
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('name'),
                                    style: 'width: 150px; text-align: right;',
                                    class: 'T15'
                                }"
                                :datainput="{
                                    required: false,
                                    id: 'data_leader_name',
                                    name: 'data[leader][name]',
                                    style: 'width: 170px;',
                                    value: getterGet.department !== undefined && getterGet.department['department'] !== undefined && getterGet.department['department']['leader'] !== undefined ? getterGet.department['department']['leader'].name : '',
                                    type: 'text'
                                }"
                            />
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('surname'),
                                    style: 'width: 100px; text-align: right;',
                                    class: 'T15'
                                }"
                                :datainput="{
                                    required: false,
                                    id: 'data_leader_surname',
                                    name: 'data[leader][surname]',
                                    style: 'width: 170px;',
                                    value: getterGet.department !== undefined && getterGet.department['department'] !== undefined && getterGet.department['department']['leader'] !== undefined ? getterGet.department['department']['leader'].surname : '',
                                    type: 'text'
                                }"
                            />
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('email'),
                                    style: 'width: 100px; text-align: right;',
                                    class: 'T15'
                                }"
                                :datainput="{
                                    required: false,
                                    id: 'data_leader_email',
                                    name: 'data[leader][email]',
                                    style: 'width: 250px;',
                                    value: getterGet.department !== undefined && getterGet.department['department'] !== undefined && getterGet.department['department']['leader'] !== undefined ? getterGet.department['department']['leader'].email : '',
                                    type: 'text'
                                }"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('name') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="width: 170px;">{{getterGet.department !== undefined && getterGet.department['department'] !== undefined && getterGet.department['department']['leader'] !== undefined ? getterGet.department['department']['leader'].name : ''}}</label>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 100px; min-width: 150px; text-align: right;">{{$t('surname') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="width: 170px;">{{getterGet.department !== undefined && getterGet.department['department'] !== undefined && getterGet.department['department']['leader'] !== undefined ? getterGet.department['department']['leader'].surname : ''}}</label>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 100px; min-width: 150px; text-align: right;">{{$t('email') + ':'}}</label>
                            <label class="gh_text_field-label T15_b">{{getterGet.department !== undefined && getterGet.department['department'] !== undefined && getterGet.department['department']['leader'] !== undefined ? getterGet.department['department']['leader'].email : ''}}</label>
                        </template>
                    </div>
                </div>
            </form>
        </template>
    </LoaderTemplate>
</template>

<script>
    import LoaderTemplate from '@/view/Shared/Loader/loader.vue';
    import CodeNameDescription from '@/view/Shared/Form/code_name_description.vue';
    import GhDataSelector from "fe-gh-data-selector-lib";

    import {mapGetters} from "vuex";
    import GhInputTextField from "fe-gh-inputtextfield-lib";

    export default {
        name: 'form_department',
        components: {
            GhInputTextField,
            LoaderTemplate,
            CodeNameDescription,
            GhDataSelector
        },
        computed: {
            ...mapGetters(['getterGet', 'getterFeed'])
        },
        data(){
            return {
                haveData: false
            }
        },
        async beforeDestroy(){
            if(!this.$root._route.params.view) {
                await this.$store.dispatch('destroyFeed', {
                    from: 'group-department'
                }, {root: true});
            }

            await this.$store.dispatch('destroyGetByType', {
                from: 'department'
            }, {root: true});
        },
        async beforeMount(){
            if(!this.$root._route.params.view){
                await this.$store.dispatch('getFeed', {
                    from: 'group-department'
                }, {root: true});
            }

            if(this.$root._route.params.id !== undefined) {
                await this.$store.dispatch('getByType', {
                    from: 'department',
                    type: 'department',
                    id: this.$root._route.params.id
                }, {root: true});
            }

            this.$nextTick(() => {
                this.haveData = true;
                this.$parent.$parent.$parent.haveData = this.haveData;
            });
        }
    }
</script>

<style>
    #data_select_group_department_selector_label{
        width: 150px;
        text-align: right;
    }
</style>