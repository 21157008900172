var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LoaderTemplate',{attrs:{"haveData":_vm.haveData},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('form',{attrs:{"id":"form_data"}},[_c('div',{staticClass:"gh-content-global"},[_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('group_assignment') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"margin-left":"10px"}},[_vm._v(_vm._s(_vm.getterGet.support !== undefined && _vm.getterGet.support['schedule'] !== undefined ? _vm.getterGet.support['schedule'].group_assignment : ''))])]),_c('div',{staticClass:"gh-row-content",staticStyle:{"margin-left":"160px","padding-bottom":"unset"}},[_c('div',_vm._l((_vm.days),function(day,keyDay){return _c('div',{key:'DAYS_' + keyDay,staticClass:"centered",staticStyle:{"width":"120px"},style:(keyDay % 2 === 0 ? 'background-color: #e9f7ff;' : '')},[_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(_vm.$t(day)))])])}),0)]),_c('div',{staticClass:"gh-row-content",staticStyle:{"padding-bottom":"unset"}},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"width":"150px","text-align":"right","margin-right":"10px","padding-top":"4px","padding-bottom":"4px"}},[_vm._v(_vm._s(_vm.$t('start_time')))]),_c('div',_vm._l((_vm.days),function(day,keyDay){return _c('div',{key:'START_TIME_' + keyDay,staticClass:"centered",staticStyle:{"width":"120px","padding-top":"4px","padding-bottom":"4px"},style:(keyDay % 2 === 0 ? 'background-color: #e9f7ff;' : '')},[(!_vm.$root._route.params.view)?[_c('GhInputHour',{attrs:{"datalabel":{
                                        style: '',
                                        text: ''
                                    },"datainput":{
                                        actualHour: false,
                                        id: 'date_start_time_' + day,
                                        name: 'data[start_time][' + day + ']',
                                        required: true,
                                        hour: _vm.getterGet.support !== undefined && _vm.getterGet.support['schedule'] !== undefined && _vm.getterGet.support['schedule'].start_time !== undefined && _vm.getterGet.support['schedule'].start_time[day] !== undefined ? _vm.getterGet.support['schedule'].start_time[day].hour : '',
                                        minute: _vm.getterGet.support !== undefined && _vm.getterGet.support['schedule'] !== undefined && _vm.getterGet.support['schedule'].start_time !== undefined && _vm.getterGet.support['schedule'].start_time[day] !== undefined ? _vm.getterGet.support['schedule'].start_time[day].minute : '',
                                        style: 'max-width: 40px;'
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(_vm.getterGet.support !== undefined && _vm.getterGet.support['schedule'] !== undefined && _vm.getterGet.support['schedule'].start_time !== undefined && _vm.getterGet.support['schedule'].start_time[day] !== undefined ? _vm.getterGet.support['schedule'].start_time[day].hour + ':' + _vm.getterGet.support['schedule'].start_time[day].minute : ''))])]],2)}),0)]),_c('div',{staticClass:"gh-row-content"},[_c('label',{staticClass:"gh_text_field-label T15_b",staticStyle:{"width":"150px","text-align":"right","margin-right":"10px","padding-top":"4px","padding-bottom":"4px"}},[_vm._v(_vm._s(_vm.$t('end_time')))]),_c('div',_vm._l((_vm.days),function(day,keyDay){return _c('div',{key:'END_TIME_' + keyDay,staticClass:"centered",staticStyle:{"width":"120px","padding-top":"4px","padding-bottom":"4px"},style:(keyDay % 2 === 0 ? 'background-color: #e9f7ff;' : '')},[(!_vm.$root._route.params.view)?[_c('GhInputHour',{attrs:{"datalabel":{
                                        style: '',
                                        text: ''
                                    },"datainput":{
                                        actualHour: false,
                                        id: 'date_end_time_' + day,
                                        name: 'data[end_time][' + day + ']',
                                        required: true,
                                        hour: _vm.getterGet.support !== undefined && _vm.getterGet.support['schedule'] !== undefined && _vm.getterGet.support['schedule'].end_time !== undefined && _vm.getterGet.support['schedule'].end_time[day] !== undefined ? _vm.getterGet.support['schedule'].end_time[day].hour : '',
                                        minute: _vm.getterGet.support !== undefined && _vm.getterGet.support['schedule'] !== undefined && _vm.getterGet.support['schedule'].end_time !== undefined && _vm.getterGet.support['schedule'].end_time[day] !== undefined ? _vm.getterGet.support['schedule'].end_time[day].minute : '',
                                        style: 'max-width: 40px;'
                                    }}})]:[_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(_vm.getterGet.support !== undefined && _vm.getterGet.support['schedule'] !== undefined && _vm.getterGet.support['schedule'].end_time !== undefined && _vm.getterGet.support['schedule'].end_time[day] !== undefined ? _vm.getterGet.support['schedule'].end_time[day].hour + ':' + _vm.getterGet.support['schedule'].end_time[day].minute : ''))])]],2)}),0)])])])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }