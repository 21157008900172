var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LoaderTemplate',{attrs:{"haveData":_vm.haveData},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('form',{attrs:{"id":"form_data"}},[_c('div',{staticClass:"gh-content-global"},[_c('CodeNameDescription',{attrs:{"code":{
                        show: false,
                        required: false,
                        value: ''
                    },"name":{
                        show: true,
                        required: true,
                        value: _vm.getterGet.support !== undefined && _vm.getterGet.support['categories'] !== undefined ? _vm.getterGet.support['categories'].name : ''
                    },"description":{
                        show: false,
                        required: false,
                        value: ''
                    }}}),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                                id: 'data_select_flow',
                                class: 'T15_b',
                                subtitle: _vm.$t('select_flow') + ':',
                                text: _vm.$t('flow'),
                                label: _vm.$t('flow'),
                                type: 'radio',
                                columns: 3,
                                style: '',
                                required: false
                            },"datainput":{
                                id: 'value_data_select_flow',
                                name: 'data[flow]'
                            },"selected_input":_vm.getterGet.support !== undefined && _vm.getterGet.support['categories'] !== undefined ? _vm.getterGet.support['categories'].flow : [],"feed":_vm.getterFeed['flows']}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('flow') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(_vm.getterGet.support !== undefined && _vm.getterGet.support['categories'] !== undefined ? Object.values(_vm.getterGet.support['categories'].flow).join(', ') : ''))])]],2),_c('div',{staticClass:"gh-row-content"},[(!_vm.$root._route.params.view)?[_c('GhDataSelector',{attrs:{"datablock":{
                                id: 'data_select_priority',
                                class: 'T15_b',
                                subtitle: _vm.$t('select_priority') + ':',
                                text: _vm.$t('priority'),
                                label: _vm.$t('priority'),
                                type: 'radio',
                                columns: 3,
                                style: '',
                                required: false
                            },"datainput":{
                                id: 'value_data_select_priority',
                                name: 'data[priority]'
                            },"selected_input":_vm.getterGet.support !== undefined && _vm.getterGet.support['categories'] !== undefined ? _vm.getterGet.support['categories'].priority : [],"feed":_vm.getterFeed['priorities']}})]:[_c('label',{staticClass:"gh_text_field-label T15",staticStyle:{"margin-right":"10px","width":"150px","min-width":"150px","text-align":"right"}},[_vm._v(_vm._s(_vm.$t('priority') + ':'))]),_c('label',{staticClass:"gh_text_field-label T15_b"},[_vm._v(_vm._s(_vm.getterGet.support !== undefined && _vm.getterGet.support['categories'] !== undefined ? Object.values(_vm.getterGet.support['categories'].priority).join(', ') : ''))])]],2)],1)])]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }