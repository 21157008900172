<template>
    <LoaderTemplate :haveData="haveData">
        <template v-slot:content>
            <GhTableCommon
                style="margin-top: 10px;"
                :extratable="{
                    id: 'patients'
                }"
                :header="header"
                :data="getterGet.patients !== undefined && getterGet.patients['patients'] !== undefined && getterGet.patients['patients'].action !== undefined ? getterGet.patients['patients'].action.data : []"
            />
        </template>
    </LoaderTemplate>
</template>

<script>
    import LoaderTemplate from '@/view/Shared/Loader/loader.vue';
    import GhTableCommon from 'fe-gh-table-common-lib';

    import {mapGetters} from "vuex";

    export default {
        name: 'list_action',
        components: {
            LoaderTemplate,
            GhTableCommon
        },
        computed: {
            ...mapGetters(['getterGet'])
        },
        data(){
            return {
                header: [
                    {text: 'action', field: 'action', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 15%;'},
                    {text: 'date_hour', field: 'date_hour', sorting: true, typeSort: 'string', style: 'min-width: 135px; max-width: 135px; width: 135px;'},
                    {text: 'call_result', field: 'call_result', sorting: true, typeSort: 'string', style: 'min-width: 150px; max-width: 150px; width: 15%;'},
                    {text: 'comment', field: 'comment', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 50%;'},
                    {text: 'document', field: 'document', sorting: true, typeSort: 'string', urlDocument:'download_document', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                ],
                haveData: false
            }
        },
        async beforeMount(){
            await this.$store.dispatch('getByType', {
                from: 'patients',
                type: 'patients',
                extra: 'action',
                id: this.$root._route.params.id
            }, {root: true});

            this.$nextTick(() => {
                this.haveData = true;
            });
        }
    }
</script>