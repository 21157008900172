import Vue from "vue";
import vuex from "vuex";
import axios from "axios";

import {API_URL} from "@/common/config";
import Global from "@/store/Global";

Vue.use(vuex);

export default{
    state: {
        permission: [],
        flagPermission: false
    },
    mutations: {
        loadPermission(state, payload) {
            state.flagPermission = !state.flagPermission;
            state.permission = payload;
        }
    },
    getters: {
        getterPermission(state){
            const dummy = state.flagPermission; // eslint-disable-line no-unused-vars
            return state.permission;
        }
    },
    actions: {
        async getPermissionByType(state, item){
            await axios.get(
                API_URL + item.type + "/permission"
            ).then(
                response => {
                    if(response.status === 200) {
                        state.commit('loadPermission', response.data.data);
                    }
                },
                error => {
                    if(error.status !== ''){
                        Global.loadError('error_popup', error.response.data.data);
                    }
                }
            )
        }
    }
};