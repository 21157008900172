import Gender from '@/view/Configuration/General/Gender/Template/section.vue';

const route = [
    {
        path: '/configuration/general/gender',
        name: 'config_general_gender',
        props: {showMenu: true, showUser: true},
        component: Gender,
        children: [
            {
                path: '/configuration/general/gender/:id',
                name: 'config_general_gender_modify',
                component: Gender,
                props: {id: null}
            },
            {
                path: '/configuration/general/gender/:id/:view',
                name: 'config_general_gender_view',
                component: Gender,
                props: {id: null, view: true}
            }
        ]
    }
];

const routes = [
    ...route,
];

export default routes;