import Vue from "vue";
import vuex from "vuex";

import createPersistedState from "vuex-persistedstate";

import Tabs from './Tabs/main';
import SubTabs from './Shared/subtab';
import Counters from './Shared/counter';
import Permission from './Shared/permission';
import Feed from './Shared/feed';
import Casuistry from './Shared/casuistry';
import List from './Shared/list';
import Set from './Shared/set';
import Delete from './Shared/delete';
import Get from './Shared/get';
import Upload from './Shared/upload';
import Login from './Login/main';

Vue.use(vuex);

export default new vuex.Store({
    modules: {
        Tabs: Tabs,
        SubTabs: SubTabs,
        Counters: Counters,
        Permission: Permission,
        Feed: Feed,
        Casuistry: Casuistry,
        List: List,
        Set: Set,
        Delete: Delete,
        Get: Get,
        Upload: Upload,
        Login: Login
    },
    plugins: [createPersistedState()]
});