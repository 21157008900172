var render = function render(){var _vm=this,_c=_vm._self._c;return _c('LoaderTemplate',{attrs:{"haveData":_vm.haveData},scopedSlots:_vm._u([{key:"content",fn:function(){return [_c('GhTableCommon',{staticStyle:{"margin-top":"10px"},attrs:{"extratable":{
                id: 'agendeds'
            },"header":_vm.header,"data":_vm.getterList['agendeds'] !== undefined && _vm.getterList['agendeds']['agendeds'] !== undefined ? _vm.getterList['agendeds']['agendeds'].data : []},scopedSlots:_vm._u([{key:"actions",fn:function({itemProp}){return [(_vm.getterList['agendeds']['agendeds'].actions.view)?[_c('GhAction',{attrs:{"dataction":{
                            id: 'action_view_patients_' + itemProp.id,
                            text: _vm.$t('view'),
                            icon: require('@/assets/gh_new_dessign/view.svg')
                        }},on:{"click":function($event){_vm.Global.windowOpen(_vm.Global.openSecondWindow('patients_patients_view', {
                            id: itemProp.id,
                            view: true,
                        }),'window_patient')}}})]:_vm._e(),(_vm.getterList['agendeds']['agendeds'].actions.confirm)?[_c('GhAction',{attrs:{"dataction":{
                            id: 'action_confirm_agendeds_' + itemProp.id,
                            text: _vm.$t('confirm'),
                            icon: require('@/assets/gh_new_dessign/tick.svg')
                        }},on:{"click":function($event){return _vm.$parent.$parent.$parent.$parent.$parent.$parent.doAction('agendeds', 'confirm', itemProp.id)}}})]:_vm._e(),(_vm.getterList['agendeds']['agendeds'].actions.nullify)?[_c('GhAction',{attrs:{"dataction":{
                            id: 'action_nullify_agendeds_' + itemProp.id,
                            text: _vm.$t('nullify'),
                            icon: require('@/assets/gh_new_dessign/denied.svg')
                        }},on:{"click":function($event){return _vm.$parent.$parent.$parent.$parent.$parent.$parent.doAction('agendeds', 'nullify', itemProp.id)}}})]:_vm._e()]}}])})]},proxy:true}])})
}
var staticRenderFns = []

export { render, staticRenderFns }