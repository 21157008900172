<template>
    <div>
        <div class="gh-row-content">
            <input type="hidden" name="data[type]" value="patients"/>
            <input type="hidden" name="data[extra_from]" value="patients"/>
            <input type="hidden" name="data[no_check]" value="true"/>
            <GhInputTextField
                :datalabel="{
                    text: $t('id_number'),
                    style: 'width: 22px; text-align: right;',
                    class: 'T15_b'
                }"
                :datainput="{
                    required: false,
                    id: 'data_id_number',
                    name: 'data[id_number]',
                    style: 'width: 100px',
                    value: '',
                    type: 'text'
                }"
            />
            <GhInputTextField
                :datalabel="{
                    text: $t('name'),
                    style: 'width: 100px; text-align: right;',
                    class: 'T15_b'
                }"
                :datainput="{
                    required: false,
                    id: 'data_name',
                    name: 'data[name]',
                    style: 'width: 200px',
                    value: '',
                    type: 'text'
                }"
            />
            <GhInputTextField
                :datalabel="{
                    text: $t('surname'),
                    style: 'width: 100px; text-align: right;',
                    class: 'T15_b'
                }"
                :datainput="{
                    required: false,
                    id: 'data_surname',
                    name: 'data[surname]',
                    style: 'width: 200px',
                    value: '',
                    type: 'text'
                }"
            />
            <GhCalendar
                :datalabel="{
                    id: 'label_data_date_of_birth',
                    text: $t('dob'),
                    style: 'width: 100px; min-width: 100px; text-align: right; margin-top: 2px;'
                }"
                :datainput="{
                    id: 'data_date_of_birth',
                    name: 'data[dob]',
                    value: '',
                    disabled_date: 'after',
                    range: new Date().getDate() + '/' + (new Date().getMonth() + 1 < 10 ? '0' : '') + (new Date().getMonth() + 1)  + '/' + new Date().getFullYear(),
                    required: false
                }"
            />
        </div>
        <div class="gh-row-content">
            <GhChecks
                v-bind:datainput="{
                    id: 'radio_and',
                    value: 'and',
                    type: 'radio',
                    name: 'data[logic]',
                    style: 'margin-left: 30px;',
                    selected: true
                }"
                v-bind:datalabel="{
                    style: '',
                    class: 'T15 input_label',
                    id: 'label_and',
                    text: $t('meet_all_search_criteria_entered')
                }"
            />
            <GhChecks
                v-bind:datainput="{
                    id: 'radio_or',
                    value: 'or',
                    type: 'radio',
                    style: 'margin-left: 30px;',
                    name: 'data[logic]',
                }"
                v-bind:datalabel="{
                    style: 'margin-left: 4px;',
                    class: 'T15 input_label',
                    id: 'label_or',
                    text: $t('meet_any_of_the_search_criteria_entered')
                }"
            />
        </div>
    </div>
</template>

<script>
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhCalendar from "fe-gh-input-calendar-lib";
    import GhChecks from "fe-gh-checks-lib";

    export default {
        name: 'filter_form',
        components: {
            GhInputTextField,
            GhCalendar,
            GhChecks
        }
    }
</script>