<template>
    <LoaderTemplate :haveData="haveData">
        <template v-slot:content>
            <form id="form_data">
                <div class="gh-content-global">
                    <CodeNameDescription
                        :code="{
                            show: false,
                            required: false,
                            value: ''
                        }"
                        :name="{
                            show: true,
                            required: true,
                            value: getterGet.support !== undefined && getterGet.support['assignments'] !== undefined ? getterGet.support['assignments'].name : ''
                        }"
                        :description="{
                            show: true,
                            required: false,
                            value: getterGet.support !== undefined && getterGet.support['assignments'] !== undefined ? getterGet.support['assignments'].description : ''
                        }"
                    />
                    <div class="gh-row-content">
                        <template v-if="!$root._route.params.view">
                            <GhInputTextField
                                :datalabel="{
                                    text: $t('email') + '*',
                                    style: 'width: 150px; text-align: right;',
                                    class: 'T15_b'
                                }"
                                :datainput="{
                                    required: true,
                                    id: 'data_email',
                                    name: 'data[email]',
                                    style: 'width: 300px;',
                                    value: getterGet.support !== undefined && getterGet.support['assignments'] !== undefined ? getterGet.support['assignments'].email : '',
                                    type: 'text'
                                }"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('email') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="width: 155px; margin-right: 10px;">{{getterGet.support !== undefined && getterGet.support['assignments'] !== undefined ? getterGet.support['assignments'].email : ''}}</label>
                        </template>
                    </div>
                    <div class="gh-row-content">
                        <template v-if="!$root._route.params.view">
                            <GhSearchWindow
                                :dataBlock="{
                                    label: {
                                        style: 'margin-right: 10px; width: 150px; min-width: 150px; text-align: right;'
                                    },
                                    ResultSearch: 'search',
                                    nameStore: 'Feed',
                                    imagelocation: require('@/assets/gh_new_dessign/floating.svg'),
                                    id: 'users',
                                    text: $t('users'),
                                    textlocation: $t('add') + ' ' + $t('users'),
                                    textuserselected: $t('selected_users'),
                                    actionresult: 'loadSearch',
                                    nameResult: 'data[users]',
                                    supportPermision: false,
                                    loader: require('@/assets/gh_new_dessign/ajax-loader_blue.gif')
                                }"
                                :mainSelector="{
                                    name: 'users_search',
                                    formId: 'data',
                                    actionsearch: 'setSearch'
                                }"
                                :tableResolt="{
                                    header: header,
                                    data: []
                                }"
                                :tableSelected="{
                                    headersummary: summary,
                                    selected: getterGet.support !== undefined && getterGet.support['assignments'] !== undefined ? getterGet.support['assignments'].users : []
                                }"
                            />
                        </template>
                        <template v-else>
                            <label class="gh_text_field-label T15" style="margin-right: 10px; width: 150px; min-width: 150px; text-align: right;">{{$t('staff') + ':'}}</label>
                            <label class="gh_text_field-label T15_b" style="margin-right: 10px;">{{''}}</label>
                        </template>
                    </div>
                </div>
            </form>
        </template>
    </LoaderTemplate>
</template>

<script>
    import LoaderTemplate from '@/view/Shared/Loader/loader.vue';
    import CodeNameDescription from '@/view/Shared/Form/code_name_description.vue';
    import GhInputTextField from "fe-gh-inputtextfield-lib";
    import GhSearchWindow from "fe-gh-float-window-lib";

    import {mapGetters} from "vuex";

    export default {
        name: 'form_category',
        components: {
            LoaderTemplate,
            CodeNameDescription,
            GhInputTextField,
            GhSearchWindow
        },
        computed: {
            ...mapGetters(['getterGet', 'getterFeed'])
        },
        data(){
            return {
                header: [
                    {text: '', field: 'check', checkall: true, typeCheck: 'checkbox', sorting: false, style: 'min-width: 16px; width: 16px;'},
                    {text: 'code', field: 'code', sorting: true, typeSort: 'code', style: 'min-width: 100px; max-width: 100px; width: 100px;'},
                    {text: 'name', field: 'name', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 20%;'},
                    {text: 'surname', field: 'surname', sorting: true, typeSort: 'string', style: 'min-width: 100px; max-width: 100px; width: 80%;'},
                ],
                summary: {
                    0: 'name',
                    1: 'surname'
                },
                haveData: false
            }
        },
        async beforeDestroy(){
            await this.$store.dispatch('destroyGetByType', {
                from: 'support'
            }, {root: true});
        },
        async beforeMount(){
            if(this.$root._route.params.id !== undefined) {
                await this.$store.dispatch('getByType', {
                    from: 'support',
                    type: 'assignments',
                    id: this.$root._route.params.id
                }, {root: true});
            }

            this.$nextTick(() => {
                this.haveData = true;
                this.$parent.$parent.$parent.haveData = this.haveData;
            });
        }
    }
</script>