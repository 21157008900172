import Confirm from '@/view/Advertisements/Confirm/main.vue';

const route = [
    {
        path: '/advertisements/confirm',
        name: 'advertisements_confirm',
        props: {showMenu: true, showUser: true},
        component: Confirm
    }
];

const routes = [
    ...route
];

export default routes;